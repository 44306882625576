import * as React from 'react';
import { observer } from 'mobx-react-lite';
import {
  FilterTabs,
  CommonTable,
  ExportProjectsButton,
  SlimTabs
} from 'components';
import { Box } from 'grommet';
import { useStores } from 'stores';
import { ArchivePageContainer } from './components/ArchivePageContainer';
import { ArchivePollTableItem } from './components/ArchivePollTableItem';
import { PollingType } from 'pages/Organizer/tabs';
import { PageTitle, BackButton } from './components/styled';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui';
import { WideFilter, CompactFilter } from './components/Filters';
import styled from 'styled-components';

interface ArchivePollsPageProps {}

export const ArchivePollsPage = observer<ArchivePollsPageProps>(
  function ArchivePollsPage() {
    const { archivePolls, user } = useStores();

    const isLapropOrWider = useMediaQuery({
      minWidth: breakpoints.laptop
    });

    const isTabletOrWider = useMediaQuery({
      minWidth: breakpoints.tablet
    });

    const isOrganizer = user.isContainRoles(['REGULATOR']);

    const isBystander = !user.isContainOneOfRoles([
      'APPLICANT',
      'REGULATOR',
      'VOTER'
    ]);

    return (
      <ArchivePageContainer>
        {(isBystander || !isLapropOrWider) && <BackButton />}

        <Box
          direction="row"
          justify="between"
          margin={{
            top: isTabletOrWider ? 'xlarge' : 'medium',
            bottom: 'medium'
          }}
        >
          <PageTitle>Архив проектов</PageTitle>

          {isOrganizer && <ExportProjectsButton />}
        </Box>

        <FiltersContainer gap="medium">
          {isTabletOrWider ? <WideFilter /> : <CompactFilter />}

          <FilterTabs
            style={{ margin: '6px 0' }}
            store={archivePolls}
            filterField="subjectType"
            component={SlimTabs as any}
            tabs={[
              { id: PollingType.district, text: 'Муниципальные районы' },
              { id: PollingType.schoolGroup, text: 'Школьные группы' }
            ]}
          />
        </FiltersContainer>

        <CommonTable
          store={archivePolls}
          columns={[]}
          customItem={{ render: ArchivePollTableItem }}
        />
      </ArchivePageContainer>
    );
  }
);

const FiltersContainer = styled(Box)`
  flex-direction: column-reverse;

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }
`;
