import * as React from 'react';
import { Text, Icon } from '@we-ui-components/base';
import { ViolationFileInputProps } from './';
import { Box } from 'grommet';
import styled from 'styled-components';

interface ViolationFileInputListProps extends ViolationFileInputProps {}

const IMAGE_TYPES = ['.jpg', '.jpeg', '.png', '.svg'];

export function ViolationFileInputList({
  value,
  onChange,
  withPreview
}: ViolationFileInputListProps) {
  if (value.length === 0) return null;

  const RenderedComponent = withPreview ? PreviewItem : TextItem;

  return (
    <Box
      direction={withPreview ? 'row' : 'column'}
      wrap={withPreview}
      margin={{ top: '12px' }}
      gap={withPreview ? 'small' : '0'}
    >
      {value.map((file, index) => (
        <RenderedComponent
          key={file.name + index}
          index={index}
          name={file.name}
          onChange={onChange}
          value={file}
          values={value}
        />
      ))}
    </Box>
  );
}

interface ITextItem {
  index: number;
  name: string;
  value: File;
  values: File[];
  onChange: (value: any) => void;
}

const TextItem = (props: ITextItem) => {
  const { index, name, values, onChange } = props;

  return (
    <Box
      key={name + index}
      direction="row"
      justify="between"
      align="start"
      width="100%"
    >
      <Text size="small">{name}</Text>

      <Icon
        glyph="Trash"
        size="xsmall"
        color="Basic500"
        hoverColor="Red500"
        onClick={() => {
          onChange(values.filter((_, index2) => index !== index2));
        }}
      />
    </Box>
  );
};

const PreviewItem = (props: ITextItem) => {
  const { index, name, value, values, onChange } = props;
  const fileExt = name.split('.').slice(-1)[0];
  const isDocument = !IMAGE_TYPES.includes(`.${fileExt.toLowerCase()}`);

  return (
    <PreviewBox direction="column" justify="start" align="start">
      {isDocument ? (
        <PreviewDoc justify="center" align="center" gap="xsmall">
          <Icon glyph="Document2" size="46" />
          {fileExt.toUpperCase()}
        </PreviewDoc>
      ) : (
        <PreviewImg src={fileToBase64(value)} alt={name} />
      )}
      <Icon
        glyph="Trash"
        size="xsmall"
        color="Basic500"
        hoverColor="Red500"
        onClick={() => {
          onChange(values.filter((_, index2) => index !== index2));
        }}
        style={{ zIndex: 1, position: 'absolute', right: '8px', top: '8px' }}
      />
      <Text
        size="small"
        margin={{ top: 'xsmall', bottom: 'small' }}
        style={{
          bottom: '8px',
          left: '8px',
          width: '130px',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {name}
      </Text>
    </PreviewBox>
  );
};

function fileToBase64(file: File) {
  return URL.createObjectURL(file); // convert to base64 string
}

const PreviewDoc = styled(Box)`
  position: relative;
  overflow: hidden;
  width: 130px;
  height: 130px;
  border: 1px solid #8d8d8d;
  border-radius: 4px;
`;

const PreviewBox = styled(Box)`
  position: relative;
  overflow: hidden;
`;

const PreviewImg = styled.img`
  width: 130px;
  height: 130px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
`;
