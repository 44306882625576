import { action, observable } from 'mobx';
import { NormalizedError } from 'services/api/errorHandler';
import { statusFetching } from 'we-oauth2/lib/constants/types';
import { StoreConstructor } from './core/StoreConstructor';
import * as violationService from 'services/violationService';
import { ViolationResponse } from 'interfaces';
import { TViolationAction } from 'services/violationService';
import stores from './index';
import { track } from '../services/transactionTracker';

export type TTransactionResponse = { txId: string };

const handleEntityUpdate = (id: string) => ({ txId }: TTransactionResponse) => {

  stores.votings.patchById(id, {
    // @ts-ignore
    lastUnsuccessfulTxId: txId,
    lastUnsuccessfulTxStatus: 'PENDING',
  });

  return txId;
};

export class ActiveViolationStore extends StoreConstructor {
  @observable violation: ViolationResponse;
  @observable fetchStatus: statusFetching = 'init';
  @observable fetchError: NormalizedError;

  @observable isPending: boolean = true;

  @action.bound
  setPending = (id: string) => (lastUnsuccessfulTxId: string) => {
    this.isPending = true;

    track(lastUnsuccessfulTxId, async () => {
      if (id === this.violation.id) {
        await this.reloadLastViolation();
        this.isPending = false;
      }
    });
  };

  @action.bound
  public reloadLastViolation(): Promise<any> {
    return this.getViolation(this.violation.id);
  }

  @action.bound
  getViolation(id: string) {
    return this.statusHandler(
      () =>
        violationService.getViolation(id).then(res => {
          let transactionPending = !!res.lastUnsuccessfulTxId;
          const isPending = () => transactionPending;

          const isSameProcess = () => res.id === this.violation.id;

          this.violation = res;
          this.isPending = isPending();

          if (isPending()) {
            if (transactionPending) {
              track(res.lastUnsuccessfulTxId, async () => {
                await this.reloadLastViolation();
                transactionPending = false;

                if (isSameProcess() && !isPending()) {
                  this.isPending = false;
                }
              });
            }
          }

          return Promise.resolve(res);
        }),
      'fetchStatus',
      'fetchError',
      async result => {
        // this.violation = result;
      },
    );
  }

  callViolationAction(id: string, action: TViolationAction, data?: any) {
    return violationService
      .callViolationAction(id, action, data)
      .then(handleEntityUpdate(id))
      .then(this.setPending(id));
  }

  @action.bound
  clear() {
    this.violation = null;
    this.fetchStatus = 'init';
    this.fetchError = null;
  }
}
