import React from 'react';
import { observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Box } from 'grommet';
import { Text } from '@we-ui-components/base';
import { TextInput } from '@we-ui-components/base';

interface IProps {
  options: any[];
  onChange: (value: string, filteredOptions: any[]) => void;
  searchString: string;
  searchPlaceholder: string;
  checkedOptionsLength: number;
}

@observer
export class FilterSearchArea extends React.Component<IProps> {
  filteredOptions(value) {
    const { options } = this.props;
    if (!value) {
      return [];
    }

    return options.filter(
      option => option.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
  }

  onChange = value => {
    this.props.onChange(value, this.filteredOptions(value));
  };

  render() {
    const {
      searchPlaceholder,
      searchString,
      checkedOptionsLength
    } = this.props;
    return (
      <Box>
        <Box direction="row" gap="medium" margin={{ bottom: 'small' }}>
          <Text bold>{this.props.options.length} МО в списке</Text>
          <Text bold>{checkedOptionsLength} выбрано</Text>
        </Box>
        <TextInput
          renderLeft={() => <div>icon</div>}
          placeholder={searchPlaceholder}
          onChange={this.onChange}
          size="xxlarge"
          value={searchString}
        />
      </Box>
    );
  }
}
