import { ISelectOption } from '@we-ui-components/base/dist/Components/src';

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export function downloadFile(
  anchorRef: HTMLAnchorElement,
  url: string,
  name: string
) {
  anchorRef.href = url;
  anchorRef.setAttribute('download', name);
  anchorRef.click();
}

export const download = (url: string, filename = '') => {
  const ahref = document.createElement('a');
  document.body.appendChild(ahref);
  downloadFile(ahref, url, filename);
  document.body.removeChild(ahref);
};

export const getFilesBase64 = (
  files: File[],
  cb: (filesWithBase64: IFileWithBase64[]) => void
) => {
  const result: IFileWithBase64[] = [];

  files.forEach((file, index) =>
    getFileBase64(file, base64 => {
      result[index] = { file, base64 };

      if (result.length === files.length) {
        cb(result);
      }
    })
  );
};

export const getFilesBase64Promise = (
  files: File[]
): Promise<IFileWithBase64[]> =>
  Promise.all(
    files.map(async file => ({
      file,
      base64: await getFileBase64Promise(file)
    }))
  );

export const getFileBase64 = (file: File, cb: (base64: string) => void) =>
  onReadAsBase64(file)(cb);

const getFileBase64Promise = (file: File): Promise<string> =>
  new Promise(resolve => onReadAsBase64(file)(resolve));

const onReadAsBase64 = (file: File) => (cb: (base64: string) => void) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => cb(reader.result as string);
};

interface IFileWithBase64 {
  file: File;
  base64: string;
}

export function getCurrentYear() {
  const now = new Date();
  return now.getFullYear();
}

export function getRange(start: number, end: number) {
  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
}

export function generateSimpleOptions(
  data: Array<string | number>
): ISelectOption[] {
  return data.map(it => ({
    value: it,
    text: it
  }));
}
