import * as React from 'react';
import { useEffect, useState } from 'react';
import { Icon, Text, Title } from '@we-ui-components/base';
import { Anchor, Box } from 'grommet';
import { useStores } from 'stores';
import { Form, Input, isRequired, MobxForm } from '@we-ui-components/form';
import { breakpoints, ButtonEx, Error as ErrorUI, Panel, Spinner2 } from 'ui';
import { observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components';
import { phoneRegister, sendConfirmCode } from 'services/userService';
import { api } from 'services/api';
import { PhoneInput } from '../Registration/PhoneInput';
import { oneOfLengths } from 'utils/validators';
import { decOfNum, hidePhone } from './helpers';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'utils/hooks';
import { ENDPOINTS } from 'services/endpoints';
import { Recaptcha } from './Recaptcha';
import { AsyncScript } from './AsyncScript';

interface ILoginPhone {
  phone: string;
  code: string;
}

enum LOGIN_STEPS {
  INPUT_PHONE = 'INPUT_PHONE',
  CONFIRM_CODE = 'CONFIRM_CODE'
}

const INVALID_PHONE_REQUEST =
  'There is already an active phone code for number ';

const ERROR_TRANSLATE = {
  [INVALID_PHONE_REQUEST]: 'Срок действия ранее направленного СМС-кода не истек'
};

function transformServerError(e) {
  const isProcessable = e.detail && e.instance && e.status;

  if (!isProcessable) {
    return e;
  }

  function isInvalidPhoneRequest() {
    return (
      e.detail.indexOf('There is already an active phone code for number') >= 0
    );
  }

  if (isInvalidPhoneRequest()) {
    e.detail = ERROR_TRANSLATE[INVALID_PHONE_REQUEST];
  }

  return e;
}

let resetFn = null;

export const LoginVoterPage = observer(function LoginVoterPage() {
  const { actionModals, user, routing } = useStores();
  const theme = useTheme();
  const timer = useCountdown();

  const refreshSMS = useLocalStore(() => {
    return {
      active: false
    };
  });

  const captcha = useLocalStore(() => {
    return {
      response: null,
      touched: false,

      isValid() {
        return !!captcha.response;
      }
    };
  });

  const [step, setStep] = React.useState<LOGIN_STEPS>(LOGIN_STEPS.INPUT_PHONE);
  const [isLoading, setIsLoading] = React.useState(false);
  const [codeId, setCodeId] = React.useState('');
  const [error, setError] = React.useState('');

  const data = useLocalStore(() => ({ ...DEFAULT_DATA }));

  const formRef = React.useRef<MobxForm>();

  const captchaRef = React.useRef(null);

  function resetCaptcha() {
    captcha.touched = false;
    captcha.response = null;
  }

  React.useEffect(() => {
    resetCaptcha();

    refreshSMS.active = false;
  }, [step]);

  React.useEffect(() => {
    const enterPressListener = (e: KeyboardEvent) => {
      if (e.key === 'Enter') onSubmit(step);
    };

    window.addEventListener('keyup', enterPressListener);

    return () => window.removeEventListener('keyup', enterPressListener);
  }, [step]);

  const showHelpModal = () => {
    actionModals.open(
      () => (
        <Box pad={{ bottom: 'large' }}>
          <Text style={{ lineHeight: '22px' }}>
            Проверьте корректность указанного номера телефона. Если номер указан
            корректно, обратитесь в службу технической поддержки.
          </Text>
        </Box>
      ),
      {
        title: 'Не приходит код подтверждения?',
        width: isLaptopOrWider ? '545px' : 'auto',
        modalStyle: {
          maxWidth: isTabletOrWider ? '545px' : '100vw'
        }
      }
    );
  };

  const onSubmit = async (action = step) => {
    const isInputPhone = step === LOGIN_STEPS.INPUT_PHONE;

    if (isInputPhone && !captcha.touched) {
      setError('Пожалуйста, пройдите проверку что вы не робот');

      return;
    }

    if (isInputPhone && !captcha.isValid()) {
      setError('Убедитесь, что вы не робот');
      return;
    }


    try {
      if (action == step) {
        await formRef.current.validateFields();
      }
    } catch (e) {
      return;
    }

    setError('');
    setIsLoading(true);

    try {
      if (action === LOGIN_STEPS.INPUT_PHONE) {
        const response = await phoneRegister(data.phone, captcha.response);
        setCodeId(response.codeId);
        setStep(LOGIN_STEPS.CONFIRM_CODE);
        const activeUntil = new Date(Date.now() + (response.ttl ? Number(response.ttl) : 90) * 1000)
        timer.setFinishDate(activeUntil);
        refreshSMS.active = false;
      }

      if (action === LOGIN_STEPS.CONFIRM_CODE) {
        const token = await sendConfirmCode({
          grant_type: 'otp',
          codeId: codeId,
          code: data.code,
          scope: ''
        });

        // @ts-ignore
        api.storage.saveToken(token);
        user.init();
      }
    } catch (e) {
      const errorUnauthorizedOrInvalid =
        e.error === 'unauthorized_client' || e.error === 'invalid_client'
          ? 'Введен неверный СМС-код'
          : null;
      const errorCodeInactive =
        e.error_description &&
        e.error_description.indexOf('Active code not found for id') >= 0
          ? 'Срок действия ранее направленного СМС-кода истек'
          : null;
      const errorWrongPhoneFormat =
        e.detail &&
        e.detail === 'Wrong format of the phone number'
          ? 'Некорректный формат номера телефона'
          : null;

      transformServerError(e);

      const msg =
        errorWrongPhoneFormat ||
        e.detail ||
        e.title ||
        errorCodeInactive ||
        errorUnauthorizedOrInvalid ||
        e.error_description ||
        e.error ||
        'Ошибка сервера';
      setError(msg);

      resetCaptcha();

      captchaRef.current && captchaRef.current.reset();
    }

    setIsLoading(false);
  };

  const resetForm = () => {
    for (const prop in data) {
      if (prop in DEFAULT_DATA) {
        data[prop] = DEFAULT_DATA[prop];
      } else {
        data[prop] = null;
        delete data[prop];
      }
    }

    setIsLoading(false);
    setCodeId('');
    setError('');
    setStep(LOGIN_STEPS.INPUT_PHONE);
  };

  const isLaptopOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.laptopL
  });
  const isTabletOrWider = useMediaQuery({ minDeviceWidth: breakpoints.tablet });

  const openFileInNewWindow = () => {
    window.open(ENDPOINTS.getConfidentialFile({ inline: 'true' }), '_blank');
  };

  return (
    <PageContainer>
      <Box
        align="center"
        justify="center"
        style={{
          margin: 'auto',
          marginTop: isLaptopOrWider ? 'auto' : '44px'
        }}
        direction="column"
        gap="30px"
      >
        {step === LOGIN_STEPS.CONFIRM_CODE || !isLaptopOrWider ? (
          <Box
            direction="row"
            fill={true}
            justify="start"
            onClick={() => {
              if (step === LOGIN_STEPS.CONFIRM_CODE) {
                resetForm();
              } else {
                routing.goBack();
              }
            }}
          >
            <Icon glyph="Left" color={theme.palette.Purple}/>
            <Text>Назад</Text>
          </Box>
        ) : null}

        <Panel
          align="center"
          style={{
            maxWidth: isLaptopOrWider ? '544px' : '464px'
          }}
          width={isLaptopOrWider ? '544px' : 'calc(100% - 16px)'}
          pad={isLaptopOrWider ? '32px' : '24px'}
          margin={{ horizontal: 'auto' }}
          gap={isLaptopOrWider ? '32px' : '24px'}
        >
          <Title
            size={isTabletOrWider ? '24px' : '18px'}
            style={{
              lineHeight: step === LOGIN_STEPS.INPUT_PHONE ? '36px' : '32px'
            }}
            color="Black"
            bold
          >
            {step === LOGIN_STEPS.INPUT_PHONE
              ? 'Добро пожаловать в систему инициативного голосования Волгоградской области'
              : `На номер ${hidePhone(
                data.phone
              )} отправлено смс с кодом подтверждения`}
          </Title>

          <Form ref={formRef} data={data} {...({} as any)}>
            {step === LOGIN_STEPS.INPUT_PHONE ? (
              <PhoneInput
                placeholder="Введите телефон"
                onChange={() => {
                  setError('');
                }}
                name="phone"
                rules={[
                  isRequired,
                  oneOfLengths([12], 'Введите корректный номер')
                ]}
              />
            ) : null}

            {step === LOGIN_STEPS.CONFIRM_CODE ? (
              <Input
                placeholder="Код из СМС"
                name="code"
                rules={[isRequired]}
                onChange={() => {
                  setError('');
                }}
              />
            ) : null}
          </Form>

          {(step === LOGIN_STEPS.INPUT_PHONE || refreshSMS.active) && (
            <AsyncScript
              url="https://www.google.com/recaptcha/api.js"
              globalName="grecaptcha"
              render={grecaptcha => {
                return (
                  <Recaptcha
                    grecaptcha={grecaptcha}
                    ref={captchaRef}
                    onResponse={response => {
                      captcha.response = response;
                      captcha.touched = true;
                      refreshSMS.active && onSubmit(LOGIN_STEPS.INPUT_PHONE);
                    }}
                    {...({} as any)}
                  />
                );
              }}
            />
          )}

          <StyledButtonEx
            fontSize={isLaptopOrWider ? '16px' : '13px'}
            style={{
              alignItems: 'center'
            }}
            spinnerRender={
              <Spinner2
                height="16px"
                width="16px"
                color="white"
                style={{ marginRight: 5 }}
              />
            }
            isLoading={isLoading}
            onClick={() => {
              onSubmit(step);
            }}
          >
            {step === LOGIN_STEPS.INPUT_PHONE ? 'Участвовать' : 'Войти'}
          </StyledButtonEx>

          {step === LOGIN_STEPS.INPUT_PHONE && (
            <Box>
              <Text size="xsmall">
                Нажимая на кнопку "Участвовать" вы соглашаетесь с
                <Anchor
                  onClick={() => openFileInNewWindow()}
                  color={theme.palette.Purple}
                >
                  {' '}
                  Политикой конфиденциальности
                </Anchor>
              </Text>
            </Box>
          )}

          {error && <ErrorUI error={error} style={{ padding: 0 }}/>}

          {step === LOGIN_STEPS.CONFIRM_CODE ? (
            <Box
              margin={{ top: 'small' }}
              direction="column"
              align={isLaptopOrWider ? 'start' : 'center'}
              fill={true}
              gap="15px"
            >
              {timer.timeLeft <= 0 ? (
                <Box
                  onClick={() => {
                    refreshSMS.active = true;
                  }}
                  direction="row"
                >
                  <Text size="13px" color={theme.palette.Purple}>
                    Отправить SMS-код повторно
                  </Text>
                </Box>
              ) : (
                <Box direction="row">
                  <Text size="13px" color={theme.palette.BlackText}>
                    Срок действия SMS-кода{' '}
                    {decOfNum(timer.timeLeft, ['секунда', 'секунды', 'секунд'])}
                  </Text>
                </Box>
              )}
              <Box direction="row" onClick={() => showHelpModal()}>
                <Text size="13px" color={theme.palette.Purple}>
                  Не приходит код подтверждения?
                </Text>
              </Box>
            </Box>
          ) : null}
        </Panel>
      </Box>
    </PageContainer>
  );
});

const StyledButtonEx = styled(ButtonEx)`
  width: 100%;

  button {
    width: 100%;
    height: 48px;
  }
`;

const PageContainer = styled(Box)`
  min-height: 100vh;
  min-width: 100vw;

  background-color: ${props => props.theme.palette.LightGrey};
  background-size: 100%;
  background-repeat: no-repeat;
`;

const DEFAULT_DATA: ILoginPhone = {
  phone: '',
  code: ''
};

const ms2s = (ts: number) => {
  return Math.trunc(ts / 1000)
}

const useDate = (updateInterval = 1000) => {
  const [date, setDate] = useState(new Date())
  useEffect(() => {
    const i = setInterval(() => {
      setDate(new Date())
    }, updateInterval)
    return () => clearInterval(i)
  }, [updateInterval])
  return date
}

const useCountdown = (until?: Date) => {
  const [finishDate, setFinishDate] = useState(until)
  const now = useDate();

  return {
    setFinishDate,
    timeLeft: finishDate ? ms2s(finishDate.getTime() - now.getTime()) : 0
  }
}
