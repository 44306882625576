import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Title } from '@we-ui-components/base';
import styled from 'styled-components';
import { Box } from 'grommet';
import { SearchInput } from 'ui';
import { FilterTabs } from 'components';
import { Table } from '@we-ui-components/rc-table';
import { useStores } from 'stores';
import { getColumns } from './getColumns';
import './UsersPage.css';
import { TablePagination } from './components/TablePagination';
import { IconButton } from 'ui/IconButton';
import { PlusIconSVG } from 'ui/Icons';
import { CreateUserModal } from './modals/CreateUserModal';
import { INewUser } from 'interfaces';
import { useLocalStore } from "mobx-react";
import {useEffect} from "react";
import { Routes } from "../../constants";

interface UsersPageProps {}

export const UsersPage = observer<UsersPageProps>(function UsersPage(props) {
  const { userList, actionModals } = useStores();

  const onSearch = React.useCallback(
    next => {
      userList.updateSearch(next);
    },
    [userList]
  );

  const handleCreateUserClick = () => {
    actionModals.open(CreateUserModal, {
      width: '931px',
      onApply: async (user: INewUser) => {
        await userList.createUser(user);
        await userList.fetch();
        actionModals.open(
          () => (
            <Box
              align="end"
              style={{ flex: '1 1 100%' }}
              pad={{ bottom: 'xlarge' }}
            >
              <Button
                onClick={() => {
                  actionModals.closeLastModal();
                }}
              >
                Закрыть
              </Button>
            </Box>
          ),
          {
            title: 'Пользователь создан',
            width: '545px'
          }
        );
      },
      applyText: () => <span>Создать</span>,
      closeText: () => <span>Отменить</span>,
      noValidation: true
    });
  };

  return (
    <PageContainer className="users-page-container">
      <Box direction="row" margin={{ bottom: '20px' }} align="center">
        <Title size="small" bold>
          Пользователи системы
        </Title>

        <IconButton
          onClick={handleCreateUserClick}
          renderIcon={() => <PlusIconSVG />}
          margin={{ left: '24px' }}
        >
          Добавить пользователя
        </IconButton>
      </Box>

      <SearchInput
        placeholder="Поиск"
        style={{ width: '650px' }}
        value={userList.search}
        onChange={onSearch}
      />

      <FilterTabs
        store={userList}
        style={{ margin: '24px 0' }}
        filterField="businessRoles"
        tabs={[
          { id: undefined, text: 'Все' },
          { id: 'REGULATOR', text: 'Организаторы' },
          { id: 'APPLICANT', text: 'Заявители' }
        ]}
      />

      <Table
        data={userList.data}
        columns={getColumns()}
        isPending={userList.isPending}
        dataLayerConfig={userList.dataFlow}
        onChangeDataFlow={(props: any) => userList.onChangeDataFlow(props)}
        paginationComponent={TablePagination}
      />
    </PageContainer>
  );
});

const PageContainer = styled(Box)`
  padding: 40px 64px;
`;
