import React, { Component, Children, cloneElement } from 'react';

export class FileThemeTreeNodeRenderer extends Component<any> {
  render() {
    const {
      children,
      listIndex,
      swapFrom,
      swapLength,
      swapDepth,
      scaffoldBlockPxWidth,
      lowerSiblingCounts,
      connectDropTarget,
      isOver,
      draggedNode,
      canDrop,
      treeIndex,
      treeId, // Delete from otherProps
      getPrevRow, // Delete from otherProps
      node, // Delete from otherProps
      path, // Delete from otherProps
      rowDirection,
      ...otherProps
    } = this.props;

    const scaffoldBlockCount = lowerSiblingCounts.length - 1;

    return connectDropTarget(
      <div
        {...{
          ...otherProps,
          style: {
            ...otherProps.style,
            paddingLeft: scaffoldBlockPxWidth * scaffoldBlockCount
          }
        }}
      >
        {Children.map(children, (child: any) => {
          // console.log(child.props.node.id);
          return cloneElement(child, {
            isOver,
            canDrop,
            draggedNode,
            lowerSiblingCounts,
            listIndex,
            swapFrom,
            swapLength,
            swapDepth
          });
        })}
      </div>
    );
  }
}

// import React, { Component, Children, cloneElement } from 'react';
//
// const styles: any = {};
//
// export class treeNodeRenderer extends Component<any> {
//   state: any = {};
//   bound: any;
//
//   render() {
//     const {
//       children,
//       swapFrom,
//       swapLength,
//       swapDepth,
//       scaffoldBlockPxWidth,
//       lowerSiblingCounts,
//       connectDropTarget,
//       isOver,
//       draggedNode,
//       canDrop,
//       treeIndex,
//       treeId,
//       listIndex,
//       rowDirection,
//       getPrevRow, // Delete from otherProps
//       node, // Delete from otherProps
//       path, // Delete from otherProps
//       ...otherProps
//     } = this.props;
//     // Construct the scaffold representing the structure of the tree
//     const scaffoldBlockCount = lowerSiblingCounts.length - 1;
//
//     return (
//       <div
//         {...otherProps}
//         onFocus={() => {}}
//         className={
//           'rstcustom__node' +
//           (this.state.highlight ? ` ${styles.highlight}` : '')
//         }
//         style={{
//           width: '50%',
//           // whiteSpace: 'nowrap',
//           position: 'relative',
//           minWidth: '100%',
//           overflow: 'hidden',
//           height: 62
//         }}
//       >
//         <div
//           className={'rstcustom__nodeContent'}
//           style={{
//             paddingLeft: scaffoldBlockPxWidth * scaffoldBlockCount,
//             position: 'absolute',
//             top: 0,
//             bottom: 0,
//             width: '100%'
//           }}
//         >
//           {Children.map(children, (child: any) =>
//             cloneElement(child, {
//               isOver,
//               canDrop,
//               draggedNode
//             })
//           )}
//         </div>
//       </div>
//     );
//   }
// }
