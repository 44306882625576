import * as React from 'react';
import { Title, Text } from '@we-ui-components/base';
import { Box } from 'grommet';
import { IStores } from 'stores';
import { Form, Input, MobxForm, isRequired } from '@we-ui-components/form';
import { ButtonEx, Spinner2, Panel } from 'ui';
import { inject, observer } from 'mobx-react';
import { IStyledProps } from 'themes';
import { observable } from 'mobx';
import { withTheme } from 'styled-components';

interface ILogin {
  login: string;
  password: string;
}

@inject('user')
@observer
class LoginPageClass extends React.Component<
  IStyledProps & Pick<IStores, 'user'>
> {
  defaultData: ILogin = {
    login: '',
    password: ''
  };

  @observable data: ILogin = this.defaultData;

  @observable isLoading = false;

  formRef: MobxForm;

  @observable error = '';

  componentDidMount() {
    window.addEventListener('keyup', this.enterPressListener);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.enterPressListener);
  }

  enterPressListener = (e: KeyboardEvent) => {
    if (e.key === 'Enter') this.onSubmit();
  };

  onSubmit = (event?: Event): void => {
    const { user } = this.props;
    this.formRef.validateFields().then((data: ILogin) => {
      if (event) {
        event.preventDefault();
      }

      this.isLoading = true;
      user.loginEx(data.login, data.password).then(
        () => {
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
          this.error = 'Введен неверный логин или пароль';
        }
      );
    });
  };

  render() {
    const { theme } = this.props;
    return (
      <Box
        style={{
          backgroundColor: theme.palette.LightGrey,
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat'
        }}
        height={{ min: '100vh' }}
        width={{ min: '100vw' }}
      >
        <Box
          align="center"
          justify="center"
          style={{ margin: 'auto' }}
          direction="row"
          gap="30px"
        >
          <Panel align="center" width="544px" pad="32px">
            <Box style={{ paddingBottom: 33, width: '100%' }}>
              <Box direction="row" gap="10px" align="baseline">
                <Title style={{ fontSize: '26px' }} color="Black" bold>
                  Добро пожаловать в систему инициативного голосования
                  Волгоградской области
                </Title>
              </Box>
            </Box>
            <Form
              ref={(ref: any): void => (this.formRef = ref)}
              data={this.data}
            >
              <Input
                placeholder="Введите email"
                name="login"
                rules={[isRequired]}
              />
              <Input
                placeholder="Введите пароль"
                name="password"
                style={{ marginTop: 18 }}
                type="password"
                rules={[isRequired]}
              />
            </Form>

            {this.error && (
              <Box align="center" style={{ paddingTop: '10px' }}>
                <Text size="small" color="Red">
                  {this.error}
                </Text>
              </Box>
            )}

            <ButtonEx
              style={{
                marginTop: 18,
                width: '481px',
                height: 46,
                alignItems: 'center',
                fontSize: '13px'
              }}
              spinnerRender={
                <Spinner2
                  height="16px"
                  width="16px"
                  color="white"
                  style={{ marginRight: 5 }}
                />
              }
              fontSize="18px"
              isLoading={this.isLoading}
              onClick={() => {
                this.onSubmit();
              }}
            >
              Войти
            </ButtonEx>
          </Panel>
        </Box>
      </Box>
    );
  }
}

export const LoginPage = withTheme(LoginPageClass as any);
