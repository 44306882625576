import * as React from 'react';

export function Done(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 16"
      {...props}
    >
      <path
        d="M6.5 10.813l7.063-7.094.937.937-8 8-3.719-3.719L3.687 8 6.5 10.813z"
        fill={props.fill || '#fff'}
      />
    </svg>
  );
}
