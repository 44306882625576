import { IUserInfo } from 'interfaces/apiEntities';
import { encryptMsg } from 'utils';
import { getMainKeyById, getSignKeyById, sendBulletin } from 'services/poll';
import { encode } from '@msgpack/msgpack'

export const encryptBulletin = async (mainKey: string, dimension: Array<[number, number, number]>, bulletin: number[][]) => {
  if ('BigInt' in window) {
    const { makeEncryptedBulletin } = await import('@wavesenterprise/voting-crypto/browser')
    return makeEncryptedBulletin({
      mainKey,
      weight: 1,
      bulletin,
      dimension,
    }, true)
  } else {
    const { makeEncryptedBulletin } = await import('@wavesenterprise/voting-crypto/browser-old')
    return makeEncryptedBulletin({
      mainKey,
      weight: 1,
      bulletin,
      dimension,
    }, true)
  }
}

export const convertUint8ArrayToBase64 = (bytes: Uint8Array) => {
  const output = []
  for (let i = 0, length = bytes.length; i < length; i++) {
    output.push(String.fromCharCode(bytes[i]))
  }
  return btoa(output.join(''))
}

export const vote = async (params: {
  pollId: string;
  contractId: string;
  selectedProjectNumber: number;
  projectsLength: number;
  userInfo: IUserInfo;
  password?: string;
}) => {
  const {
    pollId,
    contractId,
    selectedProjectNumber,
    projectsLength,
    userInfo,
    password
  } = params;

  if (selectedProjectNumber < 0) {
    throw new Error('Проект не выбран');
  }

  if (Number(projectsLength) === 0) {
    throw new Error('Нет активных проектов');
  }

  let response;
  let encryptedUserBulletin = '';

  const bulletinPart = [...new Array(projectsLength)].map(() => 0);

  bulletinPart[selectedProjectNumber] = 1;

  const bulletin = [bulletinPart];

  if (password) {
    encryptedUserBulletin = encryptMsg(JSON.stringify(bulletin), password);
  }

  // Извлекаем важные параметры для крипто библиотеки из голосования в переменные
  //
  const dimension = [[1, 1, projectsLength] as any];

  response = await getMainKeyById(pollId);

  const mainKey = response.mainKey;

  response = await getSignKeyById(pollId);

  const { regSign, publicKey } = response;

  const encryptedBulletin = await encryptBulletin(mainKey, dimension, bulletin);
  const serializedBulletin = convertUint8ArrayToBase64(encode(encryptedBulletin))

  // Формируем транзакцию
  const tx = {
    sender: userInfo.participantAddress, // реквизиты подписанта берем из токена
    senderPublicKey: userInfo.participantPublicKey,
    contractId,
    fee: 0, // хардкод, так и оставляем
    timestamp: Date.now(), // текущая дата
    contractVersion: 1, // хардкод, так и оставляем
    params: [
      {
        type: 'string',
        key: 'operation',
        value: 'vote' // что далем - голосуем
      },
      {
        type: 'string',
        key: 'vote',
        value: serializedBulletin // как - как зашифровали
      },
      {
        type: 'string',
        key: 'participant',
        value: JSON.stringify([userInfo.participantPublicKey, '1'])
      },
      {
        type: 'integer',
        key: 'weight',
        value: 1,
      },
      {
        type: 'string',
        key: 'regSign',
        value: regSign
      }
    ],
    version: 3, // хардкод, так и оставляем
    type: 104 // хардкод, так и оставляем
  };

  response = await sendBulletin(pollId, {
    encryptedBulletin: encryptedUserBulletin,
    signRequest: tx
  });

  return response;
};
