import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui/Responsive';
import { Section } from '../index';
import { Collapse, Title, Text } from '@we-ui-components/base';
import { useWindowSize } from '../windowWidth';
import { Box } from 'grommet';
import { Routes } from '../../../constants';
import { BackButton } from 'components/BackButton';
import { useTheme } from 'utils/hooks';
// @ts-ignore
import * as info from './info.json';
import styled from 'styled-components';

export const QuestionsAnswer = observer(() => {
  const isTabletOrWider = useMediaQuery({ minDeviceWidth: breakpoints.tablet });

  const theme = useTheme();
  const wSize = useWindowSize();

  return (
    <Section style={{ margin: isTabletOrWider ? '42px 0' : '20px 0' }}>
      <BackButton
        url={`/${Routes.landing}`}
        textColor={theme.palette.Purple}
        style={{
          border: '1px solid #d8d6ef',
          width: '105px',
          padding: '15px',
          color: `${theme.palette.Purple}`
        }}
      />
      <Title
        size={wSize}
        bold
        style={{ letterSpacing: '1px' }}
        text="Вопросы и ответы"
        margin={{ vertical: 'xlarge' }}
      />

      {info.content.map(item => {
        return (
          <BoxCollapse key={item.id.toString()}>
            <Collapse
              bordered
              header={item.title}
              isCollapsed={true}
              style={{
                padding: '17px 0',
                color: `${theme.palette.Purple}`,
                fontSize: '18px',
                fontWeight: 'bold'
              }}
            >
              <Box
                style={{ maxWidth: '750px' }}
                margin={{ horizontal: isTabletOrWider ? '22px' : 'small' }}
              >
                {item.description.map((content, i) => {
                  return (
                    <Text
                      key={i}
                      size={!isTabletOrWider ? 'xsmall' : 'medium'}
                      margin={{ vertical: 'xsmall' }}
                      style={{ lineHeight: '24px' }}
                    >
                      {JSONModifyContent(content)}
                    </Text>
                  );
                })}
              </Box>
            </Collapse>
          </BoxCollapse>
        );
      })}
      <Text
        size={!isTabletOrWider ? 'xsmall' : 'medium'}
        margin={{ vertical: 'xsmall' }}
      >
        Не нашли ответ на свой вопрос? Напишите письмо на email{' '}
        <b>support@budget4me-34.ru</b>
      </Text>
    </Section>
  );
});

const BoxCollapse = styled.div`
  svg {
    color: #3b33b0;
  }
`;

//function for add html tag in json text, example {{tag=“b” text=“hello”}}

function JSONModifyContent(text) {
  const regExpParams = /“.+?”/g;
  const regExpTextInBrackets = /(\{\{.+?\}\})/g;
  let parts = text.split(regExpTextInBrackets);

  if (parts.length) {
    for (let i = 1; i < parts.length; i += 2) {
      let params = parts[i].match(regExpParams);
      let tagIndex = parts[i].indexOf('tag');
      let contentIndex = parts[i].indexOf('text');

      if (params.length === 2 && tagIndex < contentIndex && tagIndex !== -1) {
        let strTag = params[0].slice(1, -1);
        let strText = params[1].slice(1, -1);
        parts[i] = React.createElement(`${strTag}`, { key: i }, `${strText}`);
      } else {
        return text.replace(regExpTextInBrackets, '');
      }
    }
    return parts;
  }

  return text;
}
