import * as React from 'react';
import { TRole } from 'interfaces/apiTypes';

const SUPPORTED_ROLES = ['APPLICANT', 'REGULATOR'] as const;

type SupportedRoleT = typeof SUPPORTED_ROLES extends readonly (infer T)[]
  ? T
  : never;

interface RoleIconProps {
  role: TRole;
}

export function RoleIcon({ role }: RoleIconProps) {
  const RoleIconSVG = roleToIconRenderMap[role];
  return <RoleIconSVG />;
}

const roleToIconRenderMap = {
  APPLICANT: PersonIcon,
  REGULATOR: CrownIcon
};

function CrownIcon() {
  return (
    <svg
      fill="none"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.658 2.85c.21-.101.46-.07.636.081.178.15.248.392.18.614l-2.226 7.291a.586.586 0 01-.56.414H3.313a.586.586 0 01-.561-.414L.525 3.545a.582.582 0 01.18-.614.588.588 0 01.637-.08L4.55 4.403 7.557.951a.587.587 0 01.886 0l3.008 3.454 3.207-1.554zM3.875 9.75L2.75 5.25 5 6.375l3-3.75 3 3.75 2.25-1.125-1.125 4.5h-8.25z"
        fill="#0A0606"
      />
    </svg>
  );
}

function PersonIcon() {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 10 10"
    >
      <path
        d="M1.696 6.96C2.846 6.49 3.947 6.256 5 6.256s2.144.234 3.275.702C9.425 7.407 10 8.002 10 8.743V10H0V8.743c0-.741.565-1.336 1.696-1.784zm5.058-2.691A2.393 2.393 0 015 5a2.393 2.393 0 01-1.754-.731 2.393 2.393 0 01-.731-1.754c0-.683.243-1.267.73-1.755C3.734.253 4.319 0 5 0c.682 0 1.267.253 1.754.76.488.488.731 1.072.731 1.755 0 .682-.243 1.267-.73 1.754z"
        fill="#0A0606"
      />
    </svg>
  );
}
