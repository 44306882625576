import * as React from 'react';
import { Box, BoxProps } from 'grommet';
import { CSSProperties, useEffect } from 'react';
import { observer } from 'mobx-react';
import { TFilterProps } from 'interfaces';
import { SearchInput } from 'ui';

interface IFilterSearchProps<S, T> extends BoxProps, TFilterProps<S, T> {
  style?: CSSProperties;
}

export const FilterSearch = observer(
  <S extends object, T extends string>({
    store,
    filterField,
    initialValue,
    ...rest
  }: IFilterSearchProps<S, T>) => {
    const searchText = store.filters[filterField] || initialValue;

    useEffect(() => {
      if (initialValue)
        store.onChangeDataFlow({ filters: { [filterField]: searchText } });
    }, []);

    const setSearchParams = (value: T, withDebounce = false) => {
      if (value) {
        store.onChangeDataFlow(
          { filters: { [filterField]: value } },
          { withDebounce }
        );
      } else {
        store.onChangeDataFlow({
          filters: { [filterField]: undefined }
        });
      }
    };

    return (
      <Box
        {...rest}
        onKeyDown={event => {
          if (event.key === 'Enter') setSearchParams(searchText);
        }}
      >
        <SearchInput
          size="auto"
          value={searchText}
          placeholder="Поиск"
          style={{
            background: '#efefef',
            border: 'none',
            borderRadius: '2px',
            height: '48px'
          }}
          onChange={value => setSearchParams(value, true)}
        />
      </Box>
    );
  }
);
