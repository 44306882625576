import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Form, MobxForm, Input, isRequired } from '@we-ui-components/form';

import { isTheSameAs } from 'utils';
import { passwordValidators } from './userPasswordValidators';

interface UpdateUserCredentialsModalProps {}

type FormData = {
  password: string;
  confirm_password: string;
};

export const UpdateUserCredentialsModal = observer<
  UpdateUserCredentialsModalProps
>(
  function UpdateUserCredentialsModal(props, ref) {
    const handleSubmit = async () => {
      const data: FormData = await formRef.current.validateFields();
      return data.password;
    };

    React.useImperativeHandle(ref, () => ({
      onValidate: handleSubmit
    }));

    const formData = useLocalStore(() => ({
      password: '',
      password_confirm: ''
    }));

    const formRef = React.useRef<MobxForm>();

    return (
      <Form ref={formRef} data={formData} {...({} as any)}>
        <Input
          placeholder="Новый пароль"
          name="password"
          style={{ marginTop: 18 }}
          type="password"
          rules={passwordValidators}
        />

        <Input
          placeholder="Повторите новый пароль"
          name="password_confirm"
          style={{ marginTop: 18 }}
          type="password"
          rules={[
            isRequired,
            isTheSameAs('password', 'Пароли должны совпадать!')
          ]}
        />
      </Form>
    );
  },
  {
    forwardRef: true
  }
);
