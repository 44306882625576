import * as React from 'react';
import { TextInput, TextInputProps } from '@we-ui-components/base';
import { Box } from 'grommet';
import { SearchIcon } from './Icons/CustomizedIcons';
import styled from 'styled-components';

export function SearchInput(props: TextInputProps) {
  return (
    <StyledTextInput
      renderLeft={
        <Box margin={{ left: '5px', right: '12px' }}>
          <SearchIcon size="medium" />
        </Box>
      }
      {...props}
    />
  );
}

const StyledTextInput = styled(TextInput)`
  font-weight: 500;
  font-size: 14px;

  & ~ label {
    left: 51px;
    padding: 0;
    font-weight: 500;
    font-size: 14px;

    color: ${props => props.theme.palette.DarkGray};
  }
`;
