import * as React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'ui/Responsive';

interface ArrowType {
  handleClick: (string) => void;
  direction: 'left' | 'right';
}

export const Arrow = ({ direction, handleClick }: ArrowType) => {
  return (
    <ArrowStyle
      data-direct={direction}
      onClick={() => handleClick(direction)}
    />
  );
};

const ArrowStyle = styled.span`
  position: absolute;
  z-index: 50;
  margin: 0 20px;
  border: solid blue;
  border-width: 0 4px 4px 0;
  padding: 10px;
  cursor: pointer;
  top: 40%;
  @media (max-width: ${breakpoints.laptop}) {
    margin: 0 10px;
    padding: 8px;
  }
  &[data-direct='left'] {
    left: 0;
    transform: rotate(135deg);
    &:hover {
      border: solid lightblue;
      border-width: 0 4px 4px 0;
      transition: 0.5s ease-out;
    }
  }
  &[data-direct='right'] {
    right: 0;
    transform: rotate(-45deg);
    &:hover {
      border: solid lightblue;
      border-width: 0 4px 4px 0;
      transition: 0.5s ease-out;
    }
  }
`;

export const MobileArrow = ({ direction, handleClick }: ArrowType) => {
  return (
    <MobileArrowStyle
      data-direct={direction}
      onClick={() => handleClick(direction)}
    />
  );
};

const MobileArrowStyle = styled.span`
  margin: 0 40px;
  border: solid #262626;
  border-width: 0 4px 4px 0;
  padding: 8px;
  cursor: pointer;
  &:hover {
    border: solid lightblue;
    border-width: 0 4px 4px 0;
    transition: 0.5s ease-out;
  }
  &[data-direct='left'] {
    left: 0;
    transform: rotate(135deg);
  }
  &[data-direct='right'] {
    right: 0;
    transform: rotate(-45deg);
  }
`;
