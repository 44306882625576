import * as React from 'react';
// @ts-ignore
import PhoneNumberInput from 'react-phone-number-input/input';
import { FieldWrapper, TextInput } from '@we-ui-components/base';
import { createField } from '@we-ui-components/form';
import { commonInputStyles } from './components';

interface PhoneInputProps {
  value: string;
  onChange: (value: string) => void;
}

export function PhoneInputComponent(props: PhoneInputProps) {
  return (
    <PhoneNumberInput
      placeholder="+7 999 123 45 67*"
      inputComponent={StyledTextInput}
      international
      countryCallingCodeEditable={false}
      withCountryCallingCode
      limitMaxLength={true}
      country="RU"
      {...props}
      onChange={(value = '') => props.onChange(value)}
    />
  );
}

const StyledTextInput = React.forwardRef<any, any>((props, ref) => (
  <TextInput style={commonInputStyles} ref={ref} {...props} />
));

export const PhoneInput = createField<any>({
  component: PhoneInputComponent,
  wrapper: FieldWrapper
});
