import * as React from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import { breakpoints } from 'ui';

export function ArchivePageContainer(props: BoxProps) {
  return <PageContainer {...props} />;
}

const PageContainer = styled(Box)`
  margin: 0 auto;
  width: 100%;
  max-width: 872px;
  padding: 0 9px;

  @media (min-width: ${breakpoints.laptopL}) {
    padding: 0;
  }
`;

type BoxProps = React.ComponentProps<typeof Box>;
