import * as React from 'react';

export const useClickOutside = (onClickOutside: () => void) => {
  const savedCb = React.useRef(onClickOutside);
  const target = React.useRef<HTMLDivElement>(null);

  React.useEffect((): void | (() => void) => {
    if (target) {
      const handler = (e: MouseEvent) => {
        if (!target.current.contains(e.target as Element)) {
          savedCb.current();
        }
      };

      window.addEventListener('click', handler);
      return () => window.removeEventListener('click', handler);
    }
  });

  return target;
};
