import * as React from 'react';
import { observer } from 'mobx-react';
import { useLocalStore } from 'mobx-react-lite';
import { Ref } from 'react';

type Recaptcha = {
  ready: (cb: any) => void;
  execute: (key: string, params: object) => Promise<any>;

  render: (key: any, params: object) => any;
  getResponse: (id: string) => any;
  reset: (id?: string) => any;
};

const SITE_KEY =
  (window as any).injectedEnv?.WE_ENV_GOOGLE_RECAPTCHA_KEY ||
  process.env.WE_ENV_GOOGLE_RECAPTCHA_KEY;

type RecaptchaProps = {
  onResponse: (response: string) => void;
  grecaptcha: Recaptcha;
};

export const Recaptcha: React.FC<RecaptchaProps> = observer(
  React.forwardRef(function Recaptcha(props, refFn: Ref<any>) {
    const state = useLocalStore(() => ({
      widgetId: null,

      visible: true,

      touched: false,
      response: null,

      isValid() {
        return !!this.response;
      }
    }));

    function render() {
      props.grecaptcha.ready(() => {
        state.widgetId = props.grecaptcha.render(ref.current, {
          sitekey: SITE_KEY,
          callback: () => {
            state.touched = true;
            state.response = props.grecaptcha.getResponse(state.widgetId);
            props.onResponse(state.response);
          }
        });
      });
    }

    React.useEffect(() => render(), []);

    React.useImperativeHandle(refFn, () => ({
      reset() {
        props.grecaptcha.reset();

        state.visible = true;
        state.touched = false;
        state.response = null;
      }
    }));

    const ref = React.useRef();

    return (
      <div
        style={{ display: state.visible ? 'block' : 'none' }}
        id="recaptcha-wrapper"
        ref={ref as any}
      />
    );
  })
);
