import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useCallback } from 'react';
import { Table } from '@we-ui-components/rc-table';
import { useStores } from 'stores/index';
import { renderListItem, renderListSubItem, SumVoteCount } from './ListItem';
import { SearchInput } from 'ui/SearchInput';
import { Box } from 'grommet';
import { IconButton } from 'ui/IconButton';
import { TrashIcon } from './icons';
import Scrollbar from 'react-scrollbars-custom';
import { Button, Text, Title } from '@we-ui-components/base';
import { useTheme } from 'utils/hooks';
import { Checkbox } from 'ui/Checkbox';
import { deletePolls } from 'services/pollSubjects';
import { useHistory } from 'react-router';
import { Routes } from '../../constants';
import { VOTER_DISABLED_STATUS } from './index';
import { SchoolTree } from './SchoolTree';
import { NODE_TYPE } from './SchoolTree/helpers';
import { createQS } from 'we-oauth2/lib/auth/api-utils';

export const VotingList = observer(({ listStatus }) => {
  const theme = useTheme();
  const { votings, user, actionModals } = useStores();

  const isRegulator = user.isContainOneOfRoles(['REGULATOR']);
  const isVoter = user.isContainOneOfRoles(['VOTER']);

  useEffect(() => {
    votings.init();
    //votings.fetch(); // TODO: correct fetch with first init

    return () => votings.clear();
  }, []);

  const onSearch = React.useCallback(
    next => {
      votings.updateSearch(next);
    },
    [votings]
  );

  const onChangeDataFlow = (props: any) => {
    votings.onChangeDataFlow(props);
  };

  function flushChanges() {
    listStatus.unsetDeleting();
    votings.delete.flush();
  }

  function deleteConfirmation() {
    const pollIds = votings.delete.selectedIds.map(
      id => votings.pollingList.find(p => p.id === id).pollId
    );

    deletePolls(pollIds)
      .then(() => {
        votings.fetch();
        actionModals.closeLastModal();
        flushChanges();
      })
      .catch(error => {
        actionModals.closeLastModal();

        actionModals.open(
          () => (
            <Box pad={{ bottom: 'xlarge' }}>
              <Text margin={{ bottom: 'xsmall' }}>
                {error.title || error.message}
              </Text>
              <Box align="end" style={{ flex: '1 1 100%' }}>
                <Button onClick={actionModals.closeLastModal}>Закрыть</Button>
              </Box>
            </Box>
          ),
          {
            title: 'Ошибка',
            width: '545px',
            onClose: () => Promise.resolve(flushChanges())
          }
        );
      });
  }

  const deletePollings = React.useCallback(function deletePollings() {
    actionModals.open(
      () => {
        const votingsDelete = votings.delete.selectedIds
          .map(id => votings.pollingList.find(p => p.id === id))
          .filter(Boolean)
          .map(({ name }) => name);

        return (
          <Box>
            <Text fontWeight="500">
              Вы уверены, что хотите удалить следующие голосования?
              <Box pad="xsmall">
                {votingsDelete.map(name => (
                  <Text>- {name}</Text>
                ))}
              </Box>
              Изменить результаты решения будет невозможно
            </Text>

            <Box direction="row" justify="end" margin="16px 0">
              <Button onClick={actionModals.closeLastModal}>Отменить</Button>

              <Button
                bgColor="#F0222B"
                onClick={deleteConfirmation}
                margin={{ left: '16px' }}
              >
                Удалить
              </Button>
            </Box>
          </Box>
        );
      },
      {
        title: 'Удаление голосований',
        width: '545px',
        position: 'flex-start'
      }
    );
  }, []);

  const history = useHistory();

  const onItemClick = useCallback(({ pollId, status, search }) => {
    if (isVoter && VOTER_DISABLED_STATUS.includes(status)) {
      return;
    }

    let url = `${Routes.poll}/${pollId}`
    if (search) {
      url += createQS({ search })
    }
    history.push(url);
  }, [history, votings, isVoter, VOTER_DISABLED_STATUS])

  return (
    <>
      <Box direction="row" margin={'24px 0'} wrap>
        {votings.pollingType === 'SCHOOL_GROUP' ? null : (
          <Box style={{ flex: isRegulator ? '1 0 50%' : '1 0' }}>
            <SearchInput
              style={!isRegulator ? { width: '100%' } : {}}
              borderBottom="none"
              borderRadius="2px"
              onChange={onSearch}
              value={votings.searchString}
              customPlaceholder={false}
              placeholder="Поиск"
            />
          </Box>
        )}
        {isRegulator && (
          <>
            <Box
              justify="center"
              align={
                votings.pollingType === 'SCHOOL_GROUP' ? 'start' : 'center'
              }
              flex="grow"
              basis="50%"
            >
              {listStatus.deleting ? (
                <Box
                  direction="column"
                  align="center"
                  margin={{ left: '10px' }}
                >
                  <IconButton onClick={flushChanges}>Отменить</IconButton>

                  <IconButton
                    disabled={votings.delete.selectedIds.length === 0}
                    margin={{ left: '16px', top: '8px' }}
                    renderIcon={() => <TrashIcon/>}
                    onClick={deletePollings}
                  >
                    Удалить голосований: {votings.delete.selectedIds.length}
                  </IconButton>
                </Box>
              ) : (
                <IconButton
                  renderIcon={() => <TrashIcon />}
                  onClick={() => listStatus.setDeleting()}
                >
                  Удалить голосования
                </IconButton>
              )}
            </Box>

            {listStatus.deleting && (
              <Box pad="small" direction="row" basis="100%" gap="16px">
                <Checkbox
                  checked={votings.delete.isAllSelected}
                  onChange={() => votings.delete.selectAll()}
                />

                <Title
                  size="14px"
                  color={theme.palette.BlackText}
                  style={{ lineHeight: 1.5 }}
                >
                  Выбрать все
                </Title>
              </Box>
            )}
          </>
        )}
      </Box>

      {votings.pollingType === 'SCHOOL_GROUP' ? (
        <SchoolTree
          loading={votings.isLoading}
          customItem={{
            render: observer(props =>
              props.type === NODE_TYPE.SCHOOL_GROUP
                ? (
                renderListItem({
                  withoutBorder: true,
                  params: props.polling,
                  isMoType: votings.isMOListType,
                  deleting: listStatus.deleting,
                  onClick: onItemClick,
                })
              ) : renderListSubItem({
                title: props.title,
                polling: props.polling,
                onClick: onItemClick,
              })
            )
          }}
        />
      ) : (
        <>
          <Scrollbar
            scrollTop={votings.scrollPosition}
            style={{ top: 0 }}
            trackYProps={{ style: { top: 0 } }}
            onUpdate={({ scrollTop }) => (votings.scrollPosition = scrollTop)}
          >
            <Box style={{ overflowY: 'auto' }}>
              {votings.isEmptyList && !votings.isLoading && (
                <Text> Голосований по выбранным параметрам не найдено</Text>
              )}
              <Table
                columns={[]}
                data={votings.pollingList}
                isPending={votings.isLoading}
                onChangeDataFlow={onChangeDataFlow}
                dataLayerConfig={votings.dataFlow}
                hidePagination={true}
                customItem={{
                  render: observer(props =>
                    renderListItem({
                      ...props,
                      isMoType: votings.isMOListType,
                      deleting: listStatus.deleting,
                      onClick: onItemClick
                    })
                  )
                }}
              />
            </Box>
          </Scrollbar>
          <SumVoteCount
            count={votings.pollingList.reduce(
              (sum, item) => sum + item.voteCount,
              0
            )}
            padding="16px"
          />
        </>
      )}
    </>
  );
});
