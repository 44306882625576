import { useState, useEffect } from 'react';

interface UseRequestParams<T> {
  onSuccess?: (data: T) => void;
  onError?: (error: string) => void;
  initialData?: T;
}

export function useRequest<T>(
  requestFn: () => Promise<T>,
  { onSuccess, onError, initialData }: UseRequestParams<T> = {}
) {
  const [status, setStatus] = useState<Status>('fetching');
  const [data, setData] = useState<T>(initialData);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setStatus('fetching');
    setError('');

    requestFn()
      .then(response => {
        setData(response);
        setStatus('success');

        if (typeof onSuccess === 'function') {
          onSuccess(response);
        }
      })
      .catch(error => {
        console.error(error);
        setStatus('error');
        setError(error);

        if (typeof onError === 'function') {
          onError(error);
        }
      });
  }, [requestFn, onSuccess, onError]);

  return [data, { status, error }] as const;
}

type Status = 'fetching' | 'success' | 'error';
