import * as React from 'react';
import { BoxProps, Box } from 'grommet';
import { useTheme } from 'utils/hooks';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui';
import { Text } from '@we-ui-components/base';

export const ProjectInfoBox: React.FC<BoxProps & {
  title: string;
  text: string;
  style?: React.CSSProperties;
  noWrap?: boolean;
}> = ({ title, text, noWrap, ...props }) => {
  const theme = useTheme();
  const isTabletOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.tablet
  });

  if (noWrap) {
    return (
      <Box {...props}>
        <Text
          size={isTabletOrWider ? '11px' : '14px'}
          color={theme.palette.Basic700}
        >
          {title}
        </Text>
        <pre style={{ margin: 0 }}>
          <Text
            size={isTabletOrWider ? '13px' : '16px'}
            margin={{ top: '8px' }}
            style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          >
            {text}
          </Text>
        </pre>
      </Box>
    );
  }

  return (
    <Box {...props}>
      <Text
        size={isTabletOrWider ? '11px' : '14px'}
        color={theme.palette.Basic700}
      >
        {title}
      </Text>
      <Text size={isTabletOrWider ? '13px' : '16px'} margin={{ top: '8px' }}>
        {text}
      </Text>
    </Box>
  );
};
