import * as React from 'react';
import { withTheme } from 'styled-components';
import { useRoutes, useTheme } from 'utils';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { IStyledProps } from 'themes';
import { IconButton } from 'ui/IconButton';
import { Icon, Text } from '@we-ui-components/base';
import { Box } from 'grommet';
import { useEffect } from 'react';
import { EncryptPasswordModal } from 'components/Head/components/EncryptPasswordModal';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui';
import { UserSVG } from './svgComponents';
import { LogoutButton } from './LogoutButton';

type TProps = IStyledProps & React.HTMLProps<HTMLDivElement>;

const PlusSVG = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 5.56376H5.56376V9H4.43624V5.56376H1V4.43624H4.43624V1H5.56376V4.43624H9V5.56376Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

export const UserMenu = withTheme(
  observer(({ style, theme, ...props }: TProps) => {
    const { user, routing } = useStores();
    const routes = useRoutes();

    const goToProfile = () => {
      if (routing.location.pathname === `/${routes.profile}`) {
        return;
      }

      routing.push(`/${routes.profile}`);
    };

    const goToCreateVote = () => {
      routing.push(`/${routes.createVote}`);
    };

    const isRegulator = user.isContainRoles(['REGULATOR']);
    const isVoter = user.isContainRoles(['VOTER']);

    const isTabletOrWider = useMediaQuery({
      minDeviceWidth: breakpoints.tablet
    });

    const isPasswordDialogButtonVisible = () => {
      const isVoterWithoutPassword = () => isVoter && !user.encryptPassword;
      return isVoterWithoutPassword() && isTabletOrWider;
    };

    // TODO routes
    return (
      <>
        {isRegulator && (
          <IconButton
            onClick={goToCreateVote}
            renderIcon={() => <PlusSVG />}
            margin={{ left: '16px' }}
          >
            Создать голосование
          </IconButton>
        )}
        {isPasswordDialogButtonVisible() && (
          <PasswordDialogButton margin={{ left: '16px' }} />
        )}

        {!isVoter && (
          <IconButton
            onClick={goToProfile}
            margin={{ left: '16px' }}
            renderIcon={() => <UserSVG />}
          >
            Профиль
          </IconButton>
        )}

        {isVoter && <LogoutButton />}
      </>
    );
  })
);

export const PasswordDialogButton: typeof IconButton = observer(
  function PasswordDialogButton({ style = {}, ...props }) {
    const theme = useTheme();
    const { actionModals, user } = useStores();
    const isLaptopOrWider = useMediaQuery({
      minDeviceWidth: breakpoints.laptopL
    });
    const isTabletOrWider = useMediaQuery({ minWidth: breakpoints.tablet });

    useEffect(() => {
      if (
        isLaptopOrWider &&
        user.isAuthorized &&
        user.roles.includes('VOTER') &&
        !user.encryptPassword
      ) {
        displayEncryptPassModal();
      }
    }, [user.isAuthorized, user.encryptPassword, user.roles, isLaptopOrWider]);

    const modalSuccessfulEncrypt = (): void => {
      actionModals.open(
        () => (
          <Text size="xlarge" bold style={{ marginBottom: '30px' }}>
            История вашего голосования доступна для отображения
          </Text>
        ),
        {
          isOverlayClose: true,
          setTimeCloseModal: 15000,
          width: isLaptopOrWider ? '384px' : '100%',
          modalStyle: {
            maxWidth: isTabletOrWider ? '545px' : '100vw'
          },
          overlayStyle: {
            padding: '32px 16px',
            alignItems: isLaptopOrWider ? 'flex-end' : 'center'
          }
        }
      );
    };

    const displayEncryptPassModal = () => {
      actionModals.open(EncryptPasswordModal, {
        width: isLaptopOrWider ? '384px' : '100%',
        applyText: () =>
          !user.encryptedContent ? <>Создать</> : <>Подтвердить</>,
        closeText: () => <>Отмена</>,
        noValidation: true,
        onApply: async data => {
          await user.saveEncryptPassword(data.password);
          modalSuccessfulEncrypt();
        },
        overlayStyle: {
          background: isLaptopOrWider ? 'transparent' : 'rgba(10, 6, 6, 0.5)',
          alignItems: isLaptopOrWider ? 'flex-end' : 'center',
          padding: '32px 16px'
        },
        modalStyle: {
          boxShadow: '0px 4px 8px rgba(10, 6, 6, 0.04)',
          position: isLaptopOrWider ? 'absolute' : 'relative',
          top: isLaptopOrWider ? 72 : 'auto',
          right: isLaptopOrWider ? 72 : 'auto',
          maxWidth: '482px',
          margin: 'auto',
          marginTop: isLaptopOrWider ? 'auto' : '32px'
        },
        isOverlayClose: true
      });
    };

    return (
      <IconButton
        onClick={() => {
          displayEncryptPassModal();
        }}
        style={{
          height: 'unset',
          whiteSpace: 'normal',
          padding: '4px 8px',
          ...style
        }}
        {...props}
      >
        <Box
          direction="row"
          align="center"
          style={{
            color: theme.palette.Yellow,
            height: 'unset'
          }}
        >
          <Icon glyph="Alert" size="14px" style={{ marginRight: 5 }} />
          <span
            style={{
              lineHeight: '16px'
            }}
          >
            {!user.encryptedContent
              ? 'Создайте пароль для просмотра истории'
              : 'Введите пароль для просмотра истории'}
          </span>
        </Box>
      </IconButton>
    );
  }
);
