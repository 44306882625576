import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui/Responsive';
import { Section } from '../index';
import { Title } from '@we-ui-components/base';
import { useWindowSize } from '../windowWidth';
import { Routes } from '../../../constants';
import { BackButton } from 'components/BackButton';
import { useTheme } from 'utils/hooks';
import { Anchor, Box } from 'grommet';
import { ENDPOINTS } from 'services/endpoints';
import { LetterIcon } from '../icons';
import styled from 'styled-components';
import { getLegalDocuments } from 'services/documentsService';
import { useLocalStore } from 'mobx-react';

function scrollToTop() {
  const container = document.querySelector('#grommetRoot');

  container.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}

const chunk = (arr, size) =>
  arr.reduce(
    (acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]),
    []
  );

const DEFAULT_CHUNK_LENGTH = 5;

function renderDocument({ id, name }: { id: string; name: string }) {
  const openFileInNewWindow = (fileId: string) => {
    window.open(ENDPOINTS.getLegalDocumentsFile(fileId));
  };

  return (
    <Box direction="row" align="center" margin={{ vertical: '12px' }}>
      <Box width="16px" margin={{ right: 'medium' }}>
        <LetterIcon />
      </Box>

      <AnchorStyle onClick={() => openFileInNewWindow(id)}>{name}</AnchorStyle>
    </Box>
  );
}

export const InfoAboutProgram = observer(() => {
  const isTabletOrWider = useMediaQuery({ minDeviceWidth: breakpoints.tablet });

  const theme = useTheme();
  const wSize = useWindowSize();

  useEffect(() => {
    scrollToTop();
  });

  const documents = useLocalStore(() => ({
    list: []
  }));

  React.useEffect(() => {
    getLegalDocuments().then(list => (documents.list = list));
  }, []);

  const [leftColumn = [], rightColumn = []] = chunk(
    documents.list,
    documents.list.length > 10
      ? Math.floor(documents.list.length / 2)
      : DEFAULT_CHUNK_LENGTH
  );

  return (
    <Section style={{ margin: isTabletOrWider ? '42px 0' : '20px 0' }}>
      <BackButton
        url={`/${Routes.landing}`}
        textColor={theme.palette.Purple}
        style={{
          border: '1px solid #d8d6ef',
          width: '105px',
          padding: '15px',
          color: `${theme.palette.Purple}`
        }}
      />
      <Title
        size={wSize}
        bold
        style={{ letterSpacing: '1px' }}
        text="Информация о программе"
        margin={{ vertical: 'xlarge' }}
      />

      <Box direction="row-responsive">
        <Box style={{ marginRight: isTabletOrWider ? '130px' : '' }}>
          {leftColumn.map(renderDocument)}
        </Box>

        <Box>{rightColumn.map(renderDocument)}</Box>
      </Box>
    </Section>
  );
});

const AnchorStyle = styled(Anchor)`
  color: #0a0606;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  max-width: 390px;
  margin-left: 12px;
`;
