import * as React from 'react';
import mobxStores, { StoresProvider } from 'stores';
import { Provider as MobxProvider } from 'mobx-react';
import { Grommet } from 'grommet';
import { Theme, mainTheme } from 'themes';
import { Router } from 'react-router-dom';

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <StoresProvider stores={mobxStores}>
      <MobxProvider {...mobxStores}>
        <Router history={mobxStores.routing.history}>
          <Grommet
            theme={{ ...Theme, ...mainTheme }}
            plain={true}
            full={true}
            id="grommetRoot"
          >
            {children}
          </Grommet>
        </Router>
      </MobxProvider>
    </StoresProvider>
  );
}
