import * as React from 'react';
import { ENDPOINTS } from 'services';
import styled from 'styled-components';
import fallbackUserPic from './unknown-person.svg';

type ImgProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

interface AvatarProps extends ImgProps {
  size: string;
  personId: string;
  shape?: 'circle' | 'square';
}

export function Avatar({
  size,
  personId,
  shape = 'circle',
  ...imgProps
}: AvatarProps) {
  const Component = shape === 'circle' ? RoundImage : BaseImage;

  const imgRef = React.useRef<HTMLImageElement>(null);

  React.useEffect(() => {
    imgRef.current.onerror = () => {
      imgRef.current.src = fallbackUserPic;
    };
  }, []);

  return (
    <Component
      ref={imgRef}
      width={size}
      height={size}
      alt=""
      src={ENDPOINTS.avatar(personId)}
      {...(imgProps as any)}
    />
  );
}

const BaseImage = styled.img``;

const RoundImage = styled(BaseImage)`
  &,
  &::before {
    border-radius: 50%;
  }
`;
