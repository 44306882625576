import { ITabOptions } from 'components/Tabs';
import { DistrictType } from 'interfaces/apiEntities';

export enum PollingType {
  district = 'DISTRICT',
  schoolGroup = 'SCHOOL_GROUP'
}

export function getRegionMapTabs(): ITabOptions[] {
  return [
    {
      text: 'Волгоградская область',
      id: 'OBLAST_DISTRICTS' as DistrictType
    },
    {
      text: 'г. Волгоград',
      id: 'VOLGOGRAD_DISTRICTS' as DistrictType
    }
  ];
}

export function getVoteListTabs(): ITabOptions[] {
  return [
    {
      text: 'Муниципальные образования',
      id: PollingType.district
    },
    {
      text: 'Школьные группы',
      id: PollingType.schoolGroup
    }
  ];
}
