import * as React from 'react';
import { Icon, Text } from '@we-ui-components/base';
import { Box } from 'grommet';
import { passwordChecks, PASSWORD_SPECIAL_SYMBOLS } from './registration.utils';

export function PasswordHint({ password }: { password: string }) {
  const isValidLength = password.length >= 8 && password.length <= 14;
  const hasForbiddenSymbols =
    password.length > 0 && passwordChecks.hasForbiddenSymbols(password);

  const hasAllCases = !(
    passwordChecks.noLowerCase(password) || passwordChecks.noUpperCase(password)
  );

  return (
    <Box>
      <Row>
        <StatusIcon ok={isValidLength} />
        <HintText>Не менее 8 и не более 14 символов</HintText>
      </Row>

      <Row>
        <StatusIcon ok={hasAllCases} />
        <HintText>Заглавные и строчные буквы латинского алфавита</HintText>
      </Row>

      <Row>
        <StatusIcon ok={!passwordChecks.noDigits(password)} />
        <HintText>Цифры</HintText>
      </Row>

      <Row align="start">
        <Box margin={{ top: '3px' }}>
          <StatusIcon ok={!passwordChecks.noSpecialSymbols(password)} />
        </Box>
        <Box>
          <HintText>Специальные символы: </HintText>
          <HintText>{PASSWORD_SPECIAL_SYMBOLS}</HintText>
        </Box>
      </Row>

      {hasForbiddenSymbols && (
        <Row>
          <StatusIcon ok={!hasForbiddenSymbols} />
          <HintText>Запрещенные символы</HintText>
        </Row>
      )}
    </Box>
  );
}

const Row: typeof Box = props => <Box direction="row" gap="5px" {...props} />;

function HintText(props: any) {
  return <Text size="xsmall" {...props} />;
}

function StatusIcon({ ok }: { ok: boolean }) {
  return ok ? <OkIcon /> : <ErrorIcon />;
}

const OkIcon = () => <Icon glyph="Check" color="Green" size="12px" />;
const ErrorIcon = () => <Icon glyph="Close" color="Red" size="12px" />;
