import * as React from 'react';
import { Box } from 'grommet';
import { Text, Title } from '@we-ui-components/base';
import { IProject, PROJECT_STATUS } from 'interfaces';
import { ENDPOINTS } from 'services/endpoints';
import { Routes } from '../../constants';
import styled from 'styled-components';
import { ProjectStatus } from '../Poll/ProjectStatus';
import { formatWithTwoDecimalsRub } from 'utils/formatNumber';
import { useStores } from 'stores/index';
import { ImplementationDetails } from 'pages/Poll/components/ImplementationDetails';
import { StarRating } from 'components/StarRatings';
import { PollingType } from '../Organizer/tabs';

export function triplets(str) {
  return str.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1\u202f');
}

export function changeTime(time) {
  return time
    .slice(0, 10)
    .split('-')
    .reverse()
    .join('.');
}

const StyledBox = styled(Box)`
  &:hover {
    box-shadow: 0 4px 8px rgba(10, 6, 6, 0.06);
  }
`;

export function ProjectListItem({ params }: { params: IProject }) {
  const { routing } = useStores();

  return (
    <StyledBox
      width="100%"
      pad="small"
      margin="16px 0"
      style={{
        backgroundColor: 'white',
        boxShadow: '0px 4px 8px rgba(10, 6, 6, 0.04))',
        borderRadius: '1px'
      }}
      onClick={() => routing.push(`/${Routes.projectPage}/${params.id}`)}
    >
      <Box direction="row-responsive">
        <CardImage src={ENDPOINTS.getProjectFile(params.logoFileId)} alt="" />
        <Box
          justify="between"
          direction="row"
          width="100%"
          style={{ position: 'relative' }}
        >
          <Box style={{ marginLeft: '16px' }}>
            <Text size="xsmall" color="#172b4d">
              Голосование по{' '}
              {params.subject.type === PollingType.schoolGroup
                ? 'школьной группе '
                : ''}
              {params.subject.name || '--'}, {params.subject.year || '--'}
            </Text>
            <Title margin="10px 0" size="xsmall" bold>
              {params.name}
            </Title>
            <Text margin="2px 0 8px" size="xsmall" color="#172b4d">
              Срок реализации проекта:{' '}
              {params.startDate ? changeTime(params.startDate) : 'не указан'}{' '}
              &mdash;{' '}
              {params.endDate ? changeTime(params.endDate) : 'не указан'}
            </Text>
            <Text size="xsmall" color="#172b4d">
              Объем требуемых средств:{' '}
              {formatWithTwoDecimalsRub(params.budgetSummary || 0)}
            </Text>
          </Box>
          <ProjectStatus status={params.status} />
        </Box>
      </Box>

      {params.status === PROJECT_STATUS.DONE && (
        <ImplementationDetails margin={{ top: '16px' }} project={params} />
      )}

      {params.score && (
        <Box pad={{ top: 'medium' }} align="end">
          <Box direction="row" flex="grow">
            <Box width="85px" align="center">
              <StarRating value={params.score?.average || 0} readonly />
            </Box>

            <Text
              margin={{ left: 'xsmall', bottom: 'xxsmall' }}
              size="xsmall"
              color="Grey"
            >
              {params.score?.average || 0} ({params.score?.totalCount || 0})
            </Text>
          </Box>
        </Box>
      )}
    </StyledBox>
  );
}

const CardImage = styled.img`
  border-radius: 4px;
  object-fit: cover;
  width: 130px;
  height: 130px;
`;
