import * as React from 'react';

export const ArrowUpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        d="M.984 9L9 .984 17.016 9l-1.453 1.406-5.579-5.578v12.188H8.016V4.828L2.39 10.406.984 9z"
        fill="#fff"
      />
    </svg>
  );
};
