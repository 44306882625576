import { useState, useEffect } from 'react';

function getTextPoint(windowWidth) {
  if (windowWidth) {
    if (windowWidth < 600) {
      return 'small';
    } else if (windowWidth < 768) {
      return 'medium';
    } else if (windowWidth < 1100) {
      return 'large';
    } else {
      return 'xlarge';
    }
  } else {
    return undefined;
  }
}

export function useWindowSize() {
  const isWindowClient = typeof window === 'object';

  const [windowSize, setWindowSize] = useState(
    isWindowClient ? getTextPoint(window.innerWidth) : undefined
  );

  useEffect(() => {
    function setSize() {
      setWindowSize(getTextPoint(window.innerWidth));
    }

    if (isWindowClient) {
      window.addEventListener('resize', setSize);
      return () => window.removeEventListener('resize', setSize);
    }
  }, [isWindowClient, setWindowSize]);

  return windowSize;
}
