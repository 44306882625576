import * as React from 'react';
import { Box } from 'grommet';
import { Text } from '@we-ui-components/base';
import { useTheme } from 'utils';
import styled from 'styled-components';

type BoxProps = React.ComponentProps<typeof Box>;

type LabelVariant = 'filled' | 'outlined';

interface LabelProps extends BoxProps {
  primaryColor: string;
  secondaryColor?: string;
  fontSize?: string;
  variant?: LabelVariant;
}

export function Label({
  primaryColor,
  fontSize,
  children,
  secondaryColor = 'White',
  variant = 'filled',
  style = {},
  ...props
}: LabelProps) {
  const variantProps = useVariantProps({
    primaryColor,
    secondaryColor,
    variant
  });

  return (
    <StyledBox
      background={variantProps.bgColor}
      style={{ borderColor: variantProps.borderColor, ...style }}
      {...props}
    >
      <Text size={fontSize} color={variantProps.textColor}>
        {children}
      </Text>
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  border-radius: 30px;
  border: 1px solid;
  padding: 4px 12px;

  position: relative;

  align-items: center;
  justify-content: center;
`;

interface UseVariantParams {
  primaryColor: string;
  secondaryColor: string;
  variant: LabelVariant;
}

interface VariantProps {
  bgColor: string;
  textColor: string;
  borderColor: string;
}

function useVariantProps({
  primaryColor,
  secondaryColor,
  variant
}: UseVariantParams) {
  const resolvedPrimaryColor = useThemeColor(primaryColor);

  const filledProps: VariantProps = {
    bgColor: resolvedPrimaryColor,
    textColor: secondaryColor,
    borderColor: resolvedPrimaryColor
  };

  const outlinedProps: VariantProps = {
    bgColor: 'white',
    textColor: resolvedPrimaryColor,
    borderColor: resolvedPrimaryColor
  };

  return variant === 'filled' ? filledProps : outlinedProps;
}

function useThemeColor(color: string) {
  const theme = useTheme();
  return theme.palette[color] || color;
}
