export const rotateBase64Image = (src: string, degrees: number, callback: (str: string) => void) =>{
  // create an off-screen canvas
  const offScreenCanvas = document.createElement('canvas');
  const offScreenCanvasCtx = offScreenCanvas.getContext('2d');
  const img = new Image();
  img.onload = function() {
    offScreenCanvas.height = img.width;
    offScreenCanvas.width = img.height;
    offScreenCanvasCtx.rotate(degrees * Math.PI / 180);
    if (degrees > 0) {
      offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
    } else {
      offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
    }
    offScreenCanvasCtx.drawImage(img, 0, 0);
    const b64 = offScreenCanvas.toDataURL("image/jpeg", 100)
    callback(b64)
  };
  img.src = src;
}
