import { ISelectProps, Select } from '@we-ui-components/base';
import * as React from 'react';
import { ListStoreConstructor } from '../stores/core/ListStoreConstructor';
import { observer } from 'mobx-react-lite';

export type TSort = 'none' | 'asc' | 'desc';

interface ITableSortSelect<S extends { id: string } = { id: string }> {
  store: ListStoreConstructor<S>;
  sortedField: string;
}

const getSorterValue = (sorter: string | string[], dataIndex: string) => {
  const parseSorter = (s: string) => s.split(',');
  if (sorter instanceof Array) {
    const sorterEntries = sorter.map(parseSorter);
    const [, value] = sorterEntries.find(([name]) => name === dataIndex) || [];
    return value;
  }
  if (typeof sorter === 'string') {
    const [name, value] = parseSorter(sorter);
    if (name === dataIndex) {
      return value;
    }
  }
  return null;
};

export const TableSortSelect = observer(
  (props: ITableSortSelect & ISelectProps) => {
    const { store, sortedField, ...selectProps } = props;

    const onChange = (value: TSort) => {
      store.onChangeDataFlow({
        sorter: `${sortedField},${value}`
      });
    };

    const value = getSorterValue(store.sorter, sortedField);

    return (
      <div>
        <Select
          size="small"
          type="filter"
          styles={{
            control: {
              border: 'none',
              width: '160px',
              height: '38px',
              fontSize: '13px'
            },
            valueContainer: {
              padding: '5px 14px',
              height: '38px',
              fontSize: '13px'
            },
            indicatorsContainer: {
              padding: 0,
              height: '38px'
            },
            dropdownIndicator: (provided: any, state: Record<string, any>) => ({
              ...provided,
              padding: 0,
              margin: '-1px 4px 0 0',
              color: state.theme.palette.Grey
            }),
            option: (provided: any, state: Record<string, any>) => {
              const getBgColor = () => {
                if (state.isSelected) return state.theme.palette.Basic300;
                if (state.isFocused) return state.theme.palette.Basic100;
                return 'transparent';
              };

              return {
                ...provided,
                backgroundColor: getBgColor(),
                color: state.theme.textColor,
                padding: '14px',
                fontSize: '13px',
                fontFamily: state.theme.fontBase
              };
            }
          }}
          {...selectProps}
          onChange={onChange}
          value={value}
        />
      </div>
    );
  }
);
