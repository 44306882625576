import * as React from 'react';
import { Box, BoxProps } from 'grommet';
import { Text } from '@we-ui-components/base';
import styled from 'styled-components';

type Props = BoxProps & { votes: number; percentage: number };

export const ProgressStyled = styled(Box)`
  height: 5px;
  width: 100%;
  position: relative;
  background-color: ${props => props.theme.palette.Background};
  overflow: hidden;
`;

export const ProgressBarStyled = styled(ProgressStyled)<{ value: number }>`
  background-color: ${props => props.theme.palette.Purple};
  position: absolute;
  top: 0;
  left: -${props => 100 - props.value}%;
`;

export const ProgressBar: React.FC<BoxProps & { value: number }> = ({
  value,
  ...props
}) => {
  return (
    <ProgressStyled {...props}>
      <ProgressBarStyled value={value} />
    </ProgressStyled>
  );
};

export const PollResultProgress: React.FC<Props> = ({
  percentage,
  votes,
  ...props
}) => {
  const percentageFixed = percentage.toFixed(2);

  return (
    <Box {...props}>
      <Text bold size="large">
        Голосов за: {votes} ({percentageFixed}%)
      </Text>

      <ProgressBar value={percentage} margin={{ top: 'small' }} />
    </Box>
  );
};
