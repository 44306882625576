import { StoreConstructor } from './core/StoreConstructor';
import { action, observable } from 'mobx';
import { statusFetching } from 'we-oauth2/lib/constants/types';
import { IStores } from 'stores';
import { NormalizedError } from 'services/api/errorHandler';
import { getIpObject } from 'services';
import { IpObject } from 'interfaces';

export class ActiveIpObjectStore extends StoreConstructor {
  @observable ipObject: IpObject;
  @observable fetchStatus: statusFetching = 'init';
  @observable fetchError: NormalizedError;
  @observable actionStatus: statusFetching = 'init';
  @observable actionError: NormalizedError;

  constructor(stores: IStores) {
    super(stores);
  }

  @action.bound
  getIpObject(id: string): Promise<IpObject> {
    return this.statusHandler(
      () => getIpObject(id),
      'fetchStatus',
      'fetchError',
      async result => {
        this.ipObject = { ...result };
      }
    );
  }

  @action.bound
  clear(): void {
    this.ipObject = null;
    this.fetchStatus = 'init';
    this.fetchError = null;
    this.actionStatus = 'init';
    this.actionError = null;
  }
}
