import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from 'grommet';
import { Form, MobxForm, Select } from '@we-ui-components/form';
import { useLocalStore } from 'mobx-react';
import { TActionModalProps } from 'components';
import styled from 'styled-components';
import { Button, Text, ISelectOption } from '@we-ui-components/base';
import { getRange, generateSimpleOptions, getCurrentYear } from 'utils';

export type ExportProjectsFormData = {
  type: 'DISTRICT' | 'SCHOOL_GROUP';
  year: number;
};

type ExportProjectsModalProps = TActionModalProps<{}>;

export const ExportProjectsModal = observer<ExportProjectsModalProps>(
  function ExportProjectsModal(props, forwardedRef) {
    const formRef = React.useRef<MobxForm>();

    const formData: ExportProjectsFormData = useLocalStore(() => ({
      type: 'DISTRICT',
      year: getCurrentYear()
    }));

    const handleSubmit = async () => {
      const data: ExportProjectsFormData = await formRef.current.validateFields();
      return data;
    };

    React.useImperativeHandle(forwardedRef, () => ({
      onValidate: handleSubmit
    }));

    return (
      <Box>
        <Form ref={formRef} data={formData} {...({} as any)}>
          <Box gap="8px" margin={{ bottom: '24px' }}>
            <Text size="13px" color="Grey">
              Тип голосования*
            </Text>

            <Box direction="row" gap="8px">
              <StyledButton
                active={formData.type === 'DISTRICT'}
                onClick={() => {
                  formData.type = 'DISTRICT';
                }}
              >
                Муниципальные образования
              </StyledButton>

              <StyledButton
                active={formData.type === 'SCHOOL_GROUP'}
                onClick={() => {
                  formData.type = 'SCHOOL_GROUP';
                }}
              >
                Школьные группы
              </StyledButton>
            </Box>
          </Box>

          <Select name="year" options={generateOptions()} />
        </Form>
      </Box>
    );
  },
  { forwardRef: true }
);

export const StyledButton = styled(Button).attrs(() => ({
  transparent: true
}))<{
  active: boolean;
}>`
  button {
    color: ${computeButtonColor};
    padding: 0;

    &:hover {
      color: ${computeButtonColor};
    }
  }
`;

function computeButtonColor(props: Record<string, any>) {
  const palette = props?.theme?.palette || {};
  return props.active ? palette.Purple : palette.Grey;
}

function generateOptions(): ISelectOption[] {
  const START_YEAR = 2021;
  return generateSimpleOptions(getRange(START_YEAR, getCurrentYear()));
}
