import { StoreConstructor } from 'stores/core/StoreConstructor';
import { action, computed, observable, reaction } from 'mobx';
import { statusFetching } from 'we-oauth2/src/constants/types';
import { getPollResults } from 'services/poll';
import { NormalizedError } from 'services/api/errorHandler';
import { IPoll, IPollResults } from 'interfaces/apiEntities';
import { IStores } from 'stores/index';

export class ActivePollResults extends StoreConstructor {
  @observable fetchStatus: statusFetching = 'init';
  @observable fetchError: NormalizedError;
  @observable results: IPollResults;

  @computed
  public get votesSummary() {
    return Object
      .entries(this.results.votesCountByProjectId || {})
      .reduce((votesSum, [, votesCount]) => votesSum + votesCount, 0)
  }

  constructor(stores: IStores) {
    super(stores);

    const { activePoll } = stores;

    reaction(
      () => activePoll.fetchStatus,
      () => {
        if (activePoll.fetchStatus !== 'success' || activePoll.poll.status !== 'FINISHED') {
          return
        }

        return this.getPollResults(activePoll.pollId)
      }
    );
  }

  @action.bound
  public getPollResults(pollId: string) {
    return this.statusHandler(
      () => getPollResults(pollId),
      'fetchStatus',
      'fetchError',
      async (results) => {
        this.results = results
      }
    )
  }

  @action.bound
  clear() {
    this.results = null;
    this.fetchStatus = 'init';
    this.fetchError = null;
  }

  public isPollResultsAvailable(poll: IPoll) {
    return poll.status === 'FINISHED' && this.fetchStatus === 'success'
  }

  public getProjectVotesCount(projectId: string): number {
    return this.results.votesCountByProjectId[projectId] || 0;
  }

  public getProjectVotesPercentage(projectId: string): number {
    const count = this.getProjectVotesCount(projectId)

    //  0 / 0 === NaN
    if (this.votesSummary === 0) {
      return 0
    }

    return Number(count / this.votesSummary * 100)
  }
}
