import { observer, useLocalStore } from 'mobx-react';
import { Form, Input, isRequired, MobxForm } from '@we-ui-components/form';
import { Box } from 'grommet';
import { Button } from '@we-ui-components/base';
import * as React from 'react';
import { useStores } from 'stores/index';
import { isTheSameAs } from 'utils/validators';
import { changePassword } from 'services/usersService';
import { encryptPassword } from 'utils/decode';
import { passwordValidators } from 'pages/Users/modals/userPasswordValidators';

const isPasswordConfirm = (
  form: MobxForm,
  fieldName: string,
  fieldNameEqual: string
) => {
  return {
    validateType: 'isPasswordConfirmValidator',
    validator(
      rule: any[],
      value: any,
      callback: (errors: any[]) => void,
      storeData?: any
    ) {
      const errors = [];

      if (
        form.getFieldValue(fieldName) !== form.getFieldValue(fieldNameEqual)
      ) {
        errors.push('Пароли должны совпадать!');
      }

      callback(errors);
    }
  };
};

export const UpdatePassword = observer(() => {
  const { user, actionModals } = useStores();
  const { userInfo } = user;

  const formRef = React.useRef<MobxForm>(null);

  const data = useLocalStore(() => ({
    password: '',
    password_confirm: ''
  }));

  const handleCancel = () => {
    actionModals.closeLastModal();
  };

  const handleSave = async () => {
    formRef.current
      .validateFields()
      .then(() =>
        changePassword(userInfo.personId, {
          password: encryptPassword(data.password)
        }).then(() => actionModals.closeLastModal())
      )
      .then(() =>
        actionModals.open(
          () => (
            <Box
              align="end"
              style={{ flex: '1 1 100%' }}
              pad={{ bottom: 'xlarge' }}
            >
              <Button onClick={() => actionModals.closeLastModal()}>
                Закрыть
              </Button>
            </Box>
          ),
          {
            title: 'Пароль изменен',
            width: '545px'
          }
        )
      );
  };

  return (
    <Box pad={{ bottom: 'large' }}>
      <Form ref={(ref: MobxForm) => (formRef.current = ref)} data={data}>
        <Input
          placeholder="Новый пароль"
          name="password"
          style={{ marginTop: 18 }}
          type="password"
          rules={passwordValidators}
        />

        <Input
          placeholder="Повторите новый пароль"
          name="password_confirm"
          style={{ marginTop: 18 }}
          type="password"
          rules={[
            isRequired,
            isTheSameAs('password', 'Пароли должны совпадать!')
          ]}
        />
      </Form>

      <Box direction="row" justify="end">
        <Button
          bordered
          transparent
          color="Blue"
          onClick={handleCancel}
          style={{ border: '1px solid #204080' }}
        >
          Отменить
        </Button>
        <Button onClick={handleSave} margin={{ left: 'small' }}>
          Сохранить изменения
        </Button>
      </Box>
    </Box>
  );
});
