import { createValidate } from 'utils';
import { isRequired, minLength } from '@we-ui-components/form';

const passwordChecks = {
  hasForbiddenSymbols: (value: string) => !/^[a-z0-9]+$/gi.test(value),
  noDigits: (value: string) => !/\d/.test(value),
  lessThan4AlphabeticChars: (value: string) => {
    const matches = value.match(/[a-z]/gi);

    if (!matches) return true;
    return matches.length < 4;
  }
};

const onlyAllowedValidator = createValidate(
  passwordChecks.hasForbiddenSymbols,
  'Пароль должен состоять только из цифр и латинских букв'
);

const digitsValidator = createValidate(
  passwordChecks.noDigits,
  'Пароль должен содержать цифры'
);

const lettersValidator = createValidate(
  passwordChecks.lessThan4AlphabeticChars,
  'Пароль должен содержать минимум 4 буквы латинского алфавита'
);

export const passwordValidators = [
  isRequired,
  minLength(5, 'Длина пароля должна быть не менее 5 символов'),
  lettersValidator,
  digitsValidator,
  onlyAllowedValidator
];

export const phoneValidators = [
  {
    type: 'string',
    max: 25,
    message: 'Максимальная длина - 25 символов'
  }
];
