import * as React from 'react';
import { useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Box } from 'grommet';
import styled from 'styled-components';
import { SearchInput } from 'ui/SearchInput';
import { SearchIcon } from 'ui/Icons/CustomizedIcons';
import { Text } from '@we-ui-components/base';
import OutsideClickHandler from 'react-outside-click-handler';
import { getDistrictsWithVotes } from 'services/voteService';
import { PollSubject } from 'interfaces/apiEntities';
import Scrollbar from 'react-scrollbars-custom';
import { useStores } from 'stores/index';

const Arrow = () => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
      d="M15.2929 20.7071L8.29289 13.7071C7.90237 13.3166 7.90237 12.6834 8.29289 12.2929C8.68342 11.9024 9.31658 11.9024 9.70711 12.2929L16 18.5858L22.2929 12.2929C22.6834 11.9024 23.3166 11.9024 23.7071 12.2929C24.0976 12.6834 24.0976 13.3166 23.7071 13.7071L16.7071 20.7071C16.3166 21.0976 15.6834 21.0976 15.2929 20.7071Z"
      fill="currentColor" />
  </svg>
)

const ControlContainer = styled(Box)`
  cursor: pointer;
  color: ${props => props.theme.palette.Purple};
  font-size: ${props => props.theme.sizes.text.xsmall};
  position: relative;
`

const FilterPanel = styled(Box)`
  position: absolute;
  z-index: 1000;
  width: 258px;
  border: 1px solid #EFEFEF;
  background: #FFFFFF;
  height: 300px;
`

const FilterOption = styled(Text) <{ onClick: (ev: MouseEvent) => void }>`
  cursor: pointer;

  &:hover {
    background-color: #EFEFEF;
  }
`


type DistrictFilterProps = {
  onChangeFilter: (districtId: string) => void;
}


function filterDistricts(list: PollSubject[], search: string) {
  if (search === '') {

    return list;
  }


  return list.filter(
    p => p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )
}

export const DistrictFilter = observer(({ onChangeFilter }: DistrictFilterProps) => {
  const [isPanelOpen, setPanelState] = useState(false)

  const { activePoll } = useStores()

  const filter = useLocalStore(() => {
    return {
      search: '',
      districtId: '',
      districts: [] as PollSubject[]
    }
  })

  React.useEffect(() => {
    getDistrictsWithVotes({ pollStatus: activePoll.poll.status })
      .then((resp) => {
        filter.districts = resp.content
      })
  }, []);

  React.useEffect(() => () => onChangeFilter(null), [])

  const districtsList = filterDistricts(filter.districts, filter.search)

  const selectedDistrict = filter.districts.find((d) => d.id === filter.districtId);

  const controlText = selectedDistrict
    ? selectedDistrict.name
    : 'Все муниципальные районы';

  const optionList = (
    districtsList.length > 0
      ? districtsList.map(
        (district) =>
          <FilterOption
            pad="16px 10px"
            size="xsmall"
            key={district.id}
            onClick={() => {
              onChangeFilter(district.id)
              filter.districtId = district.id;
              closePanel()
            }}
          >{district.name}
          </FilterOption>
      )
      : <Box pad='xsmall'>Районов не найдено</Box>
  );

  const closePanel = () => {
    setPanelState(false);
    filter.search = ''
  }

  return (
    <Box style={{ position: 'relative' }}>
      <OutsideClickHandler onOutsideClick={closePanel}>
        <ControlContainer
          color="Purple"
          direction="row"
          align="center"
          onClick={(ev: React.MouseEvent) => {
            ev.preventDefault();

            setPanelState(prev => !prev)
          }}
        >
          {controlText} <Arrow />
        </ControlContainer>

        {isPanelOpen && (
          <FilterPanel>
            <SearchInput
              style={{ height: '44px' }}
              bgColor="#FFFFFF"
              borderBottom="1px solid #EFEFEF"
              onChange={v => filter.search = v}
              renderLeft={
                <Box margin={{ left: '5px', right: '12px' }}>
                  <SearchIcon size="medium" fill='#8D8D8D' />
                </Box>
              }
            />

            <Scrollbar style={{ top: 0 }} trackYProps={{ style: { top: 0, bottom: 0, height: '100%' } }}>
              <Box style={{ overflowY: 'auto' }}>
                <FilterOption
                  pad="16px 10px"
                  size="xsmall"
                  onClick={() => {
                    onChangeFilter('')
                    filter.districtId = '';
                    closePanel()
                  }}
                >
                  Все районы
                </FilterOption>
                {optionList}
              </Box>
            </Scrollbar>
          </FilterPanel>
        )}
      </OutsideClickHandler>
    </Box>
  )
});