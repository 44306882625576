import * as React from 'react';
import { Box } from 'grommet';
import { Title, NumberInput } from '@we-ui-components/base';
import { Panel, Error as ErrorUI, ButtonEx } from 'ui';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { applyMask } from 'utils';

export const VerifyPhonePage = observer(() => {
  const [confirmationCode, setConfirmationCode] = React.useState('');
  const { user } = useStores();
  const phoneNumber = applyMask(
    hidePhone(user.userInfo.phone, { start: 5, amount: 5 }),
    'NN (NNN) NNN-NN-NN'
  );

  const [error, setError] = React.useState('');
  const [sending, setSending] = React.useState(false);

  const handleSumbit = async () => {
    try {
      setSending(true);
      await user.verify(confirmationCode);
    } catch (error) {
      setError(error?.detail || 'Неизвестная ошибка. Повторите попытку позже');
    } finally {
      setSending(false);
    }
  };

  return (
    <Box align="center" margin={{ top: '39px' }}>
      <Panel width="736px" pad="32px" margin={{ top: '27px' }} gap="16px">
        <Title>
          На номер {phoneNumber} отправлено смс с кодом подтверждения
        </Title>

        <NumberInput
          value={confirmationCode}
          onChange={value => {
            setError('');
            setConfirmationCode(value);
          }}
          style={{ padding: '18px 16px', width: '320px' }}
        />

        {error && <ErrorUI error={error} />}

        <ButtonEx
          isLoading={sending}
          disabled={confirmationCode.length === 0}
          onClick={() => {
            handleSumbit();
          }}
        >
          Подтвердить
        </ButtonEx>
      </Panel>
    </Box>
  );
});

function hidePhone(
  phone: string,
  {
    start,
    amount,
    char = '*'
  }: { start: number; amount: number; char?: string }
) {
  const end = start + amount;
  const between = (value: number) => value >= start && value < end;

  return phone
    .split('')
    .map((it, index) => (between(index) ? char : it))
    .join('');
}
