import * as React from 'react';

export const KebabIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 5 18"
      width="1em"
      {...props}
    >
      <path
        d="M1.141 13.594c.407-.406.875-.61 1.407-.61.53 0 1 .204 1.406.61.406.406.61.875.61 1.406 0 .531-.204 1-.61 1.406-.406.407-.875.61-1.406.61-.532 0-1-.203-1.407-.61C.735 16 .532 15.531.532 15c0-.531.203-1 .61-1.406zm0-6c.407-.407.875-.61 1.407-.61.53 0 1 .204 1.406.61.406.406.61.875.61 1.406 0 .531-.204 1-.61 1.406-.406.406-.875.61-1.406.61-.532 0-1-.204-1.407-.61C.735 10 .532 9.531.532 9c0-.531.203-1 .61-1.406zm2.813-3.188c-.406.407-.875.61-1.406.61-.532 0-1-.204-1.407-.61C.735 4 .532 3.531.532 3c0-.531.203-1 .61-1.406.406-.407.874-.61 1.406-.61.53 0 1 .204 1.406.61.406.406.61.875.61 1.406 0 .531-.204 1-.61 1.406z"
        fill={props.color}
      />
    </svg>
  );
};
