import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useStores } from 'stores';
import { Box } from 'grommet';
import { PROJECT_STATUS, IProject, ArchivePollApiDTO } from 'interfaces';
import { Button, Text } from '@we-ui-components/base';
import {
  ConfirmFinishModal,
  FormData as ConfirmFinishModalFormData
} from 'pages/Poll/ConfirmFinishModal';
import { useTheme } from 'utils';
import styled from 'styled-components';

interface ProjectActionsProps {
  project: IProject;
  poll: ArchivePollApiDTO;
  voted: boolean;
}

export const ProjectActions = observer<ProjectActionsProps>(
  function ProjectActions({ project, poll, voted }) {
    const { user, actionModals, archiveProjects, activeProject } = useStores();
    const theme = useTheme();

    const isPollCompleted = ['FINISHED'].includes(poll.status);

    const isOrganizer = user.isContainRoles(['REGULATOR']);
    const isVoter = user.isContainOneOfRoles(['VOTER']);

    const displayPostActionButton =
      [PROJECT_STATUS.APPROVED].includes(project.status) &&
      isOrganizer &&
      isPollCompleted;

    const displayConfirmFinishActionButton =
      project.status === PROJECT_STATUS.IN_PROGRESS &&
      isOrganizer &&
      isPollCompleted;

    const archiveProject = () => {
      actionModals.open(() => <Text size="medium" />, {
        title: `Вы уверены, что хотите перевести проект в архив?`,
        applyText: () => <>Перевести в архив</>,
        closeText: () => <>Отмена</>,
        noValidation: true,
        onApply: async () =>
          activeProject.callProjectAction(project.id, 'archive').then(() => {
            archiveProjects.init({ filters: { pollId: poll.id } });
          })
      });
    };

    const implementationProject = () => {
      actionModals.open(() => <Text size="medium" />, {
        title: `Вы уверены, что хотите перевести проект в реализацию?`,
        applyText: () => <>Перевести в реализацию</>,
        closeText: () => <>Отмена</>,
        noValidation: true,
        onApply: async () =>
          activeProject.callProjectAction(project.id, 'start').then(() => {
            archiveProjects.init({ filters: { pollId: poll.id } });
          })
      });
    };

    const openConfirmFinishModal = () => {
      actionModals.open(ConfirmFinishModal, {
        width: '545px',
        applyText: () => <span>Подтвердить</span>,
        onApply: async (data: ConfirmFinishModalFormData) =>
          activeProject
            .confirmFinish(project.id, data.description, data.files)
            .then(() => {
              archiveProjects.init({ filters: { pollId: poll.id } });
            }),
        closeText: () => <span>Отменить</span>,
        noValidation: true
      });
    };

    const rate = useLocalStore(() => ({
      setError: (err: string) => {
        rate.error = err;
      },
      error: null
    }));

    if (isOrganizer) {
      return (
        <Box
          align="center"
          direction="row"
          margin={{ left: 'auto' }}
          style={{ position: 'relative' }}
          gap="small"
        >
          {displayPostActionButton && (
            <Button
              bordered
              transparent
              color="Blue"
              onClick={() => {
                archiveProject();
              }}
              style={{ border: '1px solid #204080' }}
            >
              В архив
            </Button>
          )}
          {displayPostActionButton && (
            <Button
              onClick={() => {
                implementationProject();
              }}
            >
              В реализацию
            </Button>
          )}
          {displayConfirmFinishActionButton && (
            <Button onClick={openConfirmFinishModal}>
              Внести данные о реализации
            </Button>
          )}
        </Box>
      );
    }

    if (isVoter && voted) {
      return (
        <Button
          style={{
            background: 'inherit',
            color: theme.palette.Purple,
            border: `1px solid #d4d2eb`,
            padding: '10px 16px',
            cursor: 'auto'
          }}
          size="medium"
        >
          <LabelStyle>
            <input type="checkbox" checked disabled />
            <span />
          </LabelStyle>
          Голос отдан
        </Button>
      );
    }

    return null;
  }
);

const LabelStyle = styled.label`
  display: inline-flex;
  position: relative;
  margin-right: 10px;
  input {
    height: 22px;
    width: 22px;
    appearance: none;
  }

  input:checked {
    border: 2px solid #3b33b0;
    background-color: inherit;
  }

  input:checked + span::before {
    content: '\\2713';
    color: #3b33b0;
    position: absolute;
    left: 0.7rem;
    top: 0.35rem;
  }
`;
