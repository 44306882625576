import * as React from 'react';
import { PollSubject } from 'interfaces/apiEntities';
import { PollingStatus } from 'components/RegionMap';
import { Box } from 'grommet';
import styled from 'styled-components';
import { breakpoints } from 'ui';

const formatDate = (dateString: string) => {
  const date = new Date(Date.parse(dateString));

  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
    date
  );
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

  return `${day}.${month}`;
};

type VotingStatusProps = BoxProps &
  JSX.IntrinsicElements['div'] & { status: PollingStatus };

function mapColor({
  status,
  theme
}: BoxProps & { status: PollingStatus; theme: any }): string {
  if (status === 'APPLICATIONS_RECEIVING') {
    return theme.palette.Orange;
  }

  if (status === 'VOTING' || status === 'FINISHED') {
    return theme.palette.Green;
  }

  if (status === '__DELETED') {
    return theme.palette.White;
  }

  return theme.palette.GreyText;
}

function mapBgColor({
  status,
  theme
}: BoxProps & { status: PollingStatus; theme: any }): string {
  if (status === '__DELETED') {
    return theme.palette.Red;
  }

  return theme.palette.LightGrey50;
}

export const VotingStatusStyled: React.FC<VotingStatusProps> = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  padding: 0 4px;
  height: 16px;
  line-height: 16px;
  border-radius: 2px;
  text-align: center;
  white-space: nowrap;
  flex: 0 0 auto;

  background-color: ${mapBgColor};
  color: ${mapColor};

  @media (min-width: ${breakpoints.laptop}) {
    font-size: 11px;
  }
`;

export const PollStatus: React.FC<BoxProps & {
  poll: PollSubject;
}> = props => {
  const { status, statusDueDate } = props.poll;

  return (
    <VotingStatusStyled {...props} status={status}>
      {AREA_UI_TEXT[status]} {statusDueDate ? formatDate(statusDueDate) : ''}
    </VotingStatusStyled>
  );
};

const AREA_UI_TEXT: Record<PollSubject['status'], string> = {
  PUBLISHED: 'Прием заявок начнется',
  APPLICATIONS_RECEIVING: 'Прием заявок до',
  WAITING_FOR_VOTING: 'Голосование начнется',
  VOTING: 'Идёт голосование до',
  FINISHED: 'Голосование Завершено',
  __DELETED: 'Отменено'
};

type BoxProps = React.ComponentProps<typeof Box>;
