import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import { Routes } from '../../../constants';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/index';
import { Button } from '@we-ui-components/base';

const size = {
  mobMenu: '768px'
};

const HeaderMenuIcon = styled.div`
  @media (max-width: ${size.mobMenu}) {
    display: block;
    position: fixed;
    top: 15px;
    left: 15px;
    width: 30px;
    height: 18px;
    cursor: pointer;
    z-index: 100;
    span {
      top: 8px;
      left: 0;
      position: absolute;
      width: 100%;
      height: 2px;
      background: #000;
      &:first-child {
        top: 0;
      }
      &:last-child {
        top: auto;
        bottom: 0;
      }
    }
    &.active span {
      &:nth-of-type(1) {
        transform: rotate(45deg) translate(4px, 7px);
      }
      &:nth-of-type(2) {
        display: none;
      }
      &:nth-of-type(3) {
        transform: rotate(-45deg) translate(4px, -7px);
      }
    }
    &:hover span {
      transition: 0.2s linear;
    }
  }
`;

const BoxOpenClose = styled.div`
  .open_menu,
  .close_menu {
    position: fixed;
    top: 0;
    z-index: 99;
    display: flex;
    width: 260px;
    height: 100%;
    padding: 45px 0;
    transform: translateX(-100%);
    transition: 0.5s ease;
    box-sizing: border-box;
    background-color: #f2f2f2;
    text-align: center;

    a {
      display: none;
    }
  }

  @media (max-width: ${size.mobMenu}) {
    .open_menu {
      transform: translateX(0);
      transition: 1s ease;
      flex-direction: column;

      a {
        display: block;
        padding: 16px 0;
        margin: 0 10px;
        font-size: 14px;
        text-transform: uppercase;
        border-bottom: 0.7px solid grey;

        &:hover {
          color: #0728a4;
          cursor: pointer;
        }
      }
    }
  }
`;

const BoxBgMob = styled.div`
  .btn_vote {
    display: none;
  }
  @media (max-width: ${size.mobMenu}) {
    position: fixed;
    width: 100%;
    z-index: 80;
    background-color: white;
    box-shadow: 0 3px 9px 0 rgb(0 0 0 / 10%);
    .btn_vote {
      display: block;
    }
  }
`;

export const MenuButton = ({ handleClick, isMenuOpen }) => {
  const handle = () => {
    return isMenuOpen;
  };
  return (
    <HeaderMenuIcon
      className={isMenuOpen ? 'active' : ''}
      onClick={() => {
        handleClick(handle());
      }}
    >
      <span />
      <span />
      <span />
    </HeaderMenuIcon>
  );
};

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return null;
      }
      handler();
    };
    document.addEventListener('mousedown', listener);
  }, [ref, handler]);
};

export const MenuMobile = observer(() => {
  const [isMenuBlock, toggleBlock] = useState(false);
  const node = useRef();

  const { routing } = useStores();

  const toggleMenu = value => {
    toggleBlock(!value);
  };

  useOnClickOutside(node, () => {
    if (isMenuBlock) {
      toggleMenu(true);
    }
  });

  return (
    <BoxBgMob ref={node}>
      <Box direction="row" justify="end">
        <MenuButton handleClick={toggleMenu} isMenuOpen={isMenuBlock} />
        <Button
          fontWeight="800"
          className="btn_vote"
          onClick={() => routing.push(`/${Routes.loginVoter}`)}
        >
          Проголосовать
        </Button>
      </Box>
      <BoxOpenClose>
        <Box className={isMenuBlock ? 'open_menu' : 'close_menu'}>
          <a onClick={() => routing.push(`/${Routes.loginOrganizer}`)}>
            Войти как организатор
          </a>
          <a onClick={() => routing.push(`/${Routes.loginApplicant}`)}>
            Войти как заявитель
          </a>
          <a>Посмотреть проекты</a>
        </Box>
      </BoxOpenClose>
    </BoxBgMob>
  );
});
