import * as React from 'react';
import arrowTopSVG from './arrow-top.svg';
import { Box, BoxProps } from 'grommet';
import { CSSProperties, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { TStoreProps, IColumn } from 'interfaces';
import { Table } from '@we-ui-components/rc-table';
import { ITheme } from 'themes';
import { ThemeContext } from 'styled-components';
import { Pagination } from './components/pagination';
import unsortSVG from './unsort.svg';

interface ICommonTableProps<S> extends BoxProps, TStoreProps<S> {
  style?: CSSProperties;
  emptyListText?: string;
  columns: IColumn[];
  onRowClicked?: (rowData: S) => void;
  customItem?: {
    render:
      | React.FunctionComponent<{
          params: S;
        }>
      | React.ComponentClass<{
          params: S;
        }>;
    bodyStyle?: any;
    bodyClassName?: string;
    dir?: 'row' | 'column';
    wrap?: 'wrap' | 'no-wrap';
    wrapMargin?: string;
  };
  suppressInitialFetch?: boolean;
  suppressInitialization?: boolean;
}

export const CommonTable = observer(
  <S extends object>({
    store,
    columns,
    onRowClicked,
    customItem,
    emptyListText,
    suppressInitialFetch = false,
    suppressInitialization = false
  }: ICommonTableProps<S>) => {
    const theme: ITheme = useContext(ThemeContext);
    const [tableFetchFired, setTableFetchFired] = React.useState(false);

    const onChangeDataFlow = (props: any) => {
      const shouldBeIgnored = () => suppressInitialFetch && !tableFetchFired;
      const allowChangeDataFlow = () => setTableFetchFired(true);

      if (shouldBeIgnored()) {
        // ignore first `onChangeDataFlow` call (which comes from RcTable)
        allowChangeDataFlow();
      } else {
        store.onChangeDataFlow(props);
      }
    };

    useEffect(() => {
      if (!suppressInitialization) {
        store.init();

        return () => store.clear();
      }
    }, []);

    return (
      <>
        {store.fetchStatus !== 'init' &&
        store.fetchStatus !== 'fetching' &&
        !store.data.length ? (
          <Box
            fill={true}
            background={theme.palette.White}
            pad="medium"
            margin={{ vertical: 'medium' }}
            align="center"
          >
            {emptyListText || 'Не найдено записей по данному запросу'}
          </Box>
        ) : null}
        <Table
          columns={[]}
          data={store.data}
          isPending={store.isPending}
          onChangeDataFlow={onChangeDataFlow}
          dataLayerConfig={store.dataFlow}
          customItem={customItem}
        />
      </>
    );
  }
);
