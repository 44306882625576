import * as voteServise from 'services/voteService';
import { useRequest } from 'utils';
import { useCallback } from 'react';
import { PollSubject } from 'interfaces';
import { ISelectOption } from '@we-ui-components/base';

export function useDistrictOptions() {
  const requestFn = useCallback(async () => {
    const response = await voteServise.getDistricts();
    return response.content;
  }, []);

  const [districts, { status }] = useRequest(requestFn, { initialData: [] });

  return [formatDistrictOptions(districts), { status }] as const;
}

function formatDistrictOptions(districts: PollSubject[]): ISelectOption[] {
  return districts.map(it => ({
    text: it.name,
    value: it.id
  }));
}
