import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui';
import { HeadCompact } from './components/compact';
import { HeadWide } from './components/wide';

export function Head() {
  const isTabletOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.tablet
  });

  if (isTabletOrWider) {
    return <HeadWide />;
  }

  return <HeadCompact />;
}
