import * as React from 'react';
import { useStores } from 'stores/index';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui/Responsive';
import { Box } from 'grommet';
import { OkIcon, ShareIcon, VkIcon } from '../icons';
import { Button } from '@we-ui-components/base';
import { IProject } from 'interfaces/apiEntities';
import { baseUrl, ENDPOINTS } from 'services/endpoints';

export type ShareType = 'vk' | 'ok';

export type UrlParams = {
  url: string;
  title: string;
  image?: string;
  imageUrl?: string;
  description?: string;
  noParse?: number;
  noVkLinks?: number;
}

const vkParams = (project: IProject): UrlParams => ({
  url: baseUrl + location.pathname,
  title: project.name,
  image: ENDPOINTS.projectLogo(project.id),
  noParse: 1
})


const okParams = (project: IProject): UrlParams => ({
  url: baseUrl + location.pathname,
  title: project.name,
  imageUrl: ENDPOINTS.projectLogo(project.id)
})


export const SHARE_LINKS: Record<ShareType, string> = {
  vk: 'https://vk.com/share.php',
  ok: 'https://connect.ok.ru/offer'
}

const SHARE_PARAMS: Record<ShareType, (p: any) => UrlParams> = {
  vk: vkParams,
  ok: okParams
}

export function objectToGetParams(object: UrlParams) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

  return params.length > 0 ? `?${params.join('&')}` : '';
}

export const windowOpen = (
  url: string,
  { height = 460, width = 660, ...configRest }: { height?: number; width?: number; [key: string]: any },
) => {
  const config: { [key: string]: string | number } = {
    height,
    width,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
    ...configRest,
  };

  return window.open(
    url,
    '',
    Object.keys(config)
      .map(key => `${key}=${config[key]}`)
      .join(', '),
  );
}

export const ShareLinks = ({ onShare }: { onShare: (type: ShareType) => void }) => {
  return (
    <Box direction="row">
      <VkIcon cursor="pointer" onClick={() => onShare('vk')} />

      <Box margin={{ left: 'xsmall' }}>
        <OkIcon cursor="pointer" onClick={() => onShare('ok')} />
      </Box>
    </Box>
  );
}


export const ShareButton: React.FC<{ project: IProject }> = ({ project }) => {
  const { actionModals } = useStores();

  const isLaptopOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.laptopL
  });
  const isTabletOrWider = useMediaQuery({ minDeviceWidth: breakpoints.tablet });

  const handleClickShare = () => {
    actionModals.open(
      () => (
        <Box pad={{ bottom: 'large' }}>
          <Box direction="row">
            <ShareLinks
              onShare={type => {
                const provideParams = SHARE_PARAMS[type];
                const url = SHARE_LINKS[type]

                windowOpen(url + objectToGetParams(provideParams(project)), {})
              }}
            />
          </Box>

          <Box align="end" style={{ flex: '1 1 100%' }}>
            <Button
              onClick={() => actionModals.closeLastModal()}
              margin={{ left: 'auto' }}>Закрыть</Button>
          </Box>
        </Box>
      ),
      {
        title: 'Поделиться проектом в соц.сетях',
        width: isLaptopOrWider ? '545px' : 'auto',
        modalStyle: {
          maxWidth: isTabletOrWider ? '545px' : '100vw'
        }
      }
    );
  }


  return (
    <Button
      onClick={handleClickShare}
      color={isTabletOrWider ? 'Purple' : 'White'}
      transparent>
      <ShareIcon /> <Box margin={{ left: 'xxsmall' }}>Поделиться</Box>

    </Button>
  )
}
