import * as React from 'react';
import logoSVG from './logo';
import { withTheme } from 'styled-components';
import { BoxProps, Box } from 'grommet';
import { useStores } from 'stores';
import { observer } from 'mobx-react-lite';
import { useIsLoginRoute } from 'pages/utils';
import { TStyledChildrenProps } from 'interfaces';
import { Tabs } from 'components';
import { useLocation, useHistory } from 'react-router';
import { getApplicantTabs, getRegulatorTabs, getVoterTabs } from '../getTabs';
import { getRootPathname } from 'utils';
import { HeaderContainer } from './components';
import { UserMenu } from './UserMenu';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui';

export const HeadWide = withTheme(
  observer(({ theme }: TStyledChildrenProps<BoxProps>) => {
    const history = useHistory();
    const isLogin = useIsLoginRoute();
    const { user } = useStores();
    const { palette, container } = theme;
    const { minWidth, maxWidth } = container;
    const { pathname } = useLocation();

    const isLaptopOrWider = useMediaQuery({
      minDeviceWidth: breakpoints.laptopL
    });

    const getTabs = () => {
      if (user.isContainRoles(['REGULATOR'])) {
        return getRegulatorTabs();
      }

      if (user.isContainRoles(['APPLICANT'])) {
        return getApplicantTabs();
      }

      if (user.isContainRoles(['VOTER'])) {
        return getVoterTabs();
      }

      return [];
    };

    return (
      <HeaderContainer
        style={{
          background: palette.White,
          overflow: 'visible',
          position: 'sticky',
          top: 0,
          zIndex: 2,
          boxShadow: '0px 4px 8px rgba(10, 6, 6, 0.04)'
        }}
      >
        <Box
          direction="row"
          align="center"
          pad={{ horizontal: '32px' }}
          style={{
            minWidth,
            maxWidth,
            margin: '0 auto',
            height: 56,
            width: '100%'
          }}
        >
          <Box style={{ marginRight: 20, height: 24 }}>
            {logoSVG}
          </Box>

          {isLaptopOrWider && (
            <Tabs
              height="100%"
              tabs={getTabs()}
              selected={getRootPathname(pathname).replace('/', '')}
              onChange={id => history.push(`/${id}`)}
            />
          )}

          {!isLogin && user.isAuthorized && (
            <Box
              direction="row"
              justify="end"
              align="center"
              style={{ flex: '1 0 auto' }}
            >
              <UserMenu />
            </Box>
          )}
        </Box>
      </HeaderContainer>
    );
  })
);
