import * as React from 'react';
import { Title } from '@we-ui-components/base';
import { FilterSearch, FilterTabs, CommonTable } from 'components';
import { getTabs } from './getTabs';
import { PROJECT_STATUS } from 'interfaces/apiEntities';
import { inject, observer } from 'mobx-react';
import { IStores } from 'stores/index';
import { Box } from 'grommet';
import { IconButton } from 'ui/IconButton';
import { ProjectListItem } from './applicantColumns';
import { Routes } from '../../constants';

const PlusSVG = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 5.56376H5.56376V9H4.43624V5.56376H1V4.43624H4.43624V1H5.56376V4.43624H9V5.56376Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

type DocumentsPageProps = Pick<IStores, 'projectList' | 'user' | 'routing'>;

@inject('projectList', 'user', 'routing')
@observer
class ApplicantPage extends React.Component<DocumentsPageProps> {
  goToCreateProject = () => {
    this.props.routing.push(`${Routes.createProject}`);
  };

  componentDidMount() {
    this.props.projectList.fetch();
  }

  render() {
    const { projectList } = this.props;

    return (
      <Box
        direction="column"
        background="transparent"
        margin="0 auto"
        pad={{ vertical: 'medium', horizontal: 'small' }}
        width="872px"
      >
        <Box direction="row" align="center" margin={{ vertical: 'medium' }}>
          <Title size="small" bold>
            Мои проекты ({projectList.paginationData.totalElements})
          </Title>
          <IconButton
            onClick={this.goToCreateProject}
            renderIcon={() => <PlusSVG />}
            margin={{ left: '16px' }}
          >
            Создать проект
          </IconButton>
        </Box>
        <FilterSearch store={projectList} filterField="search" width="100%" />

        <FilterTabs
          store={projectList}
          tabs={getTabs()}
          filterField="status"
          initialValue={PROJECT_STATUS.ALL}
          margin={{ vertical: 'small' }}
        />
        <CommonTable
          store={projectList}
          columns={[]}
          emptyListText="Не найдено проектов"
          customItem={{ render: ProjectListItem }}
          suppressInitialFetch
        />
      </Box>
    );
  }
}

export { ApplicantPage };
