import * as React from 'react';
import { Box } from 'grommet';
import { IProject } from 'interfaces';
import { Title } from '@we-ui-components/base';
import { ProjectInfoBox } from './ProjectInfoBox';
import { SliderComponent } from 'ui/Slider/SliderComponent';
import styled from 'styled-components';
import { breakpoints } from 'ui';
import { useMediaQuery } from 'react-responsive';

interface ImplementationDetailsProps extends BoxProps {
  project: IProject;
}

export const ImplementationDetails = ({
  project,
  ...props
}: ImplementationDetailsProps) => {
  const isTabletOrWider = useMediaQuery({
    minWidth: breakpoints.tablet
  });

  return (
    <Container {...props}>
      <Title bold size="xsmall" margin={{ top: '12px', bottom: '8px' }}>
        Результаты реализации проекта
      </Title>

      <ProjectInfoBox
        margin={{ top: 'xsmall' }}
        title="Описание результатов реализации"
        text={project.implDescription}
        noWrap
      />

      <Title
        bold
        size={isTabletOrWider ? '13px' : '18px'}
        margin={{ top: '21px' }}
      >
        Файлы, подтверждающие результаты реализации
      </Title>
      <SliderComponent
        accessType="public"
        slideFiles={project.implFiles}
        arrow
      />
    </Container>
  );
};

const Container = styled(Box)`
  border-top: 1px solid ${props => props.theme.palette.LightGrey};
`;

type BoxProps = React.ComponentProps<typeof Box>;
