import * as React from 'react';
import { ArchivePollApiDTO } from 'interfaces';
import { Box } from 'grommet';
import styled from 'styled-components';
import { Text, Title } from '@we-ui-components/base';
import { PollingType } from 'pages/Organizer/tabs';
import { PollStatus } from 'components/PollStatus/PollStatus';
import { ListItemCounter } from 'pages/Organizer/ListItemCounter';
import { ProjectIcon, VotesIcon } from 'pages/Organizer/icons';
import { useHistory } from 'react-router';
import { Routes } from 'constants/routes';
import { dateTimeFormat } from 'utils';
import { breakpoints } from 'ui';

interface ArchivePollTableItemProps {
  params: ArchivePollApiDTO;
}

export function ArchivePollTableItem({
  params: poll
}: ArchivePollTableItemProps) {
  const history = useHistory();

  const navigate = () => {
    history.push(`/${Routes.archive}/${poll.id}`);
  };

  const formatPollName = () => {
    const PREFIX =
      poll.subject.type === PollingType.schoolGroup ? 'школьной группе ' : '';

    const name = poll.subject.name || '--';
    const year = poll.subject.year || '--';

    return `${PREFIX}${name}, ${year}`;
  };

  const formatVotingDates = () => {
    const formatDate = (date: string) =>
      date ? dateTimeFormat(date) : 'не указан';

    const startDate = formatDate(poll.votingStartDate);
    const endDate = formatDate(poll.votingEndDate);

    return (
      <>
        {startDate} &mdash; {endDate}
      </>
    );
  };

  return (
    <ArchivePollTableItemContainer onClick={navigate}>
      <Box gap="8px">
        <Title size="xsmall" bold color="#172b4d">
          Голосование по {formatPollName()}
        </Title>

        <Text margin="2px 0 8px" size="xsmall" color="#172b4d">
          {formatVotingDates()}
        </Text>
      </Box>

      <Box gap="4px">
        <PollStatus style={{ marginRight: 'auto' }} poll={poll as any} />

        <CounterContainer>
          <ListItemCounter
            count={poll.voteCount}
            icon={<VotesIcon />}
            margin="0"
          />

          {!!poll.bulletinProjectsCount && (
            <ListItemCounter
              count={poll.bulletinProjectsCount}
              icon={<ProjectIcon />}
              margin={{ left: '16px' }}
            />
          )}
        </CounterContainer>
      </Box>
    </ArchivePollTableItemContainer>
  );
}

const ArchivePollTableItemContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  cursor: pointer;

  margin-top: 24px;
  padding: 16px;
  background: ${props => props.theme.palette.White};
  box-shadow: -2px 0px 0px #f2f7f5, -10px 0px 20px rgba(0, 0, 0, 0.03);

  &:hover {
    box-shadow: 0 4px 8px rgba(10, 6, 6, 0.06);
  }

  @media (min-width: ${breakpoints.laptop}) {
    flex-direction: row;
  }
`;

const CounterContainer = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;

  @media (min-width: ${breakpoints.laptop}) {
    justify-content: flex-end;
    margin-top: 0;
  }
`;
