import React from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';
import { Text } from '@we-ui-components/base';
import groupSVG from './group.svg';
import citySVG from './location_city.svg';

export interface IRegionCheckbox {
  checked: boolean;
  searched: boolean;
  disabled: boolean;
  onClick: () => void;
  text: string;
}

export const RegionBox = (props: IRegionCheckbox) => {
  const { checked, searched, disabled, text, onClick } = props;
  return (
    <RegionCheckboxWrap
      searched={searched}
      disabled={disabled}
      direction="row"
      align="center"
      onClick={() => !disabled && onClick()}
    >
      <CheckboxIcon checked={checked} />
      <div>{text}</div>
    </RegionCheckboxWrap>
  );
};

function getCount(str: string) {
  const numbers = str
    .split(' ')
    .filter(i => !isNaN(Number(i)))
    .map(i => +i);
  if (numbers.length === 1) {
    if (numbers[0] < 100) {
      numbers.unshift(0);
    } else {
      return `${numbers[0]}+`;
    }
  }

  numbers[1] = numbers[1] - 1;

  return numbers.join(' - ');
}

export interface ISchoolCheckbox extends IRegionCheckbox {
  text: string;
  personalCount: number;
}

export const SchoolBox = (props: ISchoolCheckbox) => {
  const { checked, searched, disabled, text, onClick, personalCount } = props;
  const count = getCount(text);

  return (
    <RegionCheckboxWrap
      searched={searched}
      disabled={disabled}
      direction="row"
      align="center"
      onClick={() => !disabled && onClick()}
    >
      <CheckboxIcon checked={checked} />
      <Box gap="3px">
        <Text
          size="xsmall"
          style={{
            maxWidth: '240px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {text}
        </Text>
        <Box direction="row" gap="xsmall" align="center">
          <img src={groupSVG} alt="" />
          <Text bold size="xxsmall">
            {count}
          </Text>
        </Box>
      </Box>
      {/*<Box*/}
      {/*  direction="row"*/}
      {/*  justify="end"*/}
      {/*  align="center"*/}
      {/*  gap="xsmall"*/}
      {/*  style={{ flex: '1' }}*/}
      {/*>*/}
      {/*  <Text size="small" bold>*/}
      {/*    {personalCount}*/}
      {/*  </Text>*/}
      {/*  <img src={citySVG} alt="" />*/}
      {/*</Box>*/}
    </RegionCheckboxWrap>
  );
};

export const RegionCheckboxWrap = styled(Box)<{
  searched: boolean;
  disabled: boolean;
}>`
  width: 314px;
  max-width: 25%;
  padding: 17px;
  margin-bottom: 12px;
  margin-left: 12px;
  background-color: #efefef;
  border-radius: 4px;
  border: 3px solid ${props => (props.searched ? '#3B33B0' : '#EFEFEF')};
  opacity: ${props => (props.disabled ? '0.3' : '1')};
  transition: 0.2s all;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  overflow: hidden;

  * {
    user-select: none;
  }
`;

export const CheckboxIcon = (props: { checked: boolean }) => {
  return (
    <CheckboxWrap>
      <CheckboxMark checked={props.checked} />
    </CheckboxWrap>
  );
};

const CheckboxWrap = styled.div`
  display: flex;
  width: 14px;
  height: 14px;
  padding: 2px;
  border: 3px solid #3b33b0;
  border-radius: 5px;
  margin-right: 12px;
  flex: 0 0 auto;
`;

const CheckboxMark = styled.div<{ checked: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: ${props => (props.checked ? '#3B33B0' : 'transparent')};
`;
