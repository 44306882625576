import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Box } from 'grommet';
import {
  Form,
  Input,
  isEmail,
  isRequired,
  MobxForm
} from '@we-ui-components/form';
import { Text, Title } from '@we-ui-components/base';
import { passwordValidators, phoneValidators } from './userPasswordValidators';

import { INewUser } from 'interfaces';
import { useDistrictOptions } from './useDistrictOptions';

import {
  FixedHeightUserSelect,
  FixedWidthUserInput,
  UserRoleButton
} from '../components/styled';

interface CreateUserProps {
}

export const CreateUserModal = observer<CreateUserProps>(
  function CreateUser(props, ref) {
    const handleSubmit = async () => {
      const data: INewUser = await formRef.current.validateFields();
      return data;
    };

    const [districtOptions, { status }] = useDistrictOptions();

    React.useImperativeHandle(ref, () => ({
      onValidate: handleSubmit
    }));

    const formData = useLocalStore(() => ({
      ...DEFAULT_USER
    }));

    const formRef = React.useRef<MobxForm>();

    return (
      <Box>
        <Title
          margin={{ top: '-35px', bottom: '24px' }}
          style={{ maxWidth: '700px' }}
          bold
          size="small"
        >
          Добавление нового пользователя
        </Title>

        <Form ref={formRef} data={formData} {...({} as any)}>
          <Box direction="row" justify="between">
            <FixedWidthUserInput
              size="full"
              placeholder="Фамилия"
              name="lastName"
            />

            <FixedWidthUserInput
              size="full"
              placeholder="Имя"
              name="firstName"
            />
          </Box>

          <FixedWidthUserInput placeholder="Отчество" name="patronymic"/>

          <Box direction="row" justify="between">
            <FixedWidthUserInput
              placeholder="Email*"
              name="email"
              rules={[isRequired, isEmail]}
            />

            <FixedWidthUserInput
              placeholder="Пароль*"
              type="password"
              name="password"
              rules={passwordValidators}
            />
          </Box>

          <Box gap="8px" margin={{ bottom: '24px' }}>
            <Text size="13px" color="Grey">
              Роль пользователя*
            </Text>
            <Box direction="row" gap="8px">
              <UserRoleButton
                active={formData.businessRole === 'APPLICANT'}
                onClick={() => {
                  formData.businessRole = 'APPLICANT';
                }}
              >
                Заявитель
              </UserRoleButton>

              <UserRoleButton
                active={formData.businessRole === 'REGULATOR'}
                onClick={() => {
                  formData.businessRole = 'REGULATOR';
                  formData.districtId = null;
                  formData.company = null;
                }}
              >
                Организатор
              </UserRoleButton>
            </Box>
          </Box>

          {formData.businessRole === 'APPLICANT' && (
            <>
              <FixedHeightUserSelect
                name="districtId"
                loading={status === 'fetching'}
                options={districtOptions}
                label="Район пользователя"
              />
              <Input name="company" placeholder="Организация пользователя"/>
            </>
          )}

          <FixedWidthUserInput
            placeholder="Телефон"
            name="phone"
            rules={phoneValidators}
          />
        </Form>
      </Box>
    );
  },
  {
    forwardRef: true
  }
);

const DEFAULT_USER: INewUser = {
  email: '',
  password: '',
  firstName: null,
  lastName: null,
  patronymic: null,
  districtId: null,
  company: null,
  phone: null,
  businessRole: 'APPLICANT'
};
