import { action, observable } from 'mobx';
import { guid } from 'utils';
import { statusFetching } from 'we-oauth2/lib/constants/types';
import { ButtonExProps } from 'ui';
import { CSSProperties } from 'styled-components';

export type ActionModalBody = (data: {
  actionData?: any;
}) => React.ComponentElement<{ onClose?: () => any; actionData?: any }, any>;

export interface ActionModalOptions {
  width?: string;
  position?: 'flex-start' | 'center';
  title?: string;
  onApply?: (data?: any) => Promise<any>;
  onClose?: (data?: any) => Promise<any>;
  additionalText?: React.FC;
  closeText?: React.FC;
  applyIcon?: React.FC;
  applyText?: React.FC;
  showOther?: boolean;
  noValidation?: boolean;
  initData?: any;
  applyButtonProps?: ButtonExProps;
  isOverlayClose?: boolean;
  overlayStyle?: CSSProperties;
  modalStyle?: CSSProperties;
  actionsContainerStyle?: CSSProperties;
  setTimeCloseModal?: number;
}

export interface ActionModalConfig {
  id: string;
  bodyRender: ActionModalBody | any;
  options: ActionModalOptions;
  error?: string;
  actionStatus?: statusFetching;
}

export class ActionModalsStore {
  @observable public pool: Array<ActionModalConfig> = [];

  @action.bound
  public open = (
    bodyRender: ActionModalBody | any,
    options?: ActionModalOptions
  ): Promise<any> => {
    const id = guid();

    const modalConfig: ActionModalConfig = { bodyRender, options, id };

    const deferPromise = new Promise((resolve, reject) => {
      const onApply = options.onApply;

      options.onApply = (data: any) => {
        return (
          onApply &&
          onApply(data)
            .then(resolve)
            .then(() => this.close(id))
            .catch(err => {
              this.rejectError(err, reject);
            })
        );
      };
    });

    this.pool.push(modalConfig);

    if (options.setTimeCloseModal) {
      setTimeout(() => this.close(id), options.setTimeCloseModal);
    }

    return deferPromise;
  };

  @action.bound
  rejectError = (err: any, reject?: any) => {
    const id = this.pool[this.pool.length - 1].id;

    this.pool.find(m => m.id === id).error =
      err.detail || err.title || err.message || err;

    if (reject) reject();
    return Promise.reject(err);
  };

  @action.bound
  public close = (id: string) => {
    const modalConfig = this.pool.find(modal => modal.id === id);

    if (modalConfig) {
      if (modalConfig.options.onClose) {
        modalConfig.options.onClose();
      }

      this.pool = this.pool.filter(modal => modal.id !== id);
    }
  };

  @action.bound
  public closeLastModal = () => {
    if (this.pool.length > 0) {
      this.close(this.pool[this.pool.length - 1]?.id);
    }
  };

  @action.bound
  public closeAllModals = () => {
    this.pool.map(item => this.close(item.id));
  };
}
