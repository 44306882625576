import * as React from 'react';
import loadable from '@loadable/component';
import * as d3Type from 'd3';
import styles from './region.styl';
import clsx from 'classnames';
import { Spinner2 } from 'ui/Spinner2';
import { Box } from 'grommet';
import { DistrictType, PollSubject } from 'interfaces/apiEntities';

export type Area = {
  regionCode: string;
  name: string;
  status: PollingStatus;
};

export type PollingStatus =
  | 'PUBLISHED'
  | 'APPLICATIONS_RECEIVING'
  | 'VOTING'
  | 'FINISHED'
  | 'WAITING_FOR_VOTING'
  | '__DELETED'; // Временный

const MAP_TYPE_URLS: Record<DistrictType, string> = {
  OBLAST_DISTRICTS: 'map.svg',
  VOLGOGRAD_DISTRICTS: 'city_map.svg'
};

export type RegionAreaProps = Readonly<{
  isPending: boolean;
  type: DistrictType;
  areas: PollSubject[];
  isDisabled?: (PollSubject) => boolean;
  onClickArea?: (item: PollSubject, node: SVGGraphicsElement) => void;
  d3: typeof d3Type;
  mapRef?: React.RefObject<HTMLElement>;
}>;

export interface IRegionArea {
  zoomTo(target: PollSubject): void;
}

const D3 = loadable.lib(() => import('d3'));

export class RegionMapRender extends React.Component<RegionAreaProps, any>
  implements IRegionArea {
  private mapRef = React.createRef<HTMLDivElement>();
  private legendRef = React.createRef<HTMLDivElement>();

  public getMapRef() {
    return (this.props.mapRef || this.mapRef).current;
  }

  public zoomTo = (target: PollSubject) => {
    const d3 = this.props.d3;
    const mapRef = this.getMapRef();

    const node = d3
      .select(mapRef)
      .selectAll(`g[id=${target.regionCode}]`)
      .node() as SVGGraphicsElement;

    const xy = getBoundingBox(node);

    const { width: mw, height: mh } = mapRef.getBoundingClientRect();

    const scale = Math.min(mw / xy[1], mh / xy[3], 3);

    const tx = -xy[0] + (xy[1] * scale) / (2 * scale);
    const ty = -xy[2] + (xy[3] * scale) / (2 * scale);

    d3.select(mapRef)
      .selectAll('#viewport')
      .transition()
      .duration(750)
      .attr(
        'transform',
        'scale(' + scale + ')translate(' + tx + ',' + ty + ')'
      );
  };

  initializeMap() {
    const { onClickArea, areas, type, isDisabled } = this.props;
    const d3 = this.props.d3;

    const mapRef = this.getMapRef();

    d3.xml(MAP_TYPE_URLS[type], { headers: { Accept: 'image/svg+xml' } }).then(
      (svgMap: XMLDocument) => {
        const map = d3
          .select(mapRef)
          .node()
          .appendChild(svgMap.documentElement);

        const areasMap = d3
          .select(map)
          .selectAll('#region')
          .attr('class', styles.region)
          .selectAll('g');

        d3.select(map)
          .selectAll('#water')
          .attr('class', styles.regionWater);

        d3.select(map)
          .selectAll('#titles')
          .attr('class', styles.regionTitles);

        areasMap
          .on('mouseover', function(d) {
            d3.select(this as SVGGraphicsElement)
              .node()
              .classList.add(styles.regionAreaHover);
          })
          .on('mouseout', function(d) {
            if (!d3.select(this).classed(styles.regionAreaHover)) return;

            d3.select(this as SVGGraphicsElement)
              .node()
              .classList.remove(styles.regionAreaHover);
          });

        areasMap
          .data(areas, function(this: SVGGraphicsElement, d: Area) {
            return d && d.regionCode ? d.regionCode : this.id;
          })
          .each(function(d) {
            const className = clsx(styles.regionArea, {
              [styles.regionAreaStatusVote]:
                d.status === 'VOTING' || d.status === 'FINISHED',
              [styles.regionAreaStatusAcceptance]:
                d.status === 'APPLICATIONS_RECEIVING',
              [styles.regionAreaDisabled]: isDisabled(d)
            });

            d3.select(this as SVGGraphicsElement)
              .node()
              .classList.add(...className.split(' '));
          })
          .on('click', function(ev, area) {
            if (isDisabled(area)) {
              return;
            }

            onClickArea &&
              onClickArea(area, d3.select(this).node() as SVGGraphicsElement);
          });
      }
    );
  }

  componentDidMount() {
    this.initializeMap();
  }

  componentDidUpdate(prevProps: Readonly<RegionAreaProps>) {
    const reinitializeMap = this.props.type !== prevProps.type;

    // TODO only upd bounded data on areas change

    if (reinitializeMap) {
      this.props.d3
        .select(this.getMapRef())
        .select('svg')
        .remove();

      this.initializeMap();
    }
  }

  render() {
    if (this.props.mapRef) {
      return null;
    }

    return (
      <>
        <div ref={this.mapRef} />
        <div ref={this.legendRef} />
      </>
    );
  }
}

export const RegionMap: React.FC<Omit<
  RegionAreaProps,
  'd3'
>> = React.forwardRef((props, ref: React.Ref<RegionMapRender>) => {
  const loader = (
    <Box align="center" justify="center" height="medium">
      <Spinner2 />
    </Box>
  );

  if (props.isPending) {
    return loader;
  }

  return (
    <D3>
      {d3 => {
        return props.isPending ? (
          loader
        ) : (
          <RegionMapRender {...props} d3={d3} ref={ref} />
        );
      }}
    </D3>
  );
});

function getBoundingBox<E extends SVGGraphicsElement>(node: E) {
  const bbox = node.getBBox();

  const cx = bbox.x + bbox.width / 2;
  const cy = bbox.y + bbox.height / 2;

  return [bbox.x, bbox.width, bbox.y, bbox.height, cx, cy];
}
