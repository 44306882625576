import React from 'react';
import { Box } from 'grommet';
import { Text } from '@we-ui-components/base';
import { CollapseArrow, TavernIcon } from '../../Poll/icons';
import { INode, NODE_TYPE } from './helpers';

const NodeItem = (props: {
  node: INode;
  customItem: any;
  isSearchMatch: boolean;
  isSearchFocus: boolean;
  lowerSiblingCounts: number[];
  scaffoldBlockPxWidth: number;
}) => {
  const RenderItem = props.customItem.render;

  const isFocused = props.isSearchFocus && props.node.type === NODE_TYPE.SCHOOL;

  const scaffoldBlockCount = props.lowerSiblingCounts.length - 1;

  return (
    <Box
      direction="row"
      fill={true}
      align="center"
      style={{
        // border: props.isSearchMatch ? '1px solid yellow' : '',
        borderBottom: '1px solid #dedede',
        cursor: 'auto'
        // background: props.isSearchFocus ? '#efefef' : ''
      }}
    >
      {!!props.node.polling ? (
        <RenderItem {...props.node} />
      ) : (
        <Box
          direction="row"
          justify="between"
          align="center"
          fill={true}
          style={{
            paddingRight: props.scaffoldBlockPxWidth * scaffoldBlockCount
          }}
        >
          <Text
            bold={isFocused ? true : false}
            style={{ fontSize: isFocused ? 14 : 13 }}
          >
            {props.node.title}
          </Text>
          <Box margin={{ right: '10px' }}>
            <Text
              bold={isFocused ? true : false}
              style={{ fontSize: isFocused ? 14 : 13 }}
            >
              {props.node.type === NODE_TYPE.DISTRICT && props.node.children ? (
                <Box direction="row" align="center">
                  <TavernIcon style={{ opacity: 0.2 }} />
                  <span style={{ marginLeft: '5px' }}>
                    {props.node.children.length}
                  </span>
                </Box>
              ) : (
                ''
              )}
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};

// eslint-disable-next-line react/prefer-stateless-function
export const NodeRenderer = (props: any) => {
  const {
    scaffoldBlockPxWidth,
    toggleChildrenVisibility,
    connectDragPreview,
    connectDragSource,
    isDragging,
    canDrop,
    canDrag,
    node,
    title,
    subtitle,
    draggedNode,
    path,
    treeIndex,
    isSearchMatch,
    isSearchFocus,
    icons,
    buttons = [],
    className,
    style,
    didDrop,
    swapFrom,
    swapLength,
    swapDepth,
    treeId, // Not needed, but preserved for other renderers
    isOver, // Not needed, but preserved for other renderers
    parentNode, // Needed for dndManager
    rowDirection,
    ...otherProps
  } = props;

  return (
    <Box direction="row" align="center" fill={true}>
      <Box
        margin={{
          horizontal: 'xsmall'
        }}
        style={{ height: '100%' }}
      >
        {toggleChildrenVisibility &&
          node.children &&
          (node.children.length > 0 || typeof node.children === 'function') && (
            <Box
              onClick={() =>
                toggleChildrenVisibility({
                  node,
                  path,
                  treeIndex
                })
              }
              style={{ height: '100%' }}
              align="center"
              justify="center"
            >
              <CollapseArrow
                style={{
                  transform: node.expanded && 'rotate(180deg)',
                  marginRight: '8px'
                }}
              />
            </Box>
          )}
      </Box>

      <NodeItem {...props} />
    </Box>
  );
};
