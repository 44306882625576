import { PROJECT_STATUS, TTabITabOptionsEx } from 'interfaces';

export const getTabs = (): TTabITabOptionsEx<PROJECT_STATUS> => [
  {
    id: PROJECT_STATUS.ALL,
    text: 'Все'
  },
  {
    id: PROJECT_STATUS.DONE,
    text: 'Реализован'
  },
  {
    id: PROJECT_STATUS.IN_PROGRESS,
    text: 'В реализации'
  },
  {
    id: PROJECT_STATUS.ARCHIVED,
    text: 'В архиве'
  },
  {
    id: PROJECT_STATUS.APPROVED,
    text: 'Принят'
  },
  {
    id: PROJECT_STATUS.DECLINED,
    text: 'Отклонен'
  }
];
