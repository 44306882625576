import { IStores } from '.';
import { ListStoreConstructor } from './core/ListStoreConstructor';
import { getIpObjectList } from 'services';
import { IpObjectList } from 'interfaces';

export class IpObjectListStore extends ListStoreConstructor<IpObjectList> {
  constructor(stores: IStores) {
    super(stores, getIpObjectList, { pollingInterval: 5e4 });
  }
}
