import { IStores } from '.';
import { ListStoreConstructor } from './core/ListStoreConstructor';
import * as usersService from 'services/usersService';
import { IUser, INewUser, UpdateUserFullyRequest } from 'interfaces';
import { encryptPassword } from 'utils';
import { observable } from "mobx";

export class UserListStore extends ListStoreConstructor<IUser> {
  constructor(stores: IStores) {
    super(
      stores,
      async params => {
        return await usersService.getUsersList(params);
      },
      {}
    );
  }
  @observable
  public search: string = '';

  createUser({ password, ...user }: INewUser) {
    const hashedPassword = encryptPassword(password);
    return usersService.createUser({ password: hashedPassword, ...user });
  }

  public async deleteUser(personId) {
    return await usersService.deleteUser(personId);
  }

  public selectUser(personId: string) {
    return this.data.find(it => it.personId === personId);
  }

  public updateSearch(next: string): void {
    this.search = next;
    this.onChangeDataFlow({ filters: { search: next } }, { withDebounce: true });
  }

  public async isDeletableUser(personId) {
    const person = this.selectUser(personId);
    if (person.businessRole === 'APPLICANT') return true;

    // наверное, можно ограничиться проверкой на текущего пользователя,
    // так как по логике последний Огранизатор должен быть текущим пользователем,
    // но эта проверка все равно будет полезной на тот случай, если произошел
    // какой-то рассинхрон бэка с фронтом
    return await this.isMoreThanOneRegulator();
  }

  public isCurrentUser(personId: string) {
    return this.stores.user.userInfo.personId === personId;
  }

  public async isMoreThanOneRegulator() {
    const response = await usersService.getUsersList({
      businessRole: 'REGULATOR'
    });

    return response.totalElements > 1;
  }

  public async updateUserPassword(personId: string, newPassword: string) {
    const hashedPassword = encryptPassword(newPassword);
    return usersService.changePassword(personId, { password: hashedPassword });
  }

  public async updateUserByAdmin(
    personId: string,
    updatedUser: UpdateUserFullyRequest
  ) {
    return usersService.updateUser(personId, updatedUser);
  }
}
