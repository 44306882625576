import React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { Box } from 'grommet';
import { RegionBox, SchoolBox } from './components';
import { IGroupObject } from '../Step1';

interface IProps {
  searchedOptions: IGroupObject[];
  options: IGroupObject[];
  value: IGroupObject[];
  displayType: 'REGIONS' | 'SCHOOL_PARTY';
  onChange: (objects: IGroupObject[]) => void;
}

@observer
export class RegionList extends React.Component<IProps> {
  @computed
  get propertiesList() {
    const { options, searchedOptions, value } = this.props;
    const searched = searchedOptions.reduce((res, region) => {
      res[region.value] = true;
      return res;
    }, {});

    const checked = value.reduce((res, region) => {
      res[region.value] = true;
      return res;
    }, {});

    const disabled = options.reduce((res, region) => {
      if (region.disabled) {
        res[region.value] = true;
      }
      return res;
    }, {});

    return options.reduce((accum, region) => {
      accum[region.value] = {
        searched: !!searched[region.value],
        checked: !!checked[region.value],
        disabled: !!disabled[region.value]
      };
      return accum;
    }, {});
  }

  get disabledOptions() {
    return Object.values(this.propertiesList).filter(
      (i: any) => i.disabled
    ) as IGroupObject[];
  }

  checkRegion = (regionValue: number, checked: boolean) => {
    const { options, value, onChange } = this.props;
    if (checked) {
      const region = options.find(i => i.value === regionValue);
      return onChange(value.concat([region]));
    }

    onChange(value.filter(i => i.value !== regionValue));
  };

  checkAll = () => {
    const { options, value, onChange } = this.props;

    options.length - this.disabledOptions.length === value.length
      ? onChange([])
      : onChange(
          options
            .filter(i => !i.disabled)
            .filter(i => !this.disabledOptions.find(j => j.value === i.value))
        );
  };

  render() {
    const { options, value, displayType } = this.props;

    const DisplayBox =
      displayType === 'REGIONS' ? RegionBox : (SchoolBox as any);
    return (
      <Box style={{ marginLeft: '-12px', marginTop: '16px' }}>
        <RegionBox
          searched={false}
          disabled={false}
          checked={
            options.length - this.disabledOptions.length === value.length
          }
          text="Выбрать все"
          onClick={this.checkAll}
        />
        <Box direction="row" style={{ flexWrap: 'wrap' }}>
          {this.props.options.map(region => {
            const { checked, searched, disabled } = this.propertiesList[
              region.value
            ];
            return (
              <DisplayBox
                key={region.value}
                checked={checked}
                searched={searched}
                disabled={disabled}
                onClick={() => this.checkRegion(region.value, !checked)}
                text={region.name}
                personalCount={region.activeProjectsCount}
              />
            );
          })}
        </Box>
      </Box>
    );
  }
}
