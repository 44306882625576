import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from 'grommet';
import { useStores } from 'stores';
import { Text, Title } from '@we-ui-components/base';
import styled from 'styled-components';
import { Spinner2, ButtonEx, CloseIcon, Error as ErrorUI } from 'ui';
import { useDeleteUser, useIsDeletableUser } from './utils';

type DeleteUserModalProps = {
  personId: string;
};

export function DeleteUserModal({ personId }: DeleteUserModalProps) {
  const [isDeletable, { status }] = useIsDeletableUser(personId);

  if (status === 'fetching') {
    return (
      <Container align="center" justify="center">
        <Spinner2 color="Grey" />
      </Container>
    );
  }

  if (!isDeletable) {
    return <SingleOrganizerMessage />;
  }

  return <DeleteUserForm personId={personId} />;
}

export const DeleteUserForm = observer<DeleteUserModalProps>(
  function DeleteUserModal({ personId }) {
    const { userList, modals } = useStores();
    const person = userList.selectUser(personId);
    const [deleteUser, { status, error }] = useDeleteUser(personId);

    return (
      <Container justify="between">
        <Title size="small" bold margin={{ bottom: '6px' }}>
          Удалить пользователя
        </Title>

        <Text
          size="xsmall"
          fontWeight="500"
          style={{ lineHeight: '19px', maxWidth: '350px' }}
          margin={{ bottom: '32px' }}
        >
          Вы уверены, что хотите удалить пользователя{' '}
          <Email>{person.email}</Email> ?
        </Text>

        {status === 'error' && <ErrorUI error={error} />}

        <Box direction="row" justify="end" align="center" fill={true}>
          <ButtonEx
            size="auto"
            fontSize="16px"
            transparent
            style={{
              padding: '11px 20px',
              marginRight: 16,
              height: 40
            }}
            onClick={modals.closeModal}
            color="Black"
            disabled={status === 'fetching'}
          >
            Отмена
          </ButtonEx>
          <ButtonEx
            style={{
              padding: '12px 20px',
              height: 40
            }}
            bgColor="Red"
            fontSize="16px"
            size="auto"
            onClick={deleteUser}
            isLoading={status === 'fetching'}
            disabled={status === 'fetching'}
          >
            Удалить
          </ButtonEx>
        </Box>
      </Container>
    );
  }
);

function SingleOrganizerMessage() {
  return (
    <Container pad={{ right: '74px' }}>
      <Title size="small">
        Невозможно удалить последнюю учетную запись с ролью Организатор
      </Title>
    </Container>
  );
}

const Container: typeof Box = ({ children, ...props }) => {
  const { modals } = useStores();

  return (
    <StyledBox {...props}>
      <StyledCloseIcon hover={true} onClick={modals.closeModal} size="medium" />
      {children}
    </StyledBox>
  );
};

const Email = styled.span`
  color: ${props => props.theme.palette.Purple};
`;

const StyledBox = styled(Box)`
  width: 482px;
  padding: 32px;
  min-height: 163px;
  position: relative;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;

  top: 30px;
  right: 30px;
`;
