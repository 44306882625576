import * as React from 'react';
import { Box } from 'grommet';
import { useTheme } from 'utils';
import { Button, Text } from '@we-ui-components/base';
import { dateFormatDescription } from '../utils';
import { useStores } from 'stores';
import { PollSubjectShort } from 'interfaces';
import { VotesIcon } from 'pages/Organizer/icons';
import { CollapseArrow } from '../icons';
import { observer } from 'mobx-react';
import { PollingType } from '../../Organizer/tabs';
import styled from 'styled-components';

export const AboutVote = observer(function AboutVote() {
  const theme = useTheme();
  const { activePoll, activePollResults, user } = useStores();

  const isVoter = user.isContainOneOfRoles(['VOTER']);
  const isResultsAvailable = activePollResults.isPollResultsAvailable(
    activePoll.poll
  );
  const isResultsReady = activePoll.poll.systemStatus === 'RESULTS_READY';
  const pollResult = isResultsReady
    ? activePoll.poll.votesCount
    : activePoll.poll.dynamicVoteCount;

  const isAboutVotingHide =
    isVoter &&
    (activePoll.poll.status === 'FINISHED' ||
      activePoll.poll.status === 'VOTING');

  if (!isResultsAvailable && isAboutVotingHide) {
    return;
  }

  return (
    <Box background={theme.palette.White} pad="large">
      {!isAboutVotingHide && (
        <>
          <Text bold size="xsmall" margin={{ bottom: 'small' }}>
            О голосовании
          </Text>

          <Text fontWeight="400" size="xsmall" margin={{ bottom: 'small' }}>
            Голосование:{' '}
            {dateFormatDescription(activePoll.poll.votingStartDate)} →{' '}
            {dateFormatDescription(activePoll.poll.votingEndDate)}
          </Text>
          <Text fontWeight="400" size="xsmall" margin={{ bottom: 'small' }}>
            Прием заявок:{' '}
            {dateFormatDescription(activePoll.poll.applicationStartDate)} →{' '}
            {dateFormatDescription(activePoll.poll.applicationEndDate)}
          </Text>
          <Text fontWeight="400" size="xsmall" margin={{ bottom: 'small' }}>
            Тип: {POLL_TYPE_UI_TEXT[activePoll.poll.subject.type]}
          </Text>
          <Text fontWeight="400" size="xsmall" margin={{ bottom: 'small' }}>
            Минимальное кол-во голосов:{' '}
            {activePoll.poll.votingThreshold === null
              ? 'Любое'
              : activePoll.poll.votingThreshold}
          </Text>
        </>
      )}

      {isResultsAvailable && (
        <>
          {!isVoter && <Divider />}

          <Text bold size="xsmall" margin={{ bottom: 'small' }}>
            Ход голосования:{' '}
            {
              activePoll.poll.systemStatus === 'ERROR' ?
                'ошибка подсчета' :
                isResultsReady ? 'подсчет завершен' : 'подсчет голосов'
            }
          </Text>

          <Text
            fontWeight="400"
            size="xsmall"
            margin={{ bottom: 'small' }}
            color={
              isResultsReady ? theme.palette.BlackText : theme.palette.GreyText
            }
          >
            Голосов: <VotesIcon /> {pollResult}
          </Text>
        </>
      )}
    </Box>
  );
});

export const AboutVoteCollapse: typeof Box = observer(
  function AboutVoteCollapse(props) {
    const theme = useTheme();
    const [expanded, setExpanded] = React.useState(false);
    const { activePoll, activePollResults, user } = useStores();

    const isVoter = user.isContainOneOfRoles(['VOTER']);
    const isResultsAvailable = activePollResults.isPollResultsAvailable(
      activePoll.poll
    );
    const isResultsReady = activePoll.poll.systemStatus === 'RESULTS_READY';
    const pollResult = isResultsReady
      ? activePoll.poll.votesCount
      : activePoll.poll.dynamicVoteCount;

    const isAboutVotingHide =
      isVoter &&
      (activePoll.poll.status === 'FINISHED' ||
        activePoll.poll.status === 'VOTING');

    if (!isResultsAvailable && isAboutVotingHide) {
      return;
    }

    return (
      <Box background="white" pad="14px 26px" {...props}>
        <Box
          direction="row"
          justify="start"
          align="center"
          onClick={() => setExpanded(p => !p)}
        >
          <CollapseArrow
            style={{
              transform: expanded && 'rotate(180deg)',
              marginRight: '8px'
            }}
          />

          <Text bold size="xsmall">
            О голосовании
          </Text>
        </Box>

        {expanded && (
          <>
            {!isAboutVotingHide && (
              <>
                <Text
                  fontWeight="400"
                  size="xsmall"
                  margin={{ vertical: 'small' }}
                >
                  Голосование:
                </Text>
                <Text
                  fontWeight="400"
                  size="xsmall"
                  margin={{ bottom: 'small' }}
                >
                  {dateFormatDescription(activePoll.poll.votingStartDate)} →{' '}
                  {dateFormatDescription(activePoll.poll.votingEndDate)}
                </Text>

                <Text
                  fontWeight="400"
                  size="xsmall"
                  margin={{ bottom: 'small' }}
                >
                  Прием заявок:
                </Text>
                <Text
                  fontWeight="400"
                  size="xsmall"
                  margin={{ bottom: 'small' }}
                >
                  {dateFormatDescription(activePoll.poll.applicationStartDate)}{' '}
                  → {dateFormatDescription(activePoll.poll.applicationEndDate)}
                </Text>

                <Text
                  fontWeight="400"
                  size="xsmall"
                  margin={{ bottom: 'small' }}
                >
                  Тип: {POLL_TYPE_UI_TEXT[activePoll.poll.subject.type]}
                </Text>
                <Text
                  fontWeight="400"
                  size="xsmall"
                  margin={{ bottom: 'small' }}
                >
                  Минимальное кол-во голосов:{' '}
                </Text>
                <Text
                  fontWeight="400"
                  size="xsmall"
                  margin={{ bottom: 'small' }}
                >
                  {activePoll.poll.votingThreshold === null
                    ? 'Любое'
                    : activePoll.poll.votingThreshold}
                </Text>
              </>
            )}

            {isResultsAvailable && (
              <>
                {!isAboutVotingHide && <Divider />}

                <Text
                  bold
                  size="xsmall"
                  margin={{
                    bottom: 'small',
                    top: isAboutVotingHide ? 'medium' : 'xxsmall'
                  }}
                >
                  Ход голосования:{' '}
                  {
                    activePoll.poll.systemStatus === 'ERROR' ?
                      'ошибка подсчета' :
                      isResultsReady ? 'подсчет завершен' : 'подсчет голосов'
                  }
                </Text>

                <Text
                  fontWeight="400"
                  size="xsmall"
                  margin={{ bottom: 'small' }}
                  color={
                    isResultsReady
                      ? theme.palette.BlackText
                      : theme.palette.GreyText
                  }
                >
                  Голосов: <VotesIcon /> {pollResult}
                </Text>
              </>
            )}
          </>
        )}
      </Box>
    );
  }
);

export const AboutVoteModal = function AboutVoteModal() {
  return <Button>О голосовании</Button>;
};

const POLL_TYPE_UI_TEXT: Record<PollSubjectShort['type'], string> = {
  [PollingType.district]: 'Муниципальное образование',
  [PollingType.schoolGroup]: 'Школьная группа'
};

const Divider = styled.hr`
  width: 100%;
  border: ${props => props.theme.palette.Grey} 0.5px solid;
  background-color: ${props => props.theme.palette.Grey};
  opacity: 0.3;
`;
