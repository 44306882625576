import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { AuthStatus } from 'stores/UserStore';
import { statusFetching } from '../../constants';

interface AuthenticationGuardProps {
  render: (props: { status?: AuthStatus }) => React.ReactNode;
}

export const AuthenticationGuard = observer(function AuthenticationGuard({
  render
}: AuthenticationGuardProps) {
  const { user, catalogs } = useStores();

  const prevStatus = React.useRef<statusFetching>();

  React.useEffect(() => {
    prevStatus.current = user.status;
  }, [user.status]);

  if (
    user.status === 'fetching' ||
    (user.authStatus === 'authorized' && catalogs.fetchStatus === 'init')
  ) {
    return null;
  }

  return (
    <>
      {render({
        status: user.authStatus
      })}
    </>
  );
});
