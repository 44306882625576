import * as React from 'react';
import { useStores } from 'stores';
import { IconButton } from 'ui/IconButton';
import { UserSVG, ExitSVG } from './svgComponents';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui';
import { ExitIcon } from 'ui/Icons';
import styled from 'styled-components';

interface LogoutButtonProps {}

export function LogoutButton(props: LogoutButtonProps) {
  const { projectList, user } = useStores();
  const isTabletOrWider = useMediaQuery({ minWidth: breakpoints.tablet });

  const handleClick = () => {
    projectList.filters = { status: [] };
    user.logout();
  };

  return isTabletOrWider ? (
    <WideLogoutButton onClick={handleClick} />
  ) : (
    <CompactLogoutButton onClick={handleClick} />
  );
}

interface ButtonProps {
  onClick: () => void;
}

function WideLogoutButton({ onClick }: ButtonProps) {
  return (
    <IconButton
      onClick={onClick}
      margin={{ left: '16px' }}
      renderIcon={() => <UserSVG />}
    >
      Выйти из системы
    </IconButton>
  );
}

function CompactLogoutButton({ onClick }: ButtonProps) {
  return (
    <StyledIconButton
      onClick={onClick}
      margin={{ left: '16px' }}
      renderIcon={() => <ExitSVG />}
    />
  );
}

const StyledIconButton = styled(IconButton)`
  & span {
    margin: 0;
  }
`;
