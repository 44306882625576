import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { ClickOutside, KebabIcon } from 'ui';
import { Box } from 'grommet';
import styled from 'styled-components';
import { useStores } from 'stores';
import { DeleteUserModal } from '../modals/DeleteUserModal';
import { UpdateUserCredentialsModal } from '../modals/UpdateUserCredentialsModal';
import { UpdateUserModal } from '../modals/UpdateUserModal';
import { UpdateUserFullyRequest } from 'interfaces';

interface UsersActionsMenuProps {
  personId: string;
}

export const UsersActionsMenu = observer<UsersActionsMenuProps>(
  function UsersActionsMenu({ personId, ...props }) {
    const { modals, actionModals, userList } = useStores();

    const [isMenuVisible, setIsMenuVisible] = React.useState(false);
    const toggleVisibility = () => {
      setIsMenuVisible(v => !v);
    };

    const openDeleteModal = async () => {
      modals.openModal(() => <DeleteUserModal personId={personId} />, null, {
        width: '482px',
        isOverlayClose: true
      });
    };

    const openUpdateCredentialsModal = async () => {
      actionModals.open(UpdateUserCredentialsModal, {
        title: 'Изменение пароля',
        width: '545px',
        applyText: () => <span>Сохранить изменения</span>,
        onApply: async (newPassword: string) =>
          userList.updateUserPassword(personId, newPassword),
        closeText: () => <span>Отмена</span>,
        noValidation: true
      });
    };

    const openUpdateUserModal = async () => {
      actionModals.open(UpdateUserModal, {
        initData: { personId },
        width: '931px',
        onApply: async (data: UpdateUserFullyRequest) => {
          await userList.updateUserByAdmin(personId, data);
          await userList.fetch();
        },
        applyText: () => <span>Сохранить</span>,
        closeText: () => <span>Отменить</span>,
        noValidation: true
      });
    };

    return (
      <MenuContainer
        {...props}
        onClickOutside={(): void => setIsMenuVisible(false)}
        onClick={toggleVisibility}
      >
        <Box width="20px">
          <KebabIcon color="Grey" width="1em" />
        </Box>
        {isMenuVisible && (
          <ActionsContainer>
            <MenuItem onClick={openUpdateCredentialsModal}>
              Редактировать пароль
            </MenuItem>

            <MenuItem onClick={openUpdateUserModal}>
              Редактировать данные пользователя
            </MenuItem>

            {!userList.isCurrentUser(personId) && (
              <MenuItem color="Red" onClick={openDeleteModal}>
                Удалить пользователя
              </MenuItem>
            )}
          </ActionsContainer>
        )}
      </MenuContainer>
    );
  }
);

const MenuContainer = styled(ClickOutside)`
  position: 'relative';
  cursor: pointer;
`;

const ActionsContainer = styled(Box)`
  position: absolute;
  top: 50px;
  right: 30px;

  border: 1px solid #f4f5f8;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  background: white;

  z-index: 1;
  cursor: pointer;

  max-width: none;
  min-width: 239px;
`;

const MenuItem = styled.button`
  font-size: 13px;
  color: ${props => props.color || props.theme.palette.BlackText};
  font-weight: normal;
  font-family: ${props => props.theme.fontBase};
  padding: 16px 16px;

  border-radius: 0;
  border: none;
  background-color: transparent;

  cursor: pointer;

  text-align: start;

  &:hover {
    background-color: ${props => props.theme.palette.LightGrey};
  }
`;
