import React from 'react';
import { observer } from 'mobx-react';
import { Box } from 'grommet';
import { Form, Select, RadioGroup } from '@we-ui-components/form';
import { Text } from '@we-ui-components/base';
import { isRequired } from 'utils/validators';
import { FilterSearchArea } from './FilterSearchArea';
import { observable } from 'mobx';
import { RegionList } from './RegionList';
import { getCurrentYear } from 'utils/misc';

interface IProps {
  getRef: (form: any) => void;
  data: INewVote1Step;
  onChange: (value: any, key: string) => void;
  districts: IDistrict[];
  schoolRegions: IDistrict[];
  errorText?: string;
}

export interface INewVote1Step {
  type: 'REGIONS' | 'SCHOOL_PARTY';
  directory: TDirectory;
  objects: IGroupObject[];
}

export interface IDistrict {
  id: number;
  name: string;
  hasPoll: boolean;
  status: string;
  statusDueDate: string;
  activeProjectsCount: number;
  disabled?: boolean;
  type: 'DISTRICT' | 'SCHOOL_GROUP';
}

type TDirectory = 'ACTUAL_REGIONS_2023' | 'ACTUAL_SCHOOL_PARTY_2023';

export interface IGroupObject {
  name: string;
  value: number;
  activeProjectsCount?: number;
  disabled?: boolean;
}

@observer
export class Step1 extends React.Component<IProps> {
  @observable filteredOptions: any[] = [];
  @observable searchString: string = '';
  onChangeFilter = (value, filteredOptions) => {
    this.searchString = value;
    this.filteredOptions = filteredOptions;
  };

  onChangeVotingType = (value: string) => {
    this.searchString = '';
    this.filteredOptions = [];
    this.props.onChange(value, 'type');
  };

  render() {
    const {
      getRef,
      data,
      onChange,
      errorText,
      districts,
      schoolRegions
    } = this.props;
    const { type } = data;

    const regionOptions = districts.map(i => ({
      name: i.name,
      value: i.id,
      disabled: i.hasPoll
    }));
    const schoolGroupOptions = schoolRegions.map(i => ({
      name: i.name,
      value: i.id,
      activeProjectsCount: i.activeProjectsCount,
      disabled: i.hasPoll
    }));

    return (
      <Box margin={{ vertical: 'medium' }}>
        <Form ref={getRef} data={data}>
          <RadioGroup
            name="type"
            label="Тип голосования"
            size="auto"
            direction="row"
            displayMode="stickers"
            options={typeOptions}
            rules={[isRequired()]}
            onChange={this.onChangeVotingType}
          />
          <Box margin={{ bottom: 'small' }}>
            {type === 'REGIONS' && (
              <Select
                name="directory"
                placeholder="Справочник"
                size="xxlarge"
                options={directoryRegionsOptions}
                rules={[isRequired()]}
              />
            )}
            {type === 'SCHOOL_PARTY' && (
              <Select
                name="directory"
                placeholder="Справочник"
                size="xxlarge"
                options={directorySchoolGroupsOptions}
                rules={[isRequired()]}
              />
            )}
          </Box>
          <FilterSearchArea
            options={type === 'REGIONS' ? regionOptions : schoolGroupOptions}
            onChange={this.onChangeFilter}
            searchString={this.searchString}
            checkedOptionsLength={this.props.data.objects.length}
            searchPlaceholder={
              type === 'SCHOOL_PARTY'
                ? 'Поиск по школьным группам'
                : 'Поиск по МО'
            }
          />
          <RegionList
            displayType={type}
            options={type === 'REGIONS' ? regionOptions : schoolGroupOptions}
            searchedOptions={this.filteredOptions}
            value={this.props.data.objects}
            onChange={objects => onChange(objects, 'objects')}
          />
          {!!errorText && (
            <Text margin={{ vertical: 'xsmall' }} color="Red">
              {errorText}
            </Text>
          )}
        </Form>
      </Box>
    );
  }
}

const typeOptions = [
  {
    text: 'Муниципальные образования',
    value: 'REGIONS'
  },
  {
    text: 'Школьные группы',
    value: 'SCHOOL_PARTY'
  }
];

const directoryRegionsOptions = [
  // {
  //   text: 'Актуальные МО 2021',
  //   value: 'ACTUAL_REGIONS_2021'
  // },
  // {
  //   text: 'Актуальные МО 2022',
  //   value: 'ACTUAL_REGIONS_2022'
  // },
  {
    text: `Актуальные МО ${getCurrentYear()}`,
    value: 'ACTUAL_REGIONS_2023'
  }
];

const directorySchoolGroupsOptions = [
  // {
  //   text: 'Актуальные школьные группы 2021',
  //   value: 'ACTUAL_SCHOOL_PARTY_2021'
  // },
  // {
  //   text: 'Актуальные школьные группы 2022',
  //   value: 'ACTUAL_SCHOOL_PARTY_2022'
  // },
  {
    text: `Актуальные школьные группы ${getCurrentYear()}`,
    value: 'ACTUAL_SCHOOL_PARTY_2023'
  },
];
