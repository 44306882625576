import styled from 'styled-components';
import { Input } from '@we-ui-components/form';
import { PhoneInput } from 'pages/Registration/PhoneInput';
import { Button } from '@we-ui-components/base';
import { AsyncSelect } from 'ui';

export const FixedWidthUserInput = styled(Input)`
  width: 418px;
`;

export const FixedWidthUserPhoneUnput = styled(PhoneInput)`
  width: 418px;
`;

export const UserRoleButton = styled(Button).attrs(() => ({
  transparent: true
}))<{
  active: boolean;
}>`
  button {
    color: ${computeRoleButtonColor};
    padding: 0;

    &:hover {
      color: ${computeRoleButtonColor};
    }
  }
`;

export const FixedHeightUserSelect = styled(AsyncSelect)`
  /* control */
  > div > div > div {
    min-height: 56px;
  }
`;

function computeRoleButtonColor(props: Record<string, any>) {
  const palette = props?.theme?.palette || {};
  return props.active ? palette.Purple : palette.Grey;
}
