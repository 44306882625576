import { api } from './api';
import { ENDPOINTS } from './endpoints';
import {
  IFile,
  IProject,
  ListResponse,
  PROJECT_STATUS
} from 'interfaces/apiEntities';

export function getAllProjects(params: any): Promise<ListResponse<IProject>> {
  if (params.pollId) {
    params.sort = 'bulletinOrder,asc'; // TODO need fix in store
  } else {
    params.sort = 'createdDate,desc';
  }

  return api.get(ENDPOINTS.projects(), params);
}

export function createProject(data: any): Promise<{ id: string }> {
  return api.post(ENDPOINTS.projects(), data);
}

export function reworkProject(data: any): Promise<{ id: string }> {
  const { id } = data;
  return api.post(ENDPOINTS.reworkProjects(id), data);
}

export function getProjectsCount(id: string): Promise< Record<PROJECT_STATUS, number> > {
  return api.get(ENDPOINTS.projectsCountTab(id));
}

export function getProjectCount(data: any): Promise<{ id: string }> {
  return api.get(ENDPOINTS.projectsCount(), { status: ['DRAFT', 'IN_REVIEW'] });
}

export function uploadFile(
  file: File,
  type: 'PUBLIC' | 'PRIVATE' | 'RESULT'
): Promise<{ id: string }> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);

  return api.post(ENDPOINTS.uploadFile(), formData);
}

export function getProjects(params: any) {
  return api.get<ListResponse<IProject>>(ENDPOINTS.projects(), {
    ...params,
    size: 200 // TODO - need fix ListStoreConstructor
  });
}

export function getProject(id: string) {
  return api.get<IProject>(ENDPOINTS.projectById(id));
}

export async function getProjectFile(url: string): Promise<string> {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data as string);
    }
  });
}

export type TProjectAction =
  | 'approve'
  | 'decline'
  | 'archive'
  | 'start'
  | 'requireRework'
  | 'finish'
  | 'cancel'
  | 'delete'
  | 'completeRework';

export function callProjectAction(
  id: string,
  action: TProjectAction,
  params: any
) {
  return api.post<IProject>(ENDPOINTS.projectAction(id, action), params);
}

export function rateProject(projectId: string, value: number): Promise<void> {
  return api.post<void>(ENDPOINTS.rateProject(projectId), {
    score: value
  })
}
