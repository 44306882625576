import { api } from './api';
import { ENDPOINTS } from './endpoints';
import { INewVote } from 'pages/CreateVote';
import { ListResponse, PollSubject, ISchool } from 'interfaces/apiEntities';
import { getCurrentYear } from 'utils/misc';

export function getDistricts() {
  return api.get<ListResponse<PollSubject>>(ENDPOINTS.districts(), {
    type: 'DISTRICT',
    size: 1000,
    sort: 'name,asc',
    year: getCurrentYear()
    // hasPoll: 'true',
    // pollStatus: 'APPLICATIONS_RECEIVING'
  });
}

export function getDistrictsWithVotes(params: any = {}) {
  return api.get<ListResponse<PollSubject>>(ENDPOINTS.districtsWithVotes(), {
    size: 1000,
    hasPoll: 'true',
    pollStatus: 'APPLICATIONS_RECEIVING',
    sort: 'name,asc',
    year: getCurrentYear(),
    ...params
  });
}

export function getSchoolRegions() {
  return api.get<ListResponse<PollSubject>>(ENDPOINTS.districts(), {
    type: 'SCHOOL_GROUP',
    sort: 'id,asc',
    size: 1000,
    year: getCurrentYear(),
  });
}

export function getSchools(queryObject: Record<string, string | number>) {
  return api.get<ListResponse<ISchool>>(ENDPOINTS.schools(), queryObject);
}

export function getTechWindow() {
  return api.get<{ prepareTechWindow: number }>(ENDPOINTS.techWindow());
}

export function createVote(data: INewVote): Promise<{ pollsIds: string[] }> {
  return api.post(ENDPOINTS.vote(), data);
}
