import styled from 'styled-components';
import { Box } from 'grommet';

export const HeaderContainer = styled(Box)`
  background-color: ${props => props.theme.palette.White};
  overflow: visible;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0px 4px 8px rgba(10, 6, 6, 0.04);
`;
