import React from 'react';
import * as moment from 'moment';
import { Box } from 'grommet';
import {
  Form,
  NumberInput,
  RadioGroup,
  DateTimePicker
} from '@we-ui-components/form';
import { Text } from '@we-ui-components/base';
import {
  isRequired,
  notEqualDataField,
  isAfterDateField,
  isPresentTime,
  showHours,
  showMinutes
} from 'utils';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import WarningSVG from './Warning.svg';

interface IProps {
  getRef: (form: any) => void;
  techWindow: number;
  data: INewVote2Step;
}

export interface INewVote2Step {
  applicationStartDate: Date;
  applicationEndDate: Date;
  votingStartDate: Date;
  votingEndDate: Date;
  hasMinVotesCount: 'any' | 'number';
  votingThreshold: null | number;
}

const minDate = moment()
  .startOf('day')
  .toDate();
const maxDate = moment()
  .endOf('year')
  .toDate();

@observer
export class Step2 extends React.Component<IProps> {
  render() {
    const { getRef, data, techWindow } = this.props;
    const { hasMinVotesCount } = data;
    const relativeTime = getRelativeTime(techWindow);

    return (
      <Box direction="row" margin={{ vertical: 'medium' }}>
        <Box
          pad="medium"
          style={{ flex: '1 1 100%', backgroundColor: 'white' }}
        >
          <Form ref={getRef} data={data}>
            <Box>
              <Text size="small" bold margin={{ bottom: 'xsmall' }}>
                Даты приема проектов *
              </Text>
              <DateTimePicker
                name="applicationStartDate"
                calendarProps={{
                  placeholderText: 'Начало приема *',
                  size: 'xlarge',
                  minDate,
                  maxDate
                }}
                timeInputProps={{
                  placeholder: 'Время *'
                }}
                rules={[isRequired(), isPresentTime()]}
                {...({} as any)}
              />
              <DateTimePicker
                name="applicationEndDate"
                calendarProps={{
                  placeholderText: 'Окончание приема *',
                  size: 'xlarge',
                  minDate: data.applicationStartDate,
                  maxDate
                }}
                timeInputProps={{
                  placeholder: 'Время *'
                }}
                rules={[
                  notEqualDataField(
                    'applicationStartDate',
                    'Дата не должна совпадать с началом приема'
                  ),
                  isAfterDateField(
                    'applicationStartDate',
                    0,
                    'Дата должна быть позже даты начала приема'
                  )
                ]}
                {...({} as any)}
              />
            </Box>
            <Line />
            <Box>
              <Text size="small" bold margin={{ bottom: 'xsmall' }}>
                Даты голосования *
              </Text>
              <DateTimePicker
                name="votingStartDate"
                calendarProps={{
                  placeholderText: 'Начало голосования *',
                  size: 'xlarge',
                  minDate: data.applicationEndDate,
                  maxDate
                }}
                timeInputProps={{
                  placeholder: 'Время *'
                }}
                rules={[
                  isRequired(),
                  isAfterDateField(
                    'applicationEndDate',
                    techWindow,
                    `Между окончанием приема и началом голосования должно пройти не менее ${relativeTime}`
                  )
                ]}
                {...({} as any)}
              />
              <DateTimePicker
                name="votingEndDate"
                calendarProps={{
                  placeholderText: 'Окончание голосования *',
                  size: 'xlarge',
                  minDate: data.votingStartDate,
                  maxDate
                }}
                timeInputProps={{
                  placeholder: 'Время *'
                }}
                rules={[
                  notEqualDataField(
                    'votingStartDate',
                    'Дата не должна совпадать с началом голосования'
                  ),
                  isAfterDateField(
                    'votingStartDate',
                    0,
                    'Дата должна быть позже даты начала голосования'
                  )
                ]}
                {...({} as any)}
              />
            </Box>
            <Line />
            <RadioGroup
              name="hasMinVotesCount"
              label="Минимальное количество голосов для принятия решения"
              size="auto"
              direction="row"
              displayMode="stickers"
              options={[
                { text: 'Любое', value: 'any' },
                { text: 'Введите число', value: 'number' }
              ]}
              rules={[isRequired()]}
            />
            {hasMinVotesCount === 'number' && (
              <NumberInput
                type="integer"
                placeholder="Количество голосов"
                name="votingThreshold"
                size="large"
                rules={[isRequired()]}
              />
            )}
          </Form>
        </Box>
        <Box style={{ flex: '1 1 100%' }} pad="medium">
          <Box direction="row">
            <Box
              direction="row"
              margin={{ left: 'xsmall', right: 'medium' }}
              align="center"
            >
              <img src={WarningSVG} alt="" />
            </Box>
            <Text size="small" style={{ flex: '1' }}>
              За 2 часа до начала голосования система начнет формирование
              бюллетеней. Внесение изменений, в том числе при работе с
              проектами, будет невозможно.
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }
}

function getRelativeTime(timestamp: number) {
  const hours = moment.duration(timestamp).hours();
  const minutes = moment.duration(timestamp).minutes();

  return `${!!hours ? showHours(hours) : ''} ${
    !!minutes ? showMinutes(minutes) : ''
  }`;
}

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d1d1d1;
  margin-bottom: 16px;
  margin-top: 4px;
`;
