import {
  InternetViolation,
  ListResponse,
  ListViolationResponse,
  PhysicalViolation,
  ViolationDuplicateResponse,
  ViolationResponse,
} from 'interfaces';
import { api } from './api';
import { ENDPOINTS } from './endpoints';

export const getViolationList = async (params: any) => {
  const res = await api.get<ListResponse<ListViolationResponse>>(
    ENDPOINTS.violations(),
    params,
  );

  res.content = res.content.map(item => ({
    ...item,
    lastUnsuccessfulTxStatus:
      item.lastUnsuccessfulTxStatus ||
      (!!item.lastUnsuccessfulTxId ? 'PENDING' : null),
  }));

  return res;
};

export function getViolationDuplicateList(id: string) {
  return api.get<ListResponse<ViolationDuplicateResponse>>(
    ENDPOINTS.violationsDuplicates(id),
  );
}

export function getViolation(id: string) {
  return api.get<ViolationResponse>(ENDPOINTS.violation(id));
}

export function createViolation(
  data: InternetViolation | PhysicalViolation,
): Promise<{ txId: string }> {
  return api.post(ENDPOINTS.violations(), data);
}

export type TViolationAction =
  | 'markAsDuplicate'
  | 'takeIntoWork'
  | 'reject'
  | 'decline'
  | 'confirm';

export function callViolationAction(
  id: string,
  action: TViolationAction,
  data?: any,
): Promise<{ txId: string }> {
  return api.post(ENDPOINTS.violationAction(id, action), data);
}
