import * as React from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';

type BoxProps = React.ComponentProps<typeof Box>;

export const Counter = styled(Box).attrs({ direction: 'row', align: 'center' })`
  color: ${props => props.color || props.theme.palette.GreyText};
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.sizes.text.xsmall};
`;

export const ListItemCounter: React.FC<Readonly<
  { icon: React.ReactNode; count: number } & BoxProps
>> = ({ count, icon, children, ...props }) => {
  return (
    <Counter {...props}>
      <Box margin="0 8px 0 0">{icon}</Box>

      {count}
    </Counter>
  );
};
