import * as React from 'react';

export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={props.fill || 'black'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4236 0.799927L8.39883 7.8255L1.37407 0.799927L0.798828 1.37517L7.8244 8.39993L0.798828 15.4247L1.37407 15.9999L8.39883 8.97435L15.4236 15.9999L15.9988 15.4247L8.97326 8.39993L15.9988 1.37517L15.4236 0.799927Z"
      />
    </svg>
  );
};
