import * as React from 'react';
import './App.styl';
import { mainTheme } from 'themes';
import { Modals, ActionModals } from 'components';
import { GlobalStyle } from './global-styles';
import { AppRoutes } from './routes';
import { Providers } from './providers';

export const App = () => {
  return (
    <Providers>
      <AppRoutes />
      <Modals />
      <ActionModals />
      <GlobalStyle theme={mainTheme as any} />
    </Providers>
  );
};
