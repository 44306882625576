import { App } from './app/root';
import * as React from 'react';
import { render } from 'react-dom';

render(<App />, document.getElementById('root'));

declare const module;
if (module.hot) {
  module.hot.accept();
}
