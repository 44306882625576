import * as React from 'react';
import { Box, BoxProps } from 'grommet';
import { Title } from '@we-ui-components/base';
import { CloseIcon, breakpoints } from 'ui';
import { CSSProperties } from 'react';
import { useMediaQuery } from 'react-responsive';

export const Header: React.FC<{
  onClose: () => void;
  title: string;
  pending: boolean;
  style?: CSSProperties;
} & BoxProps> = ({ onClose, title, pending, ...props }) => {
  const isTabletOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.tablet
  });

  return (
    <Box
      direction="row"
      justify="between"
      align="start"
      pad="28px 28px 12px 32px"
      {...props}
    >
      <Box direction="row">
        <Title
          size={isTabletOrWider ? 'medium' : '24px'}
          bold
          style={{ marginRight: 16, marginTop: 19, maxWidth: '500px' }}
        >
          {title}
        </Title>
      </Box>
      <CloseIcon
        hover={true}
        style={{ cursor: 'pointer' }}
        onClick={onClose}
        size="medium"
      />
    </Box>
  );
};
