import * as React from 'react';
import {
  Calendar,
  Form,
  Input,
  MobxForm,
  NumberInput,
  Select,
  TextArea
} from '@we-ui-components/form';
import { Text, Title } from '@we-ui-components/base';
import { Box } from 'grommet';
import { observer } from 'mobx-react';
import { TActionModalProps } from 'components/ActionModals';
import { computed, observable } from 'mobx';
import {
  conditiallyRequired,
  isCorrectUrl,
  isPositive,
  isRequired,
  lessThan,
  maxLength
} from 'utils/validators';
import { Img, sphereOptions } from 'pages/Applicant/CreateProject';
import { formatWithTwoDecimalsRub } from 'utils';
import { SliderComponent } from 'ui/Slider/SliderComponent';
import { FilesIdInput } from 'pages/Applicant/FileInput';
import visibilitySVG from 'pages/Applicant/visibility_off.svg';
import { SliderFile, IProject } from 'interfaces';
import { processData } from 'pages/Applicant/ActiveProject/ReworkForm';
import { useEffect, useMemo } from "react";
import * as moment from "moment";

export type FormData = IProject & {
  files_new: File[];
  privateFiles_new: File[];
  sliderPublicFiles: SliderFile[];
  sliderPrivateFiles: SliderFile[];
  startDate: Date;
  endDate: Date;
};

@observer
export class ReworkProjectModal extends React.Component<
  TActionModalProps<IProject>
> {
  formRef: MobxForm;

  @observable
  data: FormData = {
    ...this.props.actionData.data,
    startDate: new Date(this.props.actionData.data.startDate),
    endDate: new Date(this.props.actionData.data.endDate),
    files_new: [],
    sliderPublicFiles: [],
    sliderPrivateFiles: [],
    privateFiles_new: []
  } as FormData;

  componentDidMount(): void {
    this.props.onValidate.callback = () => this.onValidate();
    this.data.sliderPublicFiles = this.data.files.map(({ id, name}) => ({ id, name }))
    this.data.sliderPrivateFiles = this.data.privateFiles.map(({ id, name}) => ({ id, name }))
  }

  onValidate = async () => {
    const data = await this.formRef.validateFields();

    if (!this.totalCost) {
      throw new Error('Общий объём требуемых средств должен быть больше 0');
    }

    return processData(data, this.props.actionData.data);
  };

  @computed
  get totalCost() {
    const {
      budgetRegion,
      budgetPeople,
      budgetDistrict,
      noBudgetFunds
    } = this.data;
    return (
      Number(budgetRegion) +
      Number(budgetPeople) +
      Number(budgetDistrict) +
      Number(noBudgetFunds)
    );
  }

  render() {
    const isSchoolProject = !!this.data.school;

    return (
      <Box style={this.props.style}>
        <Title
          margin={{ top: '-30px', bottom: '24px' }}
          style={{ maxWidth: '450px' }}
          bold
          size="small"
        >
          Доработка проекта
        </Title>

        <Form
          ref={(formRef: MobxForm) => (this.formRef = formRef)}
          data={this.data}
        >
          <Box gap="xsmall">
            <Input
              name="name"
              size="full"
              placeholder="Название проекта *"
              rules={[isRequired(), maxLength(120)]}
            />
            <DataItem
              label="Тип проекта"
              text={
                isSchoolProject
                  ? 'Школьный проект'
                  : 'Муниципальное образование'
              }
            />
            <DataItem
              label="Муниципальный район"
              text={
                isSchoolProject
                  ? this.data.school.districtName
                  : this.data.subject.name
              }
            />
            {isSchoolProject && (
              <DataItem label="Школа" text={this.data.school.name} />
            )}
            <Title size="xxxsmall" bold margin={{ vertical: 'xsmall' }}>
              Описание проекта
            </Title>
            {!isSchoolProject && (
              <Select
                name="sphere"
                label="Сфера проекта *"
                size="full"
                options={sphereOptions.filter(op => !['CULTURE', 'EDUCATION'].includes(op.value))}
                rules={[isRequired()]}
              />
            )}

            <NumberInput
              type="integer"
              name="beneficiaries"
              placeholder="Количество благополучателей, чел *"
              size="full"
              rules={[isRequired(), isPositive(), lessThan(1e8)]}
              disabled={isSchoolProject}
            />

            <TextArea
              {...({} as any)}
              name="description"
              label="Описание проекта *"
              rules={[isRequired(), maxLength(1500)]}
              style={{ height: '128px' }}
            />
            <Box direction="row" gap="small" justify="between">
              <Calendar
                name="startDate"
                size="xlarge"
                placeholderText="Начало реализации *"
                rules={[isRequired()]}
                minDate={moment().add(1, 'day').toDate()}
              />
              <Calendar
                name="endDate"
                size="xlarge"
                placeholderText="Окончание реализации *"
                rules={[isRequired()]}
              />
            </Box>
            <Input
              name="place"
              placeholder="Место реализации проекта *"
              size="full"
              rules={[isRequired()]}
            />
            <Input
              name="administrationLink"
              placeholder="Ссылка на сайт администрации *"
              size="full"
              rules={[isRequired(), isCorrectUrl()]}
            />
          </Box>
          <Text bold margin={{ bottom: 'xsmall' }}>
            Объем требуемых средств {formatWithTwoDecimalsRub(this.totalCost)}
          </Text>
          <Box>
            <Box direction="row" gap="small">
              <NumberInput
                type="decimal"
                name="budgetRegion"
                placeholder="Областной бюджет, ₽"
                size="xlarge"
              />
              <NumberInput
                type="decimal"
                name="budgetPeople"
                placeholder="Средства населения, ₽"
                size="xlarge"
              />
            </Box>
            <Box direction="row" gap="small">
              <NumberInput
                type="decimal"
                name="budgetDistrict"
                placeholder="Бюджет муниципального образования, ₽"
                size="xlarge"
              />
              <NumberInput
                type="decimal"
                name="noBudgetFunds"
                placeholder="Внебюджетные средства, ₽"
                size="xlarge"
              />
            </Box>
          </Box>

          <Box gap="xsmall">
            <Text bold>Материалы (до и после реализации проекта)*</Text>
            <Text>
              Эти материалы будут доступны всем пользователям системы.
            </Text>
            <Text size="small">Уже загруженные файлы</Text>

            {this.data.sliderPublicFiles.length !== 0 ? (
              <SliderComponent
                slideFiles={this.data.sliderPublicFiles}
                arrow
                editMode
                onChange={files => {
                  this.data.sliderPublicFiles = files;
                }}
                style={{ margin: 0 }}
              />
            ) : (
              <Text size="small" color="Basic500">
                [отсутствуют]
              </Text>
            )}

            {this.data.sliderPublicFiles.length < 3 && (
              <>
                <Text size="small" margin={{ bottom: 'xsmall' }}>
                  Новые файлы
                </Text>

                <FilesIdInput
                  name="files_new"
                  withPreview
                  rules={[
                    conditiallyRequired(() => this.data.sliderPublicFiles.length === 0)
                  ]}
                  limits={{ maxFiles: 3 - this.data.sliderPublicFiles.length }}
                  formats={['.jpg', '.png']}
                />
              </>
            )}
          </Box>

          <Box gap="xsmall">
            <Box direction="row" align="center">
              <Img src={visibilitySVG} alt="" />
              <Text bold>Приватные материалы</Text>
            </Box>
            <Text>Эти материалы будут доступны только вам и организатору.</Text>
            <Text size="small">Уже загруженные файлы</Text>
            {this.data.sliderPrivateFiles.length !== 0 ? (
              <SliderComponent
                slideFiles={this.data.sliderPrivateFiles}
                arrow
                editMode
                onChange={files => {
                  this.data.sliderPrivateFiles = files;
                }}
                style={{ margin: 0 }}
              />
            ) : (
              <Text size="small" color="Basic500">
                [отсутствуют]
              </Text>
            )}
            {this.data.sliderPrivateFiles.length < 7 && (
              <FilesIdInput
                name="privateFiles_new"
                withPreview
                limits={{
                  maxFiles: 7 - this.data.sliderPrivateFiles.length,
                  maxFileSize: 10e6
                }}
                additionalText={
                  'Размер каждого файла не должен превышать 10 Мб'
                }
                formats={['.png', '.pdf']}
              />
            )}
          </Box>
        </Form>
      </Box>
    );
  }
}

const DataItem = (props: { label: string; text: string }) => {
  return (
    <Box>
      <Text color="Grey" size="xxsmall">
        {props.label}
      </Text>
      <Text size="xsmall">{props.text}</Text>
    </Box>
  );
};
