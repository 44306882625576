import * as React from 'react';
import { IColumn, ISystemUser, IUser, TRole } from 'interfaces';
import { P, formatPersonName, applyMask } from 'utils';
import { Text } from '@we-ui-components/base';
import { RoleIcon } from './components/RoleIcon';
import { Box } from 'grommet';
import { PhoneIcon, KebabIcon } from 'ui';
import { UsersActionsMenu } from './components/UsersActionsMenu';

export const getColumns = (): Array<IColumn<IUser & { _actions: string }>> => {
  return [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 210,
      sortable: false,
      render: (value: any, data) => {
        if (!data) return null;
        return (
          <Text style={{ whiteSpace: 'nowrap' }} size="xsmall">
            {data.email}
          </Text>
        );
      }
    },
    {
      title: 'Роль',
      dataIndex: 'businessRole',
      key: 'businessRole',
      width: '50px',
      sortable: false,
      render: (value: any, data) => {
        if (data?.businessRole === undefined) return null;

        return (
          <Box align="center" justify="center">
            <RoleIcon role={data.businessRole} />
          </Box>
        );
      }
    },
    {
      title: 'ФИО',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 300,
      sortable: false,
      render: (value: any, data) => {
        if (!data) return null;

        const personName = [data.lastName, data.firstName, data.patronymic]
          .filter(i => i)
          .join(' ');

        return <Text size="xsmall">{personName}</Text>;
      }
    },
    {
      title: 'Организация',
      dataIndex: 'company',
      key: 'company',
      width: '260px',
      sortable: false,
      render: (value: any, data) => {
        if (!data) return null;

        return (
          <Text style={{ width: '260px' }} size="xsmall">
            {data.company || '--'}
          </Text>
        );
      }
    },
    {
      title: 'Район',
      dataIndex: 'district',
      key: 'district',
      width: '260px',
      sortable: false,
      render: (value: any, data) => {
        if (!data) return null;

        return (
          <Text style={{ whiteSpace: 'nowrap' }} size="xsmall">
            {data.district || '--'}
          </Text>
        );
      }
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      width: '260px',
      sortable: false,
      render: (value: any, data) => {
        if (!data?.phone) return null;

        return (
          <Box direction="row" gap="6px">
            <PhoneIcon color="Purple" />
            <Text size="xsmall">{data.phone}</Text>
          </Box>
        );
      }
    },
    {
      title: '',
      width: 15,
      key: '_actions',
      dataIndex: '_actions',
      render: (value: any, data) => {
        if (!data || !data.personId) return null;
        return <UsersActionsMenu personId={data.personId} />;
      }
    }
  ];
};
