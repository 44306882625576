import * as React from 'react';
import { Button } from '@we-ui-components/base';
import { Spinner2 } from './Spinner2';

type TButtonType = 'origin' | 'file';

export const ButtonEx = ({
  textRender = null,
  transparent,
  spinnerRender = (
    <Spinner2
      height="20px"
      width="20px"
      color={transparent ? 'Black' : 'White'}
      style={{ marginRight: 5 }}
    />
  ),
  isLoading,
  children,
  onClick,
  type,
  accept,
  ...buttonProps
}: ButtonExProps) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      {type === 'file' && (
        <input
          style={{ display: 'none' }}
          ref={hiddenFileInput}
          onChange={event => {
            onClick(event.target.files[0]);
          }}
          onClick={event => event.stopPropagation()}
          accept={accept}
          type="file"
          multiple={false}
        />
      )}
      <Button
        onClick={isLoading ? () => {} : type === 'file' ? handleClick : onClick}
        transparent={transparent}
        {...buttonProps}
      >
        {isLoading ? spinnerRender : textRender}
        {children}
      </Button>
    </>
  );
};

export type ButtonExProps = React.ComponentProps<typeof Button> & {
  textRender?: React.ReactNode;
  spinnerRender?: React.ReactNode;
  children?: React.ReactNode;
  type?: TButtonType;
  accept?: string;
  onClick?: (event?: any) => void | Promise<void>;
};
