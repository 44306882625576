import { UserInfo } from 'models';
import { api, ENDPOINTS } from 'services';
import { IUserInfo } from 'interfaces';
import { createStoresContext } from 'we-data-layer/lib/connect';
import RouterStore from 'stores/RouterStore';
import { UserStoreEx } from 'stores/UserStore';
import { CatalogsStore } from './CatalogsStore';
import { ModalsStore } from './ModalsStore';
import { ActionModalsStore } from './ActionModalsStore';
import { IpObjectListStore } from './IpObjectListStore';
import { ActiveIpObjectStore } from './ActiveIpObjectStore';

import { ActiveViolationStore } from './ActiveViolationStore';
import { ProjectListStore } from 'stores/ProjectStore';
import { ActiveProjectStore } from 'stores/ActiveProjectStore';
import { PollSubjectListStore } from 'stores/PollSubjectListStore';
import { ActivePollStore } from 'stores/ActivePollStore';
import { ActivePollResults } from 'stores/ActivePollResults';
import { UserListStore } from './UserListStore';
import { ArchivePollListStore } from './ArchivePollListStore';
import { ArchiveProjectListStore } from './ArchiveProjectListStore';

export type onAuthSuccessCb = (userInfo: UserInfo) => Promise<void>;

const createUserStore = (onAuthSuccess: onAuthSuccessCb): UserStoreEx => {
  const getUserInfo = () =>
    api
      .get(ENDPOINTS.userInfo())
      .then((userInfo: IUserInfo) => UserInfo.New(userInfo));

  const userStore = new UserStoreEx({
    userService: { login: api.login, logout: api.logout, getUserInfo },
    onAuthSuccess: (iUserInfo: IUserInfo) => {
      return new Promise(resolve => {
        resolve(UserInfo.New(iUserInfo));
      }).then(onAuthSuccess);
    }
  });

  userStore.init();

  return userStore;
};

export interface IStores {
  //usual
  user?: UserStoreEx;
  routing?: RouterStore;
  catalogs?: CatalogsStore;
  modals?: ModalsStore;
  actionModals?: ActionModalsStore;
  //business
  ipObjectList?: IpObjectListStore;
  activeIpObject?: ActiveIpObjectStore;
  votings?: PollSubjectListStore;
  activeViolation?: ActiveViolationStore;

  activePoll?: ActivePollStore;
  activePollResults?: ActivePollResults;
  projectList?: ProjectListStore;
  activeProject?: ActiveProjectStore;
  userList?: UserListStore;
  archivePolls?: ArchivePollListStore;
  archiveProjects?: ArchiveProjectListStore;
}

let onAuthSuccess;
const user = createUserStore(onAuthSuccess);

const stores: IStores = {};

stores.user = user;
user.stores = stores;
onAuthSuccess = user.onAuthSuccess;

stores.routing = new RouterStore();
stores.catalogs = new CatalogsStore(stores);
stores.modals = new ModalsStore();
stores.actionModals = new ActionModalsStore();
stores.ipObjectList = new IpObjectListStore(stores);
stores.votings = new PollSubjectListStore(stores);
stores.activeIpObject = new ActiveIpObjectStore(stores);
stores.activeViolation = new ActiveViolationStore(stores);

stores.projectList = new ProjectListStore(stores);
stores.activePoll = new ActivePollStore(stores);
stores.activePollResults = new ActivePollResults(stores);
stores.activeProject = new ActiveProjectStore(stores);
stores.userList = new UserListStore(stores);
stores.archivePolls = new ArchivePollListStore(stores);
stores.archiveProjects = new ArchiveProjectListStore(stores);

if (!process.env.production) {
  window.stores = stores;
}

const { StoresProvider, useStores } = createStoresContext<typeof stores>();
export { StoresProvider, useStores };

export default stores;
