import { IStores } from 'stores/index';
import { ListStoreConstructor } from './core/ListStoreConstructor';
import { IProject } from 'interfaces/apiEntities';
import * as archiveService from 'services/archiveService';

export class ArchiveProjectListStore extends ListStoreConstructor<IProject> {
  constructor(stores: IStores) {
    super(stores, archiveService.getArchiveProjects, {
      sorter: ['bulletinOrder,asc', 'name,asc'],
      paginationData: { pageSize: 10 },
      pollingInterval: 5e4
    });
  }
}
