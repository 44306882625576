import * as React from 'react';

export const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 7.29583 11.5892 8.49572 10.8907 9.47653L14.0208 12.6066C14.4113 12.9971 14.4113 13.6303 14.0208 14.0208C13.6303 14.4113 12.9971 14.4113 12.6066 14.0208L9.47653 10.8907C8.49572 11.5892 7.29583 12 6 12ZM10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6C2 3.79086 3.79086 2 6 2C8.20914 2 10 3.79086 10 6Z"
        fill={props.fill || '#0B1F35'}
      />
    </svg>
  );
};
