import * as React from 'react';

export const PhoneIconSVG = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 14"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.18 1.07c.263-.159.6-.118.818.099l2.467 2.467a.667.667 0 01.055.88c-.785 1.013-.888 1.51-.81 1.851.042.187.155.407.398.694.245.289.59.61 1.052 1.006.025.021.047.044.068.068.134.152.256.204.37.221.132.02.307 0 .534-.079.385-.133.76-.371 1.143-.614.1-.064.2-.128.302-.19a.667.667 0 01.827.102l2.4 2.467c.212.218.25.551.093.81-.54.89-1.233 1.499-2.034 1.842-.797.341-1.656.4-2.502.264-1.674-.27-3.364-1.312-4.682-2.64C1.36 8.992.327 7.293.065 5.614c-.133-.848-.07-1.709.272-2.507.345-.802.955-1.497 1.844-2.036zm8.287 9.51a2.886 2.886 0 01-1.13.888c-.521.224-1.117.278-1.764.174-1.311-.212-2.759-1.065-3.949-2.263S1.588 6.726 1.382 5.407c-.102-.65-.045-1.25.18-1.774.172-.402.455-.785.886-1.128l1.67 1.67c-.593.864-.896 1.662-.708 2.488.107.472.361.882.68 1.259.31.366.712.736 1.17 1.13.325.356.716.559 1.139.623.42.063.824-.018 1.172-.138.448-.156.896-.419 1.255-.644l1.641 1.687z"
        fill={props.color}
      />
    </svg>
  );
};
