import { ITabOptions } from 'ui/TabsEx';

export const getTabs = (): ITabOptions[] => [
  {
    id: 'ALL',
    text: 'Все'
  },
  {
    id: 'IN_REVIEW',
    text: 'На рассмотрении'
  },
  {
    id: 'REWORK_REQUIRED',
    text: 'На доработке'
  },

  {
    id: 'REWORK_COMPLETED',
    text: 'Доработан'
  },
  {
    id: 'APPROVED',
    text: 'Принят'
  },
  {
    id: 'DECLINED',
    text: 'Отклонен'
  },
  {
    id: 'CANCELED',
    text: 'Отозван'
  }
];
