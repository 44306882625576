import * as React from 'react';
import { ProfilePersonalData } from './ProfilePersonalData';
import { Box } from 'grommet';
import { observer } from 'mobx-react';

interface ProfilePageProps {}

export const ProfilePage = observer(function(props: ProfilePageProps) {
  return (
    <Box width="544px" margin={{ top: '40px' }} style={{ alignSelf: 'center' }}>
      <ProfilePersonalData margin={{ top: '24px' }} />
    </Box>
  );
});
