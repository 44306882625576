import { Button, Text } from '@we-ui-components/base';
import { Box } from 'grommet';
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/index';
import { vote } from './voting';
import { EncryptPasswordModal } from 'components/Head/components/EncryptPasswordModal';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui/Responsive';
import { useTheme } from 'utils';
import { IPoll, PROJECT_STATUS } from 'interfaces/apiEntities';
import { objectToGetParams, SHARE_LINKS, ShareLinks, ShareType, UrlParams, windowOpen } from './components/ShareButton';
import { getPollTitle } from './index';
import { baseUrl } from 'services/endpoints';

const vkPollParams = (poll: IPoll): UrlParams => ({
  url: baseUrl + location.pathname,
  title: getPollTitle(poll.subject),
  image: '',
  noParse: 1
})

const okPollParams = (poll: IPoll): UrlParams => ({
  url: baseUrl + location.pathname,
  title: getPollTitle(poll.subject),
  image: '',
})

export const SHARE_PARAMS: Record<ShareType, (p: any) => UrlParams> = {
  vk: vkPollParams,
  ok: okPollParams
}

export const VotingActionsPanel = observer(() => {
  const { user, actionModals, activePoll } = useStores();
  const theme = useTheme();

  const isVoter = user.isContainRoles(['VOTER']);

  const isLaptopOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.laptopL
  });

  const modalSuccessfulEncrypt = (): void => {
    actionModals.open(
      () => (
        <Text size="xlarge" bold style={{ marginBottom: '15px' }}>
          История вашего голосования доступна для отображения
        </Text>
      ),
      {
        isOverlayClose: true,
        setTimeCloseModal: 15000
      }
    );
  };

  const displayEncryptPassModal = () => {
    actionModals.open(EncryptPasswordModal, {
      width: isLaptopOrWider ? '384px' : '100%',
      applyText: () =>
        !user.encryptedContent ? <>Создать</> : <>Подтвердить</>,
      closeText: () => <>Отмена</>,
      noValidation: true,
      onApply: async data => {
        await user.saveEncryptPassword(data.password);
        modalSuccessfulEncrypt();
      },
      overlayStyle: {
        background: isLaptopOrWider ? 'transparent' : 'rgba(10, 6, 6, 0.5)',
        alignItems: isLaptopOrWider ? 'flex-end' : 'center',
        padding: '32px 16px'
      },
      modalStyle: {
        boxShadow: '0px 4px 8px rgba(10, 6, 6, 0.04)',
        position: isLaptopOrWider ? 'absolute' : 'relative',
        top: isLaptopOrWider ? 72 : 'auto',
        right: isLaptopOrWider ? 72 : 'auto',
        maxWidth: '482px',
        margin: 'auto',
        marginTop: isLaptopOrWider ? 'auto' : '32px'
      },
      isOverlayClose: true
    });
  };

  const isVoteWithPassword = !!activePoll.encryptedBulletin;

  if (activePoll.hasVote && !isVoteWithPassword) {
    return null;
  }

  if (activePoll.hasVote && isVoteWithPassword) {
    return (
      <Box direction="column" gap="20px">
        {isVoter && !user.encryptPassword ? (
          <Box direction="column" gap="10px">
            <Text size="xsmall" style={{ fontWeight: 600 }}>
              Посмотреть историю голосования
            </Text>
            <Button
              transparent={true}
              style={{
                borderRadius: 2,
                border: '1px solid rgba(59, 51, 176, 0.2)',
                color: theme.palette.Purple,
                marginTop: 10
              }}
              onClick={() => {
                displayEncryptPassModal();
              }}
            >
              Введите пароль
            </Button>
          </Box>
        ) : null}
      </Box>
    );
  }

  return (
    <Box direction="column" gap="8px">
      {isVoter && !user.encryptPassword ? (
        <Box direction="column" gap="10px">
          <Text size="xsmall" style={{ fontWeight: 600 }}>
            Задайте пароль
          </Text>
          <Text size="xsmall" style={{ lineHeight: '19px', fontWeight: 400 }}>
            Мы обеспечиваем анонимность голосования. Ваш голос будет зашифрован.
            Создание пароля позволит просмотреть историю вашего голосования.
          </Text>
          <Button
            transparent={true}
            style={{
              borderRadius: 2,
              border: '1px solid rgba(59, 51, 176, 0.2)',
              color: theme.palette.Purple,
              marginTop: 10
            }}
            onClick={() => {
              displayEncryptPassModal();
            }}
          >
            Создать пароль
          </Button>
        </Box>
      ) : null}
    </Box>
  );
});
