import { useState, useEffect, useCallback } from 'react';

interface UseRequestParams<T> {
  onSuccess?: (data: T) => void;
  onError?: (error: string) => void;
}

export function useMutation<T>(
  requestFn: () => Promise<T>,
  { onSuccess, onError }: UseRequestParams<T> = {}
) {
  const [status, setStatus] = useState<Status>('idle');
  const [error, setError] = useState<string>('');

  const mutate = useCallback(() => {
    setStatus('fetching');
    setError('');

    requestFn()
      .then(response => {
        setStatus('success');

        if (typeof onSuccess === 'function') {
          onSuccess(response);
        }
      })
      .catch(error => {
        const message =
          error?.title ?? error?.detail ?? error?.message ?? error;

        console.error(error);
        setStatus('error');
        setError(message);

        if (typeof onError === 'function') {
          onError(error);
        }
      });
  }, [onSuccess, onError, requestFn]);

  return [mutate, { status, error }] as const;
}

type Status = 'fetching' | 'success' | 'error' | 'idle';
