import { action, computed, observable } from 'mobx';
import { getPollById, getVoteById } from 'services/poll';
import { statusFetching } from 'we-oauth2/src/constants/types';

import { StoreConstructor } from 'stores/core/StoreConstructor';
import { IPoll } from 'interfaces/apiEntities';
import { NormalizedError } from 'services/api/errorHandler';
import { decryptMsg } from 'utils/crypto-js';

export class ActivePollStore extends StoreConstructor {
  @observable pollId: string;

  @observable fetchStatus: statusFetching = 'init';
  @observable fetchError: NormalizedError;
  @observable poll: IPoll;

  @observable hasVote: boolean = false;
  @observable bulletinOrder: number = -1;
  @observable encryptedBulletin: string = '';

  @action.bound
  public selectProject(order: number) {
    this.bulletinOrder = order;
  }

  @action.bound
  public clearProject() {
    this.bulletinOrder = -1;
  }

  @computed
  public get isProjectSelected() {
    return this.bulletinOrder > -1;
  }

  @computed
  public get isPollLoading() {
    return this.fetchStatus === 'fetching' || this.fetchStatus === 'init';
  }

  @computed
  public get hasError() {
    return this.fetchStatus === 'error';
  }

  @action.bound
  public getPoll(pollId: string) {
    return this.statusHandler(
      () => getPollById(pollId),
      'fetchStatus',
      'fetchError',
      async poll => {
        this.poll = poll;
        this.pollId = pollId;

        let res;

        if (this.stores.user.isContainRoles(['VOTER'])) {
          try {
            res = await getVoteById(pollId);
            this.hasVote = true;
            this.encryptedBulletin = res.encryptedBulletin;
          } catch (e) {
            this.hasVote = false;
            this.bulletinOrder = -1;
            this.encryptedBulletin = '';
          }

          const pass = this.stores.user.encryptPassword;
          if (this.hasVote && pass && !!res.encryptedBulletin) {
            const bulletin = JSON.parse(
              decryptMsg(res.encryptedBulletin, pass)
            );
            this.bulletinOrder = bulletin[0].indexOf(1);
          }
        }
      }
    );
  }

  @action.bound
  clear() {
    this.poll = null;
    this.fetchStatus = 'init';
    this.fetchError = null;
    this.bulletinOrder = -1;
    this.encryptedBulletin = '';
    this.hasVote = false;
  }
}
