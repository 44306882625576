import * as React from 'react';
import { Form, MobxForm, TextArea } from '@we-ui-components/form';
import { maxLength, isRequired, limitFileSize } from 'utils';
import { Box } from 'grommet';
import { observer } from 'mobx-react';
import { TActionModalProps } from 'components/ActionModals';
import { observable } from 'mobx';
import { Title, Text } from '@we-ui-components/base';
import { FilesIdInput } from 'pages/Applicant/FileInput';

export type FormData = {
  description: string;
  files: File[];
};

@observer
export class ConfirmFinishModal extends React.Component<TActionModalProps> {
  formRef: MobxForm;

  private MAX_FILE_SIZE = 10 * 1024 * 1024;

  @observable
  data: FormData = { description: '', files: [] };

  componentDidMount(): void {
    this.props.onValidate.callback = () => this.onValidate();
  }

  onValidate = async () => {
    const data = await this.formRef.validateFields();

    return data;
  };

  render() {
    return (
      <Box style={this.props.style}>
        <Title
          margin={{ top: '-30px', bottom: '24px' }}
          style={{ maxWidth: '450px' }}
          bold
          size="small"
        >
          Результаты реализации проекта
        </Title>

        <Form ref={(ref: any) => (this.formRef = ref)} data={this.data}>
          <TextArea
            {...({} as any)}
            name="description"
            placeholder="Описание результатов реализации*"
            rules={[isRequired(), maxLength(1500)]}
            style={{ height: '119px' }}
          />

          <Text size="xsmall" fontWeight="600" margin={{ bottom: '22px' }}>
            Файлы, подтверждающие результаты реализации*
          </Text>

          <FilesIdInput
            name="files"
            withPreview
            formats={['.jpg', '.png', '.pdf']}
            limits={{ maxFiles: 3, maxFileSize: this.MAX_FILE_SIZE }}
            rules={[
              isRequired(),
              limitFileSize(
                this.MAX_FILE_SIZE,
                'Размер файла не должен превышать 10 MB'
              )
            ]}
          />
        </Form>
      </Box>
    );
  }
}
