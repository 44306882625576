import * as React from 'react';
import * as styles from './styles.styl';
import { Text } from '@we-ui-components/base';
import { Box } from 'grommet';

export const Spinner: React.FC<React.SVGAttributes<SVGElement>> = props => {
  const boxSize = 16;
  const radius = boxSize / 2 - 1;
  const middle = boxSize / 2;
  return (
    <svg
      viewBox={`0 0 ${boxSize} ${boxSize}`}
      className={styles.spinner}
      {...props}
    >
      <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
        <stop offset="0%" stop-color="#204080"></stop>
        <stop offset="80%" stop-color="#f3f3f3"></stop>
        <stop offset="100%" stop-color="#f3f3f3"></stop>
      </linearGradient>
      <circle
        r={radius}
        cx={middle}
        cy={middle}
        strokeWidth="1.4"
        fill="none"
        //stroke={props.color || 'black'}
        stroke="url(#linearColors)"
        // strokeDasharray={Math.floor(2 * radius * Math.PI - 0)}
      />
    </svg>
  );
};

export const SpinnerWithText: React.FC<React.SVGAttributes<SVGElement> & {
  text?: string;
}> = ({ text, ...props }) => {
  return (
    <div>
      <Box direction="row" align="center">
        <Spinner style={{ width: 16, height: 16 }} {...props} />
        <Text margin={{ left: '8px', top: '2px' }} size="small" color="Blue800">
          {text || 'Обрабатывается...'}
        </Text>
      </Box>
    </div>
  );
};
