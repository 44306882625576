import { Routes } from 'constants/routes';

export function useRoutes() {
  return Routes;
}

export function getRootPathname(value: string) {
  const firstMatch = value.indexOf('/', 1);
  if (firstMatch !== -1) {
    return value.substring(0, firstMatch);
  }
  return value;
}
