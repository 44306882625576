export const hidePhone = phone => {
  const end = phone.slice(phone.length - 2);
  const start = phone.slice(2, 5);

  return `+7 ${start} ***-**-${end}`;
};

let decCache = [],
  decCases = [2, 0, 1, 1, 1, 2];

export function decOfNum(number, titles) {
  if (!decCache[number])
    decCache[number] =
      number % 100 > 4 && number % 100 < 20
        ? 2
        : decCases[Math.min(number % 10, 5)];
  return number + ' ' + titles[decCache[number]];
}
