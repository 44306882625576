import * as React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Box, Anchor } from 'grommet';
import { useStores } from 'stores';
import { ProjectStatus } from 'pages/Poll/ProjectStatus';
import { IProject, PROJECT_STATUS, ArchivePollApiDTO } from 'interfaces';
import { ShareButton } from 'pages/Poll/components/ShareButton';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui';
import { ENDPOINTS } from 'services';
import { Text, Button } from '@we-ui-components/base';
import { dateFormat } from 'utils/formatDates';
import { formatWithTwoDecimalsRub, showPeople } from 'utils/formatNumber';
import { ProjectInfoBox } from 'pages/Poll/components/ProjectInfoBox';
import { getSphere } from 'pages/Applicant/CreateProject';
import { SliderComponent } from 'ui/Slider/SliderComponent';
import { PrivateIcon, ExternalLink, CollapseArrow } from 'pages/Poll/icons';
import { useTheme } from 'utils';
import { ImplementationDetails } from 'pages/Poll/components/ImplementationDetails';
import { ProjectActions } from './ProjectActions';
import { RealizationRating } from '../components/RealizationRating';

interface ArchiveProjectListItemProps {
  project: IProject;
  poll: ArchivePollApiDTO;
  voted: boolean;
}

export const ArchiveProjectListItem = observer<ArchiveProjectListItemProps>(
  function ArchiveProjectListItem({ project, poll, voted }) {
    const { user } = useStores();
    const [expanded, setExpanded] = React.useState(false);
    const theme = useTheme();

    const isOrganizer = user.isContainOneOfRoles(['REGULATOR']);
    const isVoter = user.isContainOneOfRoles(['VOTER']);

    const isTabletOrWider = useMediaQuery({
      minDeviceWidth: breakpoints.tablet
    });

    return (
      <CardContainer
        background={voted ? '#dedcea' : theme.palette.White}
        margin={{ bottom: 'small' }}
      >
        <Box pad="small" style={{ position: 'relative' }}>
          <ProjectStatusStyled status={project.status} />

          {!isOrganizer && (
            <Box style={{ position: 'absolute', top: 25, right: 0 }}>
              <ShareButton project={project} />
            </Box>
          )}

          <Box direction={isTabletOrWider ? 'row' : 'column'}>
            <CardImage src={ENDPOINTS.projectLogo(project.id)} />

            <Box
              style={{ lineHeight: 1.5, flex: '1 1 auto' }}
              pad={{ horizontal: 'small' }}
            >
              {project.school ? (
                <Text
                  margin={{ top: 'xxsmall' }}
                  size="xsmall"
                  style={{ maxWidth: '70%' }}
                >
                  {project.school.name} ({project.school.districtName})
                </Text>
              ) : null}
              <Text margin={{ top: 'xsmall' }} size="large" bold>
                {project.name}
              </Text>

              {isTabletOrWider ? (
                <>
                  <Text margin={{ top: 'xsmall' }} size="xsmall">
                    Срок реализации проекта: {dateFormat(project.startDate)} –{' '}
                    {dateFormat(project.endDate)}
                  </Text>
                  <Text margin={{ top: 'xsmall' }} size="xsmall">
                    Объем требуемых средств:{' '}
                    {formatWithTwoDecimalsRub(project.budgetSummary)}
                  </Text>
                </>
              ) : (
                <Box margin={{ top: '40px' }} gap="15px">
                  <ProjectInfoBox
                    title="Срок реализации проекта"
                    text={`${dateFormat(project.startDate)} – ${dateFormat(
                      project.endDate
                    )}`}
                  />

                  <ProjectInfoBox
                    title="Объем требуемых средств"
                    text={formatWithTwoDecimalsRub(project.budgetSummary)}
                  />
                </Box>
              )}

              {expanded && (
                <>
                  {project.declineReason &&
                  project.status === PROJECT_STATUS.DECLINED ? (
                    <Box margin={{ top: 'xsmall' }}>
                      <ProjectInfoBox
                        title="Причина отклонения"
                        text={project.declineReason || ''}
                        noWrap
                      />
                    </Box>
                  ) : null}
                  <Box
                    direction={isTabletOrWider ? 'row' : 'column'}
                    flex="grow"
                    margin={{ top: '15px' }}
                    gap="15px"
                  >
                    <Box basis="50%" gap="15px">
                      <ProjectInfoBox
                        title="Областной бюджет"
                        text={formatWithTwoDecimalsRub(project.budgetRegion)}
                      />
                      <ProjectInfoBox
                        margin={{ top: 'xsmall' }}
                        title="Средства населения"
                        text={formatWithTwoDecimalsRub(project.budgetPeople)}
                      />
                    </Box>

                    <Box basis="50%" gap="15px">
                      <ProjectInfoBox
                        title="Бюджет муниципального образования"
                        text={formatWithTwoDecimalsRub(project.budgetDistrict)}
                      />
                      <ProjectInfoBox
                        margin={{ top: 'xsmall' }}
                        title="Внебюджетные средства"
                        text={formatWithTwoDecimalsRub(project.noBudgetFunds)}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          {expanded && (
            <>
              <Box
                flex="grow"
                margin={{ top: 'small' }}
                pad={!isTabletOrWider && '0 6px'}
                gap={!isTabletOrWider && '15px'}
              >
                <Text
                  bold
                  size={isTabletOrWider ? '13px' : '18px'}
                  margin={{ vertical: '16px' }}
                >
                  Описание проекта
                </Text>

                <Box direction={isTabletOrWider ? 'row' : 'column'}>
                  {project.sphere ? (
                    <ProjectInfoBox
                      margin={{
                        top: 'xsmall',
                        bottom: !isTabletOrWider && '15px'
                      }}
                      style={{ flexBasis: '50%' }}
                      title="Сфера деятельности"
                      text={getSphere(project.sphere)}
                    />
                  ) : null}
                  <ProjectInfoBox
                    margin={{ top: 'xsmall' }}
                    title="Благополучателей"
                    text={showPeople(project.beneficiaries)}
                  />
                </Box>

                <ProjectInfoBox
                  margin={{ top: 'xsmall' }}
                  title="Описание проекта"
                  text={project.description}
                  noWrap
                />
                <ProjectInfoBox
                  margin={{ top: 'xsmall' }}
                  title="Место реализации проекта"
                  text={project.place}
                />
              </Box>

              {project.files.length !== 0 && (
                <Box
                  flex="grow"
                  margin={{ top: isTabletOrWider ? 'small' : '16px' }}
                  pad={!isTabletOrWider && '0 6px'}
                  gap="small"
                >
                  <Text bold size={isTabletOrWider ? 'xsmall' : '18px'}>
                    Материалы (до и после реализации прокта)
                  </Text>

                  <SliderComponent
                    accessType="public"
                    slideFiles={project.files}
                    arrow
                  />
                </Box>
              )}

              {!!project.privateFiles && project.privateFiles.length !== 0 && (
                <Box
                  flex="grow"
                  margin={{ top: 'small' }}
                  gap="small"
                  pad={!isTabletOrWider && '0 6px'}
                >
                  <Text bold size="xsmall">
                    <PrivateIcon /> Приватные материалы
                  </Text>

                  <SliderComponent slideFiles={project.privateFiles} arrow />
                </Box>
              )}

              <Anchor
                href={project.administrationLink}
                margin={{ top: 'medium' }}
                color={theme.palette.Purple}
                size="xsmall"
                target='_blank'
              >
                Ссылка на сайт администрации <ExternalLink />
              </Anchor>
            </>
          )}
        </Box>

        {project.status === PROJECT_STATUS.DONE && (
          <ImplementationDetails
            pad={{ vertical: 'small' }}
            margin={{ horizontal: 'small', bottom: '10px' }}
            project={project}
          />
        )}

        <CardFooter
          direction={isTabletOrWider ? 'row' : 'column'}
          justify="between"
          align="center"
        >
          <Button
            transparent
            color="Purple"
            onClick={() => setExpanded(p => !p)}
          >
            <CollapseArrow
              style={{
                transform: expanded && 'rotate(180deg)',
                marginRight: '8px'
              }}
            />{' '}
            {expanded ? 'Свернуть' : 'Развернуть'}
          </Button>

          <ProjectActions project={project} poll={poll} voted={voted} />

          {isVoter && <RealizationRating project={project} pollId={poll.id} />}
        </CardFooter>
      </CardContainer>
    );
  }
);

const CardContainer = styled(Box)`
  box-shadow: 0 4px 8px rgba(10, 6, 6, 0.04);
  border-radius: 1px;
  width: 100%;
`;

const CardImage = styled.img`
  border-radius: 4px;
  width: 100%;
  height: auto;

  object-fit: cover;

  @media (min-width: ${breakpoints.tablet}) {
    width: 130px;
    height: 130px;
  }
`;

const CardFooter = styled(Box)`
  min-height: 66px;
  background: #fafafa;
  width: 100%;
  padding: 9px 16px;
`;

const ProjectStatusStyled = styled(ProjectStatus)`
  position: absolute;
  top: ${props => props.theme.global.edgeSize.small};
  right: ${props => props.theme.global.edgeSize.small};
`;
