import { api } from './api';
import { IpObject, IpObjectList, ListResponse } from 'interfaces';
import { ENDPOINTS } from './endpoints';

export function getIpObjectList(
  params: any
): Promise<ListResponse<IpObjectList>> {
  return api.get(ENDPOINTS.ipObjectList(), params);
}

export function getIpObject(id: string): Promise<IpObject> {
  return api.get(ENDPOINTS.ipObject(id));
}
