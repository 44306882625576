import { IStores } from 'stores/index';
import {
  IDataFlowProps,
  ListStoreConstructor
} from 'stores/core/ListStoreConstructor';
import { PollingType } from '../pages/Organizer/tabs';
import { action, computed, observable } from 'mobx';
import { getAll } from 'services/pollSubjects';
import { PollingStatus } from 'components/RegionMap';
import {
  DistrictType,
  ISchool,
  ListResponse,
  PollSubject
} from 'interfaces/apiEntities';
import { getSchools } from 'services/voteService';
import { getCurrentYear } from 'utils/misc';

// export const VOLGOGRAD_DISTRICTS = [33, 34, 35, 36, 37, 38, 39, 40];

const VOLGOGRAD_DISTRICT_PREFIX = 'г. Волгоград:';

const POLLING_STATUS: Record<PollingStatus, number> = {
  PUBLISHED: 1,
  APPLICATIONS_RECEIVING: 2,
  WAITING_FOR_VOTING: 3,
  VOTING: 4,
  FINISHED: 5,
  __DELETED: 6
};

export class PollingList extends ListStoreConstructor<PollSubject> {
  constructor(
    stores: IStores,
    fetcher: () => Promise<ListResponse<PollSubject>>
  ) {
    super(stores, fetcher, { pollingInterval: 5e4 });
  }
}

export class PollSubjectListStore extends ListStoreConstructor<PollSubject> {
  public delete = new PollingListRemove(this);

  @observable
  public schoolGroups: ListStoreConstructor<PollSubject>;

  @observable
  public districtList: ListStoreConstructor<PollSubject>;

  @observable
  public schoolsList: ListStoreConstructor<ISchool>;

  fetch = (options?: { isSilent?: boolean; withDebounce: boolean }) => {
    this.currentList.fetch(options);
  };

  @observable
  public initialized = false;

  @observable
  public searchString: string = '';

  public scrollPosition = 0;

  @observable
  public pollingType: PollingType = PollingType.district;

  @observable
  public districtType: DistrictType = 'OBLAST_DISTRICTS';

  @computed
  public get currentList() {
    return this.isMOListType ? this.districtList : this.schoolGroups;
  }

  @computed
  public get isMOListType(): boolean {
    return this.pollingType === PollingType.district;
  }

  @computed
  public get pollingList(): PollSubject[] {
    const list = this.currentList;

    const pollingList = list.data
      .map(p => {
        return {
          ...p,
          name: p.name.replace(VOLGOGRAD_DISTRICT_PREFIX, '')
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    if (this.searchString.length === 0) {
      return pollingList;
    }

    return pollingList.filter(
      p => p.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1
    );
  }

  @computed
  public get isEmptyList(): boolean {
    return this.pollingList.length === 0;
  }

  @computed
  public get isLoading(): boolean {
    return this.isPending || this.currentList.fetchStatus === 'init';
  }

  @computed
  public get isPending(): boolean {
    return this.currentList.isPending;
  }

  constructor(stores: IStores) {
    super(stores, params => getAll(params), { isLocal: true });

    this.schoolGroups = new PollingList(stores, () =>
      getAll({
        type: PollingType.schoolGroup,
        size: 1000,
        hasPoll: true,
        sort: 'id,asc',
        year: getCurrentYear()
      })
    );

    this.districtList = new PollingList(stores, () =>
      getAll({
        type: PollingType.district,
        size: 1000,
        hasPoll: true,
        sort: 'name,asc',
        year: getCurrentYear(),
      })
    );

    this.schoolsList = new ListStoreConstructor(
      stores,
      () => getSchools({ page: 0, size: 2000, year: getCurrentYear()}),
      {}
    );
  }

  public getSchools = () => {
    if (this.schoolsList.fetchStatus === 'success') {
      return Promise.resolve({ content: this.schoolsList.data });
    }

    return this.schoolsList.fetch();
  };

  @action
  public async init(dataFlow: IDataFlowProps = {}, reinitialize = false) {
    if (this.initialized && !reinitialize) {
      return;
    }

    await Promise.all([this.schoolGroups.fetch(), this.districtList.fetch()]);

    this.initialized = true;
  }

  public updatePollingType(type: PollingType): void {
    this.searchString = '';
    this.pollingType = type;

    this.districtType = 'OBLAST_DISTRICTS';
  }

  public updateSearch(next: string): void {
    this.searchString = next;
  }

  public updateDistrictType(next: DistrictType): void {
    this.searchString = '';
    this.districtType = next;
  }
}

export class PollingListRemove {
  @observable selectedIds: string[] = [];

  @computed
  public get isAllSelected() {
    return (
      this.selectedIds.length ===
      this.listStore.pollingList.filter(p => this.isDeletable(p)).length
    );
  }

  constructor(private listStore: PollSubjectListStore) {}

  public isDeletable(polling: PollSubject) {
    return POLLING_STATUS[polling.status] < 4;
  }

  public isSelected(polling: PollSubject) {
    return this.selectedIds.includes(polling.id);
  }

  @action
  public selectAll() {
    if (this.isAllSelected) {
      this.flush();

      return;
    }

    this.selectedIds = this.listStore.pollingList
      .filter(p => this.isDeletable(p))
      .map(({ id }) => id);
  }

  @action
  public selectPolling(polling: PollSubject) {
    if (this.selectedIds.includes(polling.id)) {
      this.selectedIds = this.selectedIds.filter(i => i !== polling.id);

      return;
    }

    this.selectedIds = [...this.selectedIds, polling.id];
  }

  @action
  public flush() {
    this.selectedIds = [];
  }
}
