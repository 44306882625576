import * as React from 'react';

export const PlusIconSVG = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 5.56376H5.56376V9H4.43624V5.56376H1V4.43624H4.43624V1H5.56376V4.43624H9V5.56376Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);
