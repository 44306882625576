import * as React from 'react';
import { BoxProps, Box } from 'grommet';
import { CSSProperties } from 'react';
import styled from 'styled-components';

export const TabsEx = ({
  tabs,
  selected,
  onChange,
  store,
  ...rest
}: {
  tabs: ITabOptions[];
  store?: any; // TODO fix this
  selected?: string;
  onChange?(id: string): void;
  style?: CSSProperties;
} & BoxProps) => {
  return (
    <Box {...rest} direction="column-reverse" align="baseline">
      <Tabs store={store} tabs={tabs} selected={selected} onChange={onChange} />
    </Box>
  );
};

const Tabs: React.FunctionComponent<IProps> = ({
  store,
  onChange,
  selected,
  tabs,
  small,
  children,
  ...rest
}) => (
  <Box {...rest} direction="row-responsive">
    {tabs.map(item => (
      <Tab
        store={store}
        key={item.id}
        onChange={onChange}
        selected={selected}
        small={small}
        {...item}
      />
    ))}
    {children}
  </Box>
);

function CountCurrentProject(store, name) {
  let count = store.store.allData.filter(item => item.status.includes(name.id))
    .length;

  if (name.id === 'ALL') {
    return <span>&nbsp;({store.store.allData.length})</span>;
  }

  if (count === 0) {
    return '';
  }

  return <span>&nbsp;({count})</span>;
}

const Tab: React.FunctionComponent<ITabProps> = ({
  store,
  selected,
  id,
  text,
  onChange,
  className,
  titleText,
  small,
  disabled,
  ...rest
}) => {
  return (
    <StyledTab
      active={selected == id}
      disabled={disabled}
      onClick={() => onChange && !disabled && onChange(id)}
      {...rest}
    >
      {text instanceof Function ? text() : text}
      {/*{CountCurrentProject({ store }, { id })}*/}
    </StyledTab>
  );
};

const StyledTab = styled.div<any>`
  margin: 0 5px;
  font-size: 12px;
  font-weight: 600;
  color: ${props =>
    props.active ? props.theme.palette.BlackText : props.theme.palette.Grey};
  border-bottom: ${props => (props.active ? '2px solid #4740a1' : null)};
  padding: ${props => (props.active ? '0 2px 14px' : '0 2px')};
  background-color: ${props =>
    props.active ? 'white' : props.theme.styled.tabs.tab.backgroundColor};
  font-family: ${props => props.theme.fontBase};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
`;

interface ITabProps extends ITabOptions, React.ComponentProps<any> {
  selected?: string;
  onChange?(id: string): void;
  small?: boolean;
}

export interface ITabOptions {
  id: string;
  text: string | React.ReactNode;
  titleText?: string;
  className?: string;
  disabled?: boolean;
}

interface IProps {
  selected?: string;
  onChange?(id: string): void;
  className?: string;
  tabs: ITabOptions[];
  small?: boolean;
  store?: any;
}
