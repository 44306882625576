import { api } from 'services/api';
import { ENDPOINTS } from 'services/endpoints';
import { IPoll, IPollResults } from 'interfaces/apiEntities';

export function getPollById(pollId: string): Promise<IPoll> {
  return api.get(ENDPOINTS.pollById(pollId));
}

export function getMainKeyById(pollId: string): Promise<{ mainKey: string }> {
  return api.get<{ mainKey: string }>(ENDPOINTS.getMainKey(pollId));
}

export function getPollResults(pollId: string): Promise<IPollResults> {
  return api.get(ENDPOINTS.pollResults(pollId));
}

export function getSignKeyById(
  pollId: string
): Promise<{ regSign; publicKey }> {
  return api.get<{ regSign; publicKey }>(ENDPOINTS.getSignKey(pollId));
}

export function sendBulletin(
  pollId,
  params: { encryptedBulletin: string; signRequest: any }
): Promise<any> {
  return api.post(ENDPOINTS.sendBulletin(pollId), params);
}

export function getVoteById(
  pollId: string
): Promise<{ createdDate: string; encryptedBulletin: string }> {
  return api.get<{
    createdDate: string;
    encryptedBulletin: string;
  }>(ENDPOINTS.getVote(pollId));
}
