import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, BoxProps } from 'grommet';
import { Form, isEmail, isRequired, MobxForm } from '@we-ui-components/form';
import {
  allowEmpty,
  isCorrectLastName,
  isLengthBetween,
  isTheSameAs,
  oneOfLengths,
  P
} from 'utils';
import { Text } from '@we-ui-components/base';
import { Panel, Error as ErrorUI, ButtonEx, StyledRouterLink } from 'ui';
import { StyledInput, WelcomeMessage } from './components';
import { PasswordHint } from './PasswordHint';
import { PhoneInput } from './PhoneInput';
import { Footer } from './Footer';
import { Routes } from '../../constants';
import { useLocalStore } from 'mobx-react';
import { passwordValidators } from './registration.utils';
import * as bcrypt from 'bcryptjs';
import { useStores } from 'stores';

export const RegistrationFormPanel = observer<BoxProps>(
  function RegistrationFormPanel(props) {
    const formRef = React.useRef<MobxForm>();
    const { user: userStore } = useStores();
    const [networkError, setNetworkError] = React.useState<string>();
    const [requestSending, setRequestSending] = React.useState(false);

    const [passwordHintVisible, setPasswordHintVisible] = React.useState(false);

    const user = useLocalStore<UserFormData>(() => {
      return {
        surname: '',
        name: '',
        patronymic: '',
        phoneNumber: '+7',
        email: '',
        password: '',
        repeatPassword: ''
      };
    });

    const handleSubmit = async () => {
      const formData = (await formRef.current.validateFields()) as UserFormData;

      try {
        setRequestSending(true);

        await userStore.registration({
          name: formData.name,
          surname: formData.surname,
          email: formData.email,
          patronymic: formData.patronymic,
          phoneNumber: formData.phoneNumber,
          encodedPassword: bcrypt.hashSync(formData.password)
        });

        await userStore.login(formData.email, formData.password);
      } catch (error) {
        setNetworkError(
          error?.detail || 'Неизвестная ошибка. Повторите попытку позже'
        );
      } finally {
        setRequestSending(false);
      }
    };

    return (
      <Panel width="736px" pad="32px" style={{ zIndex: 2 }} {...props}>
        <WelcomeMessage />

        <Form ref={formRef} data={user} {...({} as any)}>
          <Box justify="between" direction="row">
            <StyledInput
              placeholder="Фамилия*"
              name={P<UserFormData>(u => u.surname)}
              rules={[isRequired, isLengthBetween(2, 20), isCorrectLastName]}
            />
            <StyledInput
              placeholder="Имя*"
              name={P<UserFormData>(u => u.name)}
              rules={[isRequired, isLengthBetween(2, 20), isCorrectLastName]}
            />
          </Box>

          <StyledInput
            placeholder="Отчество"
            name={P<UserFormData>(u => u.patronymic)}
            rules={[
              allowEmpty(isLengthBetween(2, 20)),
              allowEmpty(isCorrectLastName)
            ]}
          />

          <Box justify="between" direction="row">
            <PhoneInput
              name="phoneNumber"
              rules={[
                isRequired,
                oneOfLengths([12], 'Введите корректный номер')
              ]}
            />

            <StyledInput
              placeholder="Email*"
              name={P<UserFormData>(u => u.email)}
              rules={[isRequired, isEmail]}
            />
          </Box>

          <Box justify="between" direction="row">
            <Box width="320px">
              <StyledInput
                name={P<UserFormData>(u => u.password)}
                onFocus={() => {
                  setPasswordHintVisible(true);
                }}
                onBlur={() => {
                  setPasswordHintVisible(false);
                }}
                placeholder="Пароль*"
                type="password"
                rules={[
                  isRequired,
                  isLengthBetween(8, 14),
                  ...passwordValidators
                ]}
                {...({} as any)}
              />
            </Box>

            <StyledInput
              name={P<UserFormData>(u => u.repeatPassword)}
              placeholder="Повторите пароль*"
              type="password"
              rules={[
                isRequired,
                isTheSameAs('password', 'Пароли не совпадают')
              ]}
            />
          </Box>

          {passwordHintVisible && <PasswordHint password={user.password} />}
        </Form>

        {networkError && (
          <ErrorUI style={{ marginTop: '10px' }} error={networkError} />
        )}

        <Box
          margin={{ vertical: '16px' }}
          direction="row"
          justify="between"
          align="center"
        >
          <Text>
            Уже есть аккаунт?{' '}
            <StyledRouterLink to={`/${Routes.loginOrganizer}`}>
              Войдите
            </StyledRouterLink>
          </Text>

          <ButtonEx
            isLoading={requestSending}
            onClick={() => {
              handleSubmit();
            }}
            pad={{ horizontal: '40px', vertical: '14px' }}
          >
            Создать аккаунт
          </ButtonEx>
        </Box>

        <Footer />
      </Panel>
    );
  }
);

type UserFormData = {
  surname: string;
  name: string;
  patronymic: string;
  phoneNumber: string;
  email: string;
  password: string;
  repeatPassword: string;
};
