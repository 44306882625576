import * as React from 'react';
import { BackButton, PageContainer } from 'components';
import { Button, Text, Title } from '@we-ui-components/base';
import { computed, observable } from 'mobx';
import * as moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Box } from 'grommet';
import { Routes } from 'constants/routes';
import {
  Calendar,
  Form,
  Input,
  MobxForm,
  NumberInput,
  RadioGroup,
  Select,
  TextArea
} from '@we-ui-components/form';
import {
  isCorrectUrl,
  isPositive,
  isRequired,
  lessThan,
  maxLength
} from 'utils/validators';
import styled from 'styled-components';
import { formatWithTwoDecimalsRub } from 'utils';
import {
  getDistricts,
  getDistrictsWithVotes,
  getSchools
} from 'services/voteService';
import { FilesIdInput } from './FileInput';
import { IStores } from 'stores';
import { createProject, uploadFile } from 'services';
import visibilitySVG from './visibility_off.svg';

interface IRawProject {
  name: string;
  pollId: string;
  schoolId?: string;
  type: 'REGION' | 'SCHOOL_PARTY';
  beneficiaries: number | string;
  description: string;
  startDate: Date;
  endDate: Date;
  district: string | number;
  sphere: string;
  place: string;
  administrationLink: string;
  budgetRegion: number | string;
  budgetPeople: number | string;
  budgetDistrict: number | string;
  noBudgetFunds: number | string;
  files: File[];
  privateFiles: File[];
}

interface ISubjectOption {
  text: string;
  value: string | number;
  pollId?: string | number;
  personCount?: number;
}

@inject('routing')
@observer
export class CreateProject extends React.Component<Pick<IStores, 'routing'>> {
  formRef: MobxForm;

  @observable isLoading: boolean;
  @observable error: string;

  @observable
  data: IRawProject = {
    name: '',
    pollId: '',
    schoolId: '',
    type: 'REGION',
    description: '',
    files: [],
    privateFiles: [],
    startDate: moment()
      .add(1, 'day')
      .toDate(),
    endDate: moment()
      .add(3, 'day')
      .toDate(),
    district: '',
    sphere: 'OTHER',
    place: '',
    administrationLink: '',
    beneficiaries: '',
    budgetRegion: '',
    budgetPeople: '',
    budgetDistrict: '',
    noBudgetFunds: ''
  };

  @observable
  districts: ISubjectOption[] = [];

  @observable
  districtsWithVotes: ISubjectOption[] = [];

  @observable
  schools: ISubjectOption[] = [];

  @computed
  get totalCost() {
    const {
      budgetRegion,
      budgetPeople,
      budgetDistrict,
      noBudgetFunds
    } = this.data;
    return (
      Number(budgetRegion) +
      Number(budgetPeople) +
      Number(budgetDistrict) +
      Number(noBudgetFunds)
    );
  }

  goBack = () => {
    const { routing } = this.props;
    routing.push(`/${Routes.projects}`);
  };

  createProject = () => {
    this.formRef.validateFields().then(res => {
      if (!this.totalCost) {
        this.error = 'Общий объём требуемых средств должен быть больше 0';
        return;
      }
      this.isLoading = true;
      this.error = '';
      return processData(this.data, this.pollId)
        .then(formData => {
          return createProject(formData)
            .then(() => this.goBack())
            .catch(error => {
              this.error = error.detail || error.title;
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(err => {
          this.error = err.message;
          this.isLoading = false;
        });
    });
  };

  onChangeDistrict = (value: string | number, actualType?: string) => {
    const { type } = this.data;
    const region = this.actualDistricts.find(i => value === i.value);
    this.data.schoolId = '';
    if (
      (type === 'SCHOOL_PARTY' || actualType === 'SCHOOL_PARTY') &&
      !!region
    ) {
      this.data.beneficiaries = '';
      getSchools({
        pollStatus: 'APPLICATIONS_RECEIVING',
        hasPoll: 'true',
        districtId: region.value,
        size: 1000
      }).then(res => {
        this.schools = res.content
          .filter(i => !!i.pollId)
          .map(i => ({
            text: i.name,
            value: i.id,
            pollId: i.pollId,
            personCount: i.personCount
          }));
      });
    }
  };

  onChangeSchool = (id: number) => {
    const school = this.schools.find(i => i.value === id);

    this.data.beneficiaries = school.personCount;
  };

  get actualDistricts() {
    const { type } = this.data;

    return type === 'REGION' ? this.districts : this.districtsWithVotes;
  }

  @computed
  get pollId() {
    const { type, schoolId, district } = this.data;

    const region = this.actualDistricts.find(i => district === i.value);
    const school = this.schools.find(i => schoolId === i.value);

    return type === 'REGION' ? region.pollId : school.pollId;
  }

  componentDidMount(): void {
    getDistricts().then(res => {
      this.districts = res.content
        .filter(i => !!i.pollId && i.status === 'APPLICATIONS_RECEIVING')
        .map(i => ({
          text: i.name,
          value: i.id,
          pollId: i.pollId
        }));
    });

    getDistrictsWithVotes().then(res => {
      this.districtsWithVotes = res.content
        .map(i => ({
          text: i.name,
          value: i.id
        }));
    });
  }

  render() {
    const { type } = this.data;

    return (
      <PageContainer>
        <Box pad={{ vertical: '32px', horizontal: '64px' }}>
          <Box direction="row" margin={{ bottom: 'medium' }}>
            <BackButton url={`/${Routes.homeApplicant}`}/>
          </Box>
          <Box align="center" style={{ flex: '1 1 100%' }}>
            <Box
              pad="xlarge"
              background="white"
              width="736px"
              style={{ border: '1px solid #D1D1D1' }}
            >
              <Title bold margin={{ bottom: 'medium' }}>
                Создание проекта
              </Title>
              <Form
                ref={(formRef: MobxForm) => (this.formRef = formRef)}
                data={this.data}
              >
                <Box gap="small">
                  <Input
                    name="name"
                    size="full"
                    placeholder="Название проекта *"
                    rules={[isRequired(), maxLength(120)]}
                  />
                  <RadioGroup
                    name="type"
                    label="Тип проекта"
                    size="auto"
                    direction="row"
                    displayMode="stickers"
                    options={typeOptions}
                    onChange={() => {
                      this.data.district = '';
                      this.data.beneficiaries = '';
                      this.forceUpdate();
                    }}
                    rules={[isRequired()]}
                  />
                  {this.data.type === 'REGION' && (
                    <Select
                      name="district"
                      label="Муниципальное образование *"
                      size="full"
                      options={this.districts}
                      onChange={this.onChangeDistrict}
                      rules={[isRequired()]}
                    />
                  )}
                  {this.data.type === 'SCHOOL_PARTY' && (
                    <Select
                      name="district"
                      label="Муниципальное образование *"
                      size="full"
                      options={this.districtsWithVotes}
                      onChange={this.onChangeDistrict}
                      rules={[isRequired()]}
                    />
                  )}
                  {type === 'SCHOOL_PARTY' && (
                    <Select
                      name="schoolId"
                      label="Школа *"
                      size="full"
                      options={this.schools}
                      onChange={this.onChangeSchool}
                      rules={[isRequired()]}
                    />
                  )}
                  <Text size="large" bold>
                    Описание проекта
                  </Text>
                  {type === 'REGION' && (
                    <Select
                      name="sphere"
                      label="Сфера проекта *"
                      size="full"
                      options={sphereOptions.filter(op => !['CULTURE', 'EDUCATION'].includes(op.value))}
                      rules={[isRequired()]}
                    />
                  )}

                  <NumberInput
                    type="integer"
                    name="beneficiaries"
                    placeholder="Количество благополучателей, чел *"
                    size="full"
                    rules={[isRequired(), isPositive(), lessThan(1e8)]}
                    disabled={type === 'SCHOOL_PARTY'}
                  />

                  <TextArea
                    {...({} as any)}
                    name="description"
                    label="Описание проекта *"
                    rules={[isRequired(), maxLength(1500)]}
                    style={{ height: '128px' }}
                  />
                  <Box direction="row" gap="small">
                    <Calendar
                      name="startDate"
                      size="xlarge"
                      placeholderText="Начало реализации *"
                      minDate={moment().add(1, 'day').toDate()}
                      rules={[isRequired()]}
                    />
                    <Calendar
                      name="endDate"
                      size="xlarge"
                      placeholderText="Окончание реализации *"
                      rules={[isRequired()]}
                    />
                  </Box>
                  <Input
                    name="place"
                    placeholder="Место реализации проекта *"
                    size="full"
                    rules={[isRequired()]}
                  />
                  <Input
                    name="administrationLink"
                    placeholder="Ссылка на сайт администрации *"
                    size="full"
                    rules={[isRequired(), isCorrectUrl()]}
                  />
                  <Line/>
                  <Text bold>
                    Объем требуемых средств{' '}
                    {formatWithTwoDecimalsRub(this.totalCost)}
                  </Text>
                  <Box>
                    <Box direction="row" gap="small">
                      <NumberInput
                        type="decimal"
                        name="budgetRegion"
                        placeholder="Областной бюджет, ₽"
                        size="xlarge"
                      />
                      <NumberInput
                        type="decimal"
                        name="budgetPeople"
                        placeholder="Средства населения, ₽"
                        size="xlarge"
                      />
                    </Box>
                    <Box direction="row" gap="small">
                      <NumberInput
                        type="decimal"
                        name="budgetDistrict"
                        placeholder="Бюджет муниципального образования, ₽"
                        size="xlarge"
                      />
                      <NumberInput
                        type="decimal"
                        name="noBudgetFunds"
                        placeholder="Внебюджетные средства, ₽"
                        size="xlarge"
                      />
                    </Box>
                  </Box>
                  <Text bold>Материалы (до и после реализации проекта)*</Text>
                  <Text>
                    Эти материалы будут доступны всем пользователям системы.
                  </Text>
                  <FilesIdInput
                    name="files"
                    withPreview
                    additionalText={
                      'Первый выбранный и загруженный файл будет установлен как превью-фото проекта'
                    }
                    limits={{ maxFiles: 3 }}
                    formats={['.jpg', '.png']}
                    rules={[isRequired()]}
                  />
                  <Box direction="row" align="center">
                    <Img src={visibilitySVG} alt=""/>
                    <Text bold>Приватные материалы</Text>
                  </Box>
                  <Text>
                    Эти материалы будут доступны только вам и организатору.
                  </Text>
                  <FilesIdInput
                    name="privateFiles"
                    withPreview
                    limits={{
                      maxFiles: 7,
                      maxFileSize: 10.48e6
                    }}
                    additionalText={
                      'Размер каждого файла не должен превышать 10 Мб'
                    }
                    formats={['.jpg', '.png', '.pdf']}
                  />
                </Box>
              </Form>
              {!!this.error && (
                <Text color="Red" size="small" margin={{ top: 'small' }}>
                  {this.error}
                </Text>
              )}
            </Box>
            <Box
              direction="row"
              justify="end"
              gap="medium"
              pad={{ vertical: 'xlarge' }}
              width="736px"
            >
              <Button transparent bordered size="large" onClick={this.goBack}>
                Отмена
              </Button>
              <Button
                size="auto"
                onClick={this.createProject}
                disabled={this.isLoading}
              >
                Отправить на рассмотрение
              </Button>
            </Box>
          </Box>
        </Box>
      </PageContainer>
    );
  }
}

async function processData(data: IRawProject, pollId: string | number) {
  try {
    const fileIds = await uploadFiles(data.files, 'PUBLIC');
    const privateFileIds = await uploadFiles(data.privateFiles, 'PRIVATE');

    const res = {
      ...data,
      pollId,
      startDate: data.startDate.toISOString(),
      endDate: data.endDate.toISOString(),
      files: fileIds.concat(privateFileIds)
    };

    if (data.type === 'SCHOOL_PARTY') {
      delete res.sphere;
    }

    delete res.type;
    delete res.district;
    delete res.privateFiles;

    return res;
  } catch (err) {
    throw new Error(err.detail || err.title);
  }
}

export async function uploadFiles(
  files: File[],
  type: 'PUBLIC' | 'PRIVATE'
): Promise<string[]> {
  if (!files.length) {
    return [];
  }
  const previewFile = await uploadFile(files[0], type);
  const loadedFiles = await Promise.all(
    files.slice(1).map(file => uploadFile(file, type))
  );
  return [previewFile, ...loadedFiles].map(i => i.id);
}

const typeOptions = [
  {
    text: 'Муниципальное образование',
    value: 'REGION'
  },
  {
    text: 'Школьная группа',
    value: 'SCHOOL_PARTY'
  }
];

export const sphereOptions = [
  {
    text: 'Водоснабжение, водоотведение',
    value: 'SEWERAGE'
  },
  {
    text: 'Автомобильные дороги, тротуары, пешеходные переходы, остановки',
    value: 'ROADS'
  },
  {
    text: 'Уличное освещение',
    value: 'LIGHTNING'
  },
  {
    text: 'Пожарная безопасность',
    value: 'FIRE_SAFETY'
  },
  {
    text: 'Проекты в сфере бытового обслуживания населения',
    value: 'SERVICE_SECTOR'
  },
  {
    text: 'Культурное наследие (памятники, музеи)',
    value: 'CULTURE'
  },
  {
    text: 'Проекты в сфере образования',
    value: 'EDUCATION'
  },
  {
    text: 'Проекты в сфере культуры, библиотечного дела, ремонт домов культуры',
    value: 'CULTURE_REPAIR'
  },
  {
    text: 'Физическая культура и массовый спорт',
    value: 'SPORT'
  },
  {
    text: 'Комплексное благоустройство дворов',
    value: 'LANDSCAPING'
  },
  {
    text: 'Детские игровые площадки',
    value: 'PLAYGROUNDS'
  },
  {
    text: 'Места массового отдыха и объекты организации благоустройства',
    value: 'MASS_RECREATION_PLACES'
  },
  {
    text: 'Благоустройство мест захоронений',
    value: 'GRAVEYARDS'
  },
  {
    text: 'Организация сбора твердых коммунальных отходов и мусора',
    value: 'TRASH'
  },
  {
    text: 'Проекты ЖКХ',
    value: 'RENOVATION'
  },
  {
    text: 'Крупные инфраструктурные проекты (мосты, плотины, водоёмы)',
    value: 'BRIDGES'
  },
  {
    text: 'Приобретение оборудования, техники, транспорта',
    value: 'EQUIPMENT_PURCHASE'
  },
  {
    text:
      'Проекты, направленные на уязвимые социальные группы и граждан с ограниченными возможностями',
    value: 'DISABILITIES_CITIZENS'
  },
  {
    text: 'Другое',
    value: 'OTHER'
  }
];

export const getSphere = value => {
  const sphere = sphereOptions.find(s => s.value === value);

  return sphere ? sphere.text : value;
};

export const Img = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 12px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d1d1d1;
  margin-bottom: 16px;
  margin-top: 4px;
`;
