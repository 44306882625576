import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { createQS } from 'we-oauth2/lib/auth/api-utils';

export default class CustomRouterStore extends RouterStore {
  constructor() {
    super();
    const browserHistory = createBrowserHistory();

    this.history = syncHistoryWithStore(browserHistory, this);
  }

  getSearchParams(params: string[]) {
    const sp = this.createURLSearhcParams();
    const result = {} as Record<string, string>;

    params.forEach(name => {
      if (sp.has(name)) {
        result[name] = sp.get(name);
      }
    });

    return result;
  }

  setSearchParams(newParams: Record<string, any>) {
    const updatedParams = this.getUpdatedSearchParamsState(newParams);
    const newPath = this.location.pathname + createQS(updatedParams);

    this.history.push(newPath);
  }

  private getUpdatedSearchParamsState(newParams: Record<string, any>) {
    const sp = this.createURLSearhcParams(newParams);
    const state = {} as Record<string, any>;

    sp.forEach((value, key) => {
      state[key] = value;
    });

    return state;
  }

  private createURLSearhcParams(paramsToInject?: Record<string, any>) {
    const result = new URLSearchParams(this.location.search);

    if (paramsToInject) {
      Object.entries(paramsToInject).forEach(([name, value]) => {
        const isValueSet = () => {
          if (value instanceof Array) {
            return value.length > 0;
          }

          if (typeof value === 'string') {
            return value.length > 0;
          }

          return value !== undefined;
        };

        if (isValueSet()) {
          result.set(name, value);
        } else {
          result.delete(name);
        }
      });
    }

    return result;
  }
}
