import * as React from 'react';
import arrowLeftSVG from './arrow-left.svg';
import { Box, BoxProps } from 'grommet';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { CSSProperties } from 'react';
import { Text } from '@we-ui-components/base';
import { IStyledProps } from 'themes';
import { getRootPathname } from 'utils';
import { useLocation } from 'react-router';

type BackButtonProps = {
  text?: string;
  textColor?: string;
  url?: string;
  border?: boolean;
  style?: CSSProperties;
} & BoxProps &
  IStyledProps;

export const BackButton = observer(
  ({ text = 'Назад', url, textColor, border = true, ...props }: BackButtonProps) => {
    const { pathname } = useLocation();
    const rootPathname = getRootPathname(pathname);
    // const isDetailsPage = pathname !== rootPathname;
    const { routing } = useStores();

    return (
      <Box
        direction="row"
        // width="100%"
        onClick={() => routing.push(url || rootPathname)}
        align="center"
        {...props}
      >
        <img style={{ marginRight: 6 }} src={arrowLeftSVG} />
        <Text size="xsmall" color={textColor}>{text}</Text>
      </Box>
    );
  },
);
