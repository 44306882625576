import { observer, useLocalStore } from 'mobx-react';
import {
  Form,
  Input,
  isEmail,
  isRequired,
  MobxForm
} from '@we-ui-components/form';
import { EmptyString } from '../../constants';
import { Box } from 'grommet';
import { Button } from '@we-ui-components/base';
import * as React from 'react';
import { DataPiece, Label, Value } from './components/DataPiece';
import { useStores } from 'stores/index';
import { oneOfLengths } from 'utils/validators';
import { PhoneInput } from '../Registration/PhoneInput';
import { updatePersonFull, updatePersonPartial } from 'services/userService';
import { UpdatePersonRequest } from 'interfaces/apiEntities';
import { getFullName } from 'utils/textUtils';
import { action } from 'mobx';
import { phoneValidators } from '../Users/modals/userPasswordValidators';
import { FixedWidthUserInput } from '../Users/components/styled';

const preparePhone = (phone: string) =>
  phone.startsWith('8') ? phone.replace('8', '+7') : phone;

export const UpdateProfileModal = observer(() => {
  const { user, actionModals } = useStores();
  const { userInfo } = user;

  const formRef = React.useRef<MobxForm>(null);

  const isApplicant = user.isContainOneOfRoles(['APPLICANT']);
  const isRegulator = user.isContainOneOfRoles(['REGULATOR']);

  const emailError = useLocalStore(() => ({
    error: null,
    setError: error => {
      emailError.error = error;
    }
  }));

  const data = useLocalStore(
    () =>
      ({
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        patronymic: userInfo.meta?.patronymic,
        email: userInfo.email,
        phone: preparePhone(userInfo.phone || '')
      } as UpdatePersonRequest)
  );

  const handleCancel = () => {
    actionModals.closeLastModal();
  };

  const handleSave = action(async () => {
    const pickApplicant = ({ email, phone }) => ({ email, phone });

    const req: Partial<UpdatePersonRequest> = isApplicant
      ? pickApplicant(data)
      : { ...data };

    const updateFn = isApplicant ? updatePersonPartial : updatePersonFull;

    formRef.current.validateFields().then(() =>
      updateFn(userInfo.personId, req)
        .then(() => {
          actionModals.closeLastModal();
          user.updateUserInfo();
        })
        .then(() =>
          actionModals.open(
            () => (
              <Box
                align="end"
                style={{ flex: '1 1 100%' }}
                pad={{ bottom: 'xlarge' }}
              >
                <Button onClick={() => actionModals.closeLastModal()}>
                  Закрыть
                </Button>
              </Box>
            ),
            {
              title: 'Информация изменена',
              width: '545px'
            }
          )
        )
        .catch(error => {
          console.log(error);

          if (error.meta.field === 'email') {
            const errorEmail = {
              message: error.detail || 'Что-то пошло не так',
              field: error.meta.field
            };

            formRef.current.errors.set(error.meta.field, [errorEmail]);

            emailError.setError(errorEmail);
          }
        })
    );
  });

  return (
    <Box pad={{ bottom: 'large' }}>
      <Form ref={(ref: MobxForm) => (formRef.current = ref)} data={data}>
        {isApplicant && (
          <DataPiece margin={{ bottom: 'small' }}>
            <Label>ФИО</Label>

            <Value>
              {userInfo.firstName || userInfo.firstName
                ? getFullName({
                    firstName: userInfo.firstName || '',
                    lastName: userInfo.lastName || '',
                    middleName: userInfo.meta
                      ? userInfo.meta.patronymic || ''
                      : ''
                  })
                : EmptyString}
            </Value>
          </DataPiece>
        )}

        {isApplicant && (
          <DataPiece margin={{ bottom: 'small' }}>
            <Label>Район</Label>
            {/*TODO район в модели профиля пользрвателя*/}
            <Value>{userInfo.phone || EmptyString}</Value>
          </DataPiece>
        )}

        {isApplicant && (
          <DataPiece>
            <Label>Организация пользователя</Label>
            <Value>
              {userInfo.meta?.company || userInfo.companyName || EmptyString}
            </Value>
          </DataPiece>
        )}

        {isRegulator && (
          <>
            <Input placeholder="Фамилия" name="lastName" type="text" />

            <Input placeholder="Имя" name="firstName" type="text" />

            <Input placeholder="Отчество" name="patronymic" type="text" />
          </>
        )}

        <Input
          placeholder="Email"
          name="email"
          style={{ marginTop: isApplicant ? 18 : 0 }}
          type="text"
          help={emailError.error?.message || ''}
          required
          rules={[isEmail, isRequired]}
        />

        <Input placeholder="Телефон" name="phone" rules={phoneValidators} />
      </Form>

      <Box direction="row" justify="end">
        <Button
          bordered
          transparent
          color="Blue"
          onClick={handleCancel}
          style={{ border: '1px solid #204080' }}
        >
          Отменить
        </Button>

        <Button onClick={handleSave} margin={{ left: 'small' }}>
          Сохранить изменения
        </Button>
      </Box>
    </Box>
  );
});
