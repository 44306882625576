import * as React from 'react';
import { Form, MobxForm, isRequired } from '@we-ui-components/form';
import { isTheSameAs } from 'utils';
import { Box } from 'grommet';
import { inject, observer } from 'mobx-react';
import { TActionModalProps } from 'components/ActionModals';
import { observable } from 'mobx';
import { Text, Title } from '@we-ui-components/base';
import { StyledInput } from 'pages/Registration/components';
import * as bcrypt from 'bcryptjs';
import { IStores } from 'stores/index';

@inject('user')
@observer
export class EncryptPasswordModal extends React.Component<
  TActionModalProps & Pick<IStores, 'user'>
> {
  formRef: MobxForm;

  @observable
  data = { password: '', repeatPassword: '' };

  componentDidMount(): void {
    this.props.onValidate.callback = () => this.onValidate();
  }

  asyncValidate = async newPassword => {
    return new Promise((resolve, reject) => {
      bcrypt.compare(
        newPassword,
        this.props.user.encryptedContent,
        (err, result) => {
          if (err) {
            reject(err);
          }

          if (result) {
            resolve(result);
          } else {
            reject(
              'Пароль не совпадает с паролем введенном на предыдущем устройстве'
            );
          }
        }
      );
    });
  };

  onValidate = async () => {
    const data = await this.formRef.validateFields();

    if (this.props.user.encryptedContent) {
      await this.asyncValidate(data.password);
    }

    return data;
  };

  render() {
    return (
      <Box gap="12px">
        <Title size="small" bold>
          {!this.props.user.encryptedContent
            ? 'Создание пароля'
            : 'Введите пароль'}
        </Title>

        {!this.props.user.encryptedContent ? (
          <>
            <Text size="xsmall" style={{ lineHeight: '19px', fontWeight: 400 }}>
              Мы обеспечиваем анонимность голосования. Ваш голос будет
              зашифрован.
            </Text>
            <Text size="xsmall" style={{ lineHeight: '19px', fontWeight: 400 }}>
              Создание пароля позволит просмотреть историю вашего голосования.
              Если вы не укажете пароль, история будет недоступна.
            </Text>
          </>
        ) : (
          <Text size="xsmall" style={{ lineHeight: '19px', fontWeight: 400 }}>
            Введите пароль, который вы задали ранее. Это позволит вам
            посмотреть, как вы проголосовали
          </Text>
        )}
        <Form ref={(ref: any) => (this.formRef = ref)} data={this.data}>
          <Box margin={{ top: 'small' }}>
            <StyledInput
              name={'password'}
              placeholder="Пароль*"
              type="password"
              rules={[isRequired]}
              {...({} as any)}
            />

            {!this.props.user.encryptedContent ? (
              <StyledInput
                name={'repeatPassword'}
                placeholder="Повторите пароль*"
                type="password"
                rules={[
                  isRequired,
                  isTheSameAs('password', 'Пароли не совпадают')
                ]}
              />
            ) : null}
          </Box>
        </Form>
      </Box>
    );
  }
}
