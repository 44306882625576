import { createValidate } from 'utils';

export const PASSWORD_SPECIAL_SYMBOLS = ' !"#$%&\'()*+,-./:;<=>?@[]^_`{|}~';

export const passwordChecks = {
  hasForbiddenSymbols: (value: string) =>
    !/^[a-z0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/gi.test(value),
  noUpperCase: (value: string) => !/[A-Z]/.test(value),
  noLowerCase: (value: string) => !/[a-z]/.test(value),
  noDigits: (value: string) => !/\d/.test(value),
  noSpecialSymbols: (value: string) =>
    !/[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(value)
};

const validationMessage = 'Выполнены не все условия';

const onlyAllowedValidator = createValidate(
  passwordChecks.hasForbiddenSymbols,
  validationMessage
);

const upperCaseValidator = createValidate(
  passwordChecks.noUpperCase,
  validationMessage
);

const lowerCaseValidator = createValidate(
  passwordChecks.noLowerCase,
  validationMessage
);

const digitsValidator = createValidate(
  passwordChecks.noDigits,
  validationMessage
);

const specialValidator = createValidate(
  passwordChecks.noSpecialSymbols,
  validationMessage
);

export const passwordValidators = [
  onlyAllowedValidator,
  upperCaseValidator,
  lowerCaseValidator,
  digitsValidator,
  specialValidator
];
