import * as React from 'react';
import styled from 'styled-components';
import { SyntheticEvent } from 'react';

type Props<A = any> = {
  checked: boolean;
  value?: string;
  onChange?: (...args: any[]) => void;
};

export const StyledCheckbox = styled.div<Pick<Props, 'checked'>>`
  width: 23px;
  height: 23px;
  border: 2px solid ${props => props.theme.palette.Purple};
  box-sizing: border-box;
  border-radius: 1px;

  background-color: ${props =>
    props.checked ? props.theme.palette.Purple : 'transparent'};
  color: ${props =>
    props.checked ? props.theme.palette.White : props.theme.palette.Purple};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Checkbox: React.FC<Props> = ({ checked, onChange, value }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleInputChange = (...args) => {
    onChange && onChange(...args);
  };

  const handleCheckboxClick = (ev: React.SyntheticEvent<HTMLDivElement>) => {
    ev.stopPropagation();

    inputRef.current.click();
  };

  return (
    <StyledCheckbox checked={checked} onClick={handleCheckboxClick}>
      {checked && <CheckboxIcon />}

      <input
        ref={inputRef}
        type="checkbox"
        onChange={handleInputChange}
        style={{ display: 'none' }}
        value={value}
      />
    </StyledCheckbox>
  );
};

function CheckboxIcon() {
  return (
    <svg
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.34375 8.53223L11.958 0.884277L12.9688 1.89502L4.34375 10.52L0.334473 6.51074L1.31152 5.5L4.34375 8.53223Z"
        fill="currentColor"
      />
    </svg>
  );
}
