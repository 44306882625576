import * as React from 'react';

export const ExitSVG = () => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      d="M10.656 0c.354 0 .667.135.938.406.27.271.406.584.406.938v9.312c0 .354-.135.667-.406.938-.271.27-.584.406-.938.406H1.344c-.375 0-.698-.135-.969-.406A1.341 1.341 0 010 10.656V8h1.344v2.656h9.312V1.344H1.344V4H0V1.344C0 .99.125.677.375.406.645.136.969 0 1.344 0h9.312zM4.72 8.406l1.718-1.75H0V5.344h6.438l-1.72-1.75.938-.938L9 6 5.656 9.344l-.937-.938z"
      fill="#3B33B0"
    />
  </svg>
);

export const UserSVG = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.46875 8.09375C3.69792 7.59375 4.875 7.34375 6 7.34375C7.125 7.34375 8.29167 7.59375 9.5 8.09375C10.7292 8.57292 11.3438 9.20833 11.3438 10V11.3438H0.65625V10C0.65625 9.20833 1.26042 8.57292 2.46875 8.09375ZM7.875 5.21875C7.35417 5.73958 6.72917 6 6 6C5.27083 6 4.64583 5.73958 4.125 5.21875C3.60417 4.69792 3.34375 4.07292 3.34375 3.34375C3.34375 2.61458 3.60417 1.98958 4.125 1.46875C4.64583 0.927083 5.27083 0.65625 6 0.65625C6.72917 0.65625 7.35417 0.927083 7.875 1.46875C8.39583 1.98958 8.65625 2.61458 8.65625 3.34375C8.65625 4.07292 8.39583 4.69792 7.875 5.21875Z"
      fill="currentColor"
    />
  </svg>
);
