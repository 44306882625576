import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styled, { CSSProperties } from 'styled-components';
import { breakpoints } from './Responsive';

interface IWrapProps {
  position: 'flex-start' | 'center';
}

const ModalOverlay = styled.div<IWrapProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(10, 6, 6, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 3;
  overflow: auto;
  box-sizing: border-box;
  cursor: pointer;
`;

const ModalWrap = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  flex: 1 0 auto;

  @media (max-width: ${breakpoints.laptopL}) {
    display: flex;
  }
`;

const Modal = styled.div`
  position: relative;
  z-index: 4;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: auto;

  @media (max-width: ${(props): string =>
      props.theme.global.breakpoints.small.value}px) {
    flex: 1;
  }
`;

export const ModalView: React.FC<{
  width: string;
  onClose: () => void;
  style: CSSProperties;
  overlayStyle?: CSSProperties;
  modalStyle?: CSSProperties;
  children: React.ReactNode;
  isOverlayClose?: boolean;
} & IWrapProps> = ({
  width = '700px',
  position = 'center',
  onClose,
  style,
  children,
  overlayStyle,
  modalStyle,
  isOverlayClose
}) => {
  return ReactDOM.createPortal(
    <ModalOverlay
      position={position}
      onClick={() => isOverlayClose && onClose()}
      style={{
        ...style,
        ...overlayStyle
      }}
    >
      <ModalWrap>
        <Modal
          style={{
            ...modalStyle,
            width
          }}
          onClick={(event: any) => {
            event.stopPropagation();
          }}
        >
          {children}
        </Modal>
      </ModalWrap>
    </ModalOverlay>,
    document.body
  );
};
