// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0S1mf9IDTyhXNLC6mKC6Kw\\=\\={animation:kYQ0WYmLxtdSHN3wNjB7ug\\=\\= .6s linear infinite}@-moz-keyframes kYQ0WYmLxtdSHN3wNjB7ug\\=\\={from{transform:rotate(0)}to{transform:rotate(360deg)}}@-webkit-keyframes kYQ0WYmLxtdSHN3wNjB7ug\\=\\={from{transform:rotate(0)}to{transform:rotate(360deg)}}@-o-keyframes kYQ0WYmLxtdSHN3wNjB7ug\\=\\={from{transform:rotate(0)}to{transform:rotate(360deg)}}@keyframes kYQ0WYmLxtdSHN3wNjB7ug\\=\\={from{transform:rotate(0)}to{transform:rotate(360deg)}}", "",{"version":3,"sources":["webpack://./src/ui/Spinner/styles.styl"],"names":[],"mappings":"AAAA,6BAAS,wDAAkC,CAAC,2CAAqB,KAAK,mBAAmB,CAAC,GAAG,wBAAwB,CAAC,CAAC,8CAAwB,KAAK,mBAAmB,CAAC,GAAG,wBAAwB,CAAC,CAAC,yCAAmB,KAAK,mBAAmB,CAAC,GAAG,wBAAwB,CAAC,CAAC,sCAAgB,KAAK,mBAAmB,CAAC,GAAG,wBAAwB,CAAC","sourcesContent":[".spinner{animation:spin .6s linear infinite}@-moz-keyframes spin{from{transform:rotate(0)}to{transform:rotate(360deg)}}@-webkit-keyframes spin{from{transform:rotate(0)}to{transform:rotate(360deg)}}@-o-keyframes spin{from{transform:rotate(0)}to{transform:rotate(360deg)}}@keyframes spin{from{transform:rotate(0)}to{transform:rotate(360deg)}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "_0S1mf9IDTyhXNLC6mKC6Kw==",
	"spin": "kYQ0WYmLxtdSHN3wNjB7ug=="
};
export default ___CSS_LOADER_EXPORT___;
