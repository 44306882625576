import * as React from 'react';
import styled from 'styled-components';

const prop = (prop: string, fallback: string = '') => props =>
  props[prop] || fallback ? `${prop}: ${props[prop] || fallback}` : '';

export type SvgProps = JSX.IntrinsicElements['svg'] & {
  opacity?: string;
  cursor?: string;
  width?: string;
};

export const SVG = styled.svg<SvgProps>`
  ${prop('opacity')}
  ${prop('cursor', 'auto')}
  ${prop('width')}
`;

export const Arrow: React.FC<SvgProps> = React.forwardRef((props, ref) => {
  return (
    <SVG
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 7C0.447715 7 0 6.55228 0 6C0 5.44772 0.447715 5 1 5H15C15.5523 5 16 5.44772 16 6C16 6.55228 15.5523 7 15 7H1Z"
        fill="#0A0606"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29289 1.70711C8.90237 1.31658 8.90237 0.683418 9.29289 0.292893C9.68342 -0.0976311 10.3166 -0.0976311 10.7071 0.292893L15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711C15.3166 7.09763 14.6834 7.09763 14.2929 6.70711L9.29289 1.70711Z"
        fill="#0A0606"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7071 11.7071C10.3166 12.0976 9.68342 12.0976 9.29289 11.7071C8.90237 11.3166 8.90237 10.6834 9.29289 10.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.7071 11.7071Z"
        fill="#0A0606"
      />
    </SVG>
  );
});

export const PrivateIcon = React.forwardRef((props, ref) => {
  return (
    <SVG
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.90625 4H8C8.54167 4 9.01042 4.19792 9.40625 4.59375C9.80208 4.98958 10 5.45833 10 6V6.125L7.90625 4ZM5.03125 4.53125C4.78125 5.03125 4.65625 5.52083 4.65625 6C4.65625 6.91667 4.97917 7.70833 5.625 8.375C6.29167 9.02083 7.08333 9.34375 8 9.34375C8.47917 9.34375 8.96875 9.21875 9.46875 8.96875L8.4375 7.9375C8.27083 7.97917 8.125 8 8 8C7.45833 8 6.98958 7.80208 6.59375 7.40625C6.19792 7.01042 6 6.54167 6 6C6 5.875 6.02083 5.72917 6.0625 5.5625L5.03125 4.53125ZM1.34375 0.84375L2.1875 0L14 11.8125L13.1562 12.6562C13.0521 12.5521 12.7188 12.2292 12.1562 11.6875C11.6146 11.1458 11.1979 10.7292 10.9062 10.4375C10.0104 10.8125 9.04167 11 8 11C6.35417 11 4.86458 10.5417 3.53125 9.625C2.19792 8.70833 1.23958 7.5 0.65625 6C1.17708 4.70833 2.01042 3.60417 3.15625 2.6875C2.90625 2.4375 2.55208 2.08333 2.09375 1.625C1.65625 1.16667 1.40625 0.90625 1.34375 0.84375ZM8 2.65625C7.58333 2.65625 7.17708 2.73958 6.78125 2.90625L5.34375 1.46875C6.15625 1.15625 7.04167 1 8 1C9.64583 1 11.125 1.45833 12.4375 2.375C13.7708 3.29167 14.7292 4.5 15.3125 6C14.8125 7.22917 14.0521 8.28125 13.0312 9.15625L11.0938 7.21875C11.2604 6.82292 11.3438 6.41667 11.3438 6C11.3438 5.08333 11.0104 4.30208 10.3438 3.65625C9.69792 2.98958 8.91667 2.65625 8 2.65625Z"
        fill="#8D8D8D"
      />
    </SVG>
  );
});

export const ExternalLink = React.forwardRef((props, ref) => {
  return (
    <SVG
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5C6.55228 5 7 5.44772 7 6C7 6.55228 6.55228 7 6 7H3C2.44772 7 2 7.44772 2 8V13C2 13.5523 2.44772 14 3 14H8.00377C8.55606 14 9.00377 13.5523 9.00377 13V10C9.00377 9.44772 9.45149 9 10.0038 9C10.5561 9 11.0038 9.44772 11.0038 10V13C11.0038 14.6569 9.66063 16 8.00377 16H3C1.34315 16 0 14.6569 0 13V8C0 6.34315 1.34315 5 3 5H6Z"
        fill="#3B33B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2929 0.292893C14.6834 -0.0976311 15.3166 -0.0976311 15.7071 0.292893C16.0976 0.683418 16.0976 1.31658 15.7071 1.70711L10.7071 6.70711C10.3166 7.09763 9.68342 7.09763 9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289L14.2929 0.292893Z"
        fill="#3B33B0"
      />
    </SVG>
  );
});

export const CollapseArrow: React.FC<SvgProps> = React.forwardRef(
  (props, ref) => {
    return (
      <SVG
        width="17"
        height="11"
        viewBox="0 0 17 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.33367 8.35471L1.4777 1.33325L1 1.82253L8.33367 9.33325L15.6667 1.82253L15.189 1.33325L8.33367 8.35471Z"
          fill="#3B33B0"
          stroke="#3B33B0"
        />
      </SVG>
    );
  }
);

export const VkIcon: React.FC<SvgProps> = React.forwardRef((props, ref) => {
  return (
    <SVG
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        d="M0 16.7344C0 25.5709 7.16344 32.7344 16 32.7344C24.8366 32.7344 32 25.5709 32 16.7344C32 7.89782 24.8366 0.734375 16 0.734375C7.16344 0.734375 0 7.89782 0 16.7344Z"
        fill="#3B33B0"
      />
      <path
        d="M22.0887 17.5255C21.7902 17.1486 21.8757 16.9809 22.0887 16.6439C22.0924 16.6401 24.5564 13.2355 24.8099 12.0809L24.8117 12.0801C24.9379 11.6593 24.8117 11.3501 24.2019 11.3501H22.1834C21.669 11.3501 21.4321 11.6155 21.3053 11.9124C21.3053 11.9124 20.2778 14.3724 18.8239 15.967C18.3547 16.4278 18.1378 16.5755 17.8816 16.5755C17.7554 16.5755 17.5593 16.4278 17.5593 16.007V12.0801C17.5593 11.5755 17.4154 11.3501 16.99 11.3501H13.8162C13.4939 11.3501 13.3024 11.5855 13.3024 11.8047C13.3024 12.2831 14.0293 12.3932 14.1047 13.7393V16.6601C14.1047 17.3001 13.9885 17.4178 13.7308 17.4178C13.0447 17.4178 11.3793 14.9478 10.3924 12.1209C10.1932 11.5724 9.99852 11.3508 9.48079 11.3508H7.46159C6.88541 11.3508 6.76929 11.6163 6.76929 11.9132C6.76929 12.4378 7.45544 15.0462 9.96005 18.4924C11.6293 20.8447 13.9801 22.1193 16.1185 22.1193C17.4039 22.1193 17.5609 21.8363 17.5609 21.3493C17.5609 19.1016 17.4447 18.8893 18.0885 18.8893C18.3869 18.8893 18.9008 19.037 20.1008 20.1716C21.4721 21.517 21.6979 22.1193 22.4653 22.1193H24.4837C25.0591 22.1193 25.3508 21.8363 25.1834 21.2778C24.7994 20.1031 22.2056 17.687 22.0887 17.5255Z"
        fill="white"
      />
    </SVG>
  );
});

export const OkIcon: React.FC<SvgProps> = React.forwardRef((props, ref) => {
  return (
    <SVG
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        d="M0 16.7344C0 25.5709 7.16344 32.7344 16 32.7344C24.8366 32.7344 32 25.5709 32 16.7344C32 7.89782 24.8366 0.734375 16 0.734375C7.16344 0.734375 0 7.89782 0 16.7344Z"
        fill="#3B33B0"
      />
      <path
        d="M11.4548 17.3098C11.0462 18.0971 11.5102 18.4734 12.5692 19.1163C13.4689 19.6611 14.7126 19.8604 15.5108 19.9401C15.1834 20.2485 16.6837 18.8347 12.3637 22.9069C11.4474 23.7674 12.9225 25.1479 13.8375 24.3057L16.3157 21.9635C17.2646 22.8579 18.1735 23.7151 18.7938 24.309C19.7095 25.1544 21.1834 23.7856 20.2775 22.9102C20.2099 22.8468 16.9194 19.7539 17.1206 19.9434C17.9292 19.8637 19.1539 19.6527 20.0431 19.1196L20.0425 19.1189C21.1009 18.4728 21.5649 18.0971 21.1625 17.3098C20.9188 16.8629 20.2628 16.4892 19.3895 17.1354C19.3895 17.1354 18.2105 18.0206 16.3083 18.0206C14.4056 18.0206 13.2271 17.1354 13.2271 17.1354C12.3545 16.4859 11.6954 16.8629 11.4548 17.3098Z"
        fill="white"
      />
      <path
        d="M16.307 16.8273C18.6258 16.8273 20.5187 15.0515 20.5187 12.8655C20.5187 10.6703 18.6258 8.89453 16.307 8.89453C13.9877 8.89453 12.0941 10.6703 12.0941 12.8655C12.0941 15.0515 13.9877 16.8273 16.307 16.8273ZM16.307 10.9062C17.4461 10.9062 18.3753 11.7822 18.3753 12.8655C18.3753 13.9396 17.4461 14.8157 16.307 14.8157C15.1673 14.8157 14.2381 13.9396 14.2381 12.8655C14.2375 11.7816 15.1667 10.9062 16.307 10.9062Z"
        fill="white"
      />
    </SVG>
  );
});

export const ShareIcon: React.FC<SvgProps> = React.forwardRef((props, ref) => {
  return (
    <SVG
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        d="M11.0156 4C7.76562 4.46875 5.26562 5.71875 3.51562 7.75C1.73438 9.75 0.5625 12.1719 0 15.0156C2.40625 11.6094 6.07812 9.90625 11.0156 9.90625V13.9844L18 7L11.0156 0.015625V4Z"
        fill="currentColor"
      />
    </SVG>
  );
});

export const TavernIcon: React.FC<SvgProps> = React.forwardRef((props, ref) => {
  return (
    <SVG
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6562 8.89136V7.51111H9.34375V8.89136H10.6562ZM10.6562 11.6198V10.2716H9.34375V11.6198H10.6562ZM6.65625 3.40247V2.05432H5.34375V3.40247H6.65625ZM6.65625 6.16296V4.78272H5.34375V6.16296H6.65625ZM6.65625 8.89136V7.51111H5.34375V8.89136H6.65625ZM6.65625 11.6198V10.2716H5.34375V11.6198H6.65625ZM2.65625 6.16296V4.78272H1.34375V6.16296H2.65625ZM2.65625 8.89136V7.51111H1.34375V8.89136H2.65625ZM2.65625 11.6198V10.2716H1.34375V11.6198H2.65625ZM8 6.16296H12V13H0V3.40247H4V2.05432L6 0L8 2.05432V6.16296Z"
        fill="#8D8D8D"
      />
    </SVG>
  );
});

export const ArrowDownIcon: React.FC<SvgProps> = React.forwardRef(
  (props, ref) => {
    return (
      <SVG
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5003 15.2661L6.35827 10L6 10.367L11.5003 16L17 10.367L16.6417 10L11.5003 15.2661Z"
          fill="#3B33B0"
          stroke="#3B33B0"
        />
      </SVG>
    );
  }
);

export const ArrowUpIcon: React.FC<SvgProps> = React.forwardRef(
  (props, ref) => {
    return (
      <SVG
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5003 8.73391L6.35827 14L6 13.633L11.5003 8L17 13.633L16.6417 14L11.5003 8.73391Z"
          fill="#3B33B0"
          stroke="#3B33B0"
        />
      </SVG>
    );
  }
);
