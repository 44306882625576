import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from 'grommet';
import { Button, Text, Title } from '@we-ui-components/base';
import { useStores } from 'stores/index';
import { Routes } from '../../constants';
import { Header } from './HeaderMenu';
import heroImage from './img/mother.svg';
import tech_1 from './img/Tech Life Blockchain.svg';
import tech_2 from './img/Tech Life Communication.svg';
import tech_3 from './img/Tech Life Life Management.svg';
import tech_4 from './img/Tech Life Virtual Reality.svg';
import hand from './img/hand.svg';
import styled from 'styled-components';
import { useWindowSize } from './windowWidth';
import { breakpoints, ScrollToTopButton } from 'ui';
import { useMediaQuery } from 'react-responsive';
import {
  objectToGetParams,
  SHARE_LINKS,
  ShareLinks,
  windowOpen
} from '../Poll/components/ShareButton';
import { baseUrl } from 'services/endpoints';
import { QuestionsAnswer } from './Questions&Answers';
import { InfoAboutProgram } from './InfoAboutProgram';

export const Link = styled.a`
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  margin: 0 25px;
  font-weight: bold;
  padding: 15px 0;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: #bdbdbf;
    transition: 0.2s linear;
  }
`;

export const Section = styled(Box)`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 64px;
  @media (max-width: ${breakpoints.laptop}) {
    padding: 0 25px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 0 12px;
  }
`;

const FooterSection = styled(Box)`
  padding: 0 64px;
  max-width: 1440px;
  margin: 0 auto;
  @media (max-width: ${breakpoints.mobile}) {
    padding: 0 12px;
  }
`;

const SectionBgColor = styled(Box)`
  width: 100%;
  background: linear-gradient(
    335.36deg,
    #d2483f 4.36%,
    #673edc 60.27%,
    #5a66f2 90.6%
  );
`;

const BoxFriendText = styled(Box)`
  padding-left: 100px;
  @media (max-width: ${breakpoints.laptopL}) {
    padding-left: 0;
  }
  @media (max-width: ${breakpoints.tabletM}) {
    text-align: left;
    line-height: 20px;
  }
`;

const BoxFriend = styled(Box)`
  margin: 90px 0;

  .friend_img_hand {
    margin: 23px 0 0 75px;
    min-width: 440px;
  }

  @media (max-width: ${breakpoints.laptopL}) {
    .friend_img_hand {
      margin: 23px 0 0 45px;
      min-width: 300px;
    }
  }
  @media (max-width: ${breakpoints.tabletM}) {
    flex-direction: column;
    .btn_friend {
      margin: 20px auto;
    }

    .friend_img_hand {
      margin: 0 auto;
      width: 260px;
    }
  }
`;

const MediaText = styled(Text)`
  @media (max-width: ${breakpoints.tablet}) {
    font-size: 13px;
    line-height: 16px;
  }
`;

const BoxInfoContent = styled(Box)`
  justify-content: start;
  align-items: center;
  flex-direction: column;
  padding-bottom: 40px;
  height: 364px;
`;

const ImgHeader = styled.img`
  width: 100vw;
  width: calc(100vw - 30px);

  @media (min-width: ${breakpoints.tablet}) {
    width: auto;
    max-width: 258px;
  }

  @media (min-width: ${breakpoints.laptop}) {
    max-width: 416px;
  }

  @media (min-width: ${breakpoints.laptopL}) {
    max-width: 640px;
  }
`;

const InfoContentContainer = styled(Box)`
  display: grid;

  grid-template-columns: 1fr;

  @media (min-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const LandingPage = observer(() => {
  const { routing } = useStores();
  const isLaptopOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.laptopL
  });
  const isTabletOrWider = useMediaQuery({ minDeviceWidth: breakpoints.tablet });

  const isTablet = isTabletOrWider && !isLaptopOrWider;

  const isMobile = useMediaQuery({
    maxDeviceWidth: breakpoints.mobile
  });

  const wSize = useWindowSize();

  const location = routing.location.pathname;

  return (
    <>
      {location === '/question-answer' || location === '/info-program' ? (
        <>
          <Header />
          <Section>
            {location === '/question-answer' ? (
              <QuestionsAnswer />
            ) : (
              <InfoAboutProgram />
            )}
          </Section>
        </>
      ) : (
        <>
          <SectionBgColor>
            <Header />

            <ScrollToTopButton />

            <Section>
              <Box
                justify="between"
                direction={isTabletOrWider ? 'row' : 'column'}
                style={{
                  marginBottom: isTabletOrWider ? '47px' : '0',
                  minHeight: isTabletOrWider ? 'auto' : '100vh'
                }}
              >
                <Box margin={{ top: isTabletOrWider ? '84px' : '20px' }}>
                  <Title
                    bold
                    color="white"
                    size={isLaptopOrWider ? '42px' : '32px'}
                    style={{ flex: '1 0' }}
                  >
                    Программа поддержки местных инициатив Волгоградской области
                  </Title>

                  <Button
                    fontWeight="800"
                    color="Blue700"
                    size={isTabletOrWider ? 'medium' : 'full'}
                    margin={{
                      top: isTabletOrWider ? '42px' : '72px',
                      bottom: isTablet ? '0' : '20px'
                    }}
                    bgColor="white"
                    bgHoverColor="Basic300"
                    style={{
                      boxShadow: '0px 4px 12px rgba(59, 51, 176, 0.4)'
                    }}
                    onClick={() => routing.push(`/${Routes.loginVoter}`)}
                  >
                    Проголосовать
                  </Button>
                  {!isLaptopOrWider && (
                    <Button
                      transparent
                      color="white"
                      size={isTabletOrWider ? 'medium' : 'full'}
                      margin={{
                        bottom: isTablet ? '0' : '46px'
                      }}
                      onClick={() => routing.push(`/${Routes.archive}`)}
                    >
                      Архив проектов
                    </Button>
                  )}
                </Box>

                <ImgHeader src={heroImage} alt="" />
              </Box>
            </Section>
          </SectionBgColor>

          <Section>
            <Title
              size={wSize}
              bold
              style={{ letterSpacing: '1px' }}
              margin="90px auto 60px"
              text="Это просто"
            />
            <InfoContentContainer>
              <BoxInfoContent
                direction="row"
                justify="center"
                margin="0 0 65px"
              >
                <img width="160px" src={tech_1} alt="" />
                <Box direction="row">
                  <Title size="xlarge" bold margin="25px 28px 0px 35px">
                    1
                  </Title>
                  <Box style={{ maxWidth: '225px' }}>
                    <Text
                      size="medium"
                      margin="30px 0"
                      style={{ lineHeight: '24px' }}
                    >
                      Формирование местных инициатив населения
                    </Text>
                  </Box>
                </Box>
              </BoxInfoContent>

              <BoxInfoContent
                direction="row"
                justify="center"
                margin="0 0 65px"
              >
                <img width="160px" src={tech_2} alt="" />
                <Box direction="row">
                  <Title size="xlarge" bold margin="25px 28px 0px 35px">
                    2
                  </Title>
                  <Box style={{ maxWidth: '230px' }}>
                    <Text
                      size="medium"
                      margin="30px 0"
                      style={{ lineHeight: '24px' }}
                    >
                      Отбор путём рейтингового голосования
                    </Text>
                  </Box>
                </Box>
              </BoxInfoContent>

              <BoxInfoContent
                direction="row"
                justify="center"
                margin="0 0 65px"
              >
                <img width="160px" src={tech_3} alt="" />
                <Box direction="row">
                  <Title size="xlarge" bold margin="25px 28px 0px 35px">
                    3
                  </Title>
                  <Box style={{ maxWidth: '225px' }}>
                    <Text
                      size="medium"
                      margin="30px 0"
                      style={{ lineHeight: '24px' }}
                    >
                      Реализация местных инициатив
                    </Text>
                  </Box>
                </Box>
              </BoxInfoContent>

              <BoxInfoContent
                direction="row"
                justify="center"
                margin="0 0 65px"
              >
                <img width="160px" src={tech_4} alt="" />
                <Box direction="row">
                  <Title size="xlarge" bold margin="25px 28px 0px 35px">
                    4
                  </Title>
                  <Box style={{ maxWidth: '225px' }}>
                    <Text
                      size="medium"
                      margin="30px 0"
                      style={{ lineHeight: '24px' }}
                    >
                      Информация о ходе реализации проекта
                    </Text>
                  </Box>
                </Box>
              </BoxInfoContent>
            </InfoContentContainer>
          </Section>

          <Section align={isTabletOrWider ? 'center' : 'stretch'}>
            <Button
              fontWeight="800"
              size={isTabletOrWider ? 'large' : 'full'}
              style={{
                boxShadow: '0px 4px 12px rgba(59, 51, 176, 0.4)'
              }}
              onClick={() => routing.push(`/${Routes.loginVoter}`)}
            >
              Проголосовать
            </Button>
          </Section>

          <Section>
            <BoxFriend direction="row-responsive">
              <BoxFriendText>
                <Title
                  size={wSize}
                  bold
                  style={{ letterSpacing: '1px' }}
                  margin="0 0 34px 0"
                  text="Дорогие друзья!"
                />
                <MediaText size="medium" style={{ lineHeight: '27px' }}>
                  Инициативное бюджетирование — часть общемирового тренда,
                  который представляет собой совокупность практик по решению
                  вопросов местного значения, основанных на гражданской
                  инициативе. Граждане самостоятельно определяют и выбирают
                  объекты расходования бюджетных средств, а в последствии
                  контролируют реализацию отобранных проектов.
                  <br />
                  <br />
                  Проект «Поддержка местных инициатив населения» стартовал в
                  Волгоградской области в 2019 году и показал высокую
                  заинтересованность жителей региона. В 2021 году с помощью
                  проекта жители Волгоградской области также смогут
                  самостоятельно выбирать приоритетные инициативы, которые будут
                  реализованы органами местного самоуправления. Инициативы будут
                  поддержаны средствами из областного бюджета. Это позволит
                  людям разных возрастов, профессий и уровня образования
                  участвовать в улучшении общественного пространства своих сёл и
                  городов.
                  <br />
                  <br />
                  Приглашаем вас принять участие в реализации проекта и сделать
                  свой выбор!
                </MediaText>
                <Button
                  fontWeight="800"
                  size="large"
                  className="btn_friend"
                  style={{
                    marginTop: '65px',
                    boxShadow: '0px 4px 12px rgba(59, 51, 176, 0.4)'
                  }}
                  onClick={() => routing.push(`/${Routes.loginVoter}`)}
                >
                  Проголосовать
                </Button>
              </BoxFriendText>
              <Box className="friend_img_hand">
                <img src={hand} alt="vector" />
              </Box>
            </BoxFriend>
          </Section>
        </>
      )}

      <SectionBgColor pad="90px 0">
        <Section>
          <Box direction="row" style={{ maxWidth: '1000px' }} justify="center">
            <Title
              size={wSize}
              bold
              color="white"
              style={{ textAlign: 'center' }}
              text="Принять участие в программе местных инициатив"
            />
          </Box>
          <Box
            wrap
            direction="row-responsive"
            style={{ margin: '15px auto 0' }}
            justify="center"
          >
            <Button
              fontWeight="800"
              color="Blue700"
              size="large"
              margin="medium"
              bgColor="white"
              bgHoverColor="Basic300"
              onClick={() => routing.push(`/${Routes.loginVoter}`)}
              style={{ boxShadow: '0px 4px 12px rgba(59, 51, 176, 0.4)' }}
            >
              Проголосовать
            </Button>

            {isLaptopOrWider && (
              <Button
                fontWeight="800"
                color="Blue700"
                size="large"
                margin="medium"
                bgColor="white"
                bgHoverColor="Basic300"
                onClick={() => routing.push(`/${Routes.loginApplicant}`)}
                style={{ boxShadow: '0px 4px 12px rgba(59, 51, 176, 0.4)' }}
              >
                Войти как заявитель
              </Button>
            )}

            <Button
              onClick={() => routing.push(Routes.archive)}
              fontWeight="800"
              color="Blue700"
              size="large"
              margin="medium"
              bgColor="white"
              bgHoverColor="Basic300"
              style={{ boxShadow: '0px 4px 12px rgba(59, 51, 176, 0.4)' }}
            >
              Архив проектов
            </Button>
          </Box>
        </Section>
      </SectionBgColor>

      <FooterSection direction={isMobile ? 'column' : 'row'}>
        <Box
          pad={{ vertical: 'medium' }}
          flex
          style={{ maxWidth: '360px' }}
          margin={isMobile ? '0 auto' : ''}
        >
          <Text
            size="14px"
            bold
            style={{ lineHeight: 1.5, textAlign: isMobile ? 'center' : 'left' }}
          >
            Программа поддержки местных инициатив Волгоградской области
          </Text>
          <Link
            onClick={() => routing.push(`/${Routes.infoAboutProgram}`)}
            style={{
              color: '#3B33B0',
              margin: '10px 0 0',
              padding: '0',
              textAlign: isMobile ? 'center' : 'left'
            }}
          >
            Информация о программе
          </Link>
        </Box>

        <Box
          justify="center"
          align={isMobile ? 'center' : 'end'}
          pad={{ vertical: 'medium' }}
          margin={isMobile ? {} : { left: 'auto' }}
        >
          <Text
            size="14px"
            bold
            style={{
              textAlign: isMobile ? 'center' : 'left',
              marginBottom: '10px'
            }}
          >
            Поделитесь страницей в соц. сетях
          </Text>
          <ShareLinks
            onShare={type => {
              const url = SHARE_LINKS[type];
              const imageField = type === 'vk' ? 'image' : 'imageUrl';

              windowOpen(
                url +
                  objectToGetParams({
                    url: baseUrl,
                    title:
                      'Программа местных инициатив населения Волгоградской области. Отбор путем рейтингового голосования. Реализация инициатив. Информация о результатах...',
                    [imageField]: baseUrl + '/preview.png'
                  }),
                {}
              );
            }}
          />
        </Box>
      </FooterSection>
    </>
  );
});
