import * as React from 'react';
import { Select } from '@we-ui-components/base';
import styled from 'styled-components';
import { Box } from 'grommet';
import { usePagination } from '@we-ui-components/rc-table';
import { Pager } from './Pager';
import { useTheme } from 'utils';

export function TablePagination() {
  const { setPageSize, goToPage, config } = usePagination();
  const theme = useTheme();

  return (
    <PaginationWrap>
      <Pager
        current={config.currentPage}
        total={config.totalPages}
        offset={2}
        activeColor="Purple"
        goToPage={goToPage}
      />
      <Box width="266px">
        <Select
          name="pageSize"
          type="filter"
          styles={{
            control: (provided: any) => ({
              ...provided,
              border: '1px solid #D1D1D1',
              boxShadow: 'none',
              ':hover': {
                ...provided[':hover'],
                border: '1px solid #D1D1D1',
                boxShadow: 'none'
              },
              ':active': {
                ...provided[':active'],
                border: '1px solid #D1D1D1',
                boxShadow: 'none'
              }
            }),
            indicatorsContainer: {
              padding: 0,
              borderLeft: '1px solid #D1D1D1'
            },
            singleValue: {
              fontSize: '16px',
              color: theme.palette.Black
            }
          }}
          value={config.pageSize}
          size="full"
          options={[
            { text: 'Показать 10 записей', value: 10 },
            { text: 'Показать 30 записей', value: 30 },
            { text: 'Показать 50 записей', value: 50 },
            { text: 'Показать 100 записей', value: 100 }
          ]}
          onChange={setPageSize}
        />
      </Box>
    </PaginationWrap>
  );
}

const PaginationWrap = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0;
`;
