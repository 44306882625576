import React from 'react';
import { Routes } from '../../../constants';
import { useStores } from 'stores/index';
import { observer } from 'mobx-react-lite';
import { Section, Link } from '../index';
import { MenuMobile } from './MenuMob';
import { Box } from 'grommet';
import { Button } from '@we-ui-components/base';
import { QuestionMarkIcon, QuestionMarkBlack } from '../icons';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui/Responsive';

export const Header = observer(() => {
  const { routing } = useStores();

  const questionAnswer = routing.location.pathname === '/question-answer';
  const infoProgram = routing.location.pathname === '/info-program';
  const questionOrInfo = questionAnswer || infoProgram;

  const isTabletOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.tablet
  });

  return (
    <Section width="100%">
      <Box direction="row" justify="end" align="center" pad="7px 0">
        {isTabletOrWider ? (
          <>
            <Link
              onClick={() => routing.push(`/${Routes.loginOrganizer}`)}
              style={{ color: questionOrInfo ? '#0A0606' : '#ffffff' }}
            >
              Войти как организатор
            </Link>
            <Link
              onClick={() => routing.push(`/${Routes.loginApplicant}`)}
              style={{ color: questionOrInfo ? '#0A0606' : '#ffffff' }}
            >
              Войти как заявитель
            </Link>
            <Link
              onClick={() => routing.push(`/${Routes.archive}`)}
              style={{ color: questionOrInfo ? '#0A0606' : '#ffffff' }}
            >
              Архив проектов
            </Link>
            <Link
              onClick={() => routing.push(`/${Routes.questionAnswer}`)}
              style={{
                marginRight: '32px',
                display: 'flex',
                alignItems: 'center',
                color: questionOrInfo ? '#0A0606' : '#ffffff'
              }}
            >
              {questionOrInfo ? (
                <QuestionMarkBlack style={{ marginRight: '5px' }} />
              ) : (
                <QuestionMarkIcon style={{ marginRight: '5px' }} />
              )}
              Вопросы и ответы
            </Link>

            <Button
              fontWeight="800"
              color={questionOrInfo ? '' : 'Blue700'}
              bgColor={questionOrInfo ? '' : 'white'}
              bgHoverColor={questionOrInfo ? '' : 'Basic300'}
              size="medium"
              onClick={() => routing.push(`/${Routes.loginVoter}`)}
            >
              Проголосовать
            </Button>
          </>
        ) : (
          <Link
            onClick={() => routing.push(`/${Routes.questionAnswer}`)}
            style={{
              margin: '0',
              display: 'flex',
              alignItems: 'center',
              color: questionOrInfo ? '#0A0606' : '#ffffff'
            }}
          >
            {questionOrInfo ? (
              <QuestionMarkBlack style={{ marginRight: '5px' }} />
            ) : (
              <QuestionMarkIcon style={{ marginRight: '5px' }} />
            )}
            Вопросы и ответы
          </Link>
        )}
      </Box>
    </Section>
  );
});

/*
export const Header = () => {
  return (
    <>
      <MenuMobile />
      <MenuGlobal />
    </>
  );
};*/
