import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { DocumentSVG } from 'pages/Archive/components/DocumentSVG';
import styled from 'styled-components';
import { IconButton } from 'ui/IconButton';
import { api, ENDPOINTS } from 'services';
import { createQS } from 'we-oauth2/lib/auth/api-utils';
import { download } from 'utils';
import {
  ExportProjectsModal,
  ExportProjectsFormData
} from './ExportProjectsModal';

interface ExportProjectsButtonProps {}

export const ExportProjectsButton = observer<ExportProjectsButtonProps>(
  function ExportProjectButton(props) {
    const { actionModals } = useStores();

    const handleDownloadReportClick = () => {
      actionModals.open(ExportProjectsModal, {
        width: '482px',
        title: 'Скачать отчет',
        applyText: () => <span>Создать</span>,
        closeText: () => <span>Отмена</span>,
        onApply: async ({ type, year }: ExportProjectsFormData) => {
          const url = getDownloadURL(type, year);
          download(url);
        },
        noValidation: true
      });
    };

    return (
      <StyledIconButton
        onClick={handleDownloadReportClick}
        renderIcon={() => <DocumentSVG />}
      >
        Скачать отчет
      </StyledIconButton>
    );
  }
);

const StyledIconButton = styled(IconButton)`
  display: flex;
  flex-flow: row no-wrap;
`;

function getDownloadURL(type: string, year: number) {
  const endpoint = ENDPOINTS.exportProjects();
  const access_token = api.getToken();

  return endpoint + createQS({ type, year, access_token });
}
