import * as React from 'react';
import logoSVG from './logo';
import styled, { withTheme } from 'styled-components';
import { BoxProps, Box } from 'grommet';
import { useStores } from 'stores';
import { observer } from 'mobx-react-lite';
import { useIsLoginRoute } from 'pages/utils';
import { TStyledChildrenProps } from 'interfaces';
import { UserMenu, PasswordDialogButton } from './UserMenu';
import { HeaderContainer } from './components';
import { SlimTabs } from 'components/Tabs';
import { getRegulatorTabs, getApplicantTabs, getVoterTabs } from '../getTabs';
import { getRootPathname } from 'utils';
import { useHistory, useLocation } from 'react-router-dom';

export const HeadCompact = withTheme(
  observer(({ theme }: TStyledChildrenProps<BoxProps>) => {
    const isLogin = useIsLoginRoute();
    const { user } = useStores();
    const { container } = theme;
    const { minWidth, maxWidth } = container;
    const history = useHistory();
    const { pathname } = useLocation();

    const isPasswordDialogButtonVisible = () =>
      user.isContainRoles(['VOTER']) && !user.encryptPassword;

    const getTabs = () => {
      if (user.isContainRoles(['REGULATOR'])) {
        return getRegulatorTabs();
      }

      if (user.isContainRoles(['APPLICANT'])) {
        return getApplicantTabs();
      }

      if (user.isContainRoles(['VOTER'])) {
        return getVoterTabs();
      }

      return [];
    };

    return (
      <>
        <CompactHeaderContainer>
          <Box
            direction="row"
            align="center"
            pad={{ horizontal: '8px' }}
            style={{
              minWidth,
              maxWidth,
              margin: '0 auto',
              height: 44,
              width: '100%'
            }}
          >
            <Box style={{ marginRight: 20, height: 24 }}>
              {logoSVG}
            </Box>
            <SlimTabs
              height="100%"
              tabs={getTabs()}
              selected={getRootPathname(pathname).replace('/', '')}
              onChange={id => history.push(`/${id}`)}
            />

            {!isLogin && user.isAuthorized && (
              <Box
                direction="row"
                justify="end"
                align="center"
                margin={{ left: 'auto' }}
              >
                <UserMenu />
              </Box>
            )}
          </Box>
        </CompactHeaderContainer>

        {isPasswordDialogButtonVisible() && (
          <Box pad="16px 10px">
            <PasswordDialogButton />
          </Box>
        )}
      </>
    );
  })
);

const CompactHeaderContainer = styled(HeaderContainer)`
  ${SlimTabs} {
    margin-top: auto;
  }
`;
