import { Box } from 'grommet';
import { StarRating } from 'components/StarRatings';
import * as React from 'react';
import { Button, Text } from '@we-ui-components/base';
import { useStores } from 'stores/index';

export type RateReworkProps = {
  onRate: (val: number) => void;
  error: string;
};

export const RateReworkModal: React.FC<RateReworkProps> = ({
  onRate,
  error
}) => {
  const { actionModals } = useStores();

  const [value, setValue] = React.useState<number>(null);

  const handleRate = () => {
    onRate(value);
  };

  return (
    <Box>
      <Box align="center" margin={{ top: 'small', bottom: 'small' }}>
        <StarRating value={0} width="240px" onChangeValue={setValue} />
      </Box>

      <Text pad={{ vertical: 'small' }} color="Red">
        {error}
      </Text>

      <Box direction="row" justify="end" margin="16px 0">
        <Button
          bordered
          transparent
          color="Blue"
          style={{ border: '1px solid #204080' }}
          onClick={actionModals.closeLastModal}
        >
          Отменить
        </Button>

        <Button
          margin={{ left: '16px' }}
          disabled={value === null}
          onClick={handleRate}
        >
          Поставить оценку
        </Button>
      </Box>
    </Box>
  );
};
