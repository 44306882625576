import * as React from 'react';
import { useState, useRef, useMemo, useEffect } from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';
import { ENDPOINTS } from 'services/endpoints';
import { Arrow, MobileArrow } from 'ui/Slider/Arrow';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui/Responsive';
import { Text } from '@we-ui-components/base';
import { RotateIcon } from "ui/Icons";
import { SliderFile } from "interfaces/uiTypes";
import { getProjectFile } from "services/projectService";
import { rotateBase64Image } from "utils/rotateBase64Image";

interface SliderType {
  slideImages: SliderFile[];
  onChange: (files: SliderFile) => void;
  activeIndex: number;
  width?: string;
  height?: string;
  size?: string;
  style?: {};
  arrow?: boolean;
  accessType?: 'public' | 'authorized';
}

interface SlideProps {
  isActive: boolean;
  isLaptopOrWider: boolean;
  img: SliderFile,
  accessType?: 'public' | 'authorized';
  onChange: (file: SliderFile) => void,
}

interface ThumbnailProps {
  isActive: boolean;
  img: SliderFile,
  accessType?: 'public' | 'authorized';
  onClick: () => void;
}

const Slide = (props: SlideProps) => {
  const { img, isActive, isLaptopOrWider, accessType, onChange } = props

  const imgEndpoint = accessType === 'authorized'
    ? ENDPOINTS.getProjectFile
    : ENDPOINTS.getPublicProjectFile;

  const [image, setImage] = useState(('base64' in img) ? img.base64 : imgEndpoint(img.id))

  return (
    <Box
      style={{
        display: isActive ? 'block' : 'none',
        padding: isLaptopOrWider ? '0 25px' : '0',
        position: 'relative',
        height: isLaptopOrWider ? '30vw' : '60vw',
        width: isLaptopOrWider ? '45vw' : '100%',
        textAlign: 'center',
      }}
    >
      <Card
        style={{
          height: '100%',
          width: '100%',
          objectFit: 'contain',
          maxHeight: isLaptopOrWider ? '30vw' : '60vw',
          maxWidth: isLaptopOrWider ? '45vw' : '100%',
        }}
        src={image}
      />
      <RotateContainer
        onClick={() => {
          rotateBase64Image(image, -90, (base64)=>{
            onChange({
              ...img,
              base64,
            })
            setImage(base64)
          })
        }}
      >
        <RotateIcon fill={'#FFF'} width={32} height={32}/>
      </RotateContainer>
    </Box>
  )
}

const MobileSlide = (props: SlideProps) => {
  const { img, isActive, accessType, onChange } = props

  const imgEndpoint = accessType === 'authorized'
    ? ENDPOINTS.getProjectFile
    : ENDPOINTS.getPublicProjectFile;

  const [image, setImage] = useState(('base64' in img) ? img.base64 : imgEndpoint(img.id))

  return (
    <Box
      align="center"
      justify="center"
      style={{
        display: isActive ? 'flex' : 'none',
        position: 'relative',
        height: '70vh',
      }}
    >
      <Card
        style={{
          width: '100vw',
          maxHeight: '70vh',
          objectFit: 'contain',
        }}
        src={image}
      />
      <RotateContainer
        onClick={() => {
          rotateBase64Image(image, -90, (base64)=>{
            onChange({
              ...img,
              base64,
            })
            setImage(base64)
          })
        }}
      >
        <RotateIcon fill={'#FFF'} width={32} height={32}/>
      </RotateContainer>
    </Box>
  );
}

const Thumbnail = (props: ThumbnailProps) => {
  const { img, accessType, isActive, onClick } = props
  const imgEndpoint = accessType === 'authorized'
    ? ENDPOINTS.getProjectFile
    : ENDPOINTS.getPublicProjectFile;

  const src = ('base64' in img) ? img.base64 : imgEndpoint(img.id)
  return (
    <InactiveImg
      src={src}
      onClick={() => onClick()}
      style={{
        background: isActive ? 'blue' : 'transparent',
        border:
          isActive
            ? '2px solid blue'
            : '2px solid transparent'
      }}
    />
  )
}

export const Slider = (props: SliderType) => {
  const {
    activeIndex,
    slideImages,
    arrow,
    onChange,
    accessType = 'authorized'
  } = props
  const [activeImg, setActiveImg] = useState(slideImages[activeIndex]);

  let currentIndex = useRef(activeIndex)

  const handleClick = (newIndex: number) => {
    currentIndex.current = newIndex
    setActiveImg(slideImages[currentIndex.current]);
  };

  const isLaptopOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.laptopL
  });
  const isMobile = useMediaQuery({
    maxDeviceWidth: breakpoints.mobile
  });

  let countImages = slideImages.length;

  const nextSlide = () => {
    if (currentIndex.current >= countImages - 1) {
      currentIndex.current = 0;
    } else {
      currentIndex.current+= 1
    }
    setActiveImg(slideImages[currentIndex.current]);
  };

  const prevSlide = () => {
    if (currentIndex.current === 0) {
      currentIndex.current = countImages-1;
    }else{
      currentIndex.current-= 1
    }
    setActiveImg(slideImages[currentIndex.current]);
  };

  if (isMobile) {
    return (
      <Box justify="center">
        {slideImages.map((img, key) => {
          return <MobileSlide
            key={key}
            isActive={currentIndex.current === key}
            accessType={accessType}
            isLaptopOrWider={false}
            img={img}
            onChange={(updatedFile) => {
              onChange(updatedFile)
            }}
          />
        })}
        {arrow && slideImages.length !== 1 && (
          <MobArrowBox margin="xlarge" direction="row" justify="center">
            <MobileArrow handleClick={prevSlide} direction="left"/>
            <Text size="large" style={{ width: '20px' }}>
              {currentIndex.current + 1}/{countImages}
            </Text>
            <MobileArrow handleClick={nextSlide} direction="right"/>
          </MobArrowBox>
        )}
      </Box>
    );
  }

  return (
    <>
      {arrow && slideImages.length !== 1 && (
        <>
          <Arrow handleClick={nextSlide} direction="right"/>
          <Arrow handleClick={prevSlide} direction="left"/>
        </>
      )}

      {slideImages.map((img, key) => {
        return (
          <Slide
            key={key}
            isActive={currentIndex.current === key}
            isLaptopOrWider={isLaptopOrWider}
            img={img}
            accessType={accessType}
            onChange={(updatedFile) => {
              onChange(updatedFile)
            }}
          />
        );
      })}

      <Box margin="medium" direction="row" justify="center">
        {slideImages.map((img, key) => {
          return (
            <Thumbnail
              key={key}
              isActive={currentIndex.current === key}
              img={img}
              accessType={accessType}
              onClick={() => handleClick(key)}
            />
          );
        })}
      </Box>
    </>
  );
};

const RotateContainer = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, .5);
  width: 48px;
  height: 48px;
  bottom: 0;
  left: calc(50% - 24px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  user-select: none;
`

const Card = styled.img`
  object-fit: contain;
  cursor: pointer;
`;

const InactiveImg = styled.img`
  border-radius: 4px;
  cursor: pointer;
  object-fit: cover;
  width: 80px;
  height: 80px;
  margin: 0 5px;
`;

const MobArrowBox = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
