// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E1ObzQOkmlW7XLOHqbTBlw\\=\\={fill:rgba(141,141,141,0.3);stroke:rgba(10,6,6,0.5);stroke-width:.5px}.RO1z19ztHLhr2vfj6kmBuw\\=\\={cursor:pointer}.kgBubrnJTDoJM-HUYDmb-Q\\=\\={cursor:default}.RbZ7oEa3MWeVZewq9JIuWw\\=\\={fill:#00a87a}.vTU143\\+8wUOamX5G9NfoLA\\=\\={fill:#f0b51d}._4vUM8E9qM2SvKigTofKaXA\\=\\={opacity:.8}.zUBpgqfJYrN-q\\+9TzURkbQ\\=\\={fill:currentColor;stroke:none;font-size:8px;text-anchor:middle}.IdgR4p3CXzhA6P1vH1KBJw\\=\\={pointer-events:none}._2cbqZKxX3tn\\+D7olFx\\+Geg\\=\\={pointer-events:none}", "",{"version":3,"sources":["webpack://./src/components/RegionMap/region.styl"],"names":[],"mappings":"AAAA,4BAAQ,0BAA0B,CAAC,uBAAuB,CAAC,iBAAiB,CAAC,4BAAY,cAAc,CAAC,4BAAoB,cAAc,CAAC,4BAAsB,YAAY,CAAC,6BAA4B,YAAY,CAAC,6BAAiB,UAAU,CAAC,6BAAmB,iBAAiB,CAAC,WAAW,CAAC,aAAa,CAAC,kBAAkB,CAAC,4BAAa,mBAAmB,CAAC,+BAAc,mBAAmB","sourcesContent":[".region{fill:rgba(141,141,141,0.3);stroke:rgba(10,6,6,0.5);stroke-width:.5px}.regionArea{cursor:pointer}.regionAreaDisabled{cursor:default}.regionAreaStatusVote{fill:#00a87a}.regionAreaStatusAcceptance{fill:#f0b51d}.regionAreaHover{opacity:.8}.regionArea__title{fill:currentColor;stroke:none;font-size:8px;text-anchor:middle}.regionWater{pointer-events:none}.regionTitles{pointer-events:none}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"region": "E1ObzQOkmlW7XLOHqbTBlw==",
	"regionArea": "RO1z19ztHLhr2vfj6kmBuw==",
	"regionAreaDisabled": "kgBubrnJTDoJM-HUYDmb-Q==",
	"regionAreaStatusVote": "RbZ7oEa3MWeVZewq9JIuWw==",
	"regionAreaStatusAcceptance": "vTU143+8wUOamX5G9NfoLA==",
	"regionAreaHover": "_4vUM8E9qM2SvKigTofKaXA==",
	"regionArea__title": "zUBpgqfJYrN-q+9TzURkbQ==",
	"regionWater": "IdgR4p3CXzhA6P1vH1KBJw==",
	"regionTitles": "_2cbqZKxX3tn+D7olFx+Geg=="
};
export default ___CSS_LOADER_EXPORT___;
