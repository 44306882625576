import * as React from 'react';
import styled from 'styled-components';
import { Button } from 'grommet';
import { IStyledProps } from 'themes/mainTheme';

const StyledIconButton = styled(Button)`
  font-size: 13px;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  cursor: pointer;
  background-color: ${(props: IStyledProps) => props.theme.palette.LightGrey};
  color: ${(props: IStyledProps) => props.theme.palette.Purple};
  font-weight: bold;
  border-radius: 2px;
  white-space: nowrap;

  &:focus {
    // background-color: ${(props: IStyledProps) => props.theme.palette.Purple};
    // color: ${(props: IStyledProps) => props.theme.palette.White};
  }
`;

const IconContainer = styled.span`
  margin-right: 8px;
`;

type Props = React.ComponentProps<typeof Button> & {
  renderIcon?: () => React.ReactNode;
};

export const IconButton: React.FC<Props> = ({
  renderIcon,
  children,
  ...props
}) => (
  <StyledIconButton {...props}>
    {renderIcon ? <IconContainer>{renderIcon()}</IconContainer> : null}

    {children}
  </StyledIconButton>
);
