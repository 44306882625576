import * as React from 'react';

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface DropableAreaProps extends DivProps {}

export function DropableArea({ onDrop, ...props }: DropableAreaProps) {
  useIgnoreDefaultWindowDragAndDrop();

  return (
    <div
      onDragOver={e => {
        // workaround for correct onDrop working
        e.preventDefault();
      }}
      onDrop={e => {
        // save event from reusing on event pooling,
        // so we can use it for async operation (sush as drop)
        // remove for React 17.0.0+
        e.persist();

        onDrop(e);
      }}
      {...props}
    />
  );
}

function useIgnoreDefaultWindowDragAndDrop() {
  React.useEffect(() => {
    const events = ['drop', 'dragover'];

    const ignore = (e: DragEvent): void => {
      e.preventDefault();
    };

    events.forEach(eventName => {
      window.addEventListener(eventName, ignore);
    });

    return () => {
      events.forEach(eventName => {
        window.removeEventListener(eventName, ignore);
      });
    };
  }, []);
}
