import * as React from 'react';
import { HTMLProps, PropsWithChildren } from 'react';
import { useClickOutside } from 'utils';

export const ClickOutside: React.FC<{
  onClickOutside: () => void;
} & PropsWithChildren<HTMLProps<HTMLDivElement>>> = ({
  onClickOutside,
  children,
  ...props
}) => {
  const target = useClickOutside(onClickOutside);
  return (
    <div {...props} ref={target}>
      {children}
    </div>
  );
};
