import * as React from 'react';
import { IProject, PROJECT_STATUS } from 'interfaces/apiEntities';
import { Box, BoxProps } from 'grommet';
import styled from 'styled-components';

const STATUS_UI_TEXT: Partial<Record<IProject['status'], string>> = {
  [PROJECT_STATUS.DRAFT]: 'Черновик',
  [PROJECT_STATUS.APPROVED]: 'Принят',
  [PROJECT_STATUS.DECLINED]: 'Отклонен',
  [PROJECT_STATUS.CANCELED]: 'Отозван',
  [PROJECT_STATUS.REWORK_REQUIRED]: 'Отправлен на доработку',
  [PROJECT_STATUS.REWORK_COMPLETED]: 'Доработан',
  [PROJECT_STATUS.IN_REVIEW]: 'На рассмотрении',
  [PROJECT_STATUS.IN_PROGRESS]: 'В реализации',
  [PROJECT_STATUS.DONE]: 'Реализован',
  [PROJECT_STATUS.ARCHIVED]: 'В архиве',
  [PROJECT_STATUS.DELETED]: 'Удален'
};

type VotingStatusProps = BoxProps &
  JSX.IntrinsicElements['div'] & { status: PROJECT_STATUS };

function mapColor({
  status,
  theme
}: BoxProps & { status: PROJECT_STATUS; theme: any }): string {
  if (
    [
      PROJECT_STATUS.IN_REVIEW,
      PROJECT_STATUS.REWORK_REQUIRED,
      PROJECT_STATUS.REWORK_COMPLETED
    ].includes(status)
  ) {
    return theme.palette.Orange;
  }

  if ([PROJECT_STATUS.APPROVED, PROJECT_STATUS.DONE].includes(status)) {
    return theme.palette.Green;
  }

  if (status === PROJECT_STATUS.IN_PROGRESS) {
    return theme.palette.Purple;
  }

  if (status === PROJECT_STATUS.DECLINED) {
    return theme.palette.White;
  }

  return theme.palette.GreyText;
}

function mapBgColor({
  status,
  theme
}: BoxProps & { status: PROJECT_STATUS; theme: any }): string {
  if (status === PROJECT_STATUS.DECLINED) {
    return theme.palette.Red;
  }

  return theme.palette.LightGrey50;
}

//TODO copy past from VotingStatusStyled
export const ProjectStatusStyled: React.FC<VotingStatusProps> = styled(Box)`
  font-size: ${props => props.theme.sizes.text.xxsmall};
  font-weight: 500;
  padding: 0 4px;
  height: 16px;
  line-height: 16px;
  border-radius: 2px;
  text-align: center;
  white-space: nowrap;
  flex: 0 0 auto;

  background-color: ${mapBgColor};
  color: ${mapColor};
`;

export const ProjectStatus: React.FC<BoxProps & { status: PROJECT_STATUS }> = ({
  status,
  ...props
}) => {
  return (
    <ProjectStatusStyled {...props} status={status}>
      {STATUS_UI_TEXT[status] || STATUS_UI_TEXT[PROJECT_STATUS.DRAFT]}
    </ProjectStatusStyled>
  );
};
