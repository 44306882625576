import * as React from 'react';
import { Box } from 'grommet';
import { breakpoints, Panel } from 'ui/index';
import { Text, Title } from '@we-ui-components/base';
import { useStores } from 'stores/index';
import { observer, useLocalStore } from 'mobx-react';
import { EmptyString } from '../../constants';
import styled from 'styled-components';
import { IconButton } from 'ui/IconButton';
import { useMediaQuery } from 'react-responsive';
import { UpdatePassword } from './UpdatePasswordModal';
import { DataPiece, Label, Value } from './components/DataPiece';
import { UpdateProfileModal } from './UpdateProfileModal';
import { getFullName } from 'utils/textUtils';
import { getDistricts } from 'services/voteService';

const EditIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8125 2.6875L10.5938 3.90625L8.09375 1.40625L9.3125 0.1875C9.4375 0.0625 9.59375 0 9.78125 0C9.96875 0 10.125 0.0625 10.25 0.1875L11.8125 1.75C11.9375 1.875 12 2.03125 12 2.21875C12 2.40625 11.9375 2.5625 11.8125 2.6875ZM0 9.5L7.375 2.125L9.875 4.625L2.5 12H0V9.5Z"
      fill="#3B33B0"
    />
  </svg>
);

export const ProfilePersonalData: React.FC<React.ComponentProps<
  typeof Box
>> = observer(function ProfilePersonalData(props) {
  const { user, projectList, actionModals } = useStores();
  const { userInfo } = user;
  const [districtName, setDistrictName] = React.useState('');

  React.useEffect(() => {
    if (userInfo.meta?.district) {
      getDistricts().then(res => {
        const district = res.content.find(
          i => +i.id === +userInfo.meta.district
        );
        setDistrictName(district?.name || userInfo.meta?.district);
      });
    }
  }, []);

  const isApplicant = user.isContainOneOfRoles(['APPLICANT']);

  const isLaptopOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.laptopL
  });
  const isTabletOrWider = useMediaQuery({ minDeviceWidth: breakpoints.tablet });
  const editPasswordFormRef = React.useRef(null);

  const data = useLocalStore(() => ({
    password: '',
    password_confirm: '',
    old_password: ''
  }));

  const showEditPasswordModal = () => {
    actionModals.open(UpdatePassword, {
      title: 'Изменение пароля',
      width: isLaptopOrWider ? '545px' : 'auto',
      modalStyle: {
        maxWidth: isTabletOrWider ? '545px' : '100vw'
      }
    });
  };

  const showEditProfileModal = () => {
    actionModals.open(UpdateProfileModal, {
      title: 'Редактирование профиля',
      width: isLaptopOrWider ? '545px' : 'auto',
      modalStyle: {
        maxWidth: isTabletOrWider ? '545px' : '100vw'
      }
    });
  };

  return (
    <Box direction="column" align="stretch" gap="32px" {...props}>
      <Title size="medium" bold>
        Профиль
        <IconButton
          margin={{ left: 'small' }}
          renderIcon={() => <EditIcon />}
          onClick={showEditProfileModal}
          children="Редактировать профиль"
        />
      </Title>

      <Panel pad="24px" style={{ flex: 1 }} gap="20px">
        <DataPiece>
          <Label>Email</Label>
          <Value>{userInfo.email}</Value>
        </DataPiece>

        <DataPiece>
          <Label>ФИО</Label>
          <Value>
            {userInfo.firstName || userInfo.firstName
              ? getFullName({
                  firstName: userInfo.firstName || '',
                  lastName: userInfo.lastName || '',
                  middleName: userInfo.meta
                    ? userInfo.meta.patronymic || ''
                    : ''
                })
              : EmptyString}
          </Value>
        </DataPiece>

        <DataPiece>
          <Label>Телефон</Label>
          <Value>{userInfo.phone || EmptyString}</Value>
        </DataPiece>

        {isApplicant && (
          <DataPiece>
            <Label>Район</Label>
            <Value>{districtName || EmptyString}</Value>
          </DataPiece>
        )}

        {isApplicant && (
          <DataPiece>
            <Label>Организация пользователя</Label>
            <Value>
              {userInfo.meta?.company || userInfo.companyName || EmptyString}
            </Value>
          </DataPiece>
        )}

        <Box onClick={showEditPasswordModal} alignSelf="start">
          <StyledText style={{ fontSize: '13px' }} color="Purple">
            Изменить пароль
          </StyledText>
        </Box>

        <Box
          alignSelf="start"
          onClick={() => {
            projectList.filters = { status: [] }; //TODO WTF?
            // projectList.clear(); //NOT WORK
            user.logoutEx();
          }}
        >
          <StyledText style={{ fontSize: '13px' }} color="Red">
            Выйти из системы
          </StyledText>
        </Box>
      </Panel>
    </Box>
  );
});

const StyledText = styled(Text)`
  cursor: pointer;
`;
