import { IStores } from 'stores/index';
import { ListStoreConstructor } from './core/ListStoreConstructor';
import { IProject, PROJECT_STATUS } from 'interfaces/apiEntities';
import { getAllProjects, getProjectsCount } from 'services/projectService';
import { action, computed, observable } from 'mobx';

export class ProjectListStore extends ListStoreConstructor<IProject> {

  @observable tabCountProject: Record<PROJECT_STATUS, number>;
  @computed
  public get projectList(): IProject[] {
    return this.allData;
  }

  constructor(stores: IStores) {
    super(
      stores,
      params => getAllProjects(params),
      {
        paginationData: { pageSize: 10 },
        filters: { status: [] },
        pollingInterval: 5e4
      }
    );
  }

  @action
  getProjectTabCount(id: string) {
    return this.statusHandler(() => getProjectsCount(id)).then(res => {
      this.tabCountProject = res;
    });
  }

  @action
  public updateListStatus(status: string) {
    const statusList = status === 'ALL' ? [] : [status];

    this.onChangeDataFlow({ filters: { status: statusList } });
  }

  @action
  public updateSearch(search: string) {
    this.onChangeDataFlow({ filters: { search } });
  }

  @action
  public fetchProjectList() {
    this.fetcher.sendRequest({})
  }

  @action
  public setDistrictId(districtId: string){

    this.onChangeDataFlow({ filters: { schoolDistrictId: districtId}})
  }
}
