import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from 'grommet';
import { FilterSearch, FilterTabs, CommonTable, SlimTabs } from 'components';
import { PROJECT_STATUS } from 'interfaces';
import { ArchiveProjectListItem } from './ArchiveProjectListItem';
import { useStores } from 'stores';
import { getTabs } from './getTabs';
import { useRequest } from 'utils';
import { PollingType } from 'pages/Organizer/tabs';
import { useParams } from 'react-router-dom';
import { Spinner2 } from 'ui';
import { PageTitle, BackButton } from '../components/styled';
import { ArchivePageContainer } from '../components/ArchivePageContainer';

interface ArchiveProjectsPageProps {}

export const ArchiveProjectsPage = observer<ArchiveProjectsPageProps>(
  function ArchiveProjectsPage(props) {
    const { archiveProjects, archivePolls } = useStores();
    const { id: pollId } = useParams<{ id: string }>();

    const pollRequestFn = React.useCallback(() => archivePolls.getOne(pollId), [
      pollId
    ]);

    const voteInfoRequestFn = React.useCallback(
      () => archivePolls.getVoteInfo(pollId),
      [pollId]
    );

    const [poll, { status: pollStatus }] = useRequest(pollRequestFn);
    const [voteInfo, { status: voteInfoStatus }] = useRequest(
      voteInfoRequestFn
    );

    const formatPollName = () => {
      const PREFIX =
        poll.subject.type === PollingType.schoolGroup ? 'школьной группе ' : '';

      const name = poll.subject.name || '--';
      const year = poll.subject.year || '--';

      return `${PREFIX}${name}, ${year}`;
    };

    React.useEffect(() => {
      archiveProjects.init({ filters: { pollId: pollId } });

      return () => archiveProjects.clear();
    }, []);

    if (voteInfoStatus !== 'success' || pollStatus !== 'success') {
      return (
        <Box margin={{ top: 'xlarge' }}>
          <Spinner2 color="LightGrey" height="2rem" width="auto" />
        </Box>
      );
    }

    return (
      <ArchivePageContainer
        direction="column"
        background="transparent"
        margin="0 auto"
        pad={{ vertical: 'medium', horizontal: 'small' }}
        width="872px"
      >
        <BackButton />

        <Box direction="row" align="center" margin={{ vertical: 'medium' }}>
          <PageTitle>Голосование по {formatPollName()}</PageTitle>
        </Box>

        <FilterSearch
          store={archiveProjects}
          filterField="search"
          width="100%"
        />

        <FilterTabs
          store={archiveProjects}
          tabs={getTabs()}
          filterField="status"
          initialValue={PROJECT_STATUS.ALL}
          margin={{ vertical: 'small' }}
          component={SlimTabs as any}
        />
        <CommonTable
          store={archiveProjects}
          columns={[]}
          emptyListText="Не найдено проектов"
          customItem={{
            render: ({ params }) => (
              <ArchiveProjectListItem
                project={params}
                poll={poll}
                voted={params.bulletinOrder === voteInfo.bulletinOrder}
              />
            )
          }}
          suppressInitialFetch
          suppressInitialization
        />
      </ArchivePageContainer>
    );
  }
);
