import * as React from 'react';

export function DocumentSVG() {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 12"
      style={{
        height: '1em',
        width: 'auto',
        maxWidth: '8px',
        marginTop: '5px'
      }}
    >
      <path
        d="M4.491 4.397h2.76l-2.76-2.93v2.93zm1.497 2.68V6H2.012v1.077h3.976zm0 2.128V8.128H2.012v1.077h3.976zM5.006.667L8 3.872v6.41c0 .283-.101.534-.304.75a.97.97 0 01-.702.301H1.006a.97.97 0 01-.702-.3A1.068 1.068 0 010 10.28l.023-8.563c0-.284.094-.526.281-.726.203-.217.437-.325.702-.325h4z"
        fill="#3B33B0"
      />
    </svg>
  );
}
