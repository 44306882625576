import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Box } from 'grommet';
import {
  Form,
  MobxForm,
  Input,
  isRequired,
  isEmail
} from '@we-ui-components/form';
import { Text, Title } from '@we-ui-components/base';

import { IUser, UpdateUserFullyRequest } from 'interfaces';
import { useDistrictOptions } from './useDistrictOptions';
import {
  UserRoleButton,
  FixedWidthUserInput,
  FixedHeightUserSelect,
  FixedWidthUserPhoneUnput
} from '../components/styled';
import { TActionModalProps } from 'components';
import { useStores } from 'stores';
import { phoneValidators } from './userPasswordValidators';

type UpdateUserModalProps = TActionModalProps<{ personId: string }>;

export const UpdateUserModal = observer<UpdateUserModalProps>(
  function UpdateUserModal(props, ref) {
    const { userList } = useStores();

    const initialUser = React.useMemo(() => {
      const personId = props?.actionData?.data?.personId;
      if (personId) {
        return userList.selectUser(personId);
      }

      return {} as IUser;
    }, [props?.actionData?.data?.personId]);

    const handleSubmit = async () => {
      const data: UpdateUserFullyRequest = await formRef.current.validateFields();
      return data;
    };

    const [districtOptions, { status }] = useDistrictOptions();

    React.useImperativeHandle(ref, () => ({
      onValidate: handleSubmit
    }));

    const formData = useLocalStore<UpdateUserFullyRequest>(() => {
      return {
        company: initialUser.company,
        districtId: initialUser.districtId,
        firstName: initialUser.firstName,
        lastName: initialUser.lastName,
        patronymic: initialUser.patronymic,
        phone: initialUser.phone,
        email: initialUser.email
      };
    });

    const formRef = React.useRef<MobxForm>();

    return (
      <Box>
        <Title
          margin={{ top: '-35px', bottom: '24px' }}
          style={{ maxWidth: '700px' }}
          bold
          size="small"
        >
          Редактирование данных пользователя
        </Title>

        <Form ref={formRef} data={formData} {...({} as any)}>
          <Box direction="row" justify="between">
            <FixedWidthUserInput
              size="full"
              placeholder="Фамилия"
              name="lastName"
            />

            <FixedWidthUserInput
              size="full"
              placeholder="Имя"
              name="firstName"
            />
          </Box>

          <FixedWidthUserInput placeholder="Отчество" name="patronymic" />

          {initialUser.businessRole === 'APPLICANT' && (
            <>
              <FixedHeightUserSelect
                name="districtId"
                loading={status === 'fetching'}
                options={districtOptions}
                label="Район пользователя"
              />

              <Input name="company" placeholder="Организация пользователя" />
            </>
          )}

          <Box direction="row" justify="between">
            <FixedWidthUserInput
              name="email"
              placeholder="Email*"
              rules={[isRequired, isEmail]}
            />
            <FixedWidthUserInput
              size="full"
              placeholder="Телефон"
              name="phone"
              rules={phoneValidators}
            />
          </Box>
        </Form>
      </Box>
    );
  },
  {
    forwardRef: true
  }
);
