import * as React from 'react';
import e500SVG from './500.svg';
import refreshSVG from './refresh.svg';
import { Box } from 'grommet';
import { Error } from 'ui';
import { CSSProperties } from 'react';
import { Title, Text, Button } from '@we-ui-components/base';
import { useHistory } from 'react-router';
import { useStores } from 'stores/index';
import { observer } from 'mobx-react-lite';

type TPageErrorType = 'unknown' | '404' | '500';

export const PageError = observer(
  ({ error, style, ...rest }: { error: any; style?: CSSProperties }) => {
    const { user } = useStores();

    const isRegulatorOrVoter = user.isContainOneOfRoles(['REGULATOR', 'VOTER']);
    const isApplicant = user.isContainOneOfRoles(['APPLICANT']);

    let titleText = '';
    let descriptionText = '';
    let buttonText = '';
    let type: TPageErrorType = 'unknown';
    if (typeof error === 'object') {
      if ('status' in error) {
        if (error.status === 404) {
          type = '404';
          titleText = 'Данная страница отсутствует';
          descriptionText =
            'Запрашиваемой страницы не существует. Возможно, она была удалена или в запросе указан неверный адрес страницы.';
          if (isApplicant) {
            buttonText = 'Перейти к списку проектов';
          } else if (isRegulatorOrVoter) {
            buttonText = 'Перейти к голосованиям';
          } else buttonText = 'Вернутся обратно';
        }
        if (error.status === 500) {
          type = '500';
          titleText = 'Что-то пошло не так';
          descriptionText =
            'Сервис временно недоступен, попробуйте еще раз через несколько минут.';
          buttonText = 'Обновить страницу';
        }
      }
    }

    const history = useHistory();

    const onClick = () => {
      if (type === '404') {
        history.push(`/`);
      }
      if (type === '500') {
        window.location.reload();
      }
    };

    return (
      <Box align="center" justify="center">
        <Box
          align="center"
          width="770px"
          style={{ marginTop: 50, padding: '0px 120px 50px 120px' }}
          {...rest}
        >
          {type === 'unknown' ? (
            <Error error={error} />
          ) : (
            <>
              <Title bold style={{ marginBottom: 20 }}>
                {titleText}
              </Title>
              <Text
                size="xsmall"
                margin={{ vertical: 'small' }}
                style={{ textAlign: 'center' }}
              >
                {descriptionText}
              </Text>
              <img src={e500SVG} alt="error" />
              <Button
                onClick={onClick}
              >
                {type === '500' && (
                  <img src={refreshSVG} style={{ marginRight: 13 }} alt="" />
                )}
                {buttonText}
              </Button>
            </>
          )}
        </Box>
      </Box>
    );
  }
);
