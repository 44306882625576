import { AuthenticationGuard, RedirectEx, BaseContainer } from 'components';
import { LoginPage } from 'pages';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRoutes } from 'utils';
import { AuthorizedApp } from './AuthorizedApp';
import { LandingPage } from 'pages/Landing';
import { LoginVoterPage } from 'pages/LoginVoter';
import { ArchivePollsPage } from 'pages/Archive';
import { ArchiveProjectsPage } from 'pages/Archive/details/ArchiveProjectsPage';

export function AppRoutes() {
  const Routes = useRoutes();

  return (
    <AuthenticationGuard
      render={({ status }) => {
        if (status === 'unauthenticated') {
          return (
            <Switch>
              <Route
                exact={true}
                path={`/${Routes.loginOrganizer}`}
                component={LoginPage}
              />
              <Route
                exact={true}
                path={`/${Routes.loginApplicant}`}
                component={LoginPage}
              />
              <Route
                exact={true}
                path={`/${Routes.loginVoter}`}
                component={LoginVoterPage}
              />
              <Route
                exact={true}
                path={`/${Routes.questionAnswer}`}
                component={LandingPage}
              />
              <Route
                exact={true}
                path={`/${Routes.infoAboutProgram}`}
                component={LandingPage}
              />
              <Route
                exact
                path={`/${Routes.landing}`}
                component={LandingPage}
              />

              <Route exact path={`/${Routes.archive}`}>
                <BaseContainer>
                  <ArchivePollsPage />
                </BaseContainer>
              </Route>

              <Route exact path={`/${Routes.archive}/:id`}>
                <BaseContainer>
                  <ArchiveProjectsPage />
                </BaseContainer>
              </Route>

              <RedirectEx to={`/${Routes.landing}`} />
            </Switch>
          );
        }

        return <AuthorizedApp />;
      }}
    />
  );
}
