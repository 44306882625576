import { BaseContainer, RoleRedirect, RoleRoute } from 'components';
import { observer } from 'mobx-react-lite';
import { CreateVote, UsersPage } from 'pages';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRoutes } from 'utils';
import { OrganizerPage } from 'pages/Organizer';
import { VoterPage } from 'pages/Voter';
import { ApplicantPage } from 'pages/Applicant';
import { CreateProject } from 'pages/Applicant/CreateProject';
import { PollPage } from '../pages/Poll';
import { ActiveProject } from '../pages/Applicant/ActiveProject';
import { ProfilePage } from '../pages/profile';
import { ArchivePollsPage } from 'pages/Archive';
import { ArchiveProjectsPage } from 'pages/Archive/details/ArchiveProjectsPage';
import { LandingPage } from '../pages/Landing';

export function AuthorizedApp() {
  const Routes = useRoutes();

  return (
    <BaseContainer>
      <Switch>
        <RoleRoute
          roles={['REGULATOR']}
          exact={true}
          path={`/${Routes.homeOrganizer}`}
          component={OrganizerPage}
        />
        <RoleRoute
          roles={['APPLICANT']}
          exact={true}
          path={`/${Routes.homeApplicant}`}
          component={ApplicantPage}
        />
        <RoleRoute
          roles={['APPLICANT']}
          exact={true}
          path={`/${Routes.projectPage}/:id`}
          component={ActiveProject}
        />
        <RoleRoute
          roles={['APPLICANT']}
          exact={true}
          path={`/${Routes.createProject}`}
          component={CreateProject}
        />
        <RoleRoute
          roles={['REGULATOR', 'VOTER']}
          exact
          path={`/${Routes.archive}`}
          component={ArchivePollsPage}
        />

        <RoleRoute
          roles={['REGULATOR', 'VOTER']}
          exact
          path={`/${Routes.archive}/:id`}
          component={ArchiveProjectsPage}
        />

        <RoleRoute
          roles={['VOTER']}
          exact={true}
          path={`/${Routes.homeVoter}`}
          component={VoterPage}
        />

        <RoleRoute
          exact={true}
          roles={['REGULATOR']}
          path={`/${Routes.createVote}`}
          component={CreateVote}
        />

        <RoleRoute
          exact={true}
          roles={['REGULATOR', 'VOTER']}
          path={`/${Routes.poll}/:id`}
          component={PollPage}
        />

        <RoleRoute
          exact={true}
          roles={['REGULATOR', 'APPLICANT']}
          path={`/${Routes.profile}`}
          component={ProfilePage}
        />

        <RoleRoute
          exact
          roles={['REGULATOR']}
          path={`/${Routes.users}`}
          component={UsersPage}
        />

        <RoleRoute
          exact={true}
          roles={['REGULATOR', 'APPLICANT', 'VOTER']}
          path={`/${Routes.landing}`}
          component={LandingPage}
        />

        <Route
          roles={['REGULATOR', 'APPLICANT', 'VOTER']}
          exact={true}
          path={`/${Routes.infoAboutProgram}`}
          component={LandingPage}
        />


        {/*<RoleRoute*/}
        {/*  exact*/}
        {/*  roles={EXTERNAL_ROLES}*/}
        {/*  path={`/${Routes.profile}`}*/}
        {/*  component={ProfilePage}*/}
        {/*/>*/}
        <AuthRedirect />
      </Switch>
    </BaseContainer>
  );
}

const AuthRedirect = observer(() => {
  const routes = useRoutes();

  return (
    <>
      <RoleRedirect
        rolesOptions={[
          {
            roles: ['REGULATOR'],
            to: `/${routes.homeOrganizer}`
          },
          {
            roles: ['APPLICANT'],
            to: `/${routes.homeApplicant}`
          },
          {
            roles: ['VOTER'],
            to: `/${routes.homeVoter}`
          }
        ]}
      />
    </>
  );
});
