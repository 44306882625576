import { api } from 'services/api';
import { ENDPOINTS } from 'services/endpoints';
import { ListResponse, PollSubject } from 'interfaces/apiEntities';

export function getAll(queryParams?: {}): Promise<ListResponse<PollSubject>> {
  return api.get(ENDPOINTS.allPolls(), queryParams);
}

export function deletePolls(
  pollsIds: string[]
): Promise<ListResponse<PollSubject>> {
  return api.del(ENDPOINTS.vote(), {
    pollsIds
  });
}
