import {
  ConfirmationRequest,
  ITokenEx,
  IUserInfo,
  RegistrationRequest,
  RegistrationResponse,
  UpdateAccountRequest,
  UpdatePersonRequest
} from 'interfaces';
import { api } from 'services/api';
import { ENDPOINTS } from 'services';
import { TokenEx, UserInfo } from 'models';

export namespace userService {
  export function login(userName: string, password: string): Promise<TokenEx> {
    return api
      .login(userName, password)
      .then((data: ITokenEx) => TokenEx.New(data));
  }

  export function get(): Promise<IUserInfo> {
    return api
      .get(ENDPOINTS.userInfo())
      .then((data: IUserInfo) => UserInfo.New(data));
  }

  export function registration(user: RegistrationRequest) {
    return api.post<RegistrationResponse>(ENDPOINTS.registration(), user);
  }

  export function confirmRegistration(confirmation: ConfirmationRequest) {
    return api.post(ENDPOINTS.confirmRegistration(), confirmation);
  }

  export function solvers() {
    return api.get<IUserInfo[]>(ENDPOINTS.expertSolvers());
  }

  export function solversByName(name: string) {
    return api.get<IUserInfo[]>(ENDPOINTS.expertSolvers(), { name });
  }

  export function saveEncryptedContent(params: any) {
    return api.post<ITokenEx>(ENDPOINTS.encryptedContent(), params);
  }

  export async function getEncryptedContent() {
    const res = await api.get<{ encryptedContent }>(
      ENDPOINTS.encryptedContent()
    );

    return res.encryptedContent;
  }
}

export function phoneRegister(phoneNumber: string, captchaResponse: string) {
  return api.post<{ codeId: string; ttl: string }>(
    ENDPOINTS.phoneRegister(phoneNumber, captchaResponse)
  );
}

export function sendConfirmCode(params: any) {
  return api.post<ITokenEx>(ENDPOINTS.sendConfirmCode(), params);
}

export function updateAccount(personId: string, params: UpdateAccountRequest) {
  return api.put<IUserInfo>(ENDPOINTS.updateAccount(personId), params);
}

export function updatePersonPartial(
  personId: string,
  params: Partial<UpdatePersonRequest>
) {
  return api.put<IUserInfo>(ENDPOINTS.updatePersonPartial(personId), params);
}

export function updatePersonFull(
  personId: string,
  params: Partial<UpdatePersonRequest>
) {
  return api.put<IUserInfo>(ENDPOINTS.updatePersonFull(personId), params);
}
