import * as React from 'react';
import { Input } from '@we-ui-components/form';
import { Title } from '@we-ui-components/base';
import { Box } from 'grommet';
import { IPGLogo } from 'ui/IPGLogo';

export const commonInputStyles = {
  width: '100%'
};

export const StyledInput: typeof Input = props => {
  return <Input style={commonInputStyles} {...props} />;
};

export function WelcomeMessage() {
  return (
    <Box margin={{ bottom: '24px' }}>
      <Box direction="row" gap="10px" align="baseline">
        <Title style={{ fontSize: '26px' }} color="Black" bold>
          Добро пожаловать в
        </Title>
        <IPGLogo />
      </Box>
      <Title style={{ fontSize: '26px' }} color="Black" bold>
        Пожалуйста, представьтесь
      </Title>
    </Box>
  );
}
