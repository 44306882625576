import { action, observable } from 'mobx';
import { NormalizedError } from 'services/api/errorHandler';
import { statusFetching } from 'we-oauth2/lib/constants/types';
import { StoreConstructor } from './core/StoreConstructor';
import * as projectService from 'services/projectService';
import { IProject, ISchool } from 'interfaces/apiEntities';
import { TProjectAction } from 'services/projectService';
import { getSchools } from 'services/voteService';

export class ActiveProjectStore extends StoreConstructor {
  @observable project: IProject;
  @observable fetchStatus: statusFetching = 'init';
  @observable fetchError: NormalizedError;

  @action.bound
  getProject(id: string) {
    return this.statusHandler(
      () =>
        projectService.getProject(id).then(async res => {
          let subject;

          const districtList = this.stores.votings.districtList.data;
          subject = districtList.find(i => i.pollId === res.pollId);

          return { ...res, subject: subject || {} };
        }),
      'fetchStatus',
      'fetchError',
      async result => {
        this.project = result;
      }
    );
  }

  public async confirmFinish(
    projectId: string,
    description: string,
    files: File[]
  ) {
    const resultFilesResponse = await this.uploadResultFiles(files);
    const implFiles = resultFilesResponse.map(it => it.id);

    return await this.callProjectAction(projectId, 'finish', {
      implDescription: description,
      implFiles
    });
  }

  public async uploadResultFiles(files: File[]) {
    return await Promise.all(
      files.map(file => projectService.uploadFile(file, 'RESULT'))
    );
  }

  callProjectAction(id: string, action: TProjectAction, data?: any) {
    return projectService.callProjectAction(id, action, data);
  }

  @action.bound
  clear() {
    this.project = null;
    this.fetchStatus = 'init';
    this.fetchError = null;
  }
}
