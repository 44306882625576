import { IPoll, ISchool, PollSubject } from 'interfaces/apiEntities';

export enum NODE_TYPE {
  DISTRICT = 'DISTRICT',
  SCHOOL_GROUP = 'SCHOOL_GROUP',
  SCHOOL = 'SCHOOL'
}

export interface INode {
  title: string | number;
  id: number;
  schollGroupId: number;
  children?: INode[];
  type: NODE_TYPE;
  polling?: PollSubject;
}

export const generateSchoolTree = (
  schools: ISchool[],
  pollingList: PollSubject[]
) => {
  const root: INode[] = [];

  schools.forEach(school => {
    let schoolGroup = root.find(i => i.id === school.schoolGroupId);
    const polling = pollingList.find(v => Number(v.id) === school.schoolGroupId)

    if (!schoolGroup) {
      schoolGroup = {
        polling,
        title: school.schoolGroupName,
        id: school.schoolGroupId,
        schollGroupId: school.schoolGroupId,
        type: NODE_TYPE.SCHOOL_GROUP,
        children: []
      };

      root.push(schoolGroup);
    }

    let district = schoolGroup.children.find(
      district => district.id === school.districtId
    );

    if (!district) {
      district = {
        polling,
        title: school.districtName,
        id: school.districtId,
        type: NODE_TYPE.DISTRICT,
        schollGroupId: school.schoolGroupId,
        children: []
      };

      schoolGroup.children.push(district);
    }

    district.children.push({
      ...school,
      polling,
      schollGroupId: school.schoolGroupId,
      type: NODE_TYPE.SCHOOL,
      title: school.name,
      id: Number(school.id)
    });
  });

  return root;
};

export type TSearchMethod = (school: ISchool) => boolean;

export const filteredTree = (
  schools: ISchool[],
  search: TSearchMethod,
  pollingList: PollSubject[]
): INode[] => {
  return generateSchoolTree(schools.filter(search), pollingList);
};
