import * as React from 'react';
import { ActionModalsStore, ActionModalConfig } from 'stores/ActionModalsStore';
import { Text } from '@we-ui-components/base';
import { IpObjectList } from 'interfaces';
import { useLocation } from 'react-router';

export const useIsLoginRoute = () => {
  const { pathname } = useLocation();

  return pathname.includes('login');
};

export function openDeactivateLegalEntityModal(
  actionModals: ActionModalsStore,
  legalEntity: IpObjectList,
  onDeactivate: () => void | Promise<void>
): void {
  actionModals.open(
    ({ config }: { config: ActionModalConfig }) => (
      <Text size="medium">
        Работа организации и ее пользователей в системе будет не возможна
      </Text>
    ),
    {
      //title: `Деактивировать организацию ${legalEntity.name}?`,
      title: `Деактивировать организацию и всех ее пользователей?`,
      applyText: () => <>Продолжить</>,
      closeText: () => <>Отмена</>,
      noValidation: true,
      onApply: async () => {
        await onDeactivate();
      }
    }
  );
}

export function openUnsaveModal(
  actionModals: ActionModalsStore,
  onApply: () => void | Promise<void>
  // onClose: () => void | Promise<void>,
): void {
  actionModals.open(
    ({ config }: { config: ActionModalConfig }) => (
      <Text size="medium">Имеются несохраненные изменения</Text>
    ),
    {
      title: `Вы уверены, что хотите покинуть страницу?`,
      applyText: () => <>Продолжить</>,
      closeText: () => <>Отмена</>,
      noValidation: true,
      onApply: async () => {
        await onApply();
      }
      // onClose: async () => {
      //   await onClose();
      // },
    }
  );
}
