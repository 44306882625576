import { useTheme } from 'utils/hooks';
import { Box } from 'grommet';
import { Text, Title } from '@we-ui-components/base';
import { ListItemCounter } from './ListItemCounter';
import { HouseIcon, ProjectIcon, SocialIcon, VotesIcon } from './icons';
import * as React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'ui/Checkbox';
import { useStores } from 'stores/index';
import { PollSubject } from 'interfaces/apiEntities';
import { PollStatus } from 'components/PollStatus/PollStatus';
import { breakpoints } from 'ui';
import { useMediaQuery } from 'react-responsive';

const ItemContainer = styled((props: any) => <Box {...props} />).attrs({
  justify: 'start',
  direction: 'row',
  flex: 'grow',
  width: '100%'
})`
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${props => props.theme.palette.LightGrey50};
  }

  @media (max-width: ${breakpoints.laptop}) {
    border-top: ${props => (props.withoutBorder ? '' : '1px solid #e5e5e5')};
  }
`;

const SubItemContainer = styled((props: any) => <Box {...props} />).attrs({
})`
  cursor: pointer;
  user-select: none;

  flex-direction: row;
  justify: start;
  align-items: center;
  height: 100%;
  flex-grow: 1;

  &:hover {
    background-color: ${props => props.theme.palette.LightGrey50};
  }
`;

const ReviewProjectsCounter = styled(Box)`
  min-width: 18px;
  height: 18px;
  padding: 0 6px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  line-height: 18px;
  color: ${props => props.theme.palette.White};
  background-color: ${props => props.theme.palette.Purple};
  position: relative;
  left: 5px;
`;

export type RenderLitItemProps = { params: PollSubject } & {
  isMoType: boolean;
  deleting: boolean;
  withoutBorder?: boolean;
  onClick: (poll: PollSubject) => void;
};

export function renderListItem({
  params: polling,
  isMoType,
  withoutBorder,
  deleting,
  onClick
}: RenderLitItemProps) {
  const theme = useTheme();
  const { votings, user } = useStores();

  const isVoter = user.isContainOneOfRoles(['VOTER'])

  const handleChange = React.useCallback(
    ev => {
      votings.delete.selectPolling(polling);
    },
    [polling]
  );

  const schoolGroupCounters = (
    <Box
      basis="100%"
      direction="row"
      align="center"
      style={{ lineHeight: 1.5 }}
    >
      <ListItemCounter count={1302} icon={<HouseIcon />} />
      <ListItemCounter
        count={1501}
        icon={<SocialIcon />}
        margin={{ left: '8px' }}
      />
    </Box>
  );

  const deleteCheckbox = (
    <Box justify="center" flex="grow" width="23px" margin={{ right: '12px' }}>
      <Checkbox
        checked={votings.delete.isSelected(polling)}
        onChange={handleChange}
        value={polling.id}
      />
    </Box>
  );

  const showDeleteCheckbox =
    deleting && polling.hasPoll === true && votings.delete.isDeletable(polling);

  const isTabletOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.tablet
  });

  const isLaptopOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.laptopL
  });

  const isIpadProOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.laptop
  });

  const calculateTitleWidth = () => {
    if (isLaptopOrWider && withoutBorder) return '135px';
    if (isLaptopOrWider) return '155px';
    if (isIpadProOrWider) return '400px';
    if (isTabletOrWider) return '300px';
    return '100%';
  };

  const calculatePollStatusWidth = () => {
    if (isLaptopOrWider) return '170px';
    if (isTabletOrWider) return '220px';
  };

  const calculateIndicatorsMargin = () => {
    if (isLaptopOrWider) return '18px';
    if (isTabletOrWider) return 'auto';
    return '0';
  };

  return (
    <ItemContainer
      withoutBorder={withoutBorder}
      onClick={() => onClick(polling)}
    >
      <div
        style={{
          padding: polling.type === 'SCHOOL_GROUP' ? '16px 10px' : '16px',
          display: 'flex',
          width: '100%'
        }}
      >
        {showDeleteCheckbox && deleteCheckbox}

        <Box
          justify="start"
          direction={isTabletOrWider ? 'row' : 'column'}
          wrap
          width="100%"
          gap={isTabletOrWider ? '0' : '6px'}
        >
          <Box margin={{ right: '18px' }} width={calculateTitleWidth()}>
            <Title
              size={isLaptopOrWider ? '13px' : '16px'}
              color={theme.palette.BlackText}
              style={{ lineHeight: 1.5 }}
            >
              {polling.name}
            </Title>
          </Box>

          <Box
            direction="row"
            align="center"
            justify="start"
            width={calculatePollStatusWidth()}
          >
            <PollStatus poll={polling} />
          </Box>

          <Box
            direction="row"
            margin={{ left: calculateIndicatorsMargin() }}
            width={{ width: 'auto', max: '140px', min: '130px' }}
            justify="between"
            flex={
              isIpadProOrWider && user.isContainOneOfRoles(['REGULATOR'])
                ? 'grow'
                : 'shrink'
            }
          >
            <Box direction="row" align="center">
              <ListItemCounter
                count={isVoter ? polling.inProgressProjectsCount : polling.activeProjectsCount}
                icon={<ProjectIcon />}
                margin={isTabletOrWider ? '0 auto 0 0' : '0'}
                style={{ minWidth: '5ch' }}
              />
              {polling.reviewProjectsCount > 0 && (
                <ReviewProjectsCounter>
                  {polling.reviewProjectsCount}
                </ReviewProjectsCounter>
              )}
            </Box>
            <ListItemCounter
              margin={{ left: '8px' }}
              count={polling.voteCount}
              icon={<VotesIcon />}
            />
          </Box>

          {/*{!isMoType && schoolGroupCounters}*/}
        </Box>
      </div>
    </ItemContainer>
  );
}

export type RenderLitSubItemProps = {
  title: string,
  polling: PollSubject,
  onClick: (params: { pollId: string, status: string, search: string }) => void,
};

export function renderListSubItem({ title, polling, onClick }: RenderLitSubItemProps) {
  return (
    <SubItemContainer
      onClick={() => onClick({ ...polling, search: title })}
    >
      <Box>
        <Text style={{ fontSize: 13 }}>{title}</Text>
      </Box>
    </SubItemContainer>
  )
}

export const SumVoteCount = ({
  padding,
  count,
}: {
  count: number;
  padding: string;
}) => {
  const theme = useTheme();
  const { user } = useStores();

  const isTabletOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.tablet
  });

  const isLaptopOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.laptopL
  });

  const isIpadProOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.laptop
  });

  const calculateTitleWidth = () => {
    if (isLaptopOrWider) return '155px';
    if (isIpadProOrWider) return '400px';
    if (isTabletOrWider) return '300px';
    return '100%';
  };

  const calculatePollStatusWidth = () => {
    if (isLaptopOrWider) return '170px';
    if (isTabletOrWider) return '220px';
  };

  const calculateIndicatorsMargin = () => {
    if (isLaptopOrWider) return '18px';
    if (isTabletOrWider) return 'auto';
    return '0';
  };

  return (
    <Box
      direction="row"
      style={{
        display: 'flex',
        width: '100%',
        borderTop: '1px solid #e5e5e5',
        height: isTabletOrWider ? '5%' : '10%',
        position: 'sticky',
        flex: 'grow',
      }}
    >
      <div
        style={{
          padding: padding,
          display: 'flex',
          width: '100%'
        }}
      >
        {isTabletOrWider ? (
          <Box justify="start" direction="row" wrap width="100%">
            <Box margin={{ right: '18px' }} width={calculateTitleWidth()}>
              <Title
                bold
                size="16px"
                color={theme.palette.BlackText}
                style={{ lineHeight: 1.5 }}
              >
                Всего голосов
              </Title>
            </Box>

            <Box
              direction="row"
              align="center"
              justify="start"
              width={calculatePollStatusWidth()}
            />

            <Box
              direction="row"
              margin={{ left: calculateIndicatorsMargin() }}
              width={{ width: 'auto', max: '140px', min: '130px' }}
              justify="end"
              flex={
                isIpadProOrWider && user.isContainOneOfRoles(['REGULATOR'])
                  ? 'grow'
                  : 'shrink'
              }
            >
              <ListItemCounter
                color={theme.palette.BlackText}
                count={count}
                icon={<VotesIcon />}
              />
            </Box>
          </Box>
        ) : (
          <Box justify="between" wrap width="100%" direction="row">
            <Box>
              <Title
                bold
                size="16px"
                color={theme.palette.BlackText}
                style={{ lineHeight: 1.5 }}
              >
                Всего голосов
              </Title>
            </Box>
            <ListItemCounter
              color={theme.palette.BlackText}
              count={count}
              icon={<VotesIcon />}
            />
          </Box>
        )}
      </div>
    </Box>
  );
};
