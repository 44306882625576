import { Routes } from 'constants/routes';
import { ITabOptions } from 'components';
import { ALL_ROLES } from '../../constants';

export const getRegulatorTabs = (): Array<ITabOptions> => {
  return [
    {
      text: 'Голосования',
      id: Routes.homeOrganizer,
      roles: ALL_ROLES
    },
    {
      text: 'Пользователи',
      id: Routes.users,
      roles: ['REGULATOR']
    },
    {
      text: 'Архив',
      id: Routes.archive,
      roles: ['REGULATOR', 'VOTER']
    }
  ];
};

export const getApplicantTabs = (): Array<ITabOptions> => {
  return [
    {
      text: 'Мои проекты',
      id: Routes.homeApplicant,
      roles: ALL_ROLES
    }
  ];
};

export const getVoterTabs = (): Array<ITabOptions> => {
  return [
    {
      text: 'Голосования',
      id: Routes.homeOrganizer,
      roles: ALL_ROLES
    },
    {
      text: 'Архив',
      id: Routes.archive,
      roles: ['REGULATOR', 'VOTER']
    }
  ];
};
