import { PROJECT_STATUS, TTabITabOptionsEx } from 'interfaces';

export const getTabs = (): TTabITabOptionsEx<PROJECT_STATUS> => [
  {
    id: PROJECT_STATUS.ALL,
    text: 'Все'
  },
  {
    id: PROJECT_STATUS.IN_REVIEW,
    text: 'На рассмотрении'
  },
  {
    id: PROJECT_STATUS.REWORK_REQUIRED,
    text: 'На доработке'
  },
  {
    id: PROJECT_STATUS.REWORK_COMPLETED,
    text: 'Доработано'
  },
  {
    id: PROJECT_STATUS.APPROVED,
    text: 'Принято'
  },
  {
    id: PROJECT_STATUS.IN_PROGRESS,
    text: 'На реализации'
  },
  {
    id: PROJECT_STATUS.DECLINED,
    text: 'Отклонено'
  },
  {
    id: PROJECT_STATUS.CANCELED,
    text: 'Отозвано'
  }
];
