import { Box } from 'grommet';
import { Text } from '@we-ui-components/base';
import * as React from 'react';

export const DataPiece: typeof Box = props => <Box gap="5px" {...props} />;

export const Label = (props: TextProps) => (
  <Text size="11px" color="Gray" {...props} />
);

export const Value = (props: TextProps) => <Text size="13px" {...props} />;

type BoxProps = React.ComponentProps<typeof Box>;
type TextProps = React.PropsWithChildren<React.ComponentProps<typeof Text>>;
