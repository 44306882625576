import { api } from 'services/api';
import { ENDPOINTS } from 'services/endpoints';

type ApiLegalDocument = {
  id: string;
  name: string;
};

export function getLegalDocuments(): Promise<ApiLegalDocument[]> {
  return api.get(ENDPOINTS.getLegalDocuments());
}
