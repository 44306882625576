import { api } from './api';
import { ENDPOINTS } from './endpoints';
import { ListResponse, ArchivePollApiDTO } from 'interfaces';

export function getAllArchivePolls(
  queryParams?: Record<string, any>
): Promise<ListResponse<ArchivePollApiDTO>> {
  return api.get<ListResponse<ArchivePollApiDTO>>(
    ENDPOINTS.archivePolls(),
    queryParams
  );
}

export function getArchiveProjects(
  queryParams?: Record<string, any>
): Promise<ListResponse<any>> {
  return api.get(ENDPOINTS.archiveProjects(), queryParams);
}

export function getOneArchiveProject(id: string) {
  return api.get<ArchivePollApiDTO>(ENDPOINTS.archivePoll(id));
}
