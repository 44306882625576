import * as React from 'react';
import { Form, MobxForm, TextArea } from '@we-ui-components/form';
import { maxLength, isRequired } from 'utils';
import { Box } from 'grommet';
import { observer } from 'mobx-react';
import { TActionModalProps } from 'components/ActionModals';
import { observable } from 'mobx';

@observer
export class RevisionModal extends React.Component<TActionModalProps> {
  formRef: MobxForm;

  @observable
  data = { reason: '' };

  componentDidMount(): void {
    this.props.onValidate.callback = () => this.onValidate();
  }

  onValidate = async () => {
    const data = await this.formRef.validateFields();

    return data;
  };

  render() {
    return (
      <Box style={this.props.style}>
        <Form ref={(ref: any) => (this.formRef = ref)} data={this.data}>
          <TextArea
            {...({} as any)}
            name="reason"
            placeholder="Введите причины возврата проекта на доработку"
            rules={[isRequired(), maxLength(1500)]}
            style={{ height: '128px' }}
          />
        </Form>
      </Box>
    );
  }
}
