import * as React from 'react';
import { useStores } from 'stores/index';
import { Box } from 'grommet';
import styled from 'styled-components';
import { breakpoints } from 'ui/Responsive';
import { useMediaQuery } from 'react-responsive';
import { ENDPOINTS } from 'services/endpoints';
import { Slider } from 'ui/Slider/Slider';
import { IFile } from 'interfaces/apiEntities';
import { Icon, Text } from '@we-ui-components/base';
import { SliderFile } from "interfaces/uiTypes";

function isImage(fileName: string) {
  const IMAGE_TYPES = ['.jpg', '.png', 'gif', '.svg', '.ttf'];
  const fileExt = fileName.split('.').slice(-1)[0];
  return IMAGE_TYPES.includes(`.${fileExt.toLowerCase()}`);
}

interface ImageType {
  slideFiles: SliderFile[];
  width?: string;
  height?: string;
  size?: string;
  style?: {};
  onChange?: (files: SliderFile[]) => void;
  editMode?: boolean;
  arrow?: boolean;
  accessType?: 'public' | 'authorized';
}

export function SliderComponent({
  slideFiles,
  size = '130px',
  width,
  height,
  style,
  arrow,
  onChange,
  editMode,
  accessType = 'authorized',
  ...props
}: ImageType) {
  const { actionModals } = useStores();

  const isLaptopOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.laptopL
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: breakpoints.mobile
  });

  const activeSlider = (activeName: string): void => {
    const onlyImages = slideFiles.filter(i => isImage(i.name))
    actionModals.open(
      () => (
        <Slider
          accessType={accessType}
          arrow={arrow}
          slideImages={onlyImages}
          activeIndex={onlyImages.findIndex(({ name }) => name === activeName)}
          onChange={(file) => {
            if (onChange) {
              const index = slideFiles.findIndex(({ name }) => name === file.name)
              slideFiles.splice(index, 1, file)
              onChange(slideFiles)
            }
          }}
        />
      ),

      {
        isOverlayClose: true,
        width: isLaptopOrWider ? 'auto' : isMobile ? '100vw' : '95vw',
        overlayStyle: {
          alignItems: 'center'
        },
        modalStyle: {
          top: 'auto',
          right: 'auto',
          margin: 'auto',
          background: isMobile ? '#efefef' : '#fffff',
          height: isMobile ? '100vh' : 'auto',
          marginTop: isLaptopOrWider ? 'auto' : isMobile ? '0' : '32px'
        }
      }
    );
  };

  const imgEndpoint = accessType === 'authorized'
    ? ENDPOINTS.getProjectFile
    : ENDPOINTS.getPublicProjectFile;

  const openFileInNewWindow = (src: string) => {
    window.open(src, '_blank');
  };

  return (
    <CardImageContainer style={style}>
      {slideFiles.map((img, key) => {
        const fileExt = img.name.split('.').slice(-1)[0];
        const _isImage = isImage(img.name);
        const RenderComponent = _isImage ? CardImage : CardDoc;
        const src = ('base64' in img) ? img.base64 : imgEndpoint(img.id)

        return (
          <RenderComponent
            key={key}
            src={src}
            type={fileExt.toUpperCase()}
            name={img.name}
            onRemoveClick={() =>{
              if (editMode) {
                slideFiles.splice(key, 1)
                onChange(slideFiles)
              }
            }}
            editMode={editMode}
            onClick={() => {
              return _isImage
                ? activeSlider(img.name)
                : openFileInNewWindow(src);
            }}
          />
        );
      })}
    </CardImageContainer>
  );
}

const RemoveIcon = (props: { onClick: () => void }) => {
  return (
    <Icon
      glyph="Trash"
      size="xsmall"
      color="Basic500"
      hoverColor="Red500"
      onClick={props.onClick}
      style={{ zIndex: 1, position: 'absolute', right: '8px', top: '8px' }}
    />
  );
};

const PreviewImageWrap = styled.div`
  border-radius: 4px;
  overflow: hidden;
  object-fit: cover;
  width: 130px;
  height: 130px;
  position: relative;
`;

const PreviewImage = styled.img`
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
`;

const CardImage = (props: {
  type: string;
  src: string;
  name?: string;
  onClick: () => void;
  onRemoveClick: () => void;
  editMode?: boolean;
}) => {
  const { src, name, onClick, onRemoveClick, editMode } = props;

  return (
    <PreviewImageWrap>
      <PreviewImage src={src} alt={name} onClick={onClick} />
      {editMode && <RemoveIcon onClick={onRemoveClick} />}
    </PreviewImageWrap>
  );
};

const CardDoc = (props: {
  type: string;
  src: string;
  name?: string;
  onClick: () => void;
  onRemoveClick: () => void;
  editMode?: boolean;
}) => {
  return (
    <Box style={{ position: 'relative' }}>
      <PreviewDoc
        justify="center"
        align="center"
        gap="xsmall"
        onClick={props.onClick}
      >
        <Icon glyph="Document2" size="46" />
        {props.type}
      </PreviewDoc>
      {props.editMode && <RemoveIcon onClick={props.onRemoveClick} />}
      <Text
        size="small"
        margin={{ top: 'xxsmall' }}
        style={{
          width: '130px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}
      >
        {props.name}
      </Text>
    </Box>
  );
};

const PreviewDoc = styled(Box)`
  position: relative;
  overflow: hidden;
  width: 130px;
  height: 130px;
  border: 1px solid #8d8d8d;
  border-radius: 4px;
`;

const CardImageContainer = styled(Box)`
  display: grid;
  grid-gap: 14px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
  @media (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(auto-fill, 130px);
  }
`;
