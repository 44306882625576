import * as React from 'react';
import { Box, BoxProps } from 'grommet';
import { Head } from 'components';
import { withTheme } from 'styled-components';
import { IStyledChildrenProps } from 'interfaces';
import { useLocation } from 'react-router';
import { Routes } from '../constants';

export const BaseContainer: React.FC<IStyledChildrenProps<
  BoxProps
>> = withTheme(({ theme, children, ...props }) => {
  const { palette, container } = theme;
  const { minWidth, maxWidth } = container;
  const location = useLocation();

  const excludeAuthContainer = [Routes.landing, Routes.infoAboutProgram];

  // TODO: refactor routes !!!
  if (excludeAuthContainer.includes(location.pathname.replace('/', ''))) {
    return children;
  }

  return (
    <div
      style={{
        minHeight: '100%',
        backgroundColor: palette.Background
      }}
    >
      <Head />
      <Box
        style={{
          minWidth,
          maxWidth,
          margin: '0 auto'
        }}
        {...props}
      >
        {children}
      </Box>
    </div>
  );
});
