export function concatFilesWithoutDoubles(target: File[], files: File[]) {
  const result = target.concat();
  const isDouble = (file: File) => target.some(f => isSameFile(f, file));

  for (const file of files) {
    if (!isDouble(file)) {
      result.push(file);
    }
  }

  return result;
}

function isSameFile(f1: File, f2: File) {
  return fileHash(f1) === fileHash(f2);
}

function fileHash(file: File) {
  const filePropsCopy = {};

  for (const prop in file) {
    filePropsCopy[prop] = file[prop];
  }

  return JSON.stringify(filePropsCopy);
}
