import * as React from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from './Responsive';
import { Button } from '@we-ui-components/base';
import { ArrowUpIcon } from './Icons';
import { isDefined } from 'utils';

type Offset = {
  bottom?: string | number;
  right?: string | number;
};

interface ScrollToTopButtonProps extends Offset {
  visible?: boolean;
}

export function ScrollToTopButton({
  visible,
  ...ofset
}: ScrollToTopButtonProps) {
  const isLaptopOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.laptopL
  });

  const isVisible = () => {
    if (isDefined(visible)) return visible;
    return !isLaptopOrWider;
  };

  if (!isVisible()) return null;

  return (
    <ScrollToTopButtonContainer {...ofset}>
      <StyledButton onClick={scrollToTop}>
        <ArrowUpIcon height="18px" width="18px" />
      </StyledButton>
    </ScrollToTopButtonContainer>
  );
}

function scrollToTop() {
  const container = document.querySelector('#grommetRoot');

  container.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}

const ScrollToTopButtonContainer = styled(Box)<Offset>`
  position: absolute;

  bottom: ${props => props.bottom || '20px'};
  right: ${props => props.right || '8px'};

  z-index: 2;
`;

const StyledButton = styled(Button)`
  button {
    border-radius: 50%;
    user-select: none;
  }
`;
