import { useCallback } from 'react';
import { useStores } from 'stores';
import { useMutation, useRequest } from 'utils';

export function useIsDeletableUser(personId: string) {
  const { userList } = useStores();

  const requestFn = useCallback(() => {
    return userList.isDeletableUser(personId);
  }, [personId]);

  return useRequest(requestFn);
}

export function useDeleteUser(personId: string) {
  const { userList, modals } = useStores();

  const requestFn = useCallback(() => userList.deleteUser(personId), [
    personId
  ]);

  return useMutation(requestFn, {
    onSuccess: () => {
      modals.closeModal();
      userList.fetch();
    }
  });
}
