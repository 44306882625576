import * as React from 'react';
import { Box } from 'grommet';
import { FilterSearch, SlimTabs } from 'components';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import styled from 'styled-components';
import { Select, Text } from '@we-ui-components/base';

export const WideFilter: typeof Box = observer(function WideFilter(props) {
  const { archivePolls } = useStores();

  return (
    <Box direction="row" gap="medium" justify="between">
      <Box
        direction="row"
        background="#EFEFEF"
        style={{ borderRadius: '2px' }}
        flex="grow"
        {...props}
      >
        <FilterSearch
          flex="grow"
          store={archivePolls}
          filterField="search"
        />
        <Divider />
      </Box>

      <Select
        size="medium"
        value={archivePolls.yearFilter}
        options={archivePolls.yearOptions}
        onChange={archivePolls.changeYearFilter}
      />
    </Box>
  );
});

export const CompactFilter: typeof Box = observer(function CompactFilter() {
  const { archivePolls } = useStores();

  return (
    <Box gap="medium">
      <FilterSearch
        flex="grow"
        store={archivePolls}
        filterField="search.text"
      />

      <Select
        size="full"
        value={archivePolls.yearFilter}
        options={archivePolls.yearOptions}
        onChange={archivePolls.changeYearFilter}
      />
    </Box>
  );
});

const Divider = styled(Box)`
  width: 1px;
  max-width: 1px;

  height: 40px;
  margin: auto 0;
  background-color: #d1d1d1;
`;
