import * as React from 'react';
import { CSSProperties } from 'react';
import { BoxProps } from 'grommet';
import { observer } from 'mobx-react';
import { TTabITabOptionsEx, TFilterProps } from 'interfaces';
import { ITabOptions } from '@we-ui-components/base';
import { TabsEx } from 'ui';

export interface IFilterComponent {
  tabs: ITabOptions[];
  selected?: string;
  onChange?(id: string): void;
  store: any; // TODO - need toi fix
}

interface IFilterTabsProps<S, T> extends BoxProps, TFilterProps<S, T> {
  tabs: TTabITabOptionsEx<T>;
  component?: React.FC<IFilterComponent>;
  style?: CSSProperties;
}

export const FilterTabs = observer(
  <S extends object, T extends string>({
    store,
    tabs,
    filterField,
    initialValue,
    component: Component = TabsEx,
    ...rest
  }: IFilterTabsProps<S, T>) => {
    const value = store.filters[filterField] ?? initialValue;

    const setFilter = (value: T) => {
      store.onChangeDataFlow({
        filters: {
          [filterField]: value !== initialValue ? value : undefined
        }
      });
    };

    return (
      <Component
        store={store}
        tabs={tabs}
        onChange={setFilter}
        selected={value}
        {...rest}
      />
    );
  }
);
