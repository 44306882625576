import * as React from 'react';
import { Box } from 'grommet';
import { IProject } from 'interfaces/apiEntities';
import { Button, Text, Title } from '@we-ui-components/base';
import {
  Calendar,
  Form,
  Input,
  MobxForm,
  NumberInput,
  Select,
  TextArea
} from '@we-ui-components/form';
import { computed, observable } from 'mobx';
import {
  conditiallyRequired,
  isCorrectUrl,
  isPositive,
  isRequired,
  lessThan,
  maxLength
} from 'utils/validators';
import { Img, sphereOptions, uploadFiles } from '../CreateProject';
import { formatWithTwoDecimalsRub } from 'utils/formatNumber';
import { FilesIdInput } from '../FileInput';
import visibilitySVG from '../visibility_off.svg';
import { inject, observer } from 'mobx-react';
import { reworkProject } from 'services';
import { SliderComponent } from 'ui/Slider/SliderComponent';
import { IStores } from 'stores/index';
import { Routes } from '../../../constants';
import { SliderFile } from "interfaces/uiTypes";
import { convertBase64ToFile } from "utils/base64toFile";
import * as moment from "moment";

interface IProps {
  project: IProject;
}

@inject('routing')
@observer
export class ReworkForm extends React.Component<
  IProps & Pick<IStores, 'routing'>
> {
  formRef: MobxForm;
  @observable data = {
    ...this.props.project,
    startDate: new Date(this.props.project.startDate),
    endDate: new Date(this.props.project.endDate),
    sliderPublicFiles: [],
    sliderPrivateFiles: [],
    files_new: [],
    privateFiles_new: []
  } as any;

  @observable isLoading: boolean;
  @observable error: string;

  @computed
  get totalCost() {
    const {
      budgetRegion,
      budgetPeople,
      budgetDistrict,
      noBudgetFunds
    } = this.data;
    return (
      Number(budgetRegion) +
      Number(budgetPeople) +
      Number(budgetDistrict) +
      Number(noBudgetFunds)
    );
  }

  goBack = () => {
    const { routing } = this.props;
    routing.push(`/${Routes.homeApplicant}`);
  };

  reworkProject = () => {
    this.formRef.validateFields().then(res => {
      if (!this.totalCost) {
        this.error = 'Общий объём требуемых средств должен быть больше 0';
        return;
      }
      this.isLoading = true;
      this.error = '';
      return processData(this.data, this.props.project)
        .then(formData => {
          return reworkProject(formData)
            .then(() => this.goBack())
            .catch(error => {
              this.error = error.detail || error.title;
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(err => {
          this.error = err.message;
          this.isLoading = false;
        });
    });
  };

  componentDidMount(): void {
    this.data.sliderPublicFiles = this.data.files.map(({ id, name}) => ({ id, name }))
    this.data.sliderPrivateFiles = this.data.privateFiles.map(({ id, name}) => ({ id, name }))
  }

  render() {
    const isSchoolProject = !!this.data.school;

    return (
      <Box margin={{ top: 'small' }}>
        <Form
          ref={(formRef: MobxForm) => (this.formRef = formRef)}
          data={this.data}
        >
          <Box gap="xsmall">
            <Input
              name="name"
              size="full"
              placeholder="Название проекта *"
              rules={[isRequired(), maxLength(120)]}
            />
            <DataItem
              label="Тип проекта"
              text={
                isSchoolProject
                  ? 'Школьный проект'
                  : 'Муниципальное образование'
              }
            />
            <DataItem
              label="Муниципальный район"
              text={
                isSchoolProject
                  ? this.data.school.districtName
                  : this.data.subject.name
              }
            />
            {isSchoolProject && (
              <DataItem label="Школа" text={this.data.school.name} />
            )}
            <Title size="xsmall" bold margin={{ vertical: 'xsmall' }}>
              Описание проекта
            </Title>
            {!isSchoolProject && (
              <Select
                name="sphere"
                label="Сфера проекта *"
                size="full"
                options={sphereOptions}
                rules={[isRequired()]}
              />
            )}

            <NumberInput
              type="integer"
              name="beneficiaries"
              placeholder="Количество благополучателей, чел *"
              size="full"
              rules={[isRequired(), isPositive(), lessThan(1e8)]}
              disabled={isSchoolProject}
            />

            <TextArea
              {...({} as any)}
              name="description"
              label="Описание проекта *"
              rules={[isRequired(), maxLength(1500)]}
              style={{ height: '128px' }}
            />
            <Box direction="row" gap="small" justify="between">
              <Calendar
                name="startDate"
                size="xlarge"
                placeholderText="Начало реализации *"
                rules={[isRequired()]}
                minDate={moment().add(1, 'day').toDate()}
              />
              <Calendar
                name="endDate"
                size="xlarge"
                placeholderText="Окончание реализации *"
                rules={[isRequired()]}
              />
            </Box>
            <Input
              name="place"
              placeholder="Место реализации проекта *"
              size="full"
              rules={[isRequired()]}
            />
            <Input
              name="administrationLink"
              placeholder="Ссылка на сайт администрации *"
              size="full"
              rules={[isRequired(), isCorrectUrl()]}
            />
          </Box>
          <Text bold margin={{ bottom: 'xsmall' }}>
            Объем требуемых средств {formatWithTwoDecimalsRub(this.totalCost)}
          </Text>
          <Box>
            <Box direction="row" gap="small">
              <NumberInput
                type="decimal"
                name="budgetRegion"
                placeholder="Областной бюджет, ₽"
                size="xlarge"
              />
              <NumberInput
                type="decimal"
                name="budgetPeople"
                placeholder="Средства населения, ₽"
                size="xlarge"
              />
            </Box>
            <Box direction="row" gap="small">
              <NumberInput
                type="decimal"
                name="budgetDistrict"
                placeholder="Бюджет муниципального образования, ₽"
                size="xlarge"
              />
              <NumberInput
                type="decimal"
                name="noBudgetFunds"
                placeholder="Внебюджетные средства, ₽"
                size="xlarge"
              />
            </Box>
          </Box>
          <Box gap="xsmall">
            <Text bold>Материалы (до и после реализации проекта)*</Text>
            <Text>
              Эти материалы будут доступны всем пользователям системы.
            </Text>
            <Text size="small">Уже загруженные файлы</Text>
            {this.data.sliderPublicFiles.length !== 0 ? (
              <SliderComponent
                slideFiles={this.data.sliderPublicFiles}
                arrow
                editMode
                onChange={files => {
                  this.data.sliderPublicFiles = files;
                }}
                style={{ margin: 0 }}
              />
            ) : (
              <Text size="small" color="Basic500">
                [отсутствуют]
              </Text>
            )}
            {this.data.sliderPublicFiles.length < 3 && (
              <>
                <Text size="small" margin={{ bottom: 'xsmall' }}>
                  Новые файлы
                </Text>

                <FilesIdInput
                  name="files_new"
                  withPreview
                  rules={[
                    conditiallyRequired(() => this.data.sliderPublicFiles.length === 0)
                  ]}
                  limits={{ maxFiles: 3 - this.data.sliderPublicFiles.length }}
                  formats={['.jpg', '.png']}
                />
              </>
            )}
          </Box>
          <Box gap="xsmall">
            <Box direction="row" align="center">
              <Img src={visibilitySVG} alt="" />
              <Text bold>Приватные материалы</Text>
            </Box>
            <Text>Эти материалы будут доступны только вам и организатору.</Text>
            <Text size="small">Уже загруженные файлы</Text>
            {this.data.sliderPrivateFiles.length !== 0 ? (
              <SliderComponent
                slideFiles={this.data.sliderPrivateFiles}
                arrow
                editMode
                onChange={files => {
                  this.data.sliderPrivateFiles = files;
                }}
                style={{ margin: 0 }}
              />
            ) : (
              <Text size="small" color="Basic500">
                [отсутствуют]
              </Text>
            )}
            {this.data.sliderPrivateFiles.length < 7 && (
              <FilesIdInput
                name="privateFiles_new"
                withPreview
                limits={{
                  maxFiles: 7 - this.data.sliderPrivateFiles.length,
                  maxFileSize: 10e6
                }}
                additionalText={
                  'Размер каждого файла не должен превышать 10 Мб'
                }
                formats={['.png', '.pdf']}
              />
            )}
          </Box>
        </Form>
        {!!this.error && (
          <Text color="Red" size="small" margin={{ top: 'small' }}>
            {this.error}
          </Text>
        )}
        <Box
          direction="row"
          style={{ flex: '1 1 100%' }}
          justify="end"
          gap="small"
        >
          <Button
            transparent
            bordered
            onClick={this.goBack}
            disabled={this.isLoading}
          >
            Отмена
          </Button>
          <Button onClick={this.reworkProject} disabled={this.isLoading}>
            Отправить на рассмотрение
          </Button>
        </Box>
      </Box>
    );
  }
}

const DataItem = (props: { label: string; text: string }) => {
  return (
    <Box>
      <Text color="Grey" size="small">
        {props.label}
      </Text>
      <Text>{props.text}</Text>
    </Box>
  );
};

export async function processData(
  data: IProject & {
    files_new: File[];
    privateFiles_new: File[];
    sliderPublicFiles: SliderFile[];
    sliderPrivateFiles: SliderFile[];
    startDate: Date;
    endDate: Date;
  },
  project: IProject
) {
  try {
    const file_newIds = await uploadFiles(data.files_new, 'PUBLIC');
    const privateFiles_newIds = await uploadFiles(
      data.privateFiles_new,
      'PRIVATE'
    );

    const updatedPublicFiles = await Promise.all(data.sliderPublicFiles.map( async (file) => {
      if('base64' in file){
        const [id] = await uploadFiles([convertBase64ToFile(file.base64, file.name) as File], 'PUBLIC')
        return id
      }
      return file.id
    }))

    const updatedPrivateFiles = await Promise.all(data.sliderPrivateFiles.map( async (file) => {
      if('base64' in file){
        const [id] = await uploadFiles([convertBase64ToFile(file.base64, file.name) as File], 'PRIVATE')
        return id
      }
      return file.id
    }))

    const publicIds = updatedPublicFiles.concat(file_newIds);
    const privateIds = updatedPrivateFiles.concat(privateFiles_newIds);

    const res = {
      ...project,
      ...data,
      startDate: data.startDate.toISOString(),
      endDate: data.endDate.toISOString(),
      files: publicIds.concat(privateIds)
    };

    delete res.files_new;
    delete res.privateFiles;
    delete res.privateFiles_new;
    delete res.sliderPublicFiles;
    delete res.sliderPrivateFiles;

    return res;
  } catch (err) {
    throw new Error(err.detail || err.title);
  }
}
