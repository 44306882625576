import React from 'react';
import { Box } from 'grommet';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from 'styled-components';
import { lighten } from 'polished';

interface IWizardProps {
  style?: React.CSSProperties;
  steps: IWizardStepProps[];
  footer: React.FC<{
    currentStep: number;
    isFirst: boolean;
    isLast: boolean;
    changeStep: (step: number) => void;
  }>;
}

interface IWizardStepProps {
  title: string;
  render: React.FC;
}

@observer
export class Wizard extends React.Component<IWizardProps> {
  @observable currentStep: number = 1;

  changeStep = (step: number) => {
    this.currentStep = step;
  };

  render() {
    const { style, steps, footer } = this.props;
    const Footer = footer;
    return (
      <Box style={style}>
        <Box
          direction="row"
          style={{ width: '100%', borderRadius: '6px', overflow: 'hidden' }}
        >
          {steps.map((step, i) => (
            <WizardHeaderTab
              key={step.title}
              isPassed={this.currentStep > i + 1}
              isActive={this.currentStep === i + 1}
              style={{ width: `${(100 / steps.length).toFixed(2)}%` }}
            >
              {step.title}
            </WizardHeaderTab>
          ))}
        </Box>
        <Box>
          {steps.map((step, i) => {
            const Step = step.render;
            const isActive = this.currentStep === i + 1;
            return (
              <Box
                style={{
                  height: isActive ? 'auto' : 0,
                  overflow: isActive ? 'visible' : 'hidden'
                }}
              >
                <Step />
              </Box>
            );
          })}
        </Box>
        <Box>
          <Footer
            currentStep={this.currentStep}
            changeStep={this.changeStep}
            isFirst={this.currentStep === 1}
            isLast={this.currentStep === steps.length}
          />
        </Box>
      </Box>
    );
  }
}

interface IHeaderTabProps {
  isPassed: boolean;
  isActive: boolean;
  style: React.CSSProperties;
  children: React.ReactNode;
}

const WizardHeaderTab = (props: IHeaderTabProps) => (
  <WizardTab
    style={props.style}
    justify="center"
    align="center"
    isPassed={props.isPassed}
    isActive={props.isActive}
  >
    {props.children}
  </WizardTab>
);

const WizardTab = styled(Box)<{ isPassed: boolean; isActive: boolean }>`
  height: 32px;
  color: ${props =>
    props.isPassed || props.isActive
      ? props.theme.palette.White
      : props.theme.palette.Black};
  background-color: ${props =>
    props.isPassed || props.isActive
      ? props.theme.styled.colors.colorPrimary
      : '#EFEFEF'};
  transition: 0.2s all;
  outline: none;
`;
