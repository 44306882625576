import api from "!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../node_modules/css-loader/dist/cjs.js?modules!../../../node_modules/stylus-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!./styles.styl";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};