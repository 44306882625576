import { Button, Text } from '@we-ui-components/base';
import { Box } from 'grommet';
import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { useTheme } from 'utils/hooks';
import { observer } from 'mobx-react-lite';
import { IProject, PROJECT_STATUS } from 'interfaces/apiEntities';
import { useStores } from 'stores/index';
import { ActionModalConfig } from 'stores/ActionModalsStore';
import { DeclineModal } from './DeclineModal';
import styled from 'styled-components';
import { breakpoints, KebabIcon } from 'ui';
import { RevisionModal } from './RevisionModal';
import { useOnClickOutside } from '../Landing/HeaderMenu/MenuMob';
import {
  ConfirmFinishModal,
  FormData as ConfirmFinishModalFormData
} from './ConfirmFinishModal';
import { ReworkProjectModal } from './ReworkProjectModal';
import { TProjectAction } from "services/projectService";
import { vote } from "./voting";
import {
  objectToGetParams,
  SHARE_LINKS,
  ShareLinks,
  windowOpen
} from "./components/ShareButton";
import { SHARE_PARAMS } from "./VotingActionsPanel";
import { useMediaQuery } from "react-responsive";

export const ProjectActionsPanel = observer<{ project: IProject }>(
  ({ project }) => {
    const theme = useTheme();
    const {
      user,
      activePoll,
      projectList,
      activeProject,
      actionModals,
      votings,
    } = useStores();

    const [revisionStatus, setRevisionStatus] = useState(false);
    const node = useRef();
    useOnClickOutside(node, () => {
      if (revisionStatus) {
        setRevisionStatus(false);
      }
    });

    const isVoter = user.isContainRoles(['VOTER']);
    const isOrganizer = user.isContainRoles(['REGULATOR']);


    const isLaptopOrWider = useMediaQuery({
      minDeviceWidth: breakpoints.laptop
    });
    const isTabletOrWider = useMediaQuery({
      minDeviceWidth: breakpoints.tablet
    });

    const isPollActive = [
      'APPLICATIONS_RECEIVING',
      'WAITING_FOR_VOTING'
    ].includes(activePoll.poll.status);

    const isPollCompleted = ['FINISHED'].includes(activePoll.poll.status);

    const displayActionButton =
      [PROJECT_STATUS.IN_REVIEW, PROJECT_STATUS.REWORK_COMPLETED].includes(
        project.status
      ) &&
      isOrganizer &&
      isPollActive;

    const displayPostActionButton =
      [PROJECT_STATUS.APPROVED].includes(project.status) &&
      isOrganizer &&
      isPollCompleted;

    const displayConfirmFinishActionButton =
      project.status === PROJECT_STATUS.IN_PROGRESS &&
      isOrganizer &&
      isPollCompleted;

    const makeProjectAction = useCallback((action: TProjectAction, data?: { reason: string } | IProject) => {
      activeProject.callProjectAction(project.id, action, data).then(() => {
        projectList.init({ filters: { pollId: activePoll.pollId } });
        projectList.getProjectTabCount(activePoll.pollId)
      })
    }, [projectList, activeProject, activePoll, project])

    const approveProject = () => {
      actionModals.open(
        ({ config }: { config: ActionModalConfig }) => (
          <Text size="medium"></Text>
        ),
        {
          title: `Вы уверены, что хотите принять проект?`,
          applyText: () => <>Принять</>,
          closeText: () => <>Отменить</>,
          noValidation: true,
          onApply: async () => makeProjectAction('approve')
        }
      );
    };

    const revisionProject = () => {
      setRevisionStatus(false);
      actionModals.open(RevisionModal, {
        title: `Возврат на доработку`,
        applyText: () => <>Вернуть на доработку</>,

        closeText: () => <>Отмена</>,
        noValidation: true,
        onApply: async (data: { reason: string }) => makeProjectAction('requireRework', data),
        modalStyle: { padding: '10px' }
      });
    };

    const declineProject = () => {
      setRevisionStatus(false);
      actionModals.open(DeclineModal, {
        title: `Введите причину отклонения`,
        applyText: () => <>Отклонить проект</>,
        applyButtonProps: {
          bgColor: 'Red',
          padding: '8xp 16px'
        },
        closeText: () => <>Отмена</>,
        noValidation: true,
        onApply: async (data: { reason: string }) => makeProjectAction('decline', data),
        modalStyle: { padding: '10px' }
      });
    };

    const archiveProject = () => {
      actionModals.open(
        ({ config }: { config: ActionModalConfig }) => <Text size="medium"/>,
        {
          title: `Вы уверены, что хотите перевести проект в архив?`,
          applyText: () => <>Перевести в архив</>,
          closeText: () => <>Отмена</>,
          noValidation: true,
          onApply: async () => makeProjectAction('archive'),
        }
      );
    };
    const implementationProject = () => {
      actionModals.open(
        ({ config }: { config: ActionModalConfig }) => <Text size="medium"/>,
        {
          title: `Вы уверены, что хотите перевести проект в реализацию?`,
          applyText: () => <>Перевести в реализацию</>,
          closeText: () => <>Отмена</>,
          noValidation: true,
          onApply: async () => makeProjectAction('start'),
        }
      );
    };

    const openConfirmFinishModal = () => {
      actionModals.open(ConfirmFinishModal, {
        width: '545px',
        applyText: () => <span>Подтвердить</span>,
        onApply: async (data: ConfirmFinishModalFormData) =>
          activeProject
            .confirmFinish(project.id, data.description, data.files)
            .then(() => {
              projectList.init({ filters: { pollId: activePoll.pollId } });
              projectList.getProjectTabCount(activePoll.pollId)
            }),
        closeText: () => <span>Отменить</span>,
        noValidation: true
      });
    };

    const voteForProject = async () => {
      try {
        activePoll.hasVote = true;
        await vote({
          pollId: activePoll.poll.id,
          contractId: activePoll.poll.contractId,
          selectedProjectNumber: activePoll.bulletinOrder,
          projectsLength:
            activePoll.poll.bulletinProjectsCount ||
            projectList.data.filter(
              p =>
                p.status === PROJECT_STATUS.APPROVED &&
                p.bulletinOrder !== null
            ).length,
          userInfo: user.userInfo,
          password: user.encryptPassword
        });
        actionModals.open(
          () => (
            <Box>
              <Text size="medium">Ожидайте результатов голосования</Text>

              <Text size="medium" margin={{ top: 'small', bottom: 'xsmall' }}>Поделитесь
                голосованием в
                соц.сетях:</Text>

              <ShareLinks
                onShare={type => {
                  const provideParams = SHARE_PARAMS[type];
                  const url = SHARE_LINKS[type]

                  windowOpen(url + objectToGetParams(provideParams(activePoll.poll)), {})
                }}
              />

              <Box align="end" style={{ flex: '1 1 100%' }}
                   margin={{ bottom: 'small' }}>
                <Button
                  onClick={() => actionModals.closeLastModal()}
                  margin={{ left: 'auto' }}>Закрыть</Button>
              </Box>
            </Box>
          ),
          {
            title: `Спасибо. Ваш голос учтен`,
            noValidation: true,
            overlayStyle: {
              padding: '32px 16px'
            },
            width: isLaptopOrWider ? '545px' : 'auto',
            modalStyle: {
              maxWidth: isTabletOrWider ? '545px' : '100vw'
            }
          }
        );
      } catch (e) {
        activePoll.hasVote = false;
        throw e
      }
      await votings.fetch();
    };

    const openEditProjectModal = () => {
      actionModals.open(ReworkProjectModal, {
        initData: project,
        width: '736px',
        applyText: () => <span>Подтвердить</span>,
        onApply: async (formData: IProject) => makeProjectAction('completeRework', formData),
        closeText: () => <span>Отменить</span>,
        noValidation: true,
        modalStyle: {
          margin: '96px auto 74px'
        }
      });
    };

    const deleteProject = () => {
      actionModals.open(
        ({ config }: { config: ActionModalConfig }) => (
          <Text size="medium"></Text>
        ),
        {
          title: `Вы уверены, что хотите удалить проект? Восстановить проект будет невозможно.`,
          applyText: () => <>Удалить</>,
          closeText: () => <>Отменить</>,
          noValidation: true,
          onApply: async () => makeProjectAction('delete'),
        }
      );
    };

    if (isOrganizer) {
      return (
        <Box
          align="center"
          direction="row"
          margin={{ left: 'auto' }}
          style={{ position: 'relative' }}
          gap="small"
        >
          {displayActionButton && (
            <Box ref={node}>
              <Button onClick={() => setRevisionStatus(!revisionStatus)}>
                <KebabIcon color="white" width="1em"/>
              </Button>
              {revisionStatus && (
                <BoxRevisionStyle pad="small" gap="xxsmall">
                  <Button
                    bordered
                    transparent
                    onClick={() => {
                      revisionProject();
                    }}
                    color="#483fc7"
                    style={{ border: '1px solid #483fc7' }}
                  >
                    Отправить на доработку
                  </Button>

                  <Button
                    bordered
                    transparent
                    color="#483fc7"
                    size="full"
                    style={{ border: '1px solid #483fc7' }}
                    onClick={() => {
                      setRevisionStatus(false);
                      openEditProjectModal();
                    }}
                  >
                    Доработать
                  </Button>
                  <Button
                    bordered
                    transparent
                    color="Red"
                    size="full"
                    style={{ border: '1px solid #F0222B' }}
                    onClick={() => {
                      deleteProject();
                    }}
                  >
                    Удалить
                  </Button>
                </BoxRevisionStyle>
              )}
            </Box>
          )}
          {displayActionButton && (
            <Button
              bordered
              transparent
              color="Red"
              onClick={() => {
                declineProject();
              }}
              style={{ border: '1px solid #F0222B' }}
            >
              Отклонить
            </Button>
          )}
          {displayActionButton && (
            <Button
              onClick={() => {
                approveProject();
              }}
            >
              Принять
            </Button>
          )}
          {displayPostActionButton && (
            <Button
              bordered
              transparent
              color="Blue"
              onClick={() => {
                archiveProject();
              }}
              style={{ border: '1px solid #204080' }}
            >
              В архив
            </Button>
          )}
          {displayPostActionButton && (
            <Button
              onClick={() => {
                implementationProject();
              }}
            >
              В реализацию
            </Button>
          )}
          {displayConfirmFinishActionButton && (
            <Button onClick={openConfirmFinishModal}>
              Внести данные о реализации
            </Button>
          )}
        </Box>
      );
    }

    if (
      isVoter &&
      project.status === PROJECT_STATUS.APPROVED &&
      !activePoll.hasVote &&
      activePoll.poll.status === 'VOTING'
    ) {
      return (
        <StyledBox direction="row">
          {activePoll.bulletinOrder === project.bulletinOrder ? (
            <StyledButton
              style={{
                background: 'white',
                color: theme.palette.Purple,
                border: `1px solid ${theme.palette.Purple}`
              }}
              onClick={() => {
                activePoll.clearProject();
              }}
            >
              Отменить голос
            </StyledButton>
          ) : (
            <StyledButton
              disabled={
                activePoll.isProjectSelected || project.bulletinOrder === null || activePoll.hasVote
              }
              onClick={() => {
                activePoll.selectProject(project.bulletinOrder);
                voteForProject()
              }}
            >
              Проголосовать
            </StyledButton>
          )}
        </StyledBox>
      );
    }

    if (
      activePoll.bulletinOrder === project.bulletinOrder &&
      activePoll.hasVote
    ) {
      return (
        <Button
          style={{
            background: 'inherit',
            color: theme.palette.Purple,
            border: `1px solid #d4d2eb`,
            padding: '10px 16px',
            cursor: 'auto'
          }}
          size="medium"
        >
          <LabelStyle>
            <input type="checkbox" checked disabled/>
            <span/>
          </LabelStyle>
          Голос отдан
        </Button>
      );
    }

    return null;
  }
);

const StyledBox = styled(Box)`
  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    width: auto;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;

  button {
    width: 100%;
  }
`;

const LabelStyle = styled.label`
  display: inline-flex;
  position: relative;
  margin-right: 10px;

  input {
    height: 22px;
    width: 22px;
    appearance: none;
  }

  input:checked {
    border: 2px solid #3b33b0;
    background-color: inherit;
  }

  input:checked + span::before {
    content: '\\2713';
    color: #3b33b0;
    position: absolute;
    left: 0.7rem;
    top: 0.35rem;
  }
`;

const BoxRevisionStyle = styled(Box)`
  position: absolute;
  background: white;
  z-index: 100;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(59, 51, 176, 0.4);
  top: 55px;
`;
