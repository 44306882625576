import { api } from './api';
import {
  ISystemUser,
  IUser,
  ListResponse,
  INewUser,
  UpdateUserFullyRequest
} from 'interfaces';
import { ENDPOINTS } from './endpoints';

export function getUsersList(
  params: {
    businessRole?: string;
    search?: string,
  }
): Promise<ListResponse<IUser>> {
  return api.get(ENDPOINTS.users(), params);
}

export function getUser(id: string): Promise<ISystemUser> {
  return api.get(ENDPOINTS.getUser(id));
}

export function createUser(data: INewUser) {
  return api.post(ENDPOINTS.users(), data);
}

export function updateUser(personId: string, data: UpdateUserFullyRequest) {
  return api.put(ENDPOINTS.updatePersonFull(personId), data);
}

export function changePassword(
  personId: string,
  data: any
): Promise<ISystemUser> {
  return api.put(ENDPOINTS.changePassword(personId), data);
}

export function activateUser(personId: string): Promise<ISystemUser> {
  return api.put(ENDPOINTS.activateUser(personId));
}

export function blockUser(personId: string): Promise<ISystemUser> {
  return api.put(ENDPOINTS.blockUser(personId));
}

export function deleteUser(personId: string) {
  return api.del(ENDPOINTS.getUser(personId));
}
