import * as React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Select } from '@we-ui-components/form';

export function AsyncSelect({
  loading,
  ...props
}: React.ComponentProps<typeof StyledSelect>) {
  return (
    <StyledSelect
      loading={loading}
      disabled={loading}
      glyph={loading ? 'Refresh' : 'ArrowDown'}
      {...props}
    />
  );
}

const StyledSelect = styled(Select)<{ loading: boolean }>`
  svg {
    animation: ${computeRotateAnimation};
  }
`;

function computeRotateAnimation({ loading }: { loading: boolean }) {
  if (!loading) return 'none';

  return css`
    ${rotateAnimation} 1s linear infinite
  `;
}

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
