import * as React from 'react';
import styled from 'styled-components';

const prop = (prop: string, fallback: string = '') => props =>
  props[prop] || fallback;

type SvgProps = JSX.IntrinsicElements['svg'] & {
  opacity?: string;
  cursor?: string;
};

const SVG = styled.svg<SvgProps>`
  ${prop('opacity')}
  ${prop('cursor', 'auto')}
`;

export const QuestionMarkIcon: React.FC<SvgProps> = React.forwardRef(
  (props, ref) => {
    return (
      <SVG
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <path
          d="M7.1875 5.67188C7.96875 4.89062 8.90625 4.5 10 4.5C11.0938 4.5 12.0312 4.89062 12.8125 5.67188C13.5938 6.45312 13.9844 7.39062 13.9844 8.48438C13.9844 9.32812 13.4844 10.2031 12.4844 11.1094C11.4844 11.9844 10.9844 12.7812 10.9844 13.5H9.01562C9.01562 12.7812 9.17188 12.1719 9.48438 11.6719C9.79688 11.1406 10.1406 10.7656 10.5156 10.5469C10.8906 10.2969 11.2344 10 11.5469 9.65625C11.8594 9.3125 12.0156 8.92188 12.0156 8.48438C12.0156 7.95312 11.8125 7.5 11.4062 7.125C11 6.71875 10.5312 6.51562 10 6.51562C9.46875 6.51562 9 6.71875 8.59375 7.125C8.1875 7.5 7.98438 7.95312 7.98438 8.48438H6.01562C6.01562 7.39062 6.40625 6.45312 7.1875 5.67188ZM4.32812 16.1719C5.92188 17.7344 7.8125 18.5156 10 18.5156C12.1875 18.5156 14.0625 17.7344 15.625 16.1719C17.2188 14.5781 18.0156 12.6875 18.0156 10.5C18.0156 8.3125 17.2188 6.4375 15.625 4.875C14.0625 3.28125 12.1875 2.48438 10 2.48438C7.8125 2.48438 5.92188 3.28125 4.32812 4.875C2.76562 6.4375 1.98438 8.3125 1.98438 10.5C1.98438 12.6875 2.76562 14.5781 4.32812 16.1719ZM2.92188 3.46875C4.89062 1.5 7.25 0.515625 10 0.515625C12.75 0.515625 15.0938 1.5 17.0312 3.46875C19 5.40625 19.9844 7.75 19.9844 10.5C19.9844 13.25 19 15.6094 17.0312 17.5781C15.0938 19.5156 12.75 20.4844 10 20.4844C7.25 20.4844 4.89062 19.5156 2.92188 17.5781C0.984375 15.6094 0.015625 13.25 0.015625 10.5C0.015625 7.75 0.984375 5.40625 2.92188 3.46875ZM9.01562 16.5V14.4844H10.9844V16.5H9.01562Z"
          fill="white"
        />
      </SVG>
    );
  }
);

export const QuestionMarkBlack: React.FC<SvgProps> = React.forwardRef(
  (props, ref) => {
    return (
      <SVG
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <path
          d="M7.1875 5.67188C7.96875 4.89062 8.90625 4.5 10 4.5C11.0938 4.5 12.0312 4.89062 12.8125 5.67188C13.5938 6.45312 13.9844 7.39062 13.9844 8.48438C13.9844 9.32812 13.4844 10.2031 12.4844 11.1094C11.4844 11.9844 10.9844 12.7812 10.9844 13.5H9.01562C9.01562 12.7812 9.17188 12.1719 9.48438 11.6719C9.79688 11.1406 10.1406 10.7656 10.5156 10.5469C10.8906 10.2969 11.2344 10 11.5469 9.65625C11.8594 9.3125 12.0156 8.92188 12.0156 8.48438C12.0156 7.95312 11.8125 7.5 11.4062 7.125C11 6.71875 10.5312 6.51562 10 6.51562C9.46875 6.51562 9 6.71875 8.59375 7.125C8.1875 7.5 7.98438 7.95312 7.98438 8.48438H6.01562C6.01562 7.39062 6.40625 6.45312 7.1875 5.67188ZM4.32812 16.1719C5.92188 17.7344 7.8125 18.5156 10 18.5156C12.1875 18.5156 14.0625 17.7344 15.625 16.1719C17.2188 14.5781 18.0156 12.6875 18.0156 10.5C18.0156 8.3125 17.2188 6.4375 15.625 4.875C14.0625 3.28125 12.1875 2.48438 10 2.48438C7.8125 2.48438 5.92188 3.28125 4.32812 4.875C2.76562 6.4375 1.98438 8.3125 1.98438 10.5C1.98438 12.6875 2.76562 14.5781 4.32812 16.1719ZM2.92188 3.46875C4.89062 1.5 7.25 0.515625 10 0.515625C12.75 0.515625 15.0938 1.5 17.0312 3.46875C19 5.40625 19.9844 7.75 19.9844 10.5C19.9844 13.25 19 15.6094 17.0312 17.5781C15.0938 19.5156 12.75 20.4844 10 20.4844C7.25 20.4844 4.89062 19.5156 2.92188 17.5781C0.984375 15.6094 0.015625 13.25 0.015625 10.5C0.015625 7.75 0.984375 5.40625 2.92188 3.46875ZM9.01562 16.5V14.4844H10.9844V16.5H9.01562Z"
          fill="#0A0606"
        />
      </SVG>
    );
  }
);

export const LetterIcon: React.FC<SvgProps> = React.forwardRef((props, ref) => {
  return (
    <SVG
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        d="M8.98246 7.79503H14.5029L8.98246 1.93588V7.79503ZM11.9766 13.1534V11H4.02339V13.1534H11.9766ZM11.9766 17.4101V15.2567H4.02339V17.4101H11.9766ZM10.0117 0.333374L16 6.74339V19.5634C16 20.131 15.7973 20.6318 15.3918 21.0658C14.9864 21.4664 14.5185 21.6667 13.9883 21.6667H2.0117C1.48148 21.6667 1.01365 21.4664 0.608187 21.0658C0.202729 20.6318 0 20.131 0 19.5634L0.0467836 2.43666C0.0467836 1.86911 0.233918 1.38502 0.608187 0.984391C1.01365 0.55038 1.48148 0.333374 2.0117 0.333374H10.0117Z"
        fill="#3B33B0"
      />
    </SVG>
  );
});
