import React from 'react';
import * as moment from 'moment';
import { observer, inject } from 'mobx-react';
import { Title, Button, Text } from '@we-ui-components/base';
import { MobxForm } from '@we-ui-components/form';
import { Box } from 'grommet';
import { observable, toJS } from 'mobx';
import { PageContainer } from 'components/PageContainer';
import { BackButton } from 'components/BackButton';
import { useRoutes } from 'utils/useRoutes';
import { Wizard } from './Wizard';
import { INewVote1Step, Step1 } from './Step1';
import { INewVote2Step, Step2 } from './Step2';
import {
  createVote,
  getDistricts,
  getSchoolRegions,
  getTechWindow
} from 'services';
import { Routes } from 'constants/routes';
import { IStores } from 'stores';

import leftArrow from 'components/BackButton/arrow-left.svg';
import parse from 'parse-duration';

interface IProps {}

interface INewRawVote extends INewVote1Step, INewVote2Step {}
export interface INewVote
  extends Omit<
    INewRawVote,
    | 'applicationStartDate'
    | 'applicationEndDate'
    | 'votingStartDate'
    | 'votingEndDate'
    | 'hasMinVotesCount'
  > {
  applicationStartDate: string;
  applicationEndDate: string;
  votingStartDate: string;
  votingEndDate: string;
}

@inject('actionModals', 'routing', 'votings')
@observer
export class CreateVote extends React.Component<
  IProps & Pick<IStores, 'actionModals' | 'routing' | 'votings'>
> {
  formRef1: MobxForm;
  formRef2: MobxForm;

  @observable
  districts: any[] = [];
  @observable
  schoolRegions: any[] = [];

  @observable form1: INewVote1Step = {
    type: 'REGIONS',
    directory: 'ACTUAL_REGIONS_2023',
    objects: []
  } as any;
  @observable form2: INewVote2Step = {
    applicationStartDate: moment()
      .startOf('day')
      .add(1, 'day')
      .add(12, 'hours')
      .toDate(),
    applicationEndDate: moment()
      .startOf('day')
      .add(2, 'day')
      .toDate(),
    votingStartDate: moment()
      .add(3, 'day')
      .startOf('day')
      .toDate(),
    votingEndDate: moment()
      .add(1, 'week')
      .startOf('day')
      .toDate(),
    hasMinVotesCount: 'any',
    votingThreshold: ''
  } as any;
  @observable data: INewRawVote = {} as any;
  @observable regionsError: string = '';
  @observable techWindow: number = 0;

  componentDidMount(): void {
    getDistricts().then(res => {
      this.districts = res.content;
    });
    getSchoolRegions().then(res => {
      this.schoolRegions = res.content;
    });
    getTechWindow().then(res => {
      this.techWindow = parse(`${res.prepareTechWindow}s`);
    });
  }

  nextStep = (currentStep, changeStep) => {
    switch (currentStep) {
      case 1:
        this.regionsError = '';
        this.formRef1.validateFields().then(res => {
          if (this.form1.objects.length !== 0) {
            changeStep(currentStep + 1);
          } else {
            this.regionsError = 'Выберите хотя бы один регион';
          }
        });
        break;
      case 2:
        this.formRef2.validateFields().then(res => {
          this.createVote();
        });
        break;
    }
  };

  createVote = () => {
    const { actionModals, routing, votings } = this.props;
    const data = processData({ ...toJS(this.form1), ...toJS(this.form2) });

    createVote(data)
      .then(() => {
        actionModals.open(
          () => (
            <Box
              align="end"
              style={{ flex: '1 1 100%' }}
              pad={{ bottom: 'xlarge' }}
            >
              <Button
                onClick={() => {
                  actionModals.closeLastModal();
                }}
              >
                Закрыть
              </Button>
            </Box>
          ),
          {
            title: 'Голосование создано',
            width: '545px',
            onClose: async () => {
              votings.init({}, true);
              routing.push(`/${Routes.homeOrganizer}`);
            }
          }
        );
      })
      .catch(error => {
        actionModals.open(
          () => (
            <Box pad={{ bottom: 'xlarge' }}>
              <Text margin={{ bottom: 'xsmall' }}>
                {error.title || error.message}
              </Text>
              <Box align="end" style={{ flex: '1 1 100%' }}>
                <Button onClick={actionModals.closeLastModal}>Закрыть</Button>
              </Box>
            </Box>
          ),
          {
            title: 'Ошибка',
            width: '545px'
          }
        );
      });
  };

  onChangeForm1 = (value: any, key: string) => {
    this.form1[key] = value;

    if (key === 'type') {
      this.form1.directory =
        value === 'REGIONS'
          ? 'ACTUAL_REGIONS_2023'
          : 'ACTUAL_SCHOOL_PARTY_2023';
      this.form1.objects = [];
    }
  };

  render() {
    const Routes = useRoutes();
    this.regionsError; // Mobx update render
    this.schoolRegions;
    this.districts;

    return (
      <PageContainer>
        <Box pad={{ vertical: '32px', horizontal: '64px' }}>
          <Box margin={{ bottom: 'medium' }}>
            <BackButton url={`/${Routes.homeOrganizer}`} />
          </Box>
          <Box margin={{ bottom: 'medium' }}>
            <Title size="large" bold>
              Создание голосования
            </Title>
          </Box>
          <Wizard
            steps={[
              {
                title: 'Тип голосований',
                render: () => (
                  <Step1
                    getRef={(ref: MobxForm) => (this.formRef1 = ref)}
                    data={this.form1}
                    errorText={this.regionsError}
                    onChange={this.onChangeForm1}
                    districts={this.districts}
                    schoolRegions={this.schoolRegions}
                  />
                )
              },
              {
                title: 'Параметры голосований',
                render: () => (
                  <Step2
                    getRef={(ref: MobxForm) => (this.formRef2 = ref)}
                    data={this.form2}
                    techWindow={this.techWindow}
                  />
                )
              }
            ]}
            footer={props => {
              const { currentStep, changeStep, isFirst, isLast } = props;
              return (
                <Box
                  margin={{ top: 'small' }}
                  direction="row"
                  justify="between"
                >
                  <Box>
                    {!isFirst && (
                      <Button
                        bordered
                        transparent
                        color="Purple"
                        onClick={() => changeStep(currentStep - 1)}
                      >
                        <img src={leftArrow} alt="" />
                        <span style={{ marginLeft: '12px' }}>
                          Настроить тип голосования
                        </span>
                      </Button>
                    )}
                  </Box>
                  <Box>
                    <Button
                      onClick={() => this.nextStep(currentStep, changeStep)}
                    >
                      {isLast ? 'Создать голосование' : 'Следующий шаг'}
                    </Button>
                  </Box>
                </Box>
              );
            }}
          />
        </Box>
      </PageContainer>
    );
  }
}

function processData(data: INewRawVote): INewVote {
  const res = {
    form: 'CUMULATIVE',
    ...data,
    applicationStartDate: data.applicationStartDate.toISOString(),
    applicationEndDate: data.applicationEndDate.toISOString(),
    votingStartDate: data.votingStartDate.toISOString(),
    votingEndDate: data.votingEndDate.toISOString(),
    subjectsIds: data.objects.map(i => i.value),
    votingThreshold:
      data.hasMinVotesCount === 'any' ? null : +data.votingThreshold
  };
  delete res.hasMinVotesCount;
  delete res.directory;
  delete res.objects;
  delete res.type;

  return res;
}
