import * as React from 'react';
import {
  ExportProjectsButton,
  PageContainer,
  SlimTabs,
  Tabs
} from 'components';
import { Title } from '@we-ui-components/base';

import { observer } from 'mobx-react-lite';
import { Box } from 'grommet';
import { RegionMap } from 'components/RegionMap';
import { getRegionMapTabs, getVoteListTabs, PollingType } from './tabs';
import { VotingList } from './VotingList';
import { useStores } from 'stores';
import { toJS } from 'mobx';
import school from './img/school.svg';
import { useLocalStore } from 'mobx-react';
import { DistrictType } from 'interfaces/apiEntities';
import { useHistory } from 'react-router';
import { Routes } from '../../constants';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui';
import { getCurrentYear } from 'utils/misc';

const regionTabs = getRegionMapTabs();

export const VOTER_DISABLED_STATUS = [
  'PUBLISHED',
  'APPLICATIONS_RECEIVING',
  'WAITING_FOR_VOTING'
]

export const OrganizerPage = observer(function OrganizerPage() {
  const listStatus = useLocalStore(() => ({
    deleting: false,
    setDeleting() {
      listStatus.deleting = true;
    },
    unsetDeleting() {
      listStatus.deleting = false;
    }
  }));

  const { votings, user } = useStores();
  const isLaptopOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.laptopL
  });

  const isTabletOrWider = useMediaQuery({
    minDeviceWidth: breakpoints.tablet
  });

  const history = useHistory();

  const isVoter = user.isContainOneOfRoles(['VOTER']);
  const isOrganizer = user.isContainOneOfRoles(['REGULATOR']);

  const rightContent = votings.isMOListType ? (
    <>
      <Box direction="row" justify="center">
        <Tabs
          margin="32px 0"
          height="32px"
          tabs={regionTabs}
          selected={votings.districtType}
          onChange={(r: DistrictType) => {
            votings.updateDistrictType(r);
            listStatus.unsetDeleting();
            votings.delete.flush();
          }}
        />
      </Box>

      <Box margin="0 35px">
        <RegionMap
          type={votings.districtType}
          areas={toJS(votings.pollingList)}
          isPending={votings.isLoading}
          isDisabled={poll => isVoter && VOTER_DISABLED_STATUS.includes(poll.status)}
          onClickArea={({ pollId }) => {
            history.push(`${Routes.poll}/${pollId}`);
          }}
        />
      </Box>
    </>
  ) : (
    <Box align="center" justify="center" height="100%">
      <img src={school} alt=""/>
    </Box>
  );

  return (
    <PageContainer>
      <Box
        direction="row"
        background="transparent"
        justify="between"
        style={{ padding: 0, height: 'calc(100vh - 56px)' }}
      >
        <Box
          direction="column"
          background="white"
          style={{
            maxWidth: isLaptopOrWider ? '625px' : 'unset',
            width: isLaptopOrWider ? 'auto' : '100%',
            padding: isTabletOrWider ? '32px' : '8px'
          }}
        >
          <Box
            direction="row"
            justify="between"
            margin={{ bottom: 'small' }}
            gap="medium"
          >
            <Title
              size={isTabletOrWider ? '24px' : '18px'}
              bold
              style={{
                lineHeight: isTabletOrWider ? '32px' : '24px'
              }}
            >
              {`Голосование по Волгоградской области ${getCurrentYear()}`}
            </Title>

            {isOrganizer && <ExportProjectsButton/>}
          </Box>

          <Box basis="auto">
            <SlimTabs
              tabs={getVoteListTabs()}
              selected={toJS(votings.pollingType)}
              onChange={next => {
                votings.updatePollingType(next as PollingType);
                listStatus.unsetDeleting();
                votings.delete.flush();
              }}
            />
          </Box>

          <Box flex={{ grow: 1, shrink: 0 }} basis="0%">
            <VotingList listStatus={listStatus}/>
          </Box>
        </Box>

        {isLaptopOrWider && (
          <Box direction="column" flex="grow">
            {rightContent}
          </Box>
        )}
      </Box>
    </PageContainer>
  );
});
