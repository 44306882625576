import * as React from 'react';
import { observer } from 'mobx-react';

const loadedScripts = [];

export const AsyncScript = observer(
  ({
    url,
    globalName,
    render
  }: {
    url: string;
    globalName: string;
    render: (o: any) => React.ReactElement;
  }) => {
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
      let script = document.createElement('script');

      script.src = url;
      script.async = true;

      script.onload = () => {
        if (loadedScripts.includes(url)) {
          loadedScripts.push(url);
        }

        setLoaded(true);
      };

      document.body.appendChild(script);
    }, []);

    return (
      loaded && window[globalName] !== undefined && render(window[globalName])
    );
  }
);
