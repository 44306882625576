import * as React from 'react';
import { Box, BoxProps } from 'grommet';
import { TStyledChildrenProps } from 'interfaces';
import { withTheme } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { useRef, useEffect } from 'react';

export const PageContainer = withTheme(
  observer(({ children, theme, ...props }: TStyledChildrenProps<BoxProps>) => {
    const { user } = useStores();
    const { container } = theme;
    const { minWidth, maxWidth } = container;

    useEffect(() => {
      scrollHandler();
    }, []);

    const scrollHandler = () => {
      const scrollY = refDiv.current.getBoundingClientRect().top - 56;
      user.isPageScrolling = scrollY !== 0;
    };

    const refDiv = useRef(null);

    return (
      <Box
        style={{
          minWidth,
          maxWidth,
          width: '100%',
          minHeight: 'calc(100vh - 56px)'
        }}
        onScroll={scrollHandler}
        {...props}
      >
        <div
          ref={refDiv}
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'normal'
          }}
        >
          {children}
        </div>
      </Box>
    );
  })
);
