import * as React from 'react';
import { Box } from 'grommet';
import { Text } from '@we-ui-components/base';
import { StyledAnchorElement } from 'ui';

export const Footer: typeof Box = props => {
  const userAgreementURL = () => {
    const fileName = 'Пользовательское соглашение.pdf';
    return encodeURI(`/${fileName}`);
  };

  return (
    <Box {...props}>
      <Row>
        <SmallText>
          Нажимая на кнопку “Создать аккаунт” вы подтверждаете, что ознакомились
        </SmallText>
      </Row>

      <Row>
        <SmallText> и принимаете </SmallText>
        <StyledAnchorElement
          style={{
            textDecoration: 'none',
            fontSize: '11px',
            marginLeft: '5px'
          }}
          download
          href={userAgreementURL()}
        >
          Пользовательское соглашение
        </StyledAnchorElement>
      </Row>
    </Box>
  );
};

const Row: typeof Box = props => (
  <Box direction="row" align="baseline" justify="center" {...props} />
);

const SmallText = (props: React.PropsWithChildren<{}>) => (
  <Text size="11px" color="Basic700" {...props} />
);
