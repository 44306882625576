import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from 'grommet';
import { StarRating } from 'components/StarRatings';
import { IProject, PROJECT_STATUS } from 'interfaces';
import { Text, Button } from '@we-ui-components/base';
import { isDefined } from 'utils';
import { useStores } from 'stores';
import { RateReworkModal } from 'pages/Poll/RateReworkModal';
import { useLocalStore } from 'mobx-react';
import * as projectService from 'services/projectService';

interface RealizationRatingProps {
  project: IProject;
  pollId: string;
}

export const RealizationRating = observer<RealizationRatingProps>(
  function RealizationRating({ project, pollId }) {
    const { actionModals, archiveProjects } = useStores();

    const isRatingVisible = () => isDefined(project?.score?.currentUser);
    const isRateButtonVisible = () =>
      project.status === PROJECT_STATUS.DONE && !isRatingVisible();

    const rate = useLocalStore(() => ({
      setError: (err: string) => {
        rate.error = err;
      },
      error: null
    }));

    const rateRework = () => {
      actionModals.open(
        () => (
          <RateReworkModal
            error={rate.error}
            onRate={(value: number) => {
              rate.setError(null);

              projectService
                .rateProject(project.id, value)
                .then(() => {
                  actionModals.closeLastModal();
                  archiveProjects.init({ filters: { pollId } });
                })
                .catch(err => rate.setError(err.detail));
            }}
          />
        ),
        {
          title: 'Оцените реализацию проекта'
        }
      );
    };

    if (isRatingVisible()) {
      return (
        <Box>
          <Box direction="row" flex="grow">
            <Box width="85px" align="center">
              <StarRating value={project.score?.average || 0} readonly />
            </Box>

            <Text
              margin={{ left: 'xsmall', bottom: 'xxsmall' }}
              size="xsmall"
              color="Grey"
            >
              {project.score?.average || 0} ({project.score?.totalCount || 0})
            </Text>
          </Box>

          {project.score.currentUser && (
            <Text
              margin={{ left: 'xsmall' }}
              size="xsmall"
              color="Grey"
              style={{ textAlign: 'end' }}
            >
              Ваша оценка: {project.score?.currentUser || 0}
            </Text>
          )}
        </Box>
      );
    }

    if (isRateButtonVisible()) {
      return <Button onClick={rateRework}>Оценить реализацию проекта</Button>;
    }

    return null;
  }
);
