import * as React from 'react';
import { Button, Loader, Text, Title } from '@we-ui-components/base';
import { Box } from 'grommet';
import { Routes } from '../../../constants';
import { BackButton } from 'components/BackButton';
import { changeTime } from '../applicantColumns';
import { useStores } from 'stores/index';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { IProject, PROJECT_STATUS } from 'interfaces/apiEntities';
import styled from 'styled-components';
import { ProjectStatus } from '../../Poll/ProjectStatus';
import { formatWithTwoDecimalsRub, showPeople } from 'utils/formatNumber';
import { getSphere } from '../CreateProject';
import { PageError } from 'components/PageError';
import { SliderComponent } from 'ui/Slider/SliderComponent';
import { PrivateIcon } from 'pages/Poll/icons';
import { ImplementationDetails } from 'pages/Poll/components/ImplementationDetails';
import { ReworkForm } from './ReworkForm';
import { ActionModalConfig } from 'stores/ActionModalsStore';
import { useState } from 'react';

export const ActiveProject = observer(() => {
  const { activeProject, actionModals } = useStores();
  const { id } = useParams();
  const [error, setError] = useState('');

  React.useEffect(() => {
    activeProject.getProject(id).then();

    return activeProject.clear();
  }, []);

  const openCancelModal = () => {
    actionModals.open(
      ({ config }: { config: ActionModalConfig }) => (
        <>
          <Text size="medium">
            Проект будет переведен в статус "Отозван". Это действие необратимо.
          </Text>
          <Text color="Red500" margin={{ top: 'small' }}>
            {error}
          </Text>
        </>
      ),
      {
        title: `Вы уверены, что хотите отозвать проект?`,
        applyText: () => <>Отозвать проект</>,
        closeText: () => <>Отмена</>,
        noValidation: true,
        onApply: async () => {
          activeProject
            .callProjectAction(activeProject.project.id, 'cancel')
            .then(() => {
              actionModals.closeLastModal();
              activeProject.getProject(activeProject.project.id);
            })
            .catch(err => {
              actionModals.open(
                ({ config }: { config: ActionModalConfig }) => (
                  <Text size="medium">{err.detail}</Text>
                ),
                {
                  title: `Ошибка отзыва проекта`,
                  applyText: () => <>Ок</>,
                  closeText: () => <></>,
                  noValidation: true,
                  onApply: async () => actionModals.closeLastModal()
                }
              );
            });
        }
      }
    );
  };

  const item: IProject = activeProject.project;

  if (activeProject.fetchError)
    return <PageError error={activeProject.fetchError} />;

  if (!item) {
    return <Loader />;
  }

  const isCancelButtonShown = [
    PROJECT_STATUS.IN_REVIEW,
    PROJECT_STATUS.APPROVED,
    PROJECT_STATUS.REWORK_REQUIRED,
    PROJECT_STATUS.REWORK_COMPLETED
  ].includes(item.status);

  // TODO button disabled display is wrong for transparent bordered state
  // TODO get flag for button state
  const isCancelButtonDisabled = false;

  return (
    <>
      <Box pad={{ vertical: '32px', horizontal: '64px' }}>
        <Box direction="row" justify="between" width="734px">
          <BackButton url={`/${Routes.homeApplicant}`} />
          {isCancelButtonShown && (
            <Button
              transparent
              bordered
              color="Blue700"
              size="medium"
              style={{
                opacity: isCancelButtonDisabled ? 0.2 : 1,
                cursor: isCancelButtonDisabled ? 'auto' : 'pointer'
              }}
              onClick={() => !isCancelButtonDisabled && openCancelModal()}
            >
              Отозвать проект
            </Button>
          )}
        </Box>
        <BoxResponsive
          pad={{ vertical: 'small' }}
          direction="row-responsive"
          justify="between"
          width="100%"
        >
          <Box
            style={{
              background: 'white',
              flex: '2 auto',
              boxShadow: '0px 4px 8px rgba(10, 6, 6, 0.04)',
              maxWidth: 736,
              position: 'relative'
            }}
            pad="large"
          >
            <Box justify="between" direction="row">
              <Title size="small" bold style={{ lineHeight: '34px' }}>
                {item.name}
              </Title>
              <ProjectStatus status={item.status} />
            </Box>
            {!!item.reworkReason && (
              <>
                <Text
                  margin={{ top: 'small', bottom: 'xsmall' }}
                  size="xxsmall"
                  color="#F8B700"
                >
                  Причина отправки на доработку
                </Text>
                <pre style={{ margin: 0 }}>
                  <Text
                    size="xsmall"
                    style={{
                      whiteSpace: 'normal',
                      wordWrap: 'break-word'
                    }}
                  >
                    {item.reworkReason}
                  </Text>
                </pre>
              </>
            )}
            {!!item.declineReason && (
              <>
                <Text
                  margin={{ top: 'small', bottom: 'xsmall' }}
                  size="xxsmall"
                  color="Red"
                >
                  Причина отклонения
                </Text>
                <pre style={{ margin: 0 }}>
                  <Text
                    size="xsmall"
                    style={{
                      whiteSpace: 'normal',
                      wordWrap: 'break-word'
                    }}
                  >
                    {item.declineReason}
                  </Text>
                </pre>
              </>
            )}
            {item.status === PROJECT_STATUS.REWORK_REQUIRED && (
              <ReworkForm project={item} />
            )}
            {item.status !== PROJECT_STATUS.REWORK_REQUIRED && (
              <>
                <Text
                  margin={{ vertical: 'small' }}
                  size="xxsmall"
                  color="Grey"
                >
                  Муниципальное образование
                </Text>
                <Text size="xsmall">
                  {item.school
                    ? item.school.districtName
                    : item.subject.name || '--'}
                </Text>

                {item.school ? (
                  <>
                    <Text
                      margin={{ vertical: 'small' }}
                      size="xxsmall"
                      color="Grey"
                    >
                      Школа
                    </Text>
                    <Text size="xsmall">{item.school.name}</Text>
                  </>
                ) : null}

                <Text
                  margin={{ vertical: 'small' }}
                  size="xxsmall"
                  color="Grey"
                >
                  Голосование по {item.school ? 'школьной группе' : 'району'}
                </Text>
                <Text size="xsmall">
                  {item.school
                    ? `${item.school.schoolGroupName}`
                    : `${item.subject.name || '--'}, ${item.subject.year ||
                        '--'}`}
                </Text>
                <Text margin={{ vertical: 'small' }} size="xsmall" bold>
                  Описание проекта
                </Text>
                <Box direction="row" justify="start">
                  {!item.sphere && item.school ? null : (
                    <Box style={{ width: '50%' }}>
                      <Text
                        size="xxsmall"
                        color="Grey"
                        style={{ marginBottom: '10px' }}
                      >
                        Сфера деятельности
                      </Text>
                      <Text size="xsmall">{getSphere(item.sphere)}</Text>
                    </Box>
                  )}
                  <Box margin={{ bottom: 'small' }}>
                    <Text
                      size="xxsmall"
                      color="Grey"
                      style={{ marginBottom: '10px' }}
                    >
                      Благополучателей
                    </Text>
                    <Text size="xsmall">
                      {showPeople(item.beneficiaries ? item.beneficiaries : 0)}
                    </Text>
                  </Box>
                </Box>

                <Text
                  margin={{
                    bottom: 'xsmall',
                    top: item.sphere ? 'small' : 'none'
                  }}
                  size="xxsmall"
                  color="Grey"
                >
                  Описание проекта
                </Text>
                <pre style={{ margin: 0 }}>
                  <Text
                    size="xsmall"
                    style={{
                      lineHeight: '19px',
                      whiteSpace: 'normal',
                      wordWrap: 'break-word'
                    }}
                  >
                    {item.description}
                  </Text>
                </pre>
                <Box
                  margin={{ vertical: 'small' }}
                  direction="row-responsive"
                  justify="between"
                  style={{ position: 'relative' }}
                >
                  <Box>
                    <Text
                      size="xxsmall"
                      color="Grey"
                      style={{ marginBottom: '10px' }}
                    >
                      Начало реализации проекта
                    </Text>
                    <Text size="xsmall">
                      {item.startDate
                        ? changeTime(item.startDate)
                        : 'не указан'}
                    </Text>
                  </Box>
                  <Box style={{ position: 'absolute', left: '50%' }}>
                    <Text
                      size="xxsmall"
                      color="Grey"
                      style={{ marginBottom: '10px' }}
                    >
                      Окончание реализации проекта
                    </Text>
                    <Text size="xsmall">
                      {item.endDate ? changeTime(item.endDate) : 'не указан'}
                    </Text>
                  </Box>
                </Box>
                <Text
                  margin={{ vertical: 'small' }}
                  size="xxsmall"
                  color="Grey"
                >
                  Место реализации проекта
                </Text>
                <Text size="xsmall">{item.place}</Text>
                <Box
                  pad={{ vertical: 'large' }}
                  style={{ borderBottom: '1px solid #e5e5e5' }}
                >
                  <LinkA href={item.administrationLink} target="_blank">
                    Ссылка на сайт администрации
                  </LinkA>
                </Box>
                <Text style={{ marginTop: '16px' }} size="xsmall" bold>
                  Объем требуемых средств{' '}
                  {formatWithTwoDecimalsRub(item.budgetSummary || 0)}
                </Text>
                <Box
                  margin={{ vertical: 'small' }}
                  direction="row-responsive"
                  justify="between"
                  style={{ position: 'relative' }}
                >
                  <Box>
                    <Text
                      size="xxsmall"
                      color="Grey"
                      style={{ marginBottom: '10px' }}
                    >
                      Областной бюджет{' '}
                    </Text>
                    <Text size="xsmall">
                      {formatWithTwoDecimalsRub(item.budgetRegion || 0)}
                    </Text>
                  </Box>
                  <Box style={{ position: 'absolute', left: '50%' }}>
                    <Text
                      size="xxsmall"
                      color="Grey"
                      style={{ marginBottom: '10px' }}
                    >
                      Средства населения
                    </Text>
                    <Text size="xsmall">
                      {formatWithTwoDecimalsRub(item.budgetPeople || 0)}
                    </Text>
                  </Box>
                </Box>
                <Box
                  margin={{ vertical: 'small' }}
                  direction="row-responsive"
                  justify="between"
                  style={{ position: 'relative' }}
                >
                  <Box>
                    <Text
                      size="xxsmall"
                      color="Grey"
                      style={{ marginBottom: '10px' }}
                    >
                      Бюджет муниципального образования
                    </Text>
                    <Text size="xsmall">
                      {formatWithTwoDecimalsRub(item.budgetDistrict || 0)}
                    </Text>
                  </Box>
                  <Box style={{ position: 'absolute', left: '50%' }}>
                    <Text
                      size="xxsmall"
                      color="Grey"
                      style={{ marginBottom: '10px' }}
                    >
                      Внебюджетные средства
                    </Text>
                    <Text size="xsmall">
                      {formatWithTwoDecimalsRub(item.noBudgetFunds || 0)}
                    </Text>
                  </Box>
                </Box>
                <Text size="xsmall" bold>
                  Материалы (до и после реализации проекта)
                </Text>
                <SliderComponent slideFiles={item.files} arrow />
                {!!item.privateFiles && !!item.privateFiles.length && (
                  <>
                    <Text
                      size="xsmall"
                      bold
                      margin={{ top: 'medium' }}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <PrivateIcon
                        style={{ marginRight: '8px' }}
                        {...({} as any)}
                      />
                      Приватные материалы
                    </Text>
                    <SliderComponent slideFiles={item.privateFiles} arrow />
                  </>
                )}
              </>
            )}

            {activeProject.project.status === PROJECT_STATUS.DONE && (
              <ImplementationDetails
                margin={{ top: '16px' }}
                project={activeProject?.project}
              />
            )}
          </Box>
          {/*<Box
            pad="large"
            style={{
              background: 'white',
              boxShadow: '0px 4px 8px rgba(10, 6, 6, 0.04)',
              borderRadius: '1px',
              margin: '10px 0 0 35px',
              height: 'min-content',
              flex: '1 auto'
            }}
          >
            <Text size="xsmall" bold>
              История проекта
            </Text>
            <Box direction="row" align="center" margin={{ vertical: 'small' }}>
              <svg height="10" width="10">
                <circle cx="5" cy="5" r="5" fill="Grey"/>
              </svg>
              <Text
                size="xxsmall"
                color="Grey"
                margin={{ horizontal: 'small' }}
              >
                {item.startDate ? getTimeDate(item.startDate) : 'не указан'}
              </Text>
            </Box>
            <Box direction="row">
              <Text size="xsmall" margin={{ horizontal: 'medium' }}>
                Проект опубликован. Статус
              </Text>

              <ProjectStatus status={item.status}></ProjectStatus>
            </Box>
          </Box>*/}
        </BoxResponsive>
      </Box>
    </>
  );
});

const LinkA = styled.a`
  font-size: 13px;
  line-height: 19px;
  color: #3b33b0;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    color: #6b698f;
  }
`;

const BoxResponsive = styled(Box)`
  @media (max-width: 1050px) {
    flex-direction: column;
  }
`;
