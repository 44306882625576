import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { Box } from 'grommet';
import styled from 'styled-components';
import { Loader, Text, Title } from '@we-ui-components/base';
import { Arrow } from './icons';
import { SearchInput } from 'ui/SearchInput';
import { BackButton, PageError, Tabs } from 'components';
import { getTabs } from './tabs';
import { ProjectCard } from './ProjectCard';
import { useStores } from 'stores/index';
import { Routes } from '../../constants';
import { PollingType } from '../Organizer/tabs';
import { dateFormat } from 'utils/formatDates';
import { IProject, PollSubjectShort } from 'interfaces/apiEntities';
import { PollStatus as PollStatusStyled } from 'components/PollStatus/PollStatus';
import { Table } from '@we-ui-components/rc-table';
import { toJS } from 'mobx';
import { SHARE_PARAMS, VotingActionsPanel } from './VotingActionsPanel';
import { useMediaQuery } from 'react-responsive';
import { breakpoints, ScrollToTopButton } from 'ui';
import { AboutVote, AboutVoteCollapse } from './components/AboutVote';
import { objectToGetParams, SHARE_LINKS, ShareLinks, windowOpen } from './components/ShareButton';
import { DistrictFilter } from './DistrictFilter';
import { useDebouncedCallback } from "use-debounce";
import { useCallback } from "react";

const isEqual = require('react-fast-compare')

const PollPageContainer = styled(Box)`
  padding-bottom: 62px;

  max-width: 1250px;
  margin: auto;
  width: 100%;
`;

const PollTitle = styled(Title).attrs({ as: 'h1' })`
  text-align: center;
  line-height: 1.5;
  letter-spacing: -1px;
`;

const POLL_TITLE_UI_TEXT: Record<PollSubjectShort['type'], string> = {
  [PollingType.district]: 'Голосование по',
  [PollingType.schoolGroup]: 'Голосование по школьной группе'
};

export const getPollTitle = (subject: PollSubjectShort) => {
  const pre = POLL_TITLE_UI_TEXT[subject.type];

  return pre + ` «${subject.name}», ${subject.year}`;
};

const tabs = getTabs();

const BoxPollStatus = styled(Box)`
  display: block;
  @media (min-width: ${breakpoints.tablet}) {
    display: inline-flex;
    position: relative;
    left: 30px;
  }
  @media (min-width: ${breakpoints.laptop}) {
    top: -15px;
  }
`

export const UserHasVote = styled(Text)`
  display: block;
  text-align: center;
  color: #3B33B0;
  margin-bottom: 10px;
  @media (min-width: ${breakpoints.tablet}) {
    display: inline;
    white-space: nowrap;
    line-height: initial;
  }
`

const PollStatus = styled(PollStatusStyled)`
  display: block;
  @media (min-width: ${breakpoints.tablet}) {
    display: inline;
    position: relative;
    top: -0.3em;
  }
`;

// const MemoizedTable = React.memo(Table, isEqual)


const CustomItem = {
  render: React.memo(
    ({ params: project }: { params: IProject, children?: React.ReactNode }) => {
      return (
        <ProjectCard
          key={project.id}
          project={project}
        />
      );
    },
    isEqual
  )
}

export const PollPage: React.FC<RouteComponentProps<{ id: string }>> = observer(
  ({ match }) => {
    const { activePoll, projectList, activePollResults, user, routing } = useStores();
    const [isDataStoreInitiated, setIsDataStoreInitiated] = React.useState(false);
    const [searchString, setSearchString] = React.useState('');

    const isVoter = user.isContainOneOfRoles(['VOTER']);
    const isRegulator = user.isContainOneOfRoles(['REGULATOR']);

    React.useEffect(() => {
      const pollId = match.params.id;
      const params = routing.getSearchParams(['search'])
      const search = params.search ?? ''

      if (isRegulator) {
        projectList.getProjectTabCount(pollId);
      }
      activePoll.getPoll(pollId);
      projectList.onChangeDataFlow({
        filters: {
          pollId,
          search,
        }
      });
      setSearchString(search)

      return () => {
        projectList.clear();
        activePoll.clear();
        activePollResults.clear();
      };
    }, []);

    // allow `Table` to fetch after store initialization
    React.useEffect(() => {
      const shouldAllowFetch = () => {
        return !activePoll.isPollLoading && !isDataStoreInitiated;
      };

      if (shouldAllowFetch()) {
        setIsDataStoreInitiated(true);
      }
    }, [activePoll.isPollLoading]);

    const selectedStatus =
      projectList.filters.status.length === 0
        ? 'ALL'
        : projectList.filters.status[0];

    const onChangeDataFlow = (props: any) => {
      // suppress initial fetch from `Table` components
      if (isDataStoreInitiated) {
        projectList.onChangeDataFlow(props);
      }
    }


    const onSearch = useDebouncedCallback(useCallback((search: string) => {
      projectList.updateSearch(search);
      routing.setSearchParams({ search })
    }, [projectList, routing]), 300);

    const isTabletOrWider = useMediaQuery({
      minDeviceWidth: breakpoints.tablet
    });

    const isLaptopOrWider = useMediaQuery({
      minDeviceWidth: breakpoints.laptopL
    });

    const isIpadProOrWider = useMediaQuery({
      minDeviceWidth: breakpoints.laptop
    });

    const computeHorizontalPadding = () => {
      if (isLaptopOrWider) return '0';
      if (isTabletOrWider) return '49px';
      return '9px';
    };

    const isSchoolPoll = activePoll.poll?.subject.type === PollingType.schoolGroup
    let countAllProjects = 0
    if (projectList.tabCountProject) {
      countAllProjects = Object.values(projectList.tabCountProject).reduce(
        (accumulator: number, current: number) => accumulator + current,
        0
      );
    }

    if (activePoll.fetchError && activePoll.hasError)
      return <PageError error={activePoll.fetchError} />;

    return (
      <PollPageContainer pad={{ horizontal: computeHorizontalPadding() }}>
        <ScrollToTopButton bottom="66px" />

        {activePoll.isPollLoading && (
          <Box justify="center" align="center" height="small">
            <Loader />
          </Box>
        )}

        {!activePoll.isPollLoading && (
          <>
            <Box>
              <Box margin={{ top: '32px' }} align="start">
                <BackButton
                  basis="auto"
                  url={`/${Routes.homeOrganizer}`}
                  style={{ fontSize: isLaptopOrWider ? '13px' : '16px' }}
                />
              </Box>

              <PollTitle
                style={{
                  fontSize: isLaptopOrWider ? '32px' : '18px',
                  lineHeight: isLaptopOrWider ? '48px' : '24px'
                }}
                margin={{ top: '32px' }}
                fontWeight="700"
              >
                {getPollTitle(activePoll.poll.subject)}

                {isTabletOrWider && (
                  <BoxPollStatus>
                    <PollStatus poll={activePoll.poll as any} />
                    {activePoll.hasVote ? <UserHasVote size="xsmall">Вы
                      проголосовали</UserHasVote> : null}
                  </BoxPollStatus>
                )}
              </PollTitle>

              {!isTabletOrWider && (
                <>
                  <PollStatus
                    style={{ alignSelf: 'center' }}
                    margin={{ bottom: '13px' }}
                    poll={activePoll.poll as any}
                  />
                  {activePoll.hasVote ? <UserHasVote size="xsmall">Вы
                    проголосовали</UserHasVote> : null}
                </>
              )}

              <Box direction="row" justify="center">
                <Text>{dateFormat(activePoll.poll.votingStartDate, true)}</Text>

                <Box margin="0 20px" justify="center">
                  <Arrow opacity="0.2" />
                </Box>

                <Text>{dateFormat(activePoll.poll.votingEndDate, true)}</Text>
              </Box>
            </Box>

            <Box basis="100%" margin={{ top: '24px' }} direction="row">
              <Box basis="100%">
                {!isIpadProOrWider &&
                <AboutVoteCollapse
                    width="100vw"
                    margin={{ bottom: '24px' }}
                />
                }

                <Title size={isTabletOrWider ? '24px' : '18px'} bold>
                  Проекты {countAllProjects ? <span>({countAllProjects})</span> : null}
                </Title>

                {!isVoter && (
                  <>
                    <Box margin={{ top: 'small' }}>
                      <SearchInput
                        placeholder="Поиск"
                        style={{
                          height: '56px'
                        }}
                        onChange={(search) => {
                          setSearchString(search);
                          onSearch(search)
                        }}
                        value={searchString}
                      />
                    </Box>

                    <Tabs
                      margin={{ top: 'small' }}
                      height="32px"
                      tabs={tabs}
                      selected={selectedStatus}
                      onChange={id => projectList.updateListStatus(id)}
                      projectsCount={projectList.tabCountProject}
                    />
                  </>
                )}

                <Box margin={{ top: 'small' }}>
                  {isSchoolPoll && (
                    <DistrictFilter
                      onChangeFilter={(districtId) => projectList.setDistrictId(districtId)}
                    />
                  )}

                  {!projectList.projectList.length ? (
                    <Box
                      fill={true}
                      background="white"
                      pad="medium"
                      margin={{ vertical: 'medium' }}
                      align="center"
                    >
                      {'Не найдено проектов по данному голосованию'}
                    </Box>
                  ) : (
                    <Table
                      columns={[]}
                      data={toJS(projectList.projectList)}
                      isPending={projectList.isPending}
                      onChangeDataFlow={onChangeDataFlow}
                      dataLayerConfig={toJS(projectList.dataFlow, { recurseEverything: true })}
                      customItem={CustomItem}
                    />
                  )}
                </Box>
              </Box>

              {isIpadProOrWider && (
                <Box flex="grow" basis="350px" margin={{ left: 'large' }}>
                  <AboutVote />

                  <Box direction="column" margin={{ top: 'large' }}>
                    <VotingActionsPanel />
                  </Box>

                  {isVoter && (
                    <Box margin={{ top: 'xsmall' }}>
                      <Text bold size="xsmall" margin={{ bottom: 'small' }}>
                        Поделиться голосованием в соц. сетях
                      </Text>
                      <ShareLinks
                        onShare={type => {
                          const provideParams = SHARE_PARAMS[type];
                          const url = SHARE_LINKS[type]

                          windowOpen(url + objectToGetParams(provideParams(activePoll.poll)), {})
                        }}
                      />

                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </>
        )}
      </PollPageContainer>
    );
  }
);

