import { createQS } from 'we-oauth2/lib/auth/api-utils';
import { api } from './api';

const nodeEnv = process.env.NODE_ENV;
export const baseUrl =
  nodeEnv === 'production' ? window.location.origin : process.env.BASE_URL;

export const getApiUrl = () => `${baseUrl}/api/v0`;

export const ENDPOINTS = {
  //usual
  transaction: (id: string) => `${getApiUrl()}/tx-track-info/${id}`,
  token: () => `${getApiUrl()}/vst-oauth2/oauth/token`,
  registration: () => `${getApiUrl()}/registration/register`,
  confirmRegistration: () => `${getApiUrl()}/registration/confirm`,
  accounts: () => `${getApiUrl()}/vst-oauth2/accounts`,
  account: (id: string) => `${getApiUrl()}/vst-oauth2/accounts/${id}`,
  userInfo: () => `${getApiUrl()}/vst-identity/persons/info`,
  logout: () => `${getApiUrl()}/logout`,
  companies: () => `${getApiUrl()}/vst-identity/companies`,
  businessRoles: () => `${getApiUrl()}/vst-identity/business-roles`,
  companyTypes: () => `${getApiUrl()}/vst-identity/company-types`,
  getFile: (ref: string, hash: string, name: string) =>
    `${getApiUrl()}/files/${ref}/${hash}/${name}` +
    createQS({ access_token: api.getToken() }),
  avatar: (personId: string) =>
    `${getApiUrl()}/vst-identity/persons/${personId}/avatars/current` +
    createQS({ access_token: api.getToken() }),

  //business
  ipObjectList: () => `${getApiUrl()}/ip-objects`,
  ipObject: (id: string) => `${getApiUrl()}/ip-objects/${id}`,
  violations: () => `${getApiUrl()}/violations`,
  violationsDuplicates: (id: string) =>
    `${ENDPOINTS.violations()}/${id}/duplicates`,
  violation: (id: string) => `${ENDPOINTS.violations()}/${id}`,
  violationAction: (id: string, action: string) =>
    `${ENDPOINTS.violations()}/${id}/${action}`,
  expertSolvers: () => `${getApiUrl()}/experts/solvers`,

  //voting
  vote: () => `${getApiUrl()}/polls`,

  districts: () => `${getApiUrl()}/poll-subjects`,
  districtsWithVotes: () => `${getApiUrl()}/school-districts`,
  schools: () => `${getApiUrl()}/schools`,
  techWindow: () => `${getApiUrl()}/polls/settings`,

  // polls
  allPolls: () => `${getApiUrl()}/poll-subjects`,
  pollById: (id: string) => `${ENDPOINTS.vote()}/${id}`,
  pollResults: (id: string) => `${ENDPOINTS.vote()}/${id}/results`,
  archivePolls: () => `${getApiUrl()}/public/archive/polls`,
  archivePoll: (id: string) => `${ENDPOINTS.archivePolls()}/${id}`,
  archiveProjects: () => `${getApiUrl()}/public/archive/projects`,

  // projects
  projects: () => `${getApiUrl()}/projects`,
  reworkProjects: (id: string) =>
    `${getApiUrl()}/projects/${id}/completeRework`,
  projectsCount: () => `${ENDPOINTS.projects()}/count`,
  projectsCountTab: (id: string) => `${ENDPOINTS.vote()}/${id}/statistics`,
  projectById: (id: string) => `${getApiUrl()}/projects/${id}`,
  projectLogo: (id: string) => `${getApiUrl()}/public/projects/${id}/logo`,
  exportProjects: () => `${ENDPOINTS.projects()}/export`,

  rateProject: (id: string) => `${ENDPOINTS.projectById(id)}/score`,

  projectAction: (id: string, action: string) =>
    `${getApiUrl()}/projects/${id}/${action}`,

  uploadFile: () => `${getApiUrl()}/project-files`,
  getProjectFile: (fileId: string, query?: object) =>
    `${getApiUrl()}/project-files/${fileId}` +
    createQS({ ...query, access_token: api.getToken() }),

  getPublicProjectFile: (fileId: string, query?: object) =>
    `${getApiUrl()}/public/archive/project-files/${fileId}` +
    createQS({ ...query }),

  getConfidentialFile: (query?: object) =>
    `${getApiUrl()}/public/policy-files/current` + createQS({ ...query }),

  getLegalDocuments: () =>
    `${getApiUrl()}/public/legal-documents/`,

  getLegalDocumentsFile: (fileId: string, params: object = { inline: true }) =>
    `${getApiUrl()}/public/legal-documents/${fileId}` + createQS({ ...params }),

  phoneRegister: (phoneNumber, captcha) =>
    `${getApiUrl()}/public/auth/request-phone-code?phoneNumber=${phoneNumber}&g-recaptcha-response=${captcha}`,
  sendConfirmCode: () => `${getApiUrl()}/vst-oauth2/oauth/token`,

  encryptedContent: () => `${getApiUrl()}/encrypted-content`,

  getMainKey: (pollId: string) => `${getApiUrl()}/polls/${pollId}/mainKey`,
  getSignKey: (pollId: string) => `${getApiUrl()}/polls/${pollId}/signKey`,
  getVote: (pollId: string) => `${getApiUrl()}/polls/${pollId}/vote`,
  sendBulletin: (pollId: string) => `${getApiUrl()}/polls/${pollId}/bulletins`,

  updateAccount: (personId: string) =>
    `${getApiUrl()}/users/${personId}/account`,
  updatePersonPartial: (personId: string) =>
    `${getApiUrl()}/users/${personId}/partial`,
  updatePersonFull: (personId: string) =>
    `${getApiUrl()}/users/${personId}/full`,

  // users
  users: () => `${getApiUrl()}/users`,
  getUser: (id: string) => `${ENDPOINTS.users()}/${id}`,
  updateLoginPassUser: (id: string) => `${ENDPOINTS.getUser(id)}/account`,
  changePassword: (id: string) => `${ENDPOINTS.getUser(id)}/password`,
  activateUser: (id: string) => `${ENDPOINTS.getUser(id)}/activate`,
  blockUser: (id: string) => `${ENDPOINTS.getUser(id)}/block`
};
