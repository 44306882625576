import * as React from 'react';
import { SVG, SvgProps } from '../pages/Poll/icons';
import styled from 'styled-components';
import { useTheme } from 'utils/hooks';

type StarItemProps = JSX.IntrinsicElements['path'] &
  PathProps & { ref?: any } & {
    num: number;
    isHalf: boolean;
  };

type PathProps = {
  active: boolean;
  hover: boolean;
  readonly: boolean;
  fill?: string;
};

const Path = styled.path<PathProps>`
  fill: ${({ theme, active, hover, fill }) =>
    fill ?? (active || hover ? theme.palette.Purple : theme.palette.Grey)};

  cursor: ${props => (props.readonly ? 'normal' : 'pointer')};
`;

const GAP = -1;

const Star = ({ num, isHalf, ...props }: StarItemProps) => {
  const tX = (num - 1) * 18 + GAP;

  const theme = useTheme();

  return (
    <>
      <Path
        transform={`translate(${tX}, -1)`}
        d="M8 11.5L3.875 14L4.96875 9.3125L1.34375 6.15625L6.125 5.75L8 1.34375L9.875 5.75L14.6562 6.15625L11.0312 9.3125L12.125 14L8 11.5Z"
        {...props}
      />

      {isHalf && (
        <Path
          transform={`translate(${tX}, -1)`}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 11.5L12.125 14L11.0312 9.3125L14.6562 6.15625L9.875 5.75L8 1.34375V11.5Z"
          fill={theme.palette.Grey}
          {...props}
        />
      )}
    </>
  );
};

export type StarRatingProps = {
  value: number; // 0..5
  onChangeValue?: (value: number) => void;
  readonly?: boolean;
};

export const StarRating: React.FC<StarRatingProps &
  SvgProps> = React.forwardRef(
  ({ onChangeValue, readonly = false, value: valueInitial, ...props }, ref) => {
    const [value, setValue] = React.useState(valueInitial);
    const [hover, setHover] = React.useState<number | null>(null);

    const emitClick = (nextValue: number) => {
      setValue(nextValue);

      onChangeValue(nextValue);
    };

    const pathProps = (num: number): StarItemProps => {
      const currentValue = Math.max(0, value);
      const isHalf = currentValue < num && currentValue > num - 1;

      return {
        active: isHalf || num <= currentValue,
        hover: !readonly && num <= hover,
        onClick: () => !readonly && emitClick(num),
        onMouseEnter: () => !readonly && setHover(num),
        onMouseOut: () => !readonly && setHover(null),
        readonly: readonly,
        num: num,
        isHalf: isHalf
      };
    };

    return (
      <SVG
        viewBox="0 0 85 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <Star {...pathProps(1)} />
        <Star {...pathProps(2)} />
        <Star {...pathProps(3)} />
        <Star {...pathProps(4)} />
        <Star {...pathProps(5)} />
      </SVG>
    );
  }
);
