import { api } from 'services/api';
import { ENDPOINTS } from 'services';
import { ICompany, IBusinessRole, ICompanyType } from 'interfaces';

export namespace catalogService {
  export function listCompanies(): Promise<Array<ICompany>> {
    return api.get<Array<ICompany>>(ENDPOINTS.companies());
  }

  export function getCompanies(): Promise<Array<ICompany>> {
    return api.get<Array<ICompany>>(ENDPOINTS.companies());
  }

  export function getBusinessRoles(): Promise<Array<IBusinessRole>> {
    return api.get<Array<IBusinessRole>>(ENDPOINTS.businessRoles());
  }

  export function getCompanyTypes(): Promise<Array<ICompanyType>> {
    return api.get<Array<ICompanyType>>(ENDPOINTS.companyTypes());
  }
}
