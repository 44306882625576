import { IStores } from 'stores/index';
import { ListStoreConstructor } from 'stores/core/ListStoreConstructor';
import * as archiveService from 'services/archiveService';
import * as pollService from 'services/poll';
import { ArchivePollApiDTO } from 'interfaces/apiEntities';
import { PollingType } from 'pages/Organizer/tabs';
import { observable, action, computed } from 'mobx';
import { ISelectOption } from '@we-ui-components/base/dist/Components/src';
import { generateSimpleOptions, getRange, getCurrentYear } from 'utils';
import { decryptMsg } from 'utils/crypto-js';

export class ArchivePollListStore extends ListStoreConstructor<
  ArchivePollApiDTO
> {
  @observable yearOptions: ISelectOption[] = generateYearOptions();

  constructor(stores: IStores) {
    super(stores, archiveService.getAllArchivePolls, {
      filters: {
        subjectType: PollingType.district,
      },
      sorter: ['subject.year,desc', 'subject.name,asc'],
      pollingInterval: 5e4
    });
  }

  @computed
  get yearFilter() {
    return this.filters.subjectYear ?? null;
  }

  @action.bound
  public changeYearFilter(year: number) {
    if (year === this.filters.subjectYear) {
      return;
    } else if (year === ALL_YEARS_OPTION.value) {
      this.onChangeDataFlow({ filters: { subjectYear: undefined } });
    } else {
      this.onChangeDataFlow({ filters: { subjectYear: year } });
    }
  }

  public async getOne(pollId: string) {
    const fromCache = this.select(pollId);

    if (fromCache) return fromCache;

    const fromServer = await archiveService.getOneArchiveProject(pollId);

    return fromServer;
  }

  public async getVoteInfo(pollId: string) {
    let voteInfo = {
      hasVote: false,
      encryptedBulletin: '',
      bulletinOrder: -1
    };

    if (this.stores.user.isContainRoles(['VOTER'])) {
      try {
        const response = await pollService.getVoteById(pollId);

        voteInfo.hasVote = true;
        voteInfo.encryptedBulletin = response.encryptedBulletin;
        voteInfo.bulletinOrder = this.getDecryptedBulletinOrder(
          voteInfo.encryptedBulletin
        );
      } catch (e) {
        voteInfo.hasVote = false;
        voteInfo.bulletinOrder = -1;
        voteInfo.encryptedBulletin = '';
      }
    }

    return voteInfo;
  }

  private getDecryptedBulletinOrder(encryptedBulletin: string) {
    const password = this.stores.user.encryptPassword;
    let result = -1;

    if (password && encryptedBulletin) {
      const bulletin = JSON.parse(decryptMsg(encryptedBulletin, password));
      result = bulletin[0].indexOf(1);
    }

    return result;
  }

  private select(id: string) {
    return this.data.find(it => it.id === id);
  }
}

const ALL_YEARS_OPTION: ISelectOption = {
  value: null,
  text: 'Все года'
};

function generateYearOptions() {
  const START_YEAR = 2021;
  const options = generateSimpleOptions(getRange(START_YEAR, getCurrentYear()));
  return [ALL_YEARS_OPTION, ...options];
}
