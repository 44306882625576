import * as React from 'react';
import styled from 'styled-components';
import { Title, Icon, Text } from '@we-ui-components/base';
import { breakpoints } from 'ui';
import { Box } from 'grommet';
import { useStores } from 'stores';
import { useTheme } from 'utils';

export const PageTitle = styled(Title).attrs({
  bold: true
})`
  font-size: 18px;
  text-align: center;

  @media (min-width: ${breakpoints.laptopL}) {
    font-size: 24px;
    text-align: start;
  }
`;

export const BackButton: typeof Box = props => {
  const { routing } = useStores();
  const theme = useTheme();

  return (
    <BackButtonContainer
      fill={true}
      width={{ max: '70px' }}
      margin={{ top: 'xlarge' }}
      onClick={() => {
        routing.goBack();
      }}
      {...props}
    >
      <Icon glyph="Left" height="1em" color={theme.palette.Purple} />
      <Text size="inherit">Назад</Text>
    </BackButtonContainer>
  );
};

const BackButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 70px;
  margin-top: 10px;
  font-size: 16px;

  @media (min-width: ${breakpoints.laptopL}) {
    margin-top: 32px;
    font-size: 13px;
  }
`;
