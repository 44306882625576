import * as React from 'react';
import { Anchor, Box, BoxProps } from 'grommet';
import { useTheme } from 'utils/hooks';
import styled from 'styled-components';
import { Button, Text } from '@we-ui-components/base';
import { CollapseArrow, ExternalLink, PrivateIcon } from './icons';
import { IProject, PROJECT_STATUS } from 'interfaces/apiEntities';
import { formatWithTwoDecimalsRub, showPeople } from 'utils/formatNumber';
import { dateFormat } from 'utils/formatDates';
import { ENDPOINTS } from 'services/endpoints';
import { getSphere } from '../Applicant/CreateProject';
import { ProjectActionsPanel } from './ProjectActionsPanel';
import { PollResultProgress } from './PollResultProgress';
import { ProjectStatus as ProjectStatusStyled } from './ProjectStatus';
import { useStores } from 'stores/index';
import { observer } from 'mobx-react-lite';
import { breakpoints } from 'ui';
import { useMediaQuery } from 'react-responsive';
import { SliderComponent } from 'ui/Slider/SliderComponent';
import { ShareButton } from './components/ShareButton';
import { ProjectInfoBox } from './components/ProjectInfoBox';
import { ImplementationDetails } from './components/ImplementationDetails';
import { StarRating } from 'components/StarRatings';
import { RateReworkModal } from './RateReworkModal';
import { rateProject } from 'services/projectService';
import { useLocalStore } from 'mobx-react';

const ProjectStatus = styled(ProjectStatusStyled)`
  position: absolute;
  top: ${props => props.theme.global.edgeSize.small};
  right: ${props => props.theme.global.edgeSize.small};
`;

export const ProjectCard: React.FC<BoxProps & {
  project: IProject;
}> = React.memo(
  observer(({ project, ...props }) => {
    const [expanded, setExpanded] = React.useState(false);

    const theme = useTheme();
    const {
      user,
      activePollResults,
      activePoll,
      actionModals,
      projectList
    } = useStores();

    const poll = activePoll.poll;
    const creator = project.creator;

    const isOrganizer = user.isContainOneOfRoles(['REGULATOR']);
    const isVoter = user.isContainOneOfRoles(['VOTER']);
    const isTabletOrWider = useMediaQuery({
      minDeviceWidth: breakpoints.tablet
    });

    const rate = useLocalStore(() => ({
      setError: (err: string) => {
        rate.error = err;
      },
      error: null
    }));

    const rateRework = () => {
      actionModals.open(
        () => (
          <RateReworkModal
            error={rate.error}
            onRate={(value: number) => {
              rate.setError(null);

              rateProject(project.id, value)
                .then(() => {
                  actionModals.closeLastModal();
                  projectList.fetchProjectList();
                })
                .catch(err => rate.setError(err.detail));
            }}
          />
        ),
        {
          title: 'Оцените реализацию проекта'
        }
      );
    };

    return (
      <CardContainer
        {...props}
        background={
          activePoll.bulletinOrder === project.bulletinOrder
            ? '#dedcea'
            : theme.palette.White
        }
        margin={{ top: 'medium' }}
      >
        <Box pad="small" style={{ position: 'relative' }}>
          <ProjectStatus status={project.status} />

          {isVoter && (
            <Box style={{ position: 'absolute', top: 25, right: 0 }}>
              <ShareButton project={project} />
            </Box>
          )}

          <Box direction={isTabletOrWider ? 'row' : 'column'}>
            <CardImage src={ENDPOINTS.getProjectFile(project.logoFileId)} />

            <Box
              style={{ lineHeight: 1.5, flex: '1 1 auto' }}
              pad={{ horizontal: 'small' }}
            >
              {project.school ? (
                <Text
                  margin={{ top: 'xxsmall' }}
                  size="xsmall"
                  style={{ maxWidth: '70%' }}
                >
                  {project.school.name} ({project.school.districtName})
                </Text>
              ) : null}
              <Text
                margin={{
                  top: 'xsmall',
                  right: isTabletOrWider && isVoter && '100px'
                }}
                size="large"
                bold
              >
                {project.name}
              </Text>

              {isTabletOrWider ? (
                <>
                  <Text margin={{ top: 'xsmall' }} size="xsmall">
                    Срок реализации проекта: {dateFormat(project.startDate)} –{' '}
                    {dateFormat(project.endDate)}
                  </Text>
                  <Text margin={{ top: 'xsmall' }} size="xsmall">
                    Объем требуемых средств:{' '}
                    {formatWithTwoDecimalsRub(project.budgetSummary)}
                  </Text>
                </>
              ) : (
                <Box margin={{ top: '40px' }} gap="15px">
                  <ProjectInfoBox
                    title="Срок реализации проекта"
                    text={`${dateFormat(project.startDate)} – ${dateFormat(
                      project.endDate
                    )}`}
                  />

                  <ProjectInfoBox
                    title="Объем требуемых средств"
                    text={formatWithTwoDecimalsRub(project.budgetSummary)}
                  />
                </Box>
              )}

              {expanded && (
                <>
                  {project.declineReason &&
                  project.status === PROJECT_STATUS.DECLINED ? (
                    <Box margin={{ top: 'xsmall' }}>
                      <ProjectInfoBox
                        title="Причина отклонения"
                        text={project.declineReason || ''}
                        noWrap
                      />
                    </Box>
                  ) : null}
                  <Box
                    direction={isTabletOrWider ? 'row' : 'column'}
                    flex="grow"
                    margin={{ top: '15px' }}
                    gap="15px"
                  >
                    <Box basis="50%" gap="15px">
                      <ProjectInfoBox
                        title="Областной бюджет"
                        text={formatWithTwoDecimalsRub(project.budgetRegion)}
                      />
                      <ProjectInfoBox
                        margin={{ top: 'xsmall' }}
                        title="Средства населения"
                        text={formatWithTwoDecimalsRub(project.budgetPeople)}
                      />
                    </Box>

                    <Box basis="50%" gap="15px">
                      <ProjectInfoBox
                        title="Бюджет муниципального образования"
                        text={formatWithTwoDecimalsRub(project.budgetDistrict)}
                      />
                      <ProjectInfoBox
                        margin={{ top: 'xsmall' }}
                        title="Внебюджетные средства"
                        text={formatWithTwoDecimalsRub(project.noBudgetFunds)}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          {expanded && (
            <>
              <Box
                flex="grow"
                margin={{ top: 'small' }}
                pad={!isTabletOrWider && '0 6px'}
                gap={!isTabletOrWider && '15px'}
              >
                <Text
                  bold
                  size={isTabletOrWider ? '13px' : '18px'}
                  margin={{ vertical: '16px' }}
                >
                  Описание проекта
                </Text>

                <Box direction={isTabletOrWider ? 'row' : 'column'}>
                  {project.sphere ? (
                    <ProjectInfoBox
                      margin={{
                        top: 'xsmall',
                        bottom: !isTabletOrWider && '15px'
                      }}
                      style={{ flexBasis: '50%' }}
                      title="Сфера деятельности"
                      text={getSphere(project.sphere)}
                    />
                  ) : null}
                  <ProjectInfoBox
                    margin={{ top: 'xsmall' }}
                    title="Благополучателей"
                    text={showPeople(project.beneficiaries)}
                  />
                </Box>

                <ProjectInfoBox
                  margin={{ top: 'xsmall' }}
                  title="Описание проекта"
                  text={project.description}
                  noWrap
                />
                <ProjectInfoBox
                  margin={{ top: 'xsmall' }}
                  title="Место реализации проекта"
                  text={project.place}
                />
              </Box>

              {project.files.length !== 0 && (
                <Box
                  flex="grow"
                  margin={{ top: isTabletOrWider ? 'small' : '16px' }}
                  pad={!isTabletOrWider && '0 6px'}
                  gap="small"
                >
                  <Text bold size={isTabletOrWider ? 'xsmall' : '18px'}>
                    Материалы (до и после реализации прокта)
                  </Text>

                  <SliderComponent slideFiles={project.files} arrow />
                </Box>
              )}

              {!!project.privateFiles && project.privateFiles.length !== 0 && (
                <Box
                  flex="grow"
                  margin={{ top: 'small' }}
                  gap="small"
                  pad={!isTabletOrWider && '0 6px'}
                >
                  <Text bold size="xsmall">
                    <PrivateIcon /> Приватные материалы
                  </Text>

                  <SliderComponent slideFiles={project.privateFiles} arrow />
                </Box>
              )}

              <Anchor
                href={project.administrationLink}
                margin={{ top: 'medium' }}
                color={theme.palette.Purple}
                size="xsmall"
                target='_blank'
              >
                Ссылка на сайт администрации <ExternalLink />
              </Anchor>

              {isOrganizer && (
                <Box
                  direction="row"
                  margin={{ top: isTabletOrWider ? 'small' : '16px' }}
                  pad={!isTabletOrWider && '6px 0'}
                >
                  <Text size={isTabletOrWider ? '13px' : '16px'}>
                    Заявитель:{' '}
                    {creator.patronymic ||
                    creator.firstName ||
                    creator.lastName ||
                    creator.phoneNumber ? (
                      <>
                        {creator.patronymic ||
                        creator.firstName ||
                        creator.lastName ? (
                          <>
                            {creator.lastName} {creator.firstName}{' '}
                            {creator.patronymic}{' '}
                          </>
                        ) : (
                          <>&ndash; </>
                        )}
                        {creator.phoneNumber ? (
                          <span style={{ whiteSpace: 'nowrap' }}>
                            ({' '}
                            <span style={{ color: '#3B33B0' }}>
                              {creator.phoneNumber}
                            </span>{' '}
                            )
                          </span>
                        ) : (
                          <>( &ndash; )</>
                        )}
                      </>
                    ) : (
                      <>&ndash;</>
                    )}
                  </Text>
                </Box>
              )}
            </>
          )}
        </Box>

        {activePollResults.isPollResultsAvailable(poll) && (
          <PollResultProgress
            percentage={activePollResults.getProjectVotesPercentage(project.id)}
            votes={activePollResults.getProjectVotesCount(project.id)}
            pad="small"
            margin={{ bottom: !isTabletOrWider && '10px' }}
          />
        )}

        {project.status === PROJECT_STATUS.DONE && (
          <ImplementationDetails
            pad={{ vertical: 'small' }}
            margin={{ horizontal: 'small', bottom: '10px' }}
            project={project}
          />
        )}

        <CardFooter
          direction={isTabletOrWider ? 'row' : 'column'}
          justify="between"
          align="center"
        >
          <Button
            transparent
            color="Purple"
            onClick={() => setExpanded(p => !p)}
          >
            <CollapseArrow
              style={{
                transform: expanded && 'rotate(180deg)',
                marginRight: '8px'
              }}
            />{' '}
            {expanded ? 'Свернуть' : 'Развернуть'}
          </Button>

          {!activePoll.poll.techWindowStarted && (
            <ProjectActionsPanel project={project} />
          )}

          {project.score &&
            (project.score.currentUser || !isVoter ? (
              <Box>
                <Box direction="row" flex="grow">
                  <Box width="85px" align="center">
                    <StarRating value={project.score?.average || 0} readonly />
                  </Box>

                  <Text
                    margin={{ left: 'xsmall', bottom: 'xxsmall' }}
                    size="xsmall"
                    color="Grey"
                  >
                    {project.score?.average || 0} (
                    {project.score?.totalCount || 0})
                  </Text>
                </Box>

                {project.score.currentUser && (
                  <Text
                    margin={{ left: 'xsmall' }}
                    size="xsmall"
                    color="Grey"
                    style={{ textAlign: 'end' }}
                  >
                    Ваша оценка: {project.score?.currentUser || 0}
                  </Text>
                )}
              </Box>
            ) : (
              isVoter && (
                <Button onClick={rateRework}>Оценить реализацию проекта</Button>
              )
            ))}
        </CardFooter>
      </CardContainer>
    );
  })
);

const CardContainer = styled(Box)`
  box-shadow: 0 4px 8px rgba(10, 6, 6, 0.04);
  border-radius: 1px;
  width: 100%;
`;

const CardFooter = styled(Box)`
  min-height: 66px;
  background: #fafafa;
  width: 100%;
  padding: 9px 16px;
`;

const CardImage = styled.img`
  border-radius: 4px;
  width: 100%;
  height: auto;

  object-fit: cover;

  @media (min-width: ${breakpoints.tablet}) {
    width: 130px;
    height: 130px;
  }
`;
