import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Box } from 'grommet';

import SortableTree from 'react-sortable-tree';
import { filteredTree, generateSchoolTree, INode, NODE_TYPE } from './helpers';
import { Spinner2 } from 'ui/Spinner2';
import { NodeRenderer } from './NodeRenderer';
import { FileThemeTreeNodeRenderer } from './TreeTheme';
import { SearchInput } from 'ui/SearchInput';
import { useStores } from 'stores/index';
import { ISelectOption, Select } from '@we-ui-components/base';
import { ISchool } from 'interfaces/apiEntities';
import { ArrowDownIcon, ArrowUpIcon } from '../../Poll/icons';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'ui/Responsive';
import styled from 'styled-components';
import { SumVoteCount } from '../ListItem';
import { position } from 'polished';

export const SchoolTree = observer(
  (params: { customItem: any; loading: boolean }) => {
    // const theme = useTheme();
    const { votings } = useStores();
    //
    // const isRegulator = user.isContainOneOfRoles(['REGULATOR']);
    // const isVoter = user.isContainOneOfRoles(['VOTER']);
    const isMobile = useMediaQuery({
      maxDeviceWidth: breakpoints.mobile
    });

    const isTablet = useMediaQuery({
      maxDeviceWidth: breakpoints.tabletM
    });

    const [treeData, setTreeData] = useState();
    const [schools, setSchools] = useState();
    const [loading, setLoading] = useState(true);

    const [groupFilter, setGroupFilter] = useState<number | null>(null);
    const [districtsFilter, setDistrictsFilter] = useState<number | null>(null);

    const [districtsOptions, setDistrictsOptions] = useState<ISelectOption[]>(
      []
    );
    const [schoolGroupOptions, setSchoolGroupOptions] = useState<
      ISelectOption[]
    >([]);

    const [searchString, setSearchString] = useState('');
    const [searchFocusIndex, setSearchFocusIndex] = useState(0);
    const [searchFoundCount, setSearchFoundCount] = useState(0);

    useEffect(() => {
      if (!params.loading) {
        votings.getSchools().then(res => {
          setLoading(false);

          const schools = res.content.filter((s: ISchool) =>
            votings.pollingList.find(sg => Number(sg.id) === s.schoolGroupId)
          );

          setTreeData(generateSchoolTree(schools, votings.pollingList));

          setSchools(schools);

          setDistrictsOptions(
            [{ text: 'Все районы', value: null }].concat(
              _.uniqBy(schools, (s: ISchool) => s.districtId).map(s => ({
                text: s.districtName,
                value: s.districtId
              }))
            )
          );

          setSchoolGroupOptions(
            [{ text: 'Все школьные группы', value: null }].concat(
              _.uniqBy(schools, (s: ISchool) => s.schoolGroupId).map(s => ({
                text: String(s.schoolGroupName),
                value: s.schoolGroupId
              }))
            )
          );
        });
      }
    }, [params.loading, votings.pollingList]);

    if (loading || params.loading) {
      return (
        <Box align="center" justify="center" height="medium">
          <Spinner2 />
        </Box>
      );
    }

    const setTreeDataCommon = params => {
      const {
        searchStringP = searchString,
        districtsFilterP = districtsFilter,
        groupFilterP = groupFilter
      } = params;

      setTreeData(
        filteredTree(
          schools,
          school => {
            const hasDistrict =
              !districtsFilterP || school.districtId === districtsFilterP;

            const hasGroup =
              !groupFilterP || school.schoolGroupId === groupFilterP;

            const hasSchool =
              !searchStringP ||
              school.name.toLowerCase().indexOf(searchStringP.toLowerCase()) >
                -1;

            return hasDistrict && hasGroup && hasSchool;
          },
          votings.pollingList
        )
      );
    };

    // const treeNodeRenderer = FileExplorerTheme.treeNodeRenderer;

    // Case insensitive search of `node.title`
    const customSearchMethod = ({ node, searchQuery }) =>
      searchQuery &&
      node.type === NODE_TYPE.SCHOOL &&
      node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

    const selectPrevMatch = () =>
      setSearchFocusIndex(
        searchFocusIndex !== null
          ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
          : searchFoundCount - 1
      );

    const selectNextMatch = () =>
      setSearchFocusIndex(
        searchFocusIndex !== null
          ? (searchFocusIndex + 1) % searchFoundCount
          : 0
      );

    const navigationArrow = () => {
      return (
        <Box
          direction="row"
          margin={{ horizontal: 'medium' }}
          gap="10px"
          align="center"
        >
          <Box
            style={{
              border: '1px solid #3B33B020',
              borderRadius: 5,
              cursor: 'pointer',
              opacity: !searchFoundCount ? 0.4 : 1
            }}
            pad={{ vertical: '11px', horizontal: '16px' }}
            onClick={selectPrevMatch}
            align="center"
            justify="center"
          >
            <ArrowUpIcon />
          </Box>

          <Box
            style={{
              border: '1px solid #3B33B020',
              borderRadius: 5,
              cursor: 'pointer',
              opacity: !searchFoundCount ? 0.4 : 1
            }}
            pad={{ vertical: '11px', horizontal: '16px' }}
            align="center"
            justify="center"
            onClick={selectNextMatch}
          >
            <ArrowDownIcon />
          </Box>

          <span>
            &nbsp;
            {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
            &nbsp;/&nbsp;
            {searchFoundCount || 0}
          </span>
        </Box>
      );
    };

    return (
      <Box fill={true}>
        <div style={{ height: 56, marginTop: isMobile ? '-24px' : 0 }}>
          <Box
            direction="row"
            align="center"
            pad={{ right: 'small' }}
            margin={{ right: 'small' }}
          >
            <SearchInputStyle
              style={{ width: '100%' }}
              borderBottom="none"
              borderRadius="2px"
              onChange={value => {
                setTreeDataCommon({ searchStringP: value });

                setSearchString(value);
              }}
              value={searchString}
              customPlaceholder={false}
              placeholder="Найти школу в группе"
            />

            {navigationArrow()}
          </Box>
        </div>

        <div style={{ height: 'auto', margin: '12px 0' }}>
          <Box
            direction={isMobile ? 'column' : 'row'}
            align="center"
            justify="between"
            fill={true}
            gap="10px"
          >
            <div style={{ width: isMobile ? '100%' : '48%' }}>
              <Select
                size="full"
                value={districtsFilter}
                options={districtsOptions}
                onChange={value => {
                  setDistrictsFilter(value);
                  setTreeDataCommon({ districtsFilterP: value });
                }}
              />
            </div>
            <div style={{ width: isMobile ? '100%' : '48%' }}>
              <Select
                size="full"
                value={groupFilter}
                options={schoolGroupOptions}
                onChange={value => {
                  setGroupFilter(value);
                  setTreeDataCommon({ groupFilterP: value });
                }}
              />
            </div>
          </Box>
        </div>

        <div style={{ height: '100%' }}>
          <SortableTree
            treeData={treeData}
            onChange={setTreeData}
            isVirtualized={true}
            theme={{
              treeNodeRenderer: FileThemeTreeNodeRenderer,
              scaffoldBlockPxWidth: 45,
              locals: {
                node: 'rstcustom__node',
                nodeContent: 'rstcustom__nodeContent',
                validDrop: 'rstcustom__validDrop',
                invalidDrop: 'rstcustom__invalidDrop',
                highlight: 'rstcustom__highlight'
              }
            }}
            getNodeKey={({ node }: { node: INode }) => `${node.type}-${node.schollGroupId}-${node.id}`}
            rowHeight={({
              node,
              prevPath
            }: {
              node: INode;
              prevPath: string[];
            }) => {
              return isTablet && node.type === NODE_TYPE.SCHOOL_GROUP
                ? 100
                : 62;
            }}
            canDrag={false}
            nodeContentRenderer={props => (
              <NodeRenderer {...props} customItem={params.customItem} />
            )}
            style={{ outline: 'none' }}
            innerStyle={{ outline: 'none' }}
            searchMethod={customSearchMethod}
            //
            // The query string used in the search. This is required for searching.
            searchQuery={searchString}
            onlyExpandSearchedNodes={true}
            //
            // When matches are found, this property lets you highlight a specific
            // match and scroll to it. This is optional.
            searchFocusOffset={searchFocusIndex}
            //
            // This callback returns the matches from the search,
            // including their `node`s, `treeIndex`es, and `path`s
            // Here I just use it to note how many matches were found.
            // This is optional, but without it, the only thing searches
            // do natively is outline the matching nodes.
            searchFinishCallback={matches => {
              // console.log(matches);
              // console.log(treeData);

              setSearchFoundCount(matches.length);

              setSearchFocusIndex(
                matches.length > 0 ? searchFocusIndex % matches.length : 0
              );

              // setTimeout(selectNextMatch, 1000);
            }}
          />
        </div>
        <SumVoteCount
          count={treeData?.reduce(
            (sum, item) => sum + item.polling.voteCount,
            0
          )}
          padding='16px 10px'
        />
      </Box>
    );
  }
);

const SearchInputStyle = styled(SearchInput)`
  @media (max-width: ${breakpoints.mobileL}) {
    & ~ label {
      font-size: 12px;
    }
  }
`;
