import * as React from 'react';

export function DoNotDisturb(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 16"
      {...props}
    >
      <path
        d="M12.719 11.281c.75-.937 1.125-2.031 1.125-3.281 0-1.458-.531-2.708-1.594-3.75-1.042-1.063-2.292-1.594-3.75-1.594-1.25 0-2.344.375-3.281 1.125l7.5 7.5zM8.5 13.344c1.25 0 2.344-.375 3.281-1.125l-7.5-7.5C3.531 5.656 3.156 6.75 3.156 8c0 1.458.521 2.719 1.563 3.781 1.062 1.042 2.323 1.563 3.781 1.563zM3.781 3.313C5.094 2 6.667 1.343 8.5 1.343c1.833 0 3.396.657 4.688 1.97C14.5 4.604 15.155 6.167 15.155 8c0 1.833-.656 3.406-1.969 4.719-1.291 1.291-2.854 1.937-4.687 1.937-1.833 0-3.406-.646-4.719-1.937C2.49 11.406 1.844 9.833 1.844 8c0-1.833.646-3.396 1.937-4.688z"
        fill={props.fill || '#fff'}
      />
    </svg>
  );
}
